import { useState, useEffect } from 'react';
import { Avatar, Button, Menu, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import { AuthService } from '@nask/auth-service';
import { Loader } from '@nask/ui';
import {
  ProfilB042Icon,
  Ustawienia043Icon,
  Wyloguj047Icon,
} from '@nask/ezdrp-icons';
import {
  ExitToApp as Logout,
  KeyboardArrowDown,
  Person,
  Settings,
} from '@material-ui/icons';
import { useApi, parseJSON, stringifyJSON } from '@nask/hooks';
import { getErrorResponse } from '@archiwum/utility/helper';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { UserServiceQuery } from './services/user-service-query';
import { Typography } from '@archiwum/ui/typography';
import { theme } from '@nask/theme';
import { useGetSettings } from '@archiwum/shared/settings';
import { GetUserResponse } from './models/models';

type UserProps = {};

export const User = (props: UserProps) => {
  // const history = useHistory();
  const [showSnackbar] = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<GetUserResponse>(null);
  const { setUrl } = useApi<GetUserResponse>({
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });
  useEffect(() => {
    setUrl(
      UserServiceQuery.GetUser({
        idStanowiskoProponowane:
          parseJSON<{ uid: string; sid: string }>(
            localStorage.getItem('userSetting')
          )?.sid ?? null,
      })
    );
  }, []);

  const { clear } = useGetSettings();

  const doLogout = () => {
    localStorage.removeItem('sid');
    localStorage.removeItem('userSetting');
    const authService = AuthService.getInstance();
    authService.logout();
    clear();
  };

  if (!user) {
    return (
      <LoaderContainer>
        <StyledLoader show />
      </LoaderContainer>
    );
  }

  return (
    <div>
      <Button
        id={'customized-menu'}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-controls="customized-menu"
        startIcon={
          <Avatar
            style={{ width: 44, height: 44 }}
            src={user.awatar}
            children={
              user.awatar ? undefined : `${user.imie[0]}${user.nazwisko[0]}`
            }
          />
        }
        endIcon={<KeyboardArrowDown />}
      >
        <UserDetails>
          <Typography>{`${user.imie} ${user.nazwisko}`}</Typography>
          <Typography>{user.stanowisko}</Typography>
          {/*<Tooltip*/}
          {/*  title={`${user.jednostka} ${*/}
          {/*    user.jednostkaSymbol ? `(${user.jednostkaSymbol})` : ''*/}
          {/*  }`}*/}
          {/*>*/}
          {/*  <Typography*/}
          {/*    style={{*/}
          {/*      fontSize: '0.9rem',*/}
          {/*      textAlign: 'start',*/}
          {/*      overflow: 'hidden',*/}
          {/*      textOverflow: 'ellipsis',*/}
          {/*      whiteSpace: 'nowrap',*/}
          {/*    }}*/}
          {/*  >{`${user.jednostka} ${*/}
          {/*    user.jednostkaSymbol ? `(${user.jednostkaSymbol})` : ''*/}
          {/*  }`}</Typography>*/}
          {/*</Tooltip>*/}
        </UserDetails>
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem
          onClick={() => {
            // history.push(Routes.UserProfile.path);
            setAnchorEl(null);
          }}
        >
          <EzdrpIcon as={ProfilB042Icon} />
          Profil
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            // history.push(Routes.UserSettings.path);
            setAnchorEl(null);
          }}
        >
          <EzdrpIcon as={Ustawienia043Icon} />
          Ustawienia
        </StyledMenuItem>
        <StyledMenuItem onClick={doLogout}>
          <EzdrpIcon as={Wyloguj047Icon} /> Wyloguj
        </StyledMenuItem>
      </Menu>
    </div>
  );
};

const EzdrpIcon = styled.svg`
  color: ${(props) => {
    return props.theme.colors.primary;
  }};
`;

const LoaderContainer = styled.div`
  width: 100px;
  margin-top: 15px;
`;

const UserDetails = styled.div`
  align-content: center;
  align-items: center;
  grid-gap: 0 0.5rem;
  display: grid;
  max-width: 25rem;
  text-align: start;
  text-transform: none;
`;

const StyledMenuItem = styled(MenuItem)`
  &&& {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    font-size: ${theme.typography.paragraphFontSize};
  }
`;

const StyledLoader = styled(Loader)`
  display: grid;
  grid-auto-flow: column;
  & > div {
    width: 15px;
    height: 15px;
  }
`;
