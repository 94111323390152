import { useSnackbar } from '@archiwum/shared/toast-notification';
import { useDrawerRightSelector } from '@archiwum/drawer-right';
import {
  OdbiorcaZadania,
  PoczekalniaModel,
  StanowiskoModel,
  TypObiekt,
} from '../../../models/models';
import { parseJSON, SelectModel, stringifyJSON, useApi } from '@nask/hooks';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { actionsService$ } from '@archiwum/actions-handler';
import { getErrorResponse } from '@archiwum/utility/helper';
import * as yup from 'yup';
import { ArchivingDocumentsServiceCommand } from '../../../services/archiving-documents-service-command';
import { ArchivingDocumentsServiceQuery } from '../../../services/archiving-documents-service-query';

export type PrzekazDoPoprawyFormModel = {
  selected: string;
  odbiorca: string;
  komentarz?: string;
  inne?: string;
};

export type UsePrzekazDoPoprawyProps = {
  typObiekt: TypObiekt;
  doProwadzacego?: boolean;
};

export const usePrzekazDoPoprawyFacade = (props: UsePrzekazDoPoprawyProps) => {
  const { typObiekt, doProwadzacego } = props;
  const { data } = useApi({
    service: doProwadzacego
      ? undefined
      : ArchivingDocumentsServiceQuery.GetStanowiska(),
  });
  const { data: bledy } = useApi({
    service: ArchivingDocumentsServiceQuery.GetTypyElementowDoPoprawy(),
  });

  const getStanowiskoLabel = (stanowisko: StanowiskoModel) =>
    `${stanowisko.imie} ${stanowisko.nazwisko} - ${stanowisko.nazwa}`;

  const stanowiskaOptions =
    data?.items
      ?.map((stanowisko) => ({
        label: getStanowiskoLabel(stanowisko),
        value: stringifyJSON(stanowisko),
      }))
      ?.sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) ?? [];

  const [showSnackbar] = useSnackbar();
  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as PoczekalniaModel[];
  const { isLoading, setUrl } = useApi({
    onSuccess: () => {
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });
  const initialValues: PrzekazDoPoprawyFormModel = {
    selected: stringifyJSON(selected),
    odbiorca: '',
    komentarz: undefined,
    inne: undefined,
  };
  const validationSchema: yup.SchemaOf<PrzekazDoPoprawyFormModel> = yup
    .object()
    .shape({
      selected: yup.string().required('Wybierz dokument'),
      odbiorca: doProwadzacego
        ? yup.string().nullable()
        : yup.string().required('Wybierz odbiorcę'),
      komentarz: yup.string(),
      inne: yup
        .string()
        .when('komentarz', (komentarz) =>
          komentarz?.indexOf('inne') >= 0
            ? yup.string().required('Zaznaczono "inne" - pole obowiązkowe')
            : yup.string().nullable()
        ),
    });

  const onSubmit = (values: PrzekazDoPoprawyFormModel) => {
    const selected = parseJSON<PoczekalniaModel[]>(values?.selected);
    const odbiorcaZadania = parseJSON<StanowiskoModel>(
      parseJSON<SelectModel>(values.odbiorca)?.value
    )?.idStanowisko;
    const listaPartialSprawa = parseJSON<PoczekalniaModel[]>(
      values.selected
    ).map((x) => ({ idSprawa: x.id, idProwadzacy: x.pracownikProwadzacy.id }));
    const listaOdbiorcaZadania: OdbiorcaZadania[] = listaPartialSprawa?.map(
      (partialSprawa) => ({
        idOdbiorcy: doProwadzacego
          ? partialSprawa.idProwadzacy
          : odbiorcaZadania,
        idObiektu: partialSprawa.idSprawa,
      })
    );

    if (typObiekt === TypObiekt.SPRAWY) {
      const request = {
        listaZadan: selected.map((item) => ({
          idSprawa: item.id,
          idStanowiskoOdbiorca: doProwadzacego
            ? item.pracownikProwadzacy.idStanowisko
            : odbiorcaZadania,
        })),
        opis:
          parseJSON<string[]>(values?.komentarz)
            ?.map((item) =>
              item === 'inne'
                ? `inne - ${values?.inne?.replace(',', '')}`
                : item
            )
            ?.join(', ') ?? '',
      };
      setUrl(ArchivingDocumentsServiceCommand.PrzekazDoPoprawySprawa(request));
    } else {
      const request = {
        listaZadan: selected.map((item) => ({
          idPismo: item.id,
          idStanowiskoOdbiorca: doProwadzacego
            ? item.pracownikProwadzacy.idStanowisko
            : odbiorcaZadania,
        })),
        opis:
          parseJSON<string[]>(values?.komentarz)
            ?.map((item) =>
              item === 'inne'
                ? `inne - ${values?.inne?.replace(',', '')}`
                : item
            )
            ?.join(', ') ?? '',
      };
      setUrl(
        ArchivingDocumentsServiceCommand.PrzekazDoPoprawyPismaNAS(request)
      );
    }
  };
  return {
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
    isLoading,
    selected,
    stanowiskaOptions,
    bledy: bledy ? [...bledy, 'inne'] : [],
  };
};
