import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import {
  FormValues,
  useZamawianieRaportowFacade,
} from '../use-zamawianie-raportow-facade';
import { ZamawianieReportowForm } from '../components/zamawianie-raportow-form';

export const ZamawianieRaportow = () => {
  const { formikProps, ...restProps } = useZamawianieRaportowFacade();

  return (
    <Box p={3}>
      <Formik<FormValues> {...formikProps}>
        <ZamawianieReportowForm {...restProps} />
      </Formik>
    </Box>
  );
};
