import { PoczekalniaModel } from '@archiwum/features/archiving-documents';
import { Button } from '@archiwum/ui/button';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import {
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Grid,
  Box,
  Divider,
} from '@material-ui/core';
import { useState } from 'react';
import { useStyles } from '../poczekalnia.styles';
import { NotatkaWlasna067Icon } from '@nask/ezdrp-icons';

export type KomentarzProps = {
  data: PoczekalniaModel;
  autoHide?: boolean;
};

export const Komentarz = ({ data, autoHide = true }: KomentarzProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const handleClose = () => {
    setModalVisible(false);
  };
  const classes = useStyles();
  if (autoHide && (!data.komentarz || data.komentarz?.length === 0))
    return null;
  return (
    <>
      <Tooltip title={data.komentarz ?? ''}>
        <IconButton
          onClick={() => {
            setModalVisible(true);
          }}
        >
          <NotatkaWlasna067Icon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Paper>
            <Box minWidth="32rem" width="60vw" p={2}>
              <Grid direction="column" container>
                <Grid item>
                  <Typography
                    id="simple-modal-title"
                    variantType={TypographyVariants.Header4}
                  >
                    {`Komentarz do ${
                      data.znak?.length > 0
                        ? 'sprawy ' + data.znak
                        : 'pisma ' + data.numer
                    }`}
                  </Typography>
                </Grid>
                <Divider />
                <Box my={2}>
                  <Grid item>
                    <Typography
                      id="simple-modal-description"
                      variantType={TypographyVariants.Paragraph}
                    >
                      {data.komentarz}
                    </Typography>
                  </Grid>
                </Box>
                <Grid item container justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      setModalVisible(false);
                    }}
                  >
                    Zamknij
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
