import React, { ReactNode } from 'react';
import { Box, Divider, Paper } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { useStyles } from './archiving-layout.styles';

type ArchivingLayoutProps = {
  children?: ReactNode;
  title?: string;
};

export const ArchivingLayout = ({ children, title }: ArchivingLayoutProps) => {
  const classes = useStyles();
  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            {title ?? 'Archiwizacja dokumentacji'}
          </Typography>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex" height="100%" justifyContent="space-between">
        {children}
      </Box>
    </div>
  );
};
