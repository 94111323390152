import React, { forwardRef } from 'react';
import { Container, ContainerProps } from '@material-ui/core';
import { useStyles } from './action-container.styles';

type ActionContainerProps = ContainerProps;

export const ActionContainer = forwardRef<HTMLDivElement, ActionContainerProps>(
  (props, ref) => {
    const classes = useStyles();
    return <Container classes={{ root: classes.root }} ref={ref} {...props} />;
  }
);
