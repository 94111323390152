import React from 'react';
import { IconButton } from '@nask/ui';
import {
  Brightness4Rounded as DarkThemeIcon,
  Brightness7Rounded as LightThemeIcon,
} from '@material-ui/icons';
import { useTheme } from '@nask/theme';
import styled from 'styled-components';

type ThemeToggleProps = {};

export const ThemeToggle = (props: ThemeToggleProps) => {
  const [theme, setTheme] = useTheme();
  const handleChangeTheme = () => {
    setTheme((prevState) => (prevState === 'light' ? 'dark' : 'light'));
  };
  const isDark = theme === 'dark';
  return (
    <StyledIcon onClick={handleChangeTheme}>
      {isDark ? (
        <LightThemeIcon fontSize="large" />
      ) : (
        <DarkThemeIcon fontSize="large" />
      )}
    </StyledIcon>
  );
};

const StyledIcon = styled(IconButton)`
  justify-self: center;
  align-self: center;
`;
