import { BehaviorSubject } from 'rxjs';
import { ActionStore$ } from './models';

export const actionsStore$ = new BehaviorSubject<ActionStore$<unknown>>({
  type: undefined,
  payload: undefined,
  open: false,
  renderType: undefined,
  component: undefined,
});

export const actionsService$ = {
  openCommand: ({ command, data, component }) =>
    actionsStore$.next({
      ...actionsStore$.value,
      type: 'command',
      payload: { command, data },
      open: true,
      renderType: 'drawer',
      component,
    }),
  close: () =>
    actionsStore$.next({
      ...actionsStore$.value,
      component: undefined,
      open: false,
      type: undefined,
      payload: { data: undefined, command: undefined },
    }),
  refresh: (data?) =>
    actionsStore$.next({
      ...actionsStore$.value,
      open: false,
      type: 'refresh',
      payload: data,
    }),
};
