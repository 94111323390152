import {
  Checkbox,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import MToolbar, {
  ToolbarProps as MToolbarProps,
} from '@material-ui/core/Toolbar';
import { MaterialTableProps } from 'material-table';
import React, { useMemo } from 'react';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import SearchToolbarInput from './search-toolbar-input';
import { ColumnDto } from './table-model';

interface ToolbarProps<T extends object>
  extends MToolbarProps,
    Pick<MaterialTableProps<T>, 'columns'> {
  onSearchChanged: any;
  searchText: string;
  searchInputId?: string;
  filterColumn?: boolean;
  showedColumns?: Array<keyof T>;
  toolbarComponent?: React.ReactNode;
  hideSearchInput?: boolean;
  minLengthToSearch?: number;
  columnsInfo?: ColumnDto[];
}

const Toolbar = <T extends object>(props: ToolbarProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const renderFilter = useMemo(
    () => (
      <FilterMenu<T>
        showedColumns={props.showedColumns}
        columns={props.columns}
        columnsInfo={props.columnsInfo}
        anchorEl={anchorEl}
        onClose={() => {
          console.log('CLOSE');
          setAnchorEl(null);
        }}
      />
    ),
    [props.showedColumns, props.columns, anchorEl, props.columnsInfo]
  );
  return (
    <StyledToolbar>
      <div style={{ float: 'right', margin: '1rem' }}>
        {!props.hideSearchInput && (
          <SearchToolbarInput
            searchText={props.searchText}
            searchMethod={props.onSearchChanged}
            minLengthToSearch={props.minLengthToSearch}
            searchInputId={props.searchInputId}
          />
        )}
        {props.filterColumn && (
          <Tooltip title={'Pokaż kolumny'}>
            <IconButton
              color="primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {/*<ViewColumn />*/}
              <Icon className={'fas fa-columns'} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {props.toolbarComponent && (
        <ToolbarComponentWrapper>
          {props.toolbarComponent}
        </ToolbarComponentWrapper>
      )}
      {renderFilter}
    </StyledToolbar>
  );
};

export default Toolbar;

interface FilterMenuProps<T extends object> {
  anchorEl: any;
  onClose: () => void;
  columns?: any[];
  showedColumns?: Array<keyof T>;
  columnsInfo?: ColumnDto[];
}

const FilterMenu = <T extends object>(props: FilterMenuProps<T>) => {
  const renderMenuItems = useMemo(() => {
    return props.columns
      .filter((x) => !x.hidden)
      .map((col) => (
        <MenuItem key={col.title}>
          <Checkbox
            id={col.title}
            checked={props.showedColumns.includes(col.field)}
            value={col.field}
            onChange={(event, isChecked) => {
              filterSubjectService.changeShowedColumns(props.showedColumns, {
                field: col.field,
                checked: isChecked,
              });
              console.log(col.field, isChecked);
            }}
          />
          {props.columnsInfo?.find(
            (x) =>
              x.name.toLowerCase() === col.field.toLowerCase() && x.isSearchable
          ) && (
            <Icon
              className={'fas fa-search'}
              style={{ marginLeft: '1.2rem' }}
            />
          )}
        </MenuItem>
      ));
  }, [props.columns, props.showedColumns]);
  return useMemo(
    () => (
      <Menu
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        keepMounted
        onClose={props.onClose}
      >
        {renderMenuItems}
      </Menu>
    ),
    [props]
  );
};

export const filterSubject = new Subject<any[]>();
const filterSubjectService = {
  changeShowedColumns: (
    columns: Array<keyof any | string>,
    val: { field: string; checked: boolean }
  ) => {
    if (val.checked) {
      columns.push(val.field);
    } else {
      columns = columns.filter((x) => x !== val.field);
    }
    filterSubject.next(columns);
  },
};

const StyledToolbar = styled(({ children, ...props }) => (
  <MToolbar {...props}>
    <>{children}</>
  </MToolbar>
))`
  display: flow-root;
  //grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  //grid-auto-flow: column dense;
  //grid-gap: 1rem;
  //justify-content: flex-end;
  margin-top: 2rem;
`;

const ToolbarComponentWrapper = styled.div`
  &&& > * {
    float: left;
    margin: 1rem;
  }
`;
