import { createContext } from 'react';

import { OptionsObject } from '../interface';

/**
 * Throw error when SnackbarContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    'Attempted to call useSnackbar outside of snackbar context. Make sure your app is rendered inside SnackbarProvider.'
  );
};

/**
 * The shape of the snackbar context
 */

export interface SnackbarContextType {
  showSnackbar(message: string | string[], options: OptionsObject): void;
  // hideSnackbar(key: string): void;
}

/**
 * Snackbar Context Object
 */
export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: invariantViolation,
});
