import React, { useEffect, useState } from 'react';
import { Divider, Drawer, List } from '@material-ui/core';
import clsx from 'clsx';
import {
  useBehaviorSubjectDispatch,
  useBehaviorSubjectSelector,
} from '@czaplej/use-behavior-subject';
import { BehaviorSubject } from 'rxjs';
import { useApi } from '@nask/hooks';
import { MenuServiceQuery } from './services/sidebar-service-query';
import { useStyles } from './sidebar.styles';
import { SidebarLink } from './sidebar-link';
import styled from 'styled-components';
import { theme } from '@nask/theme';
import { countersSubject$, LicznikModel } from '@archiwum/counters';

type LayoutStore = {
  drawerOpen?: boolean;
};
const layoutStore$ = new BehaviorSubject<LayoutStore>({ drawerOpen: true });
export const useLayoutSelector = <TState extends LayoutStore, TSelected>(
  selector: (state: TState) => TSelected
) => useBehaviorSubjectSelector(selector, layoutStore$);
export const useLayoutDispatch = () => useBehaviorSubjectDispatch(layoutStore$);

type SidebarProps = {
  logo: string;
};

export const Sidebar = (props: SidebarProps) => {
  const { logo } = props;
  const classes = useStyles();
  const open = useLayoutSelector((state) => state.drawerOpen);
  const dispatch = useLayoutDispatch();
  const { data: menu } = useApi({ service: MenuServiceQuery.GetMenu() });
  const [counters, setCounters] = useState<LicznikModel[]>([]);

  /*global EZDRP_APP_URL*/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const href = EZDRP_APP_URL;

  const handleDrawerClose = () => {
    dispatch((prevState) => ({
      ...prevState,
      drawerOpen: !prevState.drawerOpen,
    }));
  };
  useEffect(() => {
    const subscription = countersSubject$.subscribe((value) => {
      setCounters(value);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className={classes.sidebarContainer}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        PaperProps={{ elevation: 3 }}
      >
        <StyledImageContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = href;
          }}
        >
          <StyledImage src={logo} alt="logo" />
        </StyledImageContainer>

        <List>
          {menu?.map((menuItem) => (
            <SidebarLink key={menuItem.nazwa} route={menuItem} />
          ))}
        </List>
      </Drawer>
    </div>
  );
};

const StyledImage = styled.img`
  height: ${theme.topbar.height};
`;

const StyledImageContainer = styled.div`
  height: ${theme.topbar.height};
  &:hover {
    cursor: pointer;
  }
`;
