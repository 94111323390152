import { Column, QueryResult } from 'material-table';
import { Dispatch, SetStateAction } from 'react';
import { format, parseISO } from 'date-fns';

import { FilteringModel, PropType, SortingModel } from '../table-model';
import { TableProps } from '../wtable';
import { UseTableFacadeProps } from './use-table-facade';
export type SelectModel = {
  value: string;
  label: string;
  key?: string;
};

export const sneakyMapColumn = <T extends object>(
  showAcceptedFields: Array<Column<T>>
): Array<Column<T>> => {
  return showAcceptedFields.map((col: Column<T>) => {
    return {
      render: (data) => {
        switch (col.type) {
          case 'date':
            return getShortDate(data[col.field + '']);
          case 'datetime':
            return getDefaultDate(data[col.field + '']);
          default:
            return data[col.field + ''];
        }
      },
      ...col,
    } as Column<T>;
  });
};

export const updateDataProp = <T extends object>(
  data: PropType<UseTableFacadeProps<T>, 'data'>,
  subject,
  subjectService,
  setError: Dispatch<SetStateAction<boolean>>,
  selectedRows: PropType<UseTableFacadeProps<T>, 'selectedRows'>
): PropType<TableProps<T>, 'data'> => {
  return Array.isArray(data)
    ? data
    : (query) => {
        // console.log('QUERY ', query);
        const filtry: FilteringModel[] = query.filters.map((x) => {
          return {
            propertyColumn: x.column.field,
            propertyFilterType: 'ILIKE',
            propertyValue: x.value,
            propertyValueType: 'string',
          } as FilteringModel;
        });
        const sort: SortingModel[] = [];

        if (query.orderBy) {
          sort.push({
            propertyColumn: '' + query.orderBy.field,
            propertySortType: query.orderDirection,
          });
        }
        const searchBy = query.search;
        if (!Array.isArray(data))
          data({ ...query, sort, filtry, searchBy }, subjectService);
        return new Promise<QueryResult<T>>((resolve, reject) => {
          subject?.subscribe({
            next: (value) => {
              const tmp = value;
              tmp.data = value.data.map((element, index) => {
                if (selectedRows != null && selectedRows.length > 0) {
                  const selected =
                    selectedRows.find((x) => x.id === element.id) != null;
                  return selected
                    ? {
                        ...element,
                        tableData: { id: index, checked: selected },
                      }
                    : element;
                }
                return element;
              });
              setError(false);
              resolve(tmp);
            },
            error: (err) => {
              setError(true);
              resolve(err);
            },
          });
        });
      };
};

export const updateOptions = <T extends object>(
  options: PropType<TableProps<T>, 'options'>,
  isLoading?: boolean,
  pageSize?: string,
  pageSizeOptions?: SelectModel[]
): PropType<TableProps<T>, 'options'> => {
  if (isLoading) {
    return options;
  }
  return {
    ...options,
    pageSize: +pageSize || 10,
    pageSizeOptions: pageSizeOptions?.map((x) => +x.label),
  };
};

export const getShortDate = (value: string) => {
  if (value == null) {
    return '';
  }
  const date = parseISO(value);
  return format(date, 'yyyy-MM-dd');
};

export const getFloorDate = (date: Date | null) => {
  if (date == null) {
    return null;
  }
  date.setHours(0, 0, 0, 0);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const getCeilingDate = (date: Date | null) => {
  if (date == null) {
    return null;
  }
  date.setHours(23, 59, 59, 999);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const getMinDateOfTheYear = (year: number) => {
  if (year == null) {
    return null;
  }
  return new Date(year, 0, 1, 0, 0, 0, 0);
};

export const getMaxDateOfTheYear = (year: number) => {
  if (year == null) {
    return null;
  }
  return new Date(year, 11, 31, 23, 59, 59, 999);
};

export const getFullDate = (value: string) => {
  if (value == null) {
    return '';
  }
  const date = parseISO(value);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};
export const getDefaultDate = (value: string) => {
  if (value == null) {
    return '';
  }
  const date = parseISO(value);
  return format(date, 'yyyy-MM-dd HH:mm');
};
