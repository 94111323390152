import React from 'react';
import { GlobalLoader } from '@nask/ui';
import { useGlobalLoaderSelector } from '@nask/layout';

export const GlobLoader = () => {
  const isLoading = useGlobalLoaderSelector((state) => state.length > 0);
  return (
    <div css={'z-index: 1000; position: fixed'}>
      <GlobalLoader fullscreen show={isLoading} />
    </div>
  );
};
