import '../styles/app.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter,
  Route,
  Switch,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { authRoutes, SignInCallback, SilentRenew } from '@nask/auth';
import { AppLayout, InitialConfiguration } from '@nask/layout';
import { theme, ThemeProvider } from '@nask/theme';
import { GlobalStyle } from './global-styles';
import { RouteProps } from 'react-router';
import { Sidebar, useLayoutSelector } from '@archiwum/sidebar';
import logo from '../assets/logo.png';
import {
  createMuiTheme,
  createStyles,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import { TopBar } from '@archiwum/top-bar';
import { Actions } from '@archiwum/actions';
import { SnackbarProvider } from '@archiwum/shared/toast-notification';
import { Case, SpisNAS, SpisSpraw } from '@archiwum/features/case';
import { getRouteById, Routes } from '@archiwum/routes';
import {
  Zarchiwizowane,
  GotoweDoArchiwizacji,
  Poczekalnia,
} from '@archiwum/features/archiving-documents';
import { CssBaseline } from '@material-ui/core';
import {
  ZadaniaOtrzymane,
  ZadaniaZrealizowane,
  ZadaniaZlecone,
} from '@archiwum/features/task';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ArchivingPackage } from '@archiwum/features/archiving-package';
import { Brakowanie } from '@archiwum/features/brakowanie';
import { Reports } from '@archiwum/features/reports';
import { InitialConf } from '@archiwum/initial-conf';
import { Home } from '../home/home';
import {
  Spisy,
  Cartons,
  Documents,
} from '@archiwum/features/chronological-compositions';
import { stringifyJSON, parseJSON } from '@nask/hooks';

type RoutesPage = RouteProps;
const routesPages: RoutesPage[] = [
  {
    path: Routes.Home.path,
    component: Home,
    exact: true,
  },
  {
    path: [
      Routes.Poczekalnia.path,
      Routes.Nowe.path,
      Routes.DoPoprawy.path,
      Routes.Poprawione.path,
      Routes.Odlozone.path,
    ],
    exact: true,
    strict: false,
    component: Poczekalnia,
  },
  {
    path: [
      Routes.Zarchiwizowane.path,
      Routes.ZarchiwizowaneSprawy.path,
      Routes.ZarchiwizowaneNAS.path,
    ],
    component: Zarchiwizowane,
    exact: false,
  },
  {
    path: Routes.ZadaniaOtrzymane.path,
    component: ZadaniaOtrzymane,
    exact: true,
  },
  {
    path: Routes.ZadaniaZrealizowane.path,
    component: ZadaniaZrealizowane,
    exact: true,
  },
  {
    path: Routes.ZadaniaZlecone.path,
    component: ZadaniaZlecone,
    exact: true,
  },
  {
    path: Routes.SpisNAS.path,
    component: SpisNAS,
    exact: true,
  },
  {
    path: Routes.SpisSpraw.path,
    component: SpisSpraw,
    exact: true,
  },
  {
    path: [Routes.CaseDetails.path, Routes.NasDetails.path],
    exact: true,
    strict: false,
    component: Case,
  },
  {
    path: [
      Routes.PaczkaArchiwalna.path,
      Routes.PaczkaArchiwalnaGenerowanie.path,
      Routes.PaczkaArchiwalnaLista.path,
    ],
    exact: true,
    strict: false,
    component: ArchivingPackage,
  },
  {
    path: [
      Routes.Brakowanie.path,
      Routes.BrakowanieWytypowaneDoBrakowania.path,
      Routes.BrakowanieSpisy.path,
      Routes.BrakowanieProcesy.path,
    ],
    exact: true,
    strict: false,
    component: Brakowanie,
  },
  {
    path: [Routes.GotoweDoArchiwizacji.path],
    exact: true,
    strict: false,
    component: GotoweDoArchiwizacji,
  },
  {
    path: [
      Routes.Raporty.path,
      Routes.RaportyZamawianieRaportow.path,
      Routes.RaportyListaZamowionychRaportow.path,
    ],
    exact: true,
    strict: false,
    component: Reports,
  },
  {
    path: Routes.SkladChronologicznyDokumenty.path,
    component: Documents,
    exact: true,
  },
  {
    path: Routes.SkladChronologicznyKartony.path,
    component: Cartons,
    exact: true,
  },
  {
    path: Routes.SkladChronologicznyArchiwalnySpisy.path,
    component: Spisy,
    exact: true,
  },
];
const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4a355e',
    },
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontSize: 14,
    body1: { fontSize: theme.typography.descriptionFontSize },
    button: {
      fontSize: '16rem',
    },
  },
  overrides: {
    MuiSnackbarContent: {
      message: {
        fontSize: 14,
      },
    },
    MuiButton: createStyles({
      root: {
        fontSize: '12rem',
      },
    }),
  },
});

export function App() {
  const queryClient = new QueryClient();
  const drawerOpen = useLayoutSelector((state) => state.drawerOpen);

  const location = useLocation();
  const [initPath, setInitPath] = useState<string>(
    localStorage.getItem('initPath') ?? Routes.Home.path
  );
  useEffect(() => {
    // const userSetting = localStorage.getItem('userSetting');
    // const sid =
    //   userSetting && parseJSON<{ uid: string; sid: string }>(userSetting)?.sid;
    // if (sid) {
    //   localStorage.setItem('sid', sid);
    // }
    const isSignInPath =
      matchPath(location.pathname, {
        path: authRoutes.SignInCallback.path,
        exact: true,
      }) !== null;
    const isSilentRenewPath =
      matchPath(location.pathname, {
        path: authRoutes.SilentRenew.path,
        exact: true,
      }) !== null;
    const isIndexPath =
      matchPath(location.pathname, {
        path: '/index.html',
        exact: true,
      }) !== null;
    if (!isSignInPath && !isSilentRenewPath && !isIndexPath) {
      if (location.search.includes('sid')) {
        const sid = new URLSearchParams(location.search).get('sid');
        console.log('SID: ', sid);
        localStorage.setItem('sid', sid);
        localStorage.setItem(
          'userSetting',
          stringifyJSON({ uid: '', sid: sid })
        );
      }
      if (location.search.includes('viewId')) {
        const viewId = new URLSearchParams(location.search).get('viewId');
        localStorage.setItem(
          'initPath',
          getRouteById(viewId, Routes.Home.path)
        );
      } else {
        localStorage.setItem(
          'initPath',
          `${location.pathname}${location.search}`
        );
      }
    }
  }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route
              path={authRoutes.SignInCallback.path}
              render={(props) => (
                <SignInCallback
                  onAuthSuccess={(user) => {
                    props.history.push(initPath);
                  }}
                  onAuthError={(error) => console.error(error)}
                />
              )}
            />
            <Route path={authRoutes.SilentRenew.path} component={SilentRenew} />
            {/*{KCZ => need for handle Switch Error props}*/}
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>
              <CssBaseline />
              <MaterialThemeProvider theme={materialTheme}>
                <ThemeProvider>
                  <Layout>
                    <GlobalStyle />
                    <InitialConfiguration>
                      <InitialConf>
                        <SnackbarProvider
                          autoHideDuration={150000}
                          maxSnacks={5}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          iconVariant={{
                            success: <i className="fa fa-check" />,
                            error: <i className="fa fa-remove" />,
                            warning: <i className="fa fa-warning" />,
                            info: <i className="fa fa-info" />,
                          }}
                        >
                          <>
                            <TopBar />
                            <Sidebar logo={logo} />
                            <Main drawerOpen={drawerOpen}>
                              {routesPages.map((x) => (
                                <Route key={x.path + ''} {...x} />
                              ))}
                            </Main>
                            <Actions />
                          </>
                        </SnackbarProvider>
                      </InitialConf>
                    </InitialConfiguration>
                  </Layout>
                </ThemeProvider>
              </MaterialThemeProvider>
            </>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;

const Layout = styled(AppLayout)`
  display: grid;
  grid-template-areas: 'sidebar topbar' 'sidebar main';
  grid-template-columns: ${theme.sidebar.width} 1fr;
  grid-template-rows: ${theme.topbar.height} 1fr;
`;

const Main = styled.div<{ drawerOpen: boolean }>`
  overflow-y: auto;
  display: grid;
  grid-area: main;
  background-color: ${theme.colors.bodyBg};
  height: calc(100vh - ${theme.topbar.height});
  margin-left: ${(props) =>
    !props.drawerOpen
      ? `calc(${theme.sidebar.widthClosed} - ${theme.sidebar.width})`
      : 0};
  transition: margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;
