import { Badge, Tooltip } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../counters.styles';
import { LicznikValue } from '../models/models';
import clsx from 'clsx';

export const PartialCounter = (data: LicznikValue) => {
  const classes = useStyles();
  const {
    countsContainer,
    counter,
    normal,
    danger,
    warning,
    ...tooltipClasses
  } = classes;

  return (
    <Tooltip title={data?.tooltipText} classes={tooltipClasses}>
      <Badge
        color={'primary'}
        classes={{
          root: clsx(classes.counter, {
            [classes.normal]: data.colorType === 'default',
            [classes.warning]: data.colorType === 'warning',
            [classes.danger]: data.colorType === 'danger',
          }),
        }}
      >
        {data.value}
      </Badge>
    </Tooltip>
  );
};
