import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 300,
      padding: '10px',
    },
  })
);
