import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch, matchPath } from 'react-router-dom';
import { Box, Divider, Paper, Tab } from '@material-ui/core';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Tabs } from '@archiwum/ui/tabs';
import { Routes } from '@archiwum/routes';

import { useStyles } from './archiving-package.styles';
import { useArchivingPackageFacade } from './use-archiving-package-facade';
import { ArchivingPackagesList } from './archiving-packages-list/archiving-packages-list';
import { CreateArchivingPackage } from './create-archiving-package/create-archiving-package';

type SpisSprawLayoutProps = {};

export const ArchivingPackage = (props: SpisSprawLayoutProps) => {
  const { tabs, activeTab, handleChangeTab } = useArchivingPackageFacade();
  const drawerDispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => {
      drawerDispatch({ selected: [], data: [] });
    };
  }, []);

  useEffect(() => {
    if (
      matchPath(history.location.pathname, {
        path: Routes.PaczkaArchiwalna.path,
        exact: true,
      }) !== null
    ) {
      history.replace(Routes.PaczkaArchiwalnaGenerowanie.path);
    }
  }, [history.location.pathname]);

  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            Paczka archiwalna
          </Typography>
          <Tabs
            classes={{ root: classes.tabs, indicator: classes.indicator }}
            TabIndicatorProps={{ children: <span /> }}
            value={activeTab}
            onChange={handleChangeTab}
          >
            {tabs.map((tab) => (
              <Tab
                classes={{ root: classes.tab, selected: classes.tabSelected }}
                key={tab.label}
                label={tab.label}
                value={tab}
              />
            ))}
          </Tabs>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex" height="100%" justifyContent="space-between">
        <Switch>
          <Route
            exact
            path={Routes.PaczkaArchiwalnaGenerowanie.path}
            component={CreateArchivingPackage}
          />

          <Route
            exact
            path={Routes.PaczkaArchiwalnaLista.path}
            component={ArchivingPackagesList}
          />
        </Switch>
      </Box>
    </div>
  );
};
