import React, { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { ZadanieModel } from '../models/models';

type ZadaniaOtrzymaneTableProps = Pick<
  UseTableFacadeProps<ZadanieModel>,
  'tableRef' | 'data'
>;

export const ZadaniaOtrzymaneTable = (props: ZadaniaOtrzymaneTableProps) => {
  const dispatch = useDrawerRightDispatch();

  return (
    <Table<ZadanieModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        // {
        //   field: 'znak',
        //   title: 'Znak',
        //   render: (data) => (
        //     <Box
        //       display="grid"
        //       gridAutoFlow="column"
        //       alignItems="center"
        //       justifyContent="space-between"
        //     >
        //       <Link
        //         classes={{ root: classes.root }}
        //         onClick={() => handleRedirect(data)}
        //       >
        //         {data.znak}
        //       </Link>
        //       <Box pl={1}>
        //         <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
        //       </Box>
        //     </Box>
        //   ),
        // },
        {
          field: 'tytul',
          title: 'Tytuł zadania',
        },
        {
          field: 'uzytkownikOdbiorca',
          title: 'Odbiorca zadania',
          render: (data) => `${data.uzytkownikOdbiorca.pelnaNazwa}`,
        },
        { field: 'dataUtworzenia', title: 'Data wygenerowania', type: 'date' },

        {
          field: 'typZadania',
          title: 'Typ zadania',
        },
        {
          field: 'status',
          title: 'Status',
        },
        {
          field: 'komentarz',
          title: 'Komentarz',
        },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, data),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'zadanie-do-poprawy.anuluj':
      return 'Anuluj';
    case 'zadanie-do-poprawy.przydziel':
      return 'Przydziel';
    default:
      return command;
  }
};

const getComponent = <T extends unknown>(command: string, data: T) => {
  const ZadanieAnuluj = lazy(() =>
    import('../context-actions/zadanie-anuluj').then((module) => ({
      default: module.ZadanieAnuluj,
    }))
  );

  switch (command) {
    case 'zadanie-do-poprawy.anuluj':
      return <ZadanieAnuluj />;
    case 'zadanie-do-poprawy.przydziel':
      return <div>Nie znaleziono akcji</div>;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
