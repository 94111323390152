import clsx from 'clsx';
import { useStyles } from './typ-prowadzenia.styles';
import {
  ElektroniczneProwadzenieA037Icon,
  PapieroweProwadzenieA036Icon,
} from '@nask/ezdrp-icons';

export enum TypProwadzenia {
  PAPIEROWE = 1,
  ELEKTRONICZNE = 2,
}

type TypProwadzeniaProps = {
  typProwadzenia: TypProwadzenia;
};

export const TypProwadzeniaAvatar = ({
  typProwadzenia,
}: TypProwadzeniaProps) => {
  const classes = useStyles();
  if (typProwadzenia === TypProwadzenia.PAPIEROWE)
    return (
      <PapieroweProwadzenieA036Icon
        className={clsx(classes.avatar, classes.papierowe)}
      />
    );
  return (
    <ElektroniczneProwadzenieA037Icon
      className={clsx(classes.avatar, classes.elektroniczne)}
    />
  );
};
