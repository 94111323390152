import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import { getUrl } from '@archiwum/utility/service';
import { Axios } from '@nask/axios-observable';
import { take, map } from 'rxjs/operators';
import { GetUserReportsRequest, ReportModel } from '../models/models';

export const ReportsServiceQuery = {
  GetUserReports: (request: QueryViewRequestBase<GetUserReportsRequest>) =>
    Axios.post<PagedResultDto<ReportModel>>(
      getUrl('raporty-uzytkownika', ''),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  DownloadReport: (request: { idRaport: string }) =>
    Axios.post<string>(getUrl('pobierz-raport', ''), request).pipe(
      take(1),
      map((data) => data.data)
    ),
};
