import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    caseLink: {
      color: theme.typography.linkColor,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    exclamationIcon: {
      color: theme.colors.statusBackgroundColorActive,
    },
    exclamationIconPink: {
      color: '#ce3694',
    },
  })
);
