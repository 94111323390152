import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';

type Commands = string;
export type AllOperations = {
  id: string;
  etykieta: string;
  grupa?: string;
};
function getDuplicates(filterArray, count) {
  return filterArray.filter(
    (i) => filterArray.filter((ii) => ii === i).length === count
  );
}

function mapAllOperationsToArray(
  data: Array<{ listaDostepnychOperacji: Commands[] }>
) {
  let empty = false;
  const map = flatMap(data, (key) => {
    if (key.listaDostepnychOperacji.length === 0) {
      empty = true;
    }
    return key.listaDostepnychOperacji;
  });
  if (empty) {
    return [];
  } else {
    return map;
  }
}

export const getOperations = <T extends string[]>(
  data: Array<{ listaDostepnychOperacji: T }>
): T => {
  const length = data.length;
  let operations;
  if (length > 2) {
    const array = mapAllOperationsToArray(data);
    const filterArray = filter(
      array,
      (key) => key.includes('*') || key.includes('#')
    );
    const duplicates = getDuplicates(filterArray, length);
    operations = uniq(duplicates);
  } else if (length === 2) {
    const array = mapAllOperationsToArray(data);
    const filterArray = filter(
      array,
      (key) => key.includes('*') || key.includes('@') || key.includes('#')
    );
    const duplicates = getDuplicates(filterArray, length);
    operations = uniq(duplicates);
  } else if (length === 1) {
    operations = filter(
      flatMap(data, (x) =>
        x.listaDostepnychOperacji.filter(
          (key) => !key.includes('@') && !key.includes('#')
        )
      ),
      (item, index, array) => {
        return !includes(array, item, index + 1);
      }
    );
  } else {
    operations = [];
  }
  return operations;
};

// export const getGroupedOperations = <T extends { listaDostepnychOperacji: Commands[] }>(
//   allOperations: AllOperations[],
//   operations: Commands[],
//   data?: T | T[]
// ): { operations: OperationModel<T>[]; groupOperations: GroupOperation<T>[] } => {
//   const filerCommands = allOperations?.filter((x) => operations.includes(x.id as Commands));
//   const commandsWithoutGroup = filerCommands?.filter((x) => x.grupa === undefined);
//   const commandsWithGroup = filerCommands?.filter((x) => x.grupa !== undefined);
//   let mappedOperations: OperationModel<T>[] = [];
//   let mappedGroupedOperations: GroupOperation<T>[] = [];
//   if (commandsWithoutGroup.length > 0) {
//     mappedOperations = commandsWithoutGroup.map((x) => ({ label: x.etykieta, command: x.id, data }));
//   }
//
//   if (commandsWithGroup.length > 0) {
//     for (const groupCommand of commandsWithGroup) {
//       check(groupCommand, mappedGroupedOperations, data);
//     }
//   }
//   return { groupOperations: mappedGroupedOperations, operations: mappedOperations };
// };
