import createStyles from '@material-ui/core/styles/createStyles';

import { TRANSITION_DELAY, TRANSITION_DOWN_DURATION } from '../utils/constants';

const styles = (theme) =>
  createStyles({
    root: {},
    base: {
      fontSize: '1.6rem',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
    },
    variantSuccess: {
      backgroundColor: '#3abf94',
    },
    variantError: {
      backgroundColor: '#f55d5d',
    },
    variantInfo: {
      backgroundColor: '#9964e3',
    },
    variantWarning: {
      backgroundColor: '#ffc247',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    messageContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
    },
    messageElement: {
      '&:last-child': {
        fontSize: 'smaller',
      },
    },
    iconVariant: {
      opacity: 0.9,
      height: 24,
      marginRight: theme.spacing(),
      display: 'flex',
      alignItems: 'center',
    },
  });

/**
 * @param {object} anchorOrigin - how snack is postioned. e.g.
 * { vertical: 'bottom', horizontal: 'left' }
 * @param {number} level - Level on which snakcbar should be displayed
 * (when snackbars are stacked on top of eachother)
 * @returns {object}
 */
const getTransitionStyles = (level, anchorOrigin, start, minus) => ({
  [anchorOrigin.vertical]: start + (level * 48 + level * 16 + 20) * minus,

  WebKitTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
  MozTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
  msTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
  OTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
  transition: `all ${TRANSITION_DOWN_DURATION}ms`,
  transitionDelay: `${TRANSITION_DELAY}ms`,
});

export { styles, getTransitionStyles };
