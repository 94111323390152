import { Axios } from '@nask/axios-observable';
import { map, take } from 'rxjs/operators';
import { getUrl } from '@archiwum/utility/service';
import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import {
  GetBrakowanieRequestModel,
  GetListaWykazowJRWADoBrakowaniaResponseModel,
  GetObiektySpisuBrakowaniaRequestModel,
  GetRequestFiltry,
  GetRpwDetailsRequestModel,
  GetRpwDetailsResponse,
  GetUzytkownicyResponseModel,
  BrakowanieModel,
  ObiektSpisuBrakowania,
  SprawaTechniczna,
  SprawyFiltry,
  SpisyBrakowaniaFiltry,
  SpisBrakowania,
  KlasaJRWADoAPLista,
  GetJRWAWyszukajWykazRequest,
  ProcesBrakowania,
  SpisBrakowaniaShort,
} from '../models/models';

export const BrakowanieServiceQuery = {
  GetSprawyBrakowania: (
    request: QueryViewRequestBase<GetBrakowanieRequestModel>
  ) =>
    Axios.post<PagedResultDto<BrakowanieModel>>(
      getUrl('sprawy/do-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetPismaNASBrakowanie: (
    request: QueryViewRequestBase<GetBrakowanieRequestModel>
  ) =>
    Axios.post<PagedResultDto<BrakowanieModel>>(
      getUrl('pisma-nas/do-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetPismaNASFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(
      getUrl('pisma-nas/do-brakowania/filtry'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(
      getUrl('sprawy/do-brakowania/filtry'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetRpwDetails: (requestData: GetRpwDetailsRequestModel) =>
    Axios.post<GetRpwDetailsResponse>(
      getUrl('/pisma/szczegoly-rpw'),
      requestData
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyDoPoprawy: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<BrakowanieModel>>(
      getUrl('zadania/do-poprawy/utowrzone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetPismaNASDoPoprawy: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<BrakowanieModel>>(
      getUrl('zadania/do-poprawy/utowrzone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetTypyElementowDoPoprawy: () =>
    Axios.post<string[]>(
      getUrl('zadania/do-poprawy/typy-elementow-do-poprawy/lista'),
      {}
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetUzytkownicy: () =>
    Axios.post<GetUzytkownicyResponseModel>(
      getUrl('uzytkownicy/lista'),
      {}
    ).pipe(
      take(1),
      map((data) => data.data.items)
    ),
  GetListaWykazowJRWADoBrakowania: (request: { searchValue: string }) =>
    Axios.post<GetListaWykazowJRWADoBrakowaniaResponseModel>(
      getUrl(
        'jrwa/schemat-wykaz/wykazy-mozliwe-do-tworzenia-sprawy-do-brakowania/lista',
        '/ezdrp'
      ),
      request
    ).pipe(
      take(1),
      map((data) => data.data.lista)
    ),
  GetListaSprawTechnicznych: (request: {
    czySprawyBrakowania: boolean;
    czySprawyPaczkiArchiwalnej: boolean;
  }) =>
    Axios.post<SprawaTechniczna[]>(
      getUrl('proxy/sprawy/lista-spraw-technicznych/pobierz', '/ezdrp'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSpisyBrakowaniaFiltry: () =>
    Axios.post<SpisyBrakowaniaFiltry>(getUrl('spisy-brakowania/filtry')).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSpisyBrakowania: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<SpisBrakowania>>(
      getUrl('spisy-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetObiektySpisuBrakowania: (
    request: QueryViewRequestBase<GetObiektySpisuBrakowaniaRequestModel>
  ) =>
    Axios.post<PagedResultDto<ObiektSpisuBrakowania>>(
      getUrl('obiekty-do-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),

  GetJRWAWyszukajWykaz: (request: GetJRWAWyszukajWykazRequest) =>
    Axios.post<{ lista: KlasaJRWADoAPLista[] }>(
      getUrl('jrwa-as-wyszukaj-wykaz', '/'),
      request
    ).pipe(
      take(1),
      map((data) => data.data.lista)
    ),
  GetProcesyBrakowania: (request: QueryViewRequestBase<{}>) =>
    Axios.post<PagedResultDto<ProcesBrakowania>>(
      getUrl('procesy-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetObiektyProcesuBrakowania: (
    request: QueryViewRequestBase<{ idProcesBrakowania: string }>
  ) =>
    Axios.post<PagedResultDto<ObiektSpisuBrakowania>>(
      getUrl('obiekty-w-procesie-brakowania/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSpisyBrakowaniaLista: () =>
    Axios.post<{ lista: SpisBrakowaniaShort[] }>(
      getUrl('spisy-brakowania/lista-wyboru')
    ).pipe(
      take(1),
      map((data) => data.data.lista)
    ),
};
