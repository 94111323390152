import { useBehaviorSubject } from '@czaplej/use-behavior-subject';
import { MenuModel } from '@archiwum/sidebar';
import { Routes } from '@archiwum/routes';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';

import {
  PathModel,
  getBreadcrumbsArray,
  updateBreadcrumbsWithMenu,
} from './utility';

type UseNavigationBreadcrumbsFacadeProps = {
  menu?: MenuModel[];
};
const subject$ = new BehaviorSubject<PathModel[]>([]);
export const useNavigationBreadcrumbsFacade = (
  props: UseNavigationBreadcrumbsFacadeProps
) => {
  const location = useLocation();
  const history = useHistory();
  const { state, setState } = useBehaviorSubject<PathModel[]>({
    subject: subject$,
    initialState: [],
  });

  useEffect(() => {
    const paths = Object.keys(Routes).map((key) => ({ ...Routes[key] }));
    const [removeFirst, ...rest] = paths;
    const breadcrumbsArray = getBreadcrumbsArray(location.pathname, rest);
    setState(breadcrumbsArray);
    // const updateWithMenuNames = updateBreadcrumbsWithMenu(
    //   breadcrumbsArray,
    //   props.menu
    // );
    // setState(updateWithMenuNames);
  }, [location, props.menu]);

  const handleClick = (path) => {
    history.push(path);
  };
  return { state, handleClick };
};
