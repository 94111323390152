import {
  createMuiTheme,
  createStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import MaterialTable, { MaterialTableProps } from 'material-table';
import React, { forwardRef, MutableRefObject } from 'react';
import { theme } from '@nask/theme';

export type TableRef = {
  onQueryChange?: () => void;
  onSelectionChange?: (data?: unknown[] | null) => void;
};

export type TableBaseProps<T extends object> = Omit<
  MaterialTableProps<T>,
  'tableRef'
> & {
  tableRef?: MutableRefObject<TableRef | undefined>;
};
const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef<SVGSVGElement>((props, ref) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef<SVGSVGElement>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef<SVGSVGElement>((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<SVGSVGElement>((props, ref) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef<SVGSVGElement>((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef<SVGSVGElement>((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef<SVGSVGElement>((props, ref) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => (
    <ArrowUpward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiTableCell: createStyles({
      root: {
        fontSize: 16,
        padding: '16px 8px',
      },
      head: {
        fontWeight: 'bold',
        fontStretch: 'condensed',
        fontSize: 14,
        color: theme.typography.textColor,
      },
    }),
    MuiTableBody: {
      root: {
        fontSize: 16,
      },
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: theme.sidebar.backgroundSubMenu,
        },
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: 14,
        '&:hover': { backgroundColor: theme.colors.background },
      },
    },
    MuiCheckbox: {
      root: {
        color: theme.colors.primary,
      },
      colorSecondary: {
        '&$checked': {
          color: theme.colors.primary,
        },
      },
    },
    MuiTypography: {
      caption: {
        fontSize: 14,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: theme.typography.descriptionFontSize,
      },
    },
  },
});

const TableBase = <T extends object>({ ...props }: TableBaseProps<T>) => {
  const theme = useTheme();
  return (
    <MuiThemeProvider theme={{ ...theme, overrides: materialTheme.overrides }}>
      <MaterialTable<T>
        icons={tableIcons}
        {...props}
        options={{ emptyRowsWhenPaging: false, ...props.options }}
      />
    </MuiThemeProvider>
  );
};

export default TableBase;
