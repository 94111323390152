import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Box, Divider, Paper, Tab } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Tabs } from '@archiwum/ui/tabs';
import { useStyles } from './components/poczekalnia-layout.styles';
import { SelectModel } from '@nask/hooks';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '@archiwum/routes';
import { Counter, countersSubject$, LicznikModel } from '@archiwum/counters';

import { Nowe } from '../nowe/views/nowe';
import { DoPoprawy } from '../do-poprawy/views/do-poprawy';
import { Poprawione } from '../poprawione/views/poprawione';
import { Odlozone } from '../odlozone/views/odlozone';

type PoczekalniaProps = {};

const tabsPoczekalnia = [
  { label: 'Nowe', value: Routes.Nowe.path, idCounter: 'POCZEKALNIA-NOWE' },
  {
    label: 'Do poprawy',
    value: Routes.DoPoprawy.path,
    idCounter: 'POCZEKALNIA-DOPOPRAWY',
  },
  {
    label: 'Poprawione',
    value: Routes.Poprawione.path,
    idCounter: 'POCZEKALNIA-POPRAWIONE',
  },
  {
    label: 'Odłożone',
    value: Routes.Odlozone.path,
    idCounter: 'POCZEKALNIA-ODLOZONE',
  },
];

function getActivePath(pathname: string, tabs: SelectModel[]) {
  let result: string | boolean = false;
  for (const tab of tabs) {
    const isActive =
      matchPath(pathname, { path: tab.value, exact: true }) !== null;
    if (isActive) {
      result = tab.value;
      break;
    }
  }
  return result;
}

export const Poczekalnia = (props: PoczekalniaProps) => {
  const tabs = tabsPoczekalnia;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [counters, setCounters] = useState<LicznikModel[]>([]);
  const [activeTab, setActiveTab] = useState(() => {
    return getActivePath(location.pathname, tabs);
  });
  useEffect(() => {
    setActiveTab(getActivePath(location.pathname, tabs));
  }, [location.pathname]);

  useEffect(() => {
    if (
      matchPath(location.pathname, {
        path: Routes.Poczekalnia.path,
        exact: true,
      }) !== null
    ) {
      history.replace(Routes.Nowe.path);
    }
  }, [location.pathname]);

  useEffect(() => {
    const subscription = countersSubject$.subscribe((counters) =>
      setCounters(counters)
    );
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            {'Archiwizacja dokumentacji'}
          </Typography>
          <Tabs
            classes={{ root: classes.tabs, indicator: classes.indicator }}
            TabIndicatorProps={{ children: <span /> }}
            value={activeTab}
            onChange={(event, value) => {
              history.push(value);
              setActiveTab(value);
            }}
          >
            {tabs.map((tab) => (
              <Tab
                classes={{ root: classes.tab, selected: classes.tabSelected }}
                key={tab.value}
                label={
                  <div className={classes.tabContent}>
                    {tab.label}
                    <Counter
                      data={counters?.find((item) => item.id === tab.idCounter)}
                    />
                  </div>
                }
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider />
        </Paper>
      </Box>

      <Box display="flex" height="100%" justifyContent="space-between">
        <Switch>
          <Route exact path={Routes.Nowe.path} component={Nowe} />
          <Route exact path={Routes.DoPoprawy.path} component={DoPoprawy} />
          <Route exact path={Routes.Poprawione.path} component={Poprawione} />
          <Route exact path={Routes.Odlozone.path} component={Odlozone} />
        </Switch>
      </Box>
    </div>
  );
};
