import { Box, Container, Divider, Paper } from '@material-ui/core';
import { Tab, Tabs } from '@archiwum/ui/tabs';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { SelectModel } from '@nask/hooks';
import { Typography } from '@archiwum/ui/typography';
import { SearchInput } from '@archiwum/shared/search-input';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { DrawerRight } from '@archiwum/drawer-right';
import { Counter } from '@archiwum/counters';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';

import { FolderArchiwum, TypProwadzeniaLabelValue } from '../../models/models';
import { SprawyTable } from '../../poczekalnia/components/sprawy-table';
import { PismaNasTable } from '../../poczekalnia/components/pisma-nas-table';
import { usePoczekalniaFacade } from '../../hooks/use-poczekalnia-facade';
import { useSelectedDispatch } from '../../hooks/use-selected';
import { getFilters } from '../../helper';
import { useStyles } from '../../poczekalnia/poczekalnia.styles';
import { getPismaNASQuery, getSprawyQuery } from '../helper';

export const Odlozone = () => {
  const {
    onChange,
    tableRef,
    state,
    data,
    handleChangeTab,
    handleUpdateSelect,
    handleUpdateDate,
    typProwadzeniaOptions,
    katArchiwalnaOptions,
    symboleJRWAOptions,
    prowadzacyOptions,
    komorkaOrganizacyjnaOptions,
    searchValue,
    activeTab,
    tabs,
    counters,
  } = usePoczekalniaFacade({ foldery: [FolderArchiwum.Odlozone] });
  const dispatch = useSelectedDispatch();
  const classes = useStyles();
  return (
    <>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                <Box display="grid" gridAutoFlow="row">
                  <Box p={0.5} m={0.5} justifySelf="flex-end">
                    <SearchInput
                      searchMethod={(phrase: string) => {
                        onChange(phrase);
                      }}
                    />
                  </Box>
                  <Box display="flex" flexWrap="wrap">
                    <Box m={0.5} p={0.5} width={200}>
                      <Autocomplete
                        label="Komórka organizacyjna"
                        name="komorkaOrganizacyjna"
                        options={komorkaOrganizacyjnaOptions}
                        value={
                          komorkaOrganizacyjnaOptions.find(
                            (option) =>
                              option.value === state.komorkaOrganizacyjna
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'komorkaOrganizacyjna',
                            v as SelectModel
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <Autocomplete
                        label="Prowadzący sprawę"
                        name="prowadzacy"
                        options={prowadzacyOptions}
                        value={
                          prowadzacyOptions.find(
                            (option) => option.value === state.prowadzacy
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect('prowadzacy', v as SelectModel)
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <Autocomplete
                        label="Symbol JRWA"
                        name="symbolJRWA"
                        options={symboleJRWAOptions}
                        value={
                          symboleJRWAOptions.find(
                            (option) => option.value === state.symbolJRWA
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect('symbolJRWA', v as SelectModel)
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <Autocomplete
                        label="Kat. archiwalna"
                        name={'kategoriaArchiwalna'}
                        options={katArchiwalnaOptions}
                        value={
                          katArchiwalnaOptions.find(
                            (option) =>
                              option.value === state.kategoriaArchiwalna
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'kategoriaArchiwalna',
                            v as SelectModel
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <Autocomplete
                        label="Typ prowadzenia"
                        name="typProwadzenia"
                        options={typProwadzeniaOptions}
                        value={
                          typProwadzeniaOptions.find(
                            (option) => option.value === state.typProwadzenia
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <Box
                            display="grid"
                            gridGap={8}
                            gridAutoFlow="column"
                            alignItems="center"
                          >
                            <TypProwadzeniaAvatar
                              typProwadzenia={option.value}
                            />
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'typProwadzenia',
                            v as TypProwadzeniaLabelValue
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <DatePicker
                        label="Data zakończenia od"
                        name="dataZakonczeniaOd"
                        value={state.dataZakonczeniaOd}
                        onChange={(date) =>
                          handleUpdateDate(date, 'dataZakonczeniaOd')
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <DatePicker
                        label="Data zakończenia do"
                        name="dataZakonczeniaDo"
                        value={state.dataZakonczeniaDo}
                        onChange={(date) =>
                          handleUpdateDate(date, 'dataZakonczeniaDo')
                        }
                      />
                    </Box>

                    <Box m={0.5} p={0.5} width={200}>
                      <DatePicker
                        label="Data przyjęcia od"
                        name="dataPrzyjeciaOd"
                        maxDate={new Date()}
                        maxDateMessage={
                          'Data nie powinna być późniejsza niż dzień bieżący'
                        }
                        value={state.dataPrzyjeciaOd}
                        onChange={(date) =>
                          handleUpdateDate(date, 'dataPrzyjeciaOd')
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5} width={200}>
                      <DatePicker
                        label="Data przyjęcia do"
                        name="dataPrzyjeciaDo"
                        maxDate={new Date()}
                        maxDateMessage={
                          'Data nie powinna być późniejsza niż dzień bieżący'
                        }
                        value={state.dataPrzyjeciaDo}
                        onChange={(date) =>
                          handleUpdateDate(date, 'dataPrzyjeciaDo')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box>
            <Tabs
              classes={{ indicator: classes.indicator, root: classes.root }}
              value={activeTab}
              onChange={handleChangeTab}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={
                    <div className={classes.tabContent}>
                      {tab.label}
                      <Counter
                        data={counters?.find(
                          (item) => item.id === tab.idCounter
                        )}
                      />
                    </div>
                  }
                  value={tab.value}
                  classes={{
                    root: classes.tab,
                    selected: classes.activeTab,
                  }}
                />
              ))}
            </Tabs>
            <Divider classes={{ root: classes.borderBottomTab }} />
          </Box>
          <Paper classes={{ root: classes.paperShadowBottom }}>
            {activeTab === tabs[0].value ? (
              <SprawyTable
                tableRef={tableRef}
                data={(query, result) => {
                  getSprawyQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch
                  );
                }}
                hiddenFields={['dataZarchiwizowania', 'historia']}
                nameColumnIncomingDate={'Data odłożenia'}
              />
            ) : (
              <PismaNasTable
                tableRef={tableRef}
                data={(query, result) => {
                  getPismaNASQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch
                  );
                }}
                hiddenFields={['dataZarchiwizowania', 'historia']}
                nameColumnIncomingDate={'Data odłożenia'}
              />
            )}
          </Paper>
        </Container>
      </Box>

      <DrawerRight />
    </>
  );
};
