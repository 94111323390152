import { Box, Tooltip } from '@material-ui/core';
import { generatePath, useHistory } from 'react-router';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Routes } from '@archiwum/routes';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';

import { BrakowanieModel, TypObiekt } from '../../models/models';
import { useStyles } from './sprawy-table.styles';
import { lazy } from 'react';
import { DataUtworzenia064Icon } from '@nask/ezdrp-icons';

type SprawyTableProps = Pick<
  UseTableFacadeProps<BrakowanieModel>,
  'tableRef' | 'data'
>;

export const SprawyTable = (props: SprawyTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleRedirect = (item: BrakowanieModel) => {
    history.push(generatePath(Routes.CaseDetails.path, { caseId: item.id }));
  };

  return (
    <Table<BrakowanieModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'znak',
          title: 'Znak sprawy',
          render: (data) => (
            <Box
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
            >
              <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
              {data.znak}
              {data.czyWSpisieBrakowania && (
                <Tooltip title={'Sprawa do brakowania'}>
                  <span>
                    <DataUtworzenia064Icon
                      className={classes.exclamationIcon}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          ),
        },
        {
          field: 'tytul',
          title: 'Tytuł sprawy',
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kat. arch.',
        },
        {
          field: 'prowadzacy',
          title: 'Prowadzący sprawę',
        },
        {
          field: 'komorkaOrganizacyjna',
          title: 'Komórka organizacyjna',
        },
        {
          field: 'dataZakonczenia',
          title: 'Data zakończenia',
          type: 'date',
        },
        { field: 'folderOpis', title: 'Status' },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations
              .filter(
                (f) => !['*archiwum.obiekt.spis-brakowania.utworz'].includes(f)
              )
              .map((x) => ({
                data,
                command: x,
                label: getLabel(x),
                onClick: () => handleActionClick(x, data),
              })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case '*przenies-do-folderu.zarchiwizowane':
      return 'Archiwizuj';
    case '*zadanie-do-poprawy.utworz':
      return 'Przekaż do poprawy';
    case '*paczka-archiwalna.generuj':
      return 'Generuj paczkę archiwalną';
    case '*przenies-do-folderu.odlozone':
      return 'Przenieś do odłożonych';
    case 'archiwum.obiekt.historia':
      return 'Historia';
    case '*archiwum.obiekt.dodaj-do-spisu':
      return 'Dodaj do spisu brakowania';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const DodajDoSpisuBrakowania = lazy(() =>
    import(
      '../../context-actions/dodaj-do-spisu-brakowania/dodaj-do-spisu-brakowania'
    ).then((module) => ({
      default: module.DodajDoSpisuBrakowania,
    }))
  );
  const Historia = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Historia,
    }))
  );
  switch (command) {
    case 'archiwum.obiekt.historia':
      return <Historia typObiekt={TypObiekt.SPRAWY} />;
    case '*archiwum.obiekt.dodaj-do-spisu':
      return <DodajDoSpisuBrakowania typObiekt={TypObiekt.SPRAWY} />;

    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
