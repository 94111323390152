import { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';

import { SpisModel } from '../models/models';

type SpisyTableProps = Pick<
  UseTableFacadeProps<SpisModel>,
  'tableRef' | 'data'
>;

export const SpisyTable = (props: SpisyTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const handleRefresh = () => {
    props.tableRef?.current?.onQueryChange?.();
  };

  return (
    <Table<SpisModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'numer', title: 'Numer' },
        {
          field: 'rocznik',
          title: 'Rocznik',
        },
        { field: 'dataPrzekazania', title: 'Data przekazania', type: 'date' },
        {
          field: 'stanowiskoPrzekazujacego',
          title: 'Pracownik przekazujący',
          render: (rowData) =>
            rowData.stanowiskoPrzekazujacego != null
              ? `${rowData.stanowiskoPrzekazujacego?.imie} ${
                  rowData.stanowiskoPrzekazujacego?.nazwisko ?? ''
                }`
              : '',
        },
        { field: 'dataPrzyjecia', title: 'Data przyjęcia', type: 'date' },
        {
          field: 'stanowiskoPrzyjmujacego',
          title: 'Pracownik przyjmujący',
          render: (rowData) =>
            rowData.stanowiskoPrzyjmujacego != null
              ? `${rowData.stanowiskoPrzyjmujacego?.imie} ${rowData.stanowiskoPrzyjmujacego?.nazwisko}`
              : '',
        },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'archiwum.skladyArchiwalne.przegladajSpis':
      return 'Przeglądaj kartony w spisie';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const PrzegladajSpis = lazy(() =>
    import('../context-actions/przegladaj-spis').then((module) => ({
      default: module.PrzegladajSpis,
    }))
  );

  switch (command) {
    case 'archiwum.skladyArchiwalne.przegladajSpis':
      return <PrzegladajSpis />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
