import React, { ElementType, forwardRef } from 'react';
import {
  Typography as MaterialTypography,
  TypographyProps as MaterialTypographyProps,
} from '@nask/ui';
import { useStyles } from './typography.styles';
import clsx from 'clsx';

export enum TypographyVariants {
  Header1 = 'HEADER1',
  Header2 = 'HEADER2',
  Header3 = 'HEADER3',
  Header4 = 'HEADER4',
  Header5 = 'HEADER5',
  Paragraph = 'PARAGRAPH',
  Description = 'DESCRIPTION',
  Default = 'DEFAUT',
}
export type TypographyProps = MaterialTypographyProps & {
  variantType?: TypographyVariants;
  component?: ElementType;
};

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  (props, ref) => {
    const {
      variantType = TypographyVariants.Default,
      classes: propClasses = {},
      ...rest
    } = props;
    const classes = useStyles();
    const { root, ...restClasses } = propClasses;
    return (
      <MaterialTypography
        classes={{
          root: clsx(
            {
              [classes.header1]: variantType === TypographyVariants.Header1,
              [classes.header2]: variantType === TypographyVariants.Header2,
              [classes.header3]: variantType === TypographyVariants.Header3,
              [classes.header4]: variantType === TypographyVariants.Header4,
              [classes.header5]: variantType === TypographyVariants.Header5,
              [classes.description]:
                variantType === TypographyVariants.Description,
              [classes.paragraph]: variantType === TypographyVariants.Paragraph,
              [classes.default]: variantType === TypographyVariants.Default,
            },
            root
          ),
          ...restClasses,
        }}
        ref={ref}
        {...rest}
      />
    );
  }
);
