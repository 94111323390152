import {
  Filters,
  FolderArchiwum,
  LabelValue,
  TypProwadzeniaLabelValue,
} from '@archiwum/features/archiving-documents';

export enum TypObiekt {
  SPRAWY = 1,
  PISMA_NAS = 2,
}

export enum TypProwadzenia {
  ELEKTRONICZNE = 1,
  PAPIEROWE,
}

export type CaseStatus = 'W_Toku' | 'Odlozona' | 'Zawieszona' | 'Zakonczona';

export type GetCaseRequestModel = {
  typObiekt: TypObiekt;
  idObiekt: string;
};
export type XML_Model = {
  archiwumID: string;
  value: string;
};
export type XML_Boolean_Model = {
  archiwumID: string;
  value: boolean;
};

export type XML_Number_Model = {
  archiwumID: string;
  value: number;
};

export type XML_Type_Model<T> = {
  archiwumID: string;
  value: T;
};

export type DatyModel = {
  dataModyfikacji?: XML_Model;
  dataNaPismie?: XML_Model;
  dataNadania?: XML_Model;
  dataOdlozenia?: XML_Model;
  dataRejestracji?: XML_Model;
  dataUtworzenia: XML_Model;
  dataWplywu: XML_Model;
  dataWszczecia?: XML_Model;
  dataZakonczenia?: XML_Model;
  dataZakonczeniaKPA?: XML_Model;
  dataZawieszenia?: XML_Model;
  terminZalatwienia: XML_Model;
};
export type PracownikModel = {
  id: XML_Model;
  imie: XML_Model;
  nazwisko: XML_Model;
  stanowisko: XML_Model;
  idStanowiska?: XML_Model;
  komorkaOrganizacyjna: KomorkaOrganizacyjnaModel;
};

export type NaglowekModel = {
  daty: DatyModel;
  doOdczytu: XML_Boolean_Model;
  idSprawa: XML_Model;
  jRWA?: XML_Model;
  kategoriaArchiwalna: XML_Model;
  nadawcaDomyslny?: XML_Model;
  numer: XML_Number_Model;
  podmiot: PodmiotModel;
  pracownikInicjujacy: PracownikModel;
  pracownikProwadzacy: PracownikModel;
  rokZalozenia: XML_Number_Model;
  status: XML_Type_Model<CaseStatus>;
  typProwadzenia: XML_Type_Model<TypProwadzenia>;
  tytul: XML_Model;
  znak: XML_Model;
};

export type DokumentModel = {
  aktualnaWersja?: DokumentWersjaModel;
  id?: XML_Model;
  idDokument?: XML_Model;
  informacjeODokumencieWSkladzie?: any;
  katalog?: XML_Model;
  metadane?: any;
  nazwa?: XML_Model;
  typ?: XML_Model | XML_Number_Model;
  wersjeDokumentu?: DokumentWersjaModel[];
};

export type DokumentWersjaModel = {
  autor?: PracownikModel;
  dataUtworzenia?: XML_Model;
  id?: XML_Model;
  idDokument?: XML_Model;
  idPlikStorage?: XML_Model;
  podpisy?: PodpisModel[];
  rozszerzenie?: XML_Model;
  wersja?: XML_Model;
  wlasciciel?: PracownikModel;
};

export type PodpisModel = any;

export type PismoModel = {
  daty?: DatyModel;
  dekretacje?: DekretacjaModel[];
  dokumenty?: DokumentModel[];
  idPismo?: XML_Model;
  metadane?: any;
  nadawcy?: NadawcaModel[];
  numerRPW?: XML_Model;
  pracownikOdbiorca?: PracownikModel;
  pracownikRejestrujacy?: PracownikModel;
  rodzajWplywu?: XML_Model;
  rodzajDokumentu?: XML_Model;
  typOdwzorowania?: XML_Model;
  typDokumentu?: XML_Model;
  tytul?: XML_Model;
  znakNaPismie?: XML_Model;
};

export type DekretacjaModel = any;

export type AktaModel = {
  dokumenty: DokumentModel[];
  pisma: PismoModel[];
};

export type SprawaModel = {
  naglowek: NaglowekModel;
  akta: AktaModel;
  uczestnicy: UczestnicyModel;
};

export type UczestnicyModel = {
  nadawcy: NadawcaModel[];
};

export type NadawcaModel = {
  id?: XML_Model;
  imie?: XML_Model;
  drugieImie?: XML_Model;
  nazwisko?: XML_Model;
  nazwa?: XML_Model;
};

export type PodmiotModel = {
  id?: XML_Model;
  nazwa?: XML_Model;
  nIP?: XML_Model;
  skrot?: XML_Model;
  komorkaOrganizacyjna?: KomorkaOrganizacyjnaModel;
};

export type KomorkaOrganizacyjnaModel = {
  id?: XML_Model;
  symbol?: XML_Model;
  nazwa?: XML_Model;
};

export type CaseResponseModel = SprawaModel;

export type GetSpisSprawRequestModel = {
  foldery: FolderArchiwum[];
};

export type GetRequestFiltry = {
  foldery: FolderArchiwum[];
};

export type SprawyFiltry = {
  komorkiOrganizacyjne: Filters;
  prowadzacy: Filters;
  symboleJRWA: Filters;
  kategorieArchiwalne: Filters;
  typProwadzenia: Filters<TypProwadzeniaLabelValue>;
  dataZakonczeniaOd: Filters<LabelValue>;
  dataZakonczeniaDo: Filters<LabelValue>;
  dataPrzyjeciaOd: Filters<LabelValue>;
  dataPrzyjeciaDo: Filters<LabelValue>;
  folder: Filters<LabelValue>;
};

export type SprawyPoczekalniaFilters = {
  komorkaOrganizacyjna: string | null;
  prowadzacy: string | null;
  symbolJRWA: string | null;
  kategoriaArchiwalna: string | null;
  typProwadzenia: TypProwadzenia | null;
  dataZakonczeniaOd: string | null;
  dataZakonczeniaDo: string | null;
  dataPrzyjeciaOd: string | null;
  dataPrzyjeciaDo: string | null;
  folder: string | null;
};

export type GenerujPaczkeArchiwalnaRequestModel = {
  typObiektu: TypObiekt;
  listaIdObiekt: string[];
};

export type GetElementyDoPoprawyRequestModel = {
  typObiekt: TypObiekt;
  idObiekt: string;
};

export type DodajElementDoPoprawyRequestModel = GetElementyDoPoprawyRequestModel & {
  elementDoPoprawy: {
    nazwaWyswietlana: string;
    idElementDoPoprawy: string;
    wartoscElementuDoPoprawy: string;
  };
};

export type WalidujElementDoPoprawyRequestModel = GetElementyDoPoprawyRequestModel & {
  SlownikWartosciDoZmiany: { [key: string]: string };
};
