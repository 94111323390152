import 'moment/locale/pl';

import {
  DatePickerProps,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Input } from '@archiwum/shared/input';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useStyles } from './date-picker.styles';
import MomentUtils from '@date-io/moment';

type DateTimePickerProps = DatePickerProps & {};

export const DatePicker = (props: DateTimePickerProps) => {
  const { InputProps, ...rest } = props;
  const classesFromParent = InputProps?.classes ?? {};
  const classes = useStyles();
  if (rest.value === undefined || rest.value === '') rest.value = null;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
      <KeyboardDatePicker
        format="YYYY-MM-DD"
        inputVariant="outlined"
        variant="inline"
        invalidDateMessage={'Nieporpawny format daty'}
        autoOk
        InputAdornmentProps={{
          position: 'end',
          style: {
            marginLeft: 0,
            order: 2,
          },
        }}
        InputProps={{
          ...InputProps,
          classes: {
            ...classesFromParent,
            inputAdornedEnd: classes.inputAdornedEnd,
          },
          startAdornment: props.value && (
            <IconButton
              classes={{ root: classes.clearIcon }}
              onClick={() => {
                props.onChange(null);
              }}
            >
              <Clear />
            </IconButton>
          ),
        }}
        TextFieldComponent={Input}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
