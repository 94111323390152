import React, { Suspense, useEffect, useState } from 'react';
import {
  actionsService$,
  actionsStore$,
  ActionStore$,
} from '@archiwum/actions-handler';
import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './actions.styles';

export const Actions = () => {
  const classes = useStyles();
  const [state, setState] = useState<ActionStore$<unknown>>(
    () => actionsStore$.value
  );

  useEffect(() => {
    const subscription = actionsStore$.subscribe({
      next: (value) => {
        setState(value);
      },
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  if (state.renderType === 'method') {
    return (
      <Suspense fallback={'...'}>
        {/*<ExecuteCorrectMethod commandData={state} />*/}
      </Suspense>
    );
  } else if (state.renderType === 'modal') {
    return (
      <Suspense fallback={'...'}>
        {/*<RenderCorrectModal commandState={state} />*/}
      </Suspense>
    );
  } else if (state.renderType === 'drawer') {
    return (
      <div id={'drawer'}>
        <Drawer
          classes={{
            paper: clsx(classes.root, {
              [classes.drawerOpen]: state.open,
              [classes.drawerClose]: !state.open,
            }),
          }}
          open={state.open}
          anchor="right"
          onClose={actionsService$.close}
        >
          <Suspense fallback={'...'}>{state.component}</Suspense>
        </Drawer>
      </div>
    );
  } else {
    return null;
  }
};

export default Actions;
