import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Avatar } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { useStyles } from '../../case.styles';

export enum StatusLabels {
  P = 'P',
  A = 'A',
  M = 'M',
  S = 'S',
  W = 'W',
  U = 'U',
  Z = 'Z',
}

type StatusAvatarProps = {
  label: StatusLabels;
  isActive: boolean;
};

export const StatusAvatar: FunctionComponent<StatusAvatarProps> = (props) => {
  const classes = useStyles();
  const { label, isActive = false } = props;
  return (
    <Avatar
      className={clsx(
        classes[`status${isActive ? 'Active' : 'Inactive'}`],
        classes[`statusAvatar`]
      )}
    >
      <Typography
        variant={'caption'}
        variantType={TypographyVariants.Paragraph}
        className={classes.statusAvatar}
      >
        {label}
      </Typography>
    </Avatar>
  );
};
