import React, { useEffect } from 'react';
import { Box, Divider, Paper, Tab } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Tabs } from '@archiwum/ui/tabs';
import { useStyles } from './tasks-layout.styles';
import { BehaviorSubject } from 'rxjs';
import {
  useBehaviorSubjectDispatch,
  useBehaviorSubjectSelector,
} from '@czaplej/use-behavior-subject';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { useLocation } from 'react-router-dom';

type TasksLayoutProps = {
  children?: React.ReactNode;
};
export const TasksLayout = (props: TasksLayoutProps) => {
  const { children } = props;
  const drawerDispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    drawerDispatch({ selected: [], data: [] });
  }, [location.pathname]);

  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            Zadania archiwum
          </Typography>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex" height="100%" justifyContent="space-between">
        {children}
      </Box>
    </div>
  );
};
