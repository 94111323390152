import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';

import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import {
  GetListaPaczekArchiwalnychRequestModel,
  GetListaSprawArchiwalnychRequestModel,
  GetObiektyPaczkiArchiwalnejRequestModel,
  GetListaKlasJRWADoAPResponseModel,
  GetListaKlasJRWADoAPRequestModel,
  ObiektPaczkiArchiwalnej,
  PaczkaArchiwalna,
  SprawaArchiwalna,
  SprawyFiltry,
} from '../models/models';

export const ArchivingPackageServiceQuery = {
  GetListaPaczekArchiwalnych: (
    request: QueryViewRequestBase<GetListaPaczekArchiwalnychRequestModel>
  ) =>
    Axios.post<PagedResultDto<PaczkaArchiwalna>>(
      getUrl('paczka-archiwalna/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetListaSprawArchiwalnych: (
    request: QueryViewRequestBase<GetListaSprawArchiwalnychRequestModel>
  ) =>
    Axios.post<PagedResultDto<SprawaArchiwalna>>(
      getUrl('sprawy/gotowe-do-przekazania-ap/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyFiltry: (request: { czyWszystkie: boolean }) =>
    Axios.post<SprawyFiltry>(
      getUrl('sprawy/gotowe-do-przekazania-ap/filtry'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetObiektyPaczkiArchiwalnej: (
    request: QueryViewRequestBase<GetObiektyPaczkiArchiwalnejRequestModel>
  ) =>
    Axios.post<PagedResultDto<ObiektPaczkiArchiwalnej>>(
      getUrl('paczka-archiwalna/obiekty/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data.items)
    ),
  GetZnakiSpraw: () =>
    Axios.post<string[]>(getUrl('paczka-archiwalna/znaki-spraw/lista')).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetListaKlasJRWADoAP: (request: GetListaKlasJRWADoAPRequestModel) =>
    Axios.post<GetListaKlasJRWADoAPResponseModel>(
      getUrl(
        'jrwa/schemat-wykaz/wykazy-mozliwe-do-tworzenia-sprawy-do-przekazywania-dokumentacji-do-ap/lista',
        '/ezdrp'
      ),
      request
    ).pipe(
      take(1),
      map((data) => data.data.lista)
    ),
};
