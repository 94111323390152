import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { DokumentModel } from '../../models/models';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { StatusArea } from './status-area';
import { getShortDate } from 'libs/shared/table/src/lib/hooks/methods';
import { RowActionsButton } from './row-actions-button';

type ListRowDocumentProps = {
  rowData: DokumentModel;
};

export const ListRowDocument = ({ rowData }: ListRowDocumentProps) => {
  const status = {
    m: rowData?.metadane ? true : false,
    s: rowData?.informacjeODokumencieWSkladzie,
    a: false,
    p:
      rowData?.wersjeDokumentu?.findIndex((doc) => doc?.podpisy?.length > 0) >=
      0,
    w: false,
  };
  return (
    <>
      <Typography variantType={TypographyVariants.Paragraph}>
        {rowData?.nazwa?.value}
      </Typography>
      <StatusArea actId={rowData?.idDokument?.value} status={status} />

      <Typography variantType={TypographyVariants.Paragraph}>
        {getShortDate(rowData?.aktualnaWersja?.dataUtworzenia?.value)}
      </Typography>
    </>
  );
};
