import {
  useBehaviorSubjectDispatch,
  useBehaviorSubjectSelector,
} from '@czaplej/use-behavior-subject';
import { BehaviorSubject } from 'rxjs';

import { Spis } from '../../../models/models';

type CreateSpisDoBrakowaniaStoreModel = {
  activeStep: number;
  znakSprawy: string;
  symbolJrwa: string;
  czyBrakujeKlasyJRWA: boolean;
  tytul: string;
  listaIdObiektDoBrakowania: string[];
  spisyDoWygenerowania: Spis[];
};
const initValue = {
  activeStep: 0,
  znakSprawy: '',
  czyBrakujeKlasyJRWA: false,
  symbolJrwa: '',
  tytul: '',
  listaIdObiektDoBrakowania: [],
  spisyDoWygenerowania: [],
};
const createSpisDoBrakowaniaStore$ = new BehaviorSubject<CreateSpisDoBrakowaniaStoreModel>(
  initValue
);

export const useCreateSpisDoBrakowaniaDispatch = () =>
  useBehaviorSubjectDispatch(createSpisDoBrakowaniaStore$);
export const useCreateSpisDoBrakowaniaSelector = <TState, TSelected>(
  selector: (store: CreateSpisDoBrakowaniaStoreModel) => TSelected
): TSelected =>
  useBehaviorSubjectSelector(selector, createSpisDoBrakowaniaStore$);

export const setInitValueCreateSpisDoBrakowania = () => {
  createSpisDoBrakowaniaStore$.next(initValue);
};
