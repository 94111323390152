import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import {
  EdytujPaczkeArchiwalnaRequestModel,
  GenerujPaczkeArchiwalnaRequestModel,
  GenerujSpisZdawczoZbiorczyRequestModel,
  PobierzPaczkeRequestModel,
  UsunPaczkeArchiwalnaRequestModel,
} from '../models/models';

export const ArchivingPackageServiceCommand = {
  GenerujSpisZdawczoZbiorczy: (
    request: GenerujSpisZdawczoZbiorczyRequestModel
  ) =>
    Axios.post<string>(getUrl('paczka-archiwalna/pliki/pobierz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  PobierzPaczke: (request: PobierzPaczkeRequestModel) =>
    Axios.post<string>(getUrl('paczka-archiwalna/pliki/pobierz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GenerujWniosekDoAp: (request: PobierzPaczkeRequestModel) =>
    Axios.post(getUrl('paczka-archiwalna/pliki/pobierz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GenerujPaczkeArchiwalna: (request: GenerujPaczkeArchiwalnaRequestModel) =>
    Axios.post(
      getUrl('paczka-archiwalna/generuj', '/proxy/archiwum'),
      request
    ).pipe(take(1)),
  UsunPaczkeArchiwalna: (request: UsunPaczkeArchiwalnaRequestModel) =>
    Axios.post(getUrl('paczka-archiwalna/usun'), request).pipe(take(1)),
  EdytujPaczkeArchiwalna: (request: EdytujPaczkeArchiwalnaRequestModel) =>
    Axios.post(getUrl('paczka-archiwalna/zmien-nazwe'), request).pipe(take(1)),
};
