import { MouseEvent, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { useApi } from '@nask/hooks';
import { getErrorResponse } from '@archiwum/utility/helper';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

import { useStyles } from './archiving-package-table.styles';
import { ArchivingPackageServiceQuery } from '../services/archiving-package-service-query';
import { ElementPaczki, PaczkaArchiwalna, TypObiekt } from '../models/models';
import { ArchivingPackageServiceCommand } from '../services/archiving-package-service-command';
import { Pobierz107Icon } from '@nask/ezdrp-icons';

type RenderDetailPanelProps = {
  rowData: PaczkaArchiwalna;
};

type LongMenuProps = {
  elementPaczki: ElementPaczki;
  id: string;
};

const LongMenu = ({ elementPaczki, id }: LongMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showSnackbar] = useSnackbar();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setUrl } = useApi<string>({
    onSuccess: (response) => {
      handleClose();
      window.open(response, '_blank');
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const handleDownloadDocument = () => {
    setUrl(
      ArchivingPackageServiceCommand.PobierzPaczke({
        elementPaczki: elementPaczki,
        idZestawPaczkiArchiwalnej: id,
      })
    );
  };

  return (
    <div style={{ marginRight: '2rem' }}>
      <Tooltip classes={{ tooltip: classes.tooltip }} title="otwórz">
        <IconButton
          aria-label="otwórz"
          aria-controls="long-menu"
          aria-haspopup="true"
          classes={{ root: classes.iconButton }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}> */}
        {/* <Box display="flex"> */}
        {/* <FontAwesomeIcon */}
        {/* style={{ */}
        {/* width: '1.6rem', */}
        {/* height: '1.6rem', */}
        {/* marginRight: '1.6rem', */}
        {/* }} */}
        {/* icon={faPencilAlt} */}
        {/* /> */}
        {/* <span>Zmień nazwę</span> */}
        {/* </Box> */}
        {/* </MenuItem> */}
        <MenuItem onClick={handleDownloadDocument}>
          <Pobierz107Icon className={classes.downloadIcon} />
          Pobierz dokument
        </MenuItem>
      </Menu>
    </div>
  );
};

export const RenderDetailPanel = ({ rowData }: RenderDetailPanelProps) => {
  const classes = useStyles();
  const [showSnackbar] = useSnackbar();
  const { data } = useApi({
    service: ArchivingPackageServiceQuery.GetObiektyPaczkiArchiwalnej({
      typObiektu: TypObiekt.SPRAWY,
      idZestawPaczkiArchiwalnej: rowData.id,
      // pageInfo: { pageNumber: 0, pageSize: 1000 },
    }),
  });

  return (
    <Box className={classes.detailPanel}>
      <Box
        ml={3}
        mr={3}
        borderLeft="1px solid #dbdbdb"
        borderRight="1px solid #dbdbdb"
      >
        <Box p={2}>ZAWARTOŚĆ PACZKI ARCHIWALNEJ</Box>
        <Box p={2} fontWeight={700} style={{ backgroundColor: '#efecf1' }}>
          Dokumenty towarzyszące
        </Box>
        <Box
          p={2}
          borderBottom="1px solid #dbdbdb"
          display="flex"
          alignItems="center"
        >
          <LongMenu id={rowData.id} elementPaczki={ElementPaczki.WNIOSEK} />
          <Typography variantType={TypographyVariants.Paragraph}>
            Wniosek o przekazanie materiałów archiwalnych
          </Typography>
        </Box>
        <Box
          p={2}
          borderBottom="1px solid #dbdbdb"
          display="flex"
          alignItems="center"
        >
          <LongMenu
            id={rowData.id}
            elementPaczki={ElementPaczki.SPIS_ZDAWCZO_ZBIORCZY}
          />
          <Typography variantType={TypographyVariants.Paragraph}>
            Spis zdawczo-odbiorczy
          </Typography>
        </Box>
        <Box
          p={2}
          borderBottom="1px solid #dbdbdb"
          display="flex"
          alignItems="center"
        >
          <LongMenu id={rowData.id} elementPaczki={ElementPaczki.PLIK_SHA256} />
          <Typography variantType={TypographyVariants.Paragraph}>
            Plik kontrolny
          </Typography>
        </Box>
        <Box p={2} display="flex" alignItems="center">
          <LongMenu id={rowData.id} elementPaczki={ElementPaczki.PLIK_JRWA} />
          <Typography variantType={TypographyVariants.Paragraph}>
            Plik JRWA
          </Typography>
        </Box>

        <Box p={2} fontWeight={700} style={{ backgroundColor: '#efecf1' }}>
          Załączone sprawy
        </Box>
        <Box p={2}>
          {data?.map((item) => (
            <Typography>{item.sygnatura}</Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
