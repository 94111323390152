import { Typography } from '@archiwum/ui/typography';
import { Container, Divider } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';
import { useStyles } from '../../data-list.style';
import { LabelModel } from '../../list-state';
import { useDataListContext } from '../../util/context';
//import { RowContainer } from './row-container';

type LabelsHeaderProps = {
  labels?: LabelModel[];
};

export const LabelsHeader: FunctionComponent<LabelsHeaderProps> = ({
  labels,
}) => {
  const { dispatch, store } = useDataListContext();
  const checkVisible = store.options?.selection;
  const actionsVisible = store.options?.showActionsButton;
  const gridTemplateColumns = `${
    store.options.preserveUnusedSpace || checkVisible ? '4rem ' : ' '
  }${store.options.preserveUnusedSpace || actionsVisible ? '4rem ' : ' '}${
    store.options.templateColumns ?? 'auto'
  }`;

  const classes = useStyles();
  return (
    <>
      <div className={classes.labelsRow} style={{ gridTemplateColumns }}>
        {checkVisible && <p />}
        {actionsVisible && (
          <Typography>
            <strong>Akcje</strong>
          </Typography>
        )}
        {labels?.map((column, index) => {
          return (
            <Typography key={index}>
              <strong>{column.labelText}</strong>
            </Typography>
          );
        })}
      </div>
      <Divider />
    </>
  );
};
