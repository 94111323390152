import React, { useEffect } from 'react';
import { useApi } from '@nask/hooks';
import { CaseServiceQuery } from './services/case-service-query';
import { useParams } from 'react-router';
import { TypObiekt } from './models/models';
import { RouterParams, Routes } from '@archiwum/routes';
import { Box, Container, Grid } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { actionsService$ } from '@archiwum/actions-handler';
import { useStyles } from './case.styles';
import { Button } from '@archiwum/ui/button';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { PoczekalniaModel } from '@archiwum/features/archiving-documents';
import { Loader } from '@nask/ui';
import { matchPath, useLocation } from 'react-router-dom';

import {
  AdditionalInfo,
  CaseInfoSection,
  MainInfo,
} from './components/case-info-section';
import { renderNadawca } from './helpers/render-nadawca';
import { getProperDate } from './helpers/get-proper-date';
import { CaseActs } from './components/case-acts';

const options = ['Zmień nazwę', 'Metadane', 'Pobierz na dysk', 'Historia'];
export const Case = () => {
  const dispatch = useDrawerRightDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const location = useLocation();
  const isCase = matchPath(location.pathname, Routes.CaseDetails.path) !== null;
  const { caseId } = useParams<RouterParams>();
  const classes = useStyles();

  //const sprawa: SprawaModel = data;
  const { data: sprawa } = useApi({
    service: CaseServiceQuery.GetCase({
      idObiekt: caseId,
      typObiekt: TypObiekt.SPRAWY,
    }),
  });

  const title = sprawa?.naglowek.tytul.value;
  const id = sprawa?.naglowek.idSprawa.value;
  const znakSprawy =
    sprawa?.naglowek.znak.value ?? sprawa?.naglowek.numer.value;
  const status = sprawa?.naglowek.status.value;
  const prowadzacySprawe = `${sprawa?.naglowek.pracownikProwadzacy.imie.value} ${sprawa?.naglowek.pracownikProwadzacy.nazwisko.value}`;

  useEffect(() => {
    if (sprawa) {
      const selectedCase = {
        numer: znakSprawy,
        znak: znakSprawy,
        tytul: title,
        id: caseId,
      } as PoczekalniaModel;
      dispatch({ data: [selectedCase] });
    }
  }, [sprawa]);

  if (!sprawa) {
    return (
      <Box display="grid" alignItems="center">
        <Loader show />
      </Box>
    );
  }
  return (
    <Container
      maxWidth="xl"
      disableGutters
      classes={{ root: classes.container }}
    >
      <Box display="flex" justifyContent="flex-end" p={2}>
        {buttons.map((button) => (
          <Box key={button.label} m={0.5} p={0.5}>
            <Button
              color="primary"
              variant="contained"
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          </Box>
        ))}
      </Box>
      <CaseInfoSection>
        <MainInfo
          typProwadzenia={sprawa?.naglowek?.typProwadzenia?.value}
          znak={sprawa?.naglowek?.znak?.value}
          pracownikProwadzacy={sprawa?.naglowek?.pracownikProwadzacy}
          kategoriaArchiwalna={sprawa?.naglowek?.kategoriaArchiwalna.value}
        />
        <AdditionalInfo
          status={sprawa?.naglowek?.status?.value}
          nadawca={renderNadawca(
            sprawa?.akta?.pisma[0]?.nadawcy[0],
            false,
            sprawa?.naglowek?.nadawcaDomyslny?.value ?? ''
          )}
          dataWszczecia={sprawa?.naglowek?.daty?.dataWszczecia?.value}
          proper2ndDate={getProperDate(
            sprawa?.naglowek?.status.value,
            sprawa?.naglowek?.daty
          )}
        />
        <Grid container item>
          <Typography variant="h4" variantType={TypographyVariants.Header4}>
            {sprawa?.naglowek?.tytul?.value}
          </Typography>
        </Grid>
      </CaseInfoSection>
      <CaseActs actsData={sprawa?.akta} />
    </Container>
  );
};

export default Case;

const buttons = [
  {
    label: 'Przekaż do poprawy',
    command: '*zadanie-do-poprawy.utworz',
    onClick: () => handleActionClick('*zadanie-do-poprawy.utworz', []),
  },
  {
    label: 'Odłóż',
    command: '*przenies-do-folderu.odlozone1',
    onClick: () => handleActionClick('*przenies-do-folderu.odlozone1', []),
  },
  {
    label: 'Archiwizuj',
    command: '*przenies-do-folderu.odlozone',
    onClick: () => handleActionClick('*przenies-do-folderu.odlozone', []),
  },
];

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, data),
  });
};

const getComponent = (command: string, data) => {
  const Archiwizuj = React.lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Archiwizuj,
    }))
  );
  const PrzekazDoPoprawy = React.lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.PrzekazDoPoprawy,
    }))
  );
  const Odloz = React.lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Odloz,
    }))
  );
  switch (command) {
    case '*przenies-do-folderu.odlozone':
      return <Archiwizuj showSearch={false} typObiekt={TypObiekt.SPRAWY} />;
    case '*przenies-do-folderu.odlozone1':
      return <Odloz showSearch={false} typObiekt={TypObiekt.SPRAWY} />;
    case '*zadanie-do-poprawy.utworz':
      return <PrzekazDoPoprawy typObiekt={TypObiekt.SPRAWY} />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
