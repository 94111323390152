import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { MenuModel } from './models/models';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton } from '@nask/ui';
import { useStyles } from './sidebar-link.styles';
import clsx from 'clsx';
import { getRouteById, getRouteIcon } from '@archiwum/routes';
import { Counter } from '@archiwum/counters';
import { getCounter } from '@archiwum/counters';
import { useActiveLink } from './use-active-link';

const menuId2counterIdMapping = [
  { menuId: 'SPRAWY_W_TOKU', counterId: 'SPRAWY_W_TOKU' },
  { menuId: 'BIURKO_WSPOLDZIELONE', counterId: 'BIURKO_WSPOLDZIELONE' },
  { menuId: 'BIURKO_ZAWIESZONE', counterId: 'BIURKO_ZAWIESZONE' },
  { menuId: 'BIURKO_ODLOZONE', counterId: 'BIURKO_ODLOZONE' },
  { menuId: 'BIURKO_WYROZNIKI', counterId: 'BIURKO_WYROZNIKI' },
  {
    menuId: 'KANCELARIA_PISMA_ZAREJESTROWANE',
    counterId: 'KANCELARIA_PISMA_ZAREJESTROWANE',
  },
  { menuId: 'PISMA_PRZYJETE', counterId: 'PISMA_PRZYJETE' },
  { menuId: 'ZADANIA_DO_REALIZACJI', counterId: 'ZADANIA_DO_REALIZACJI' },
  {
    menuId: 'ZADANIA_ZLECONE_DO_REALIZACJI',
    counterId: 'ZADANIA_ZLECONE_DO_REALIZACJI',
  },
  { menuId: 'ZADANIA_W_REALIZACJI', counterId: 'ZADANIA_W_REALIZACJI' },
  {
    menuId: 'ZADANIA_ZLECONE_W_REALIZACJI',
    counterId: 'ZADANIA_ZLECONE_W_REALIZACJI',
  },
  { menuId: 'ARCHIWUM_POCZEKALNIA', counterId: 'POCZEKALNIA' },
  {
    menuId: 'ARCHIWUM_GOTOWE_DO_ARCHIWIZACJI',
    counterId: 'POCZEKALNIA-GOTOWEDOARCHIWIZACJI',
  },
  {
    menuId: 'ARCHIWUM-POCZEKALNIA-DOPOPRAWY',
    counterId: 'POCZEKALNIA-DOPOPRAWY',
  },
  {
    menuId: 'ARCHIWUM-POCZEKALNIA-POPRAWIONE',
    counterId: 'POCZEKALNIA-POPRAWIONE',
  },
  {
    menuId: 'ARCHIWUM-POCZEKALNIA-ODLOZONE',
    counterId: 'POCZEKALNIA-ODLOZONE',
  },
];

type SidebarLinkProps = {
  route: MenuModel;
  //counters?: LicznikModel[];
  deep?: boolean;
};
export const SidebarLink = (props: SidebarLinkProps) => {
  const { route, deep } = props;
  const history = useHistory();
  const path = getRouteById(route.id, route.url);
  // const match = useRouteMatch({ path: path, exact: false });
  // const isActive = match !== null;
  const [open, setOpen] = useState(false);
  const { active } = useActiveLink(path, deep);
  const hasIcon = route.lista && !route.czyRozwiniety;
  const classes = useStyles({ isActive: active, open: open && active, deep });
  const counterId = menuId2counterIdMapping.find(
    (item) => item.menuId === route.id
  )?.counterId;
  const counter = getCounter(counterId);

  const handleOpenChildren = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prevState) => !prevState);
  };

  const handleRouteClick = () => {
    if (route.url) {
      window.location.href = route.url;
    } else {
      history.push(path);
    }
  };

  useEffect(() => {
    if (route.id === 'ARCHIWUM' || route.czyRozwiniety === true) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <ListItem
        button
        key={route.nazwa}
        onClick={handleRouteClick}
        classes={{
          root: clsx(classes.listItem, { [classes.open]: open && active }),
        }}
      >
        <ListItemIcon
          classes={{ root: clsx(classes.linkIcon, classes.linkIconSize) }}
        >
          {getRouteIcon(route.id)}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.routeText, root: classes.padding }}
          primary={route.nazwa}
        />
        {counter && <Counter data={counter} />}
        {hasIcon && (
          <IconButton
            style={{ padding: '0' }}
            color={'inherit'}
            onClick={handleOpenChildren}
          >
            <ChevronRightIcon
              classes={{
                root: clsx(classes.linkIcon, {
                  [classes.iconOpen]: open,
                  [classes.iconClose]: !open,
                }),
              }}
            />
          </IconButton>
        )}
      </ListItem>
      <Collapse
        mountOnEnter
        in={open}
        classes={{
          wrapper: classes.collapseWrapper,
          entered: classes.collapseEntered,
        }}
      >
        <List classes={{ root: classes.list }}>
          {route.lista?.map((routeItem) => (
            <SidebarLink
              key={routeItem.nazwa}
              route={routeItem}
              // counters={counters?.values.filter((item) =>
              //   item.id.startsWith(routeItem.id)
              //              )}
              deep
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
