import React, { memo, useState } from 'react';
import { Divider } from '@material-ui/core';
import { useStyles } from '../../case.styles';
import { AktaModel, DokumentModel, PismoModel } from '../../models/models';
import { CaseSection } from '../case-section';
import { prepareActs } from '../../helpers/get-acts';
import { DataList } from '@archiwum/shared/data-list';
import { ListRowDelivery } from './list-row-delivery';
import { ListRowDocument } from './list-row-document';
import { Typography } from '@archiwum/ui/typography';

type CaseActsProps = {
  actsData: AktaModel;
};

export const CaseActs = memo((props: CaseActsProps) => {
  const classes = useStyles();
  const { actsData } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleSearch = (phrase: string) => {
    // something to do with the search phrase
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const acts = {
    pisma: actsData.pisma.slice(),
    dokumenty: actsData.dokumenty.slice(),
  };

  const prepared = prepareActs(acts);

  return (
    <CaseSection id="case-acts">
      <DataList<PismoModel | DokumentModel>
        data={prepared}
        title="AKTA SPRAWY"
        options={{
          templateColumns: 'auto 28rem 10rem',
          preserveUnusedSpace: false,
          selection: false,
          showClearButton: false,
          showActionsButton: true,
          showColumnLabels: true,
          labels: [
            { labelText: 'Tytuł' },
            { labelText: 'Status' },
            { labelText: 'Data' },
          ],
        }}
        isActionsButtonVisible={(data: PismoModel | DokumentModel) =>
          data?.hasOwnProperty('idDokument') ? true : false
        }
        renderBody={(data: PismoModel | DokumentModel) => {
          if (data?.hasOwnProperty('idPismo'))
            return <ListRowDelivery rowData={data as PismoModel} />;
          if (data?.hasOwnProperty('idDokument'))
            return <ListRowDocument rowData={data as DokumentModel} />;
          return <Typography>unknown type of row</Typography>;
        }}
      />
      <Divider />
    </CaseSection>
  );
});
