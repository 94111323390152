import {
  FilteringModel,
  OwnQuery,
  SubjectServiceModel,
} from '@archiwum/shared/table';
import { TasksServiceQuery } from './services/tasks-service-query';

export function getZadaniaOtrzymaneQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters: FilteringModel[],
  dispatch: (data: unknown) => void
) {
  TasksServiceQuery.GetZadaniaUtworzone({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}

export function getZadaniaWystawioneQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters: FilteringModel[],
  dispatch: (data: unknown) => void
) {
  TasksServiceQuery.GetZadaniaPrzydzielone({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}
