import { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Form, FormInput, FormRadio, FormRadioGroup } from '@archiwum/forms';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

import { useStyles } from '../create-spis-do-brakowania.styles';
import { useFormStepCasesFacade } from './use-form-step-cases-facade';
import { FormStepCasesModel } from './use-step-cases-facade';
import { Loader } from '@nask/ui';
import { ExclamationInfoContainer } from '@archiwum/shared/exclamation-info-container';
import { useCreateSpisDoBrakowaniaDispatch } from '../create-spis-do-brakowania-store';

export const FormStepCases = () => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormStepCasesModel>();
  const classes = useStyles();
  const dispatch = useCreateSpisDoBrakowaniaDispatch();

  const {
    listaKlasJRWADoAP,
    jRWAIsLoading,
    listaKlasJRWADoAPMissing,
    listaSprawTechnicznych,
  } = useFormStepCasesFacade();

  useEffect(() => {
    if (values.dolaczanieDoSprawy === 'nowa') {
      setFieldValue('znakSprawy', '');
      setFieldTouched('idWykaz', false);
      setFieldTouched('nazwaSprawy', false);
      dispatch({ czyBrakujeKlasyJRWA: listaKlasJRWADoAPMissing });
    }
    if (values.dolaczanieDoSprawy === 'istniejaca') {
      setFieldValue('idWykaz', '');
      setFieldValue('czyBrakujeKlasyJRWA', false);
      setFieldTouched('znakSprawy', false);
      dispatch({ czyBrakujeKlasyJRWA: false });
    }
  }, [values.dolaczanieDoSprawy]);

  useEffect(() => {
    setFieldValue(
      'kategoriaArchiwalna',
      listaKlasJRWADoAP
        ?.filter((x) => x.id === values.idWykaz)?.[0]
        ?.kategorieArchiwalne?.filter((y) => y.czyGlowna === true)?.[0]?.katArch
    );
  }, [values.idWykaz]);

  return (
    <Form id={'brakowanie-step-cases'}>
      <FormRadioGroup name="dolaczanieDoSprawy">
        <FormRadio
          key={'nowa-sprawa'}
          value={'nowa'}
          label={'Dołączenie spisu do nowej sprawy'}
        />
        {values.dolaczanieDoSprawy === 'nowa' && (
          <>
            <Box pb={3} pl={3} className={classes.wrapper}>
              <Box pt={0.5} pb={0.5}>
                <FormInput name={'nazwaSprawy'} label={'Tytuł sprawy'} />
              </Box>
              {jRWAIsLoading ? (
                <Loader show />
              ) : listaKlasJRWADoAPMissing ? (
                <Box mt={3}>
                  <ExclamationInfoContainer
                    contentInfo={
                      <span>
                        Uwaga!
                        <br />
                        Brak wskazanych klas w JRWA powiąznych z brakowaniem.
                      </span>
                    }
                  />
                </Box>
              ) : (
                <FormRadioGroup name="idWykaz">
                  {listaKlasJRWADoAP?.map((item) => (
                    <>
                      <FormRadio
                        key={`item-${item.id}`}
                        value={item.id}
                        label={`[${item.symbol}] ${item.nazwa}`}
                      />
                      <Divider />
                    </>
                  ))}
                </FormRadioGroup>
              )}
            </Box>
          </>
        )}

        <FormRadio
          key={'istniejaca-sprawa'}
          value={'istniejaca'}
          label={'Dołączenie spisu do istniejącej sprawy'}
        />
        {values.dolaczanieDoSprawy === 'istniejaca' && (
          <Box pb={3} pl={3} className={classes.wrapper}>
            {listaSprawTechnicznych && listaSprawTechnicznych.length > 0 ? (
              <>
                <Box className={classes.row} fontWeight={700}>
                  <span></span>
                  <Typography
                    className={classes.tableHeader}
                    variantType={TypographyVariants.Paragraph}
                  >
                    Znak sprawy
                  </Typography>
                </Box>
                <Divider />
                <FormRadioGroup name="znakSprawy">
                  {listaSprawTechnicznych?.map((item) => (
                    <>
                      <FormRadio
                        key={`item-${item.znakSprawy}`}
                        value={item.znakSprawy}
                        label={item.znakSprawy}
                      />
                      <Divider />
                    </>
                  ))}
                </FormRadioGroup>
              </>
            ) : (
              <Typography
                className={classes.textInfo}
                variantType={TypographyVariants.Paragraph}
              >
                Brak spraw
              </Typography>
            )}
          </Box>
        )}
      </FormRadioGroup>
    </Form>
  );
};
