import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch, matchPath } from 'react-router-dom';
import { Box, Divider, Paper, Tab } from '@material-ui/core';
import { DrawerRight, useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Tabs } from '@archiwum/ui/tabs';
import { Routes } from '@archiwum/routes';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';

import { useStyles } from './brakowanie.styles';
import { useBrakowanieFacade } from './use-brakowanie-facade';
import { WytypowaneDoBrakowania } from './wytypowane-do-brakowania/views/wytypowane-do-brakowania';
import { SpisyBrakowania } from './spisy-brakowania/views/spisy-brakowania';
import { ProcesyBrakowania } from './procesy-brakowania/views/procesy-brakowania';

type SpisSprawLayoutProps = {};

export const Brakowanie = (props: SpisSprawLayoutProps) => {
  const { tabs, activeTab, handleChangeTab } = useBrakowanieFacade();
  const drawerDispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => {
      drawerDispatch({ selected: [], data: [] });
    };
  }, []);

  useEffect(() => {
    if (
      matchPath(history.location.pathname, {
        path: Routes.Brakowanie.path,
        exact: true,
      }) !== null
    ) {
      history.replace(Routes.BrakowanieWytypowaneDoBrakowania.path);
    }
  }, [history.location.pathname]);

  return (
    <>
      <Box>
        <Paper square elevation={0}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              classes={{ root: classes.header }}
              variantType={TypographyVariants.Header2}
            >
              Brakowanie akt
            </Typography>
            {/* {activeTab.label === 'Brakowanie' && (
              <SummaryCounter
              />
            )} */}
          </Box>
          <Tabs
            classes={{ root: classes.tabs, indicator: classes.indicator }}
            TabIndicatorProps={{ children: <span /> }}
            value={activeTab}
            onChange={handleChangeTab}
          >
            {tabs.map((tab) => (
              <Tab
                classes={{ root: classes.tab, selected: classes.tabSelected }}
                key={tab.label}
                label={tab.label}
                value={tab}
              />
            ))}
          </Tabs>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex">
        <Box width="100%">
          <NavigationBreadcrumbs />
          <Switch>
            <Route
              exact
              path={Routes.BrakowanieWytypowaneDoBrakowania.path}
              component={WytypowaneDoBrakowania}
            />
            <Route
              exact
              path={Routes.BrakowanieSpisy.path}
              component={SpisyBrakowania}
            />
            <Route
              exact
              path={Routes.BrakowanieProcesy.path}
              component={ProcesyBrakowania}
            />
          </Switch>
        </Box>
        <DrawerRight />
      </Box>
    </>
  );
};
