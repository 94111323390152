import { Column } from 'material-table';
import { useEffect, useState } from 'react';

import { PropType } from '../table-model';
import { filterSubject } from '../toolbar';
import { TableProps } from '../wtable';
import { sneakyMapColumn } from './methods';
import { UseTableFacadeProps } from './use-table-facade';

type UseTableColumnsProps<T extends object> = UseTableFacadeProps<T> & {};

function getShowedCol<T extends object>(
  columns: PropType<UseTableColumnsProps<T>, 'columns'>
) {
  return columns.map((x) => x.field);
}

export const useTableColumns = <T extends object>(
  props: UseTableColumnsProps<T>
) => {
  const filteredColumns = props.columns.filter((x) => x.field);
  const [columns, setColumns] = useState<PropType<TableProps<T>, 'columns'>>(
    filteredColumns
  );
  const showed = getShowedCol(filteredColumns);
  const [showedColumns, setShowedColumns] = useState<Array<string | keyof T>>(
    []
  );
  // TODO:
  // const [showedColumns, setShowedColumns] = useState<Array<string | keyof T>>(showed);

  function updateColumns(
    mapColumns: Array<Column<T>>,
    updatedShowedColumns: Array<string | keyof T>
  ) {
    mapColumns.push();
    setColumns(mapColumns);
    setShowedColumns(updatedShowedColumns);
  }

  useEffect(() => {
    const subscription = filterSubject?.subscribe({
      next: (updatedShowedColumns) => {
        const showAcceptedFields = filteredColumns.filter((x) =>
          updatedShowedColumns.includes(x.field)
        );
        const mapColumns = sneakyMapColumn<T>(showAcceptedFields);
        updateColumns(mapColumns, updatedShowedColumns);
      },
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const showedCol = getShowedCol(filteredColumns);
    const showAcceptedFields = showedCol
      ? filteredColumns.filter((x) => showedCol.includes(x.field))
      : filteredColumns;
    const mapColumns = sneakyMapColumn<T>(showAcceptedFields);
    setColumns(mapColumns);
  }, []);

  return {
    columns,
    showedColumns,
  };
};
