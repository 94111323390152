import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import { ZadaniaFiltry, ZadanieModel } from '../models/models';

export const TasksServiceQuery = {
  GetZadaniaUtworzone: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<ZadanieModel>>(
      getUrl('zadania/utworzone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetZadaniaPrzydzielone: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<ZadanieModel>>(
      getUrl('zadania/przydzielone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),

  GetZadaniaFiltry: () =>
    Axios.post<ZadaniaFiltry>(getUrl('/zadania/filtry'), {}).pipe(
      take(1),
      map((data) => data.data)
    ),
};
