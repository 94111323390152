import { ChangeEvent, useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Wyszukiwanie012Icon } from '@nask/ezdrp-icons';
import { useDebounce } from '@nask/hooks';
import { Input } from '@archiwum/shared/input';
import { theme } from '@nask/theme';

type SearchInputProps = {
  label?: string;
  disabled?: boolean;
  delay?: number;
  searchMethod: (value: string) => unknown;
};

export const SearchInput = ({
  label,
  disabled,
  delay,
  searchMethod,
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const debouncedQuery = useDebounce(searchValue, delay ?? 450);
  useEffect(() => {
    searchMethod(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <Input
      disabled={disabled}
      defaultValue={debouncedQuery}
      label={label ?? 'Wyszukaj'}
      onChange={(e) => onChange(e)}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Wyszukiwanie012Icon
              fontSize={'1.8px'}
              color={theme.typography.descriptionColor}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
