import { useDrawerRightSelector } from '@archiwum/drawer-right';
import { useQueryStream } from '@archiwum/hooks';
import { useApi } from '@nask/hooks';
import { PoczekalniaModel, TypObiekt } from '../../../models/models';
import { ArchivingDocumentsServiceQuery } from '../../../services/archiving-documents-service-query';

export type UseHistoriaFacade = {
  typObiekt: TypObiekt;
};

export const useHistoriaFacade = (props: UseHistoriaFacade) => {
  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as PoczekalniaModel[];
  const { id, znak, numer } = selected?.length > 0 && selected[0];

  const { isLoading, data } = useApi({
    service: ArchivingDocumentsServiceQuery.GetHistoria({ idObiektu: id }),
  });
  return { id, znak, numer, isLoading, data };
};
