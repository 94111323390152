import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import {
  CaseResponseModel,
  GetCaseRequestModel,
  GetElementyDoPoprawyRequestModel,
  GetRequestFiltry,
  GetSpisSprawRequestModel,
  SprawyFiltry,
} from '../models/models';
import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';

export const CaseServiceQuery = {
  GetCase: (request: GetCaseRequestModel) =>
    Axios.post<CaseResponseModel>(
      getUrl('wizualizator/obiekt/pobierz'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSpisSpraw: (request: QueryViewRequestBase<GetSpisSprawRequestModel>) =>
    Axios.post<PagedResultDto<CaseResponseModel>>(
      getUrl('folder/sprawy/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(getUrl('folder/sprawy/filtry'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetElementyDoPoprawy: (request: GetElementyDoPoprawyRequestModel) =>
    Axios.post(getUrl('elementy-do-poprawy/pobierz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSpisNAS: (request: QueryViewRequestBase<GetSpisSprawRequestModel>) =>
    Axios.post<PagedResultDto<CaseResponseModel>>(
      getUrl('folder/pisma-nas/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetNASFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(getUrl('folder/pisma-nas/filtry'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
};
