import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    counterValue: { color: theme.colors.focused },
    counterIcon: { fontSize: spacing(4) },
    smallerCounterIcon: { fontSize: spacing(3) },

    paper: {
      boxShadow: '-1px 3px 25px 3px rgb(172 167 184 / 46%)',
    },
  })
);
