import React, { ReactNode, useState } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { DeliveryRow } from '../../types/acts-table-types';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { TableRowDocument } from './table-row-document';
import { useStyles } from '../../case.styles';
import { StatusArea } from './status-area';
import { getShortDate } from 'libs/shared/table/src/lib/hooks/methods';
import { TableHeaderRow } from './table-header-row';
import clsx from 'clsx';
import { DataListBodyRow } from '@archiwum/shared/data-list';
import { PismoModel } from '../../models/models';

type ListRowDeliveryProps = {
  rowData: PismoModel;
};

export const ListRowDelivery = ({ rowData }: ListRowDeliveryProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const haveDocs = rowData?.dokumenty?.length > 0;
  const status = {
    m: rowData?.metadane ? true : false,
    s: false,
  };

  return (
    <>
      <Typography variantType={TypographyVariants.Paragraph}>
        <strong>{rowData?.numerRPW?.value}</strong> {rowData?.tytul?.value}
        {haveDocs && (
          <IconButton onClick={handleToggle} size="medium">
            {isOpen ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </IconButton>
        )}
      </Typography>
      <StatusArea actId={rowData?.idPismo?.value} status={status} />
      <Typography variantType={TypographyVariants.Paragraph}>
        {getShortDate(rowData?.daty?.dataUtworzenia?.value)}
      </Typography>
      <Collapse
        timeout="auto"
        in={isOpen}
        unmountOnExit
        style={{
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: -1,
        }}
      >
        {rowData?.dokumenty?.map((doc, index) => {
          return <DataListBodyRow key={index} rowData={doc} />;
        })}
      </Collapse>
    </>
  );
};
