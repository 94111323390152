import { Box } from '@material-ui/core';
import { useRef } from 'react';
import { TableRef } from '@archiwum/shared/table';

import { SpisBrakowania } from '../../models/models';
import { ObiektySpisuBrakowaniaTable } from './obiekty-spisu-brakowania-table';
import { getObiektySpisuBrakowaniaQuery } from '../../helper';
import { useStyles } from '../../brakowanie.styles';
type RenderDetailsPanelProps = {
  rowData: SpisBrakowania;
};

export const RenderDetailsPanel = ({ rowData }: RenderDetailsPanelProps) => {
  const tableRef = useRef<TableRef>();
  const classes = useStyles();

  return (
    <Box pl={9} pt={6} pb={6} className={classes.detailPanel}>
      <ObiektySpisuBrakowaniaTable
        tableRef={tableRef}
        data={(query, result) => {
          getObiektySpisuBrakowaniaQuery(
            query,
            result,
            '',
            [],
            undefined,
            rowData.id
          );
        }}
      />
    </Box>
  );
};
