import { DependencyList, useEffect } from 'react';

import { actionsStore$ } from './actions-handler';
import { ActionStore$ } from './models';

type UseRefreshProps<T> = Partial<ActionStore$<T>> & {
  handleRefresh?: (data?: T) => void;
  deps?: DependencyList;
};

export const useRefresh = <T extends unknown>(props: UseRefreshProps<T>) => {
  const { handleRefresh, type, deps } = props;
  const compareValue = type ?? 'refresh';
  const upDeps = deps ?? [];
  useEffect(() => {
    const subscription = actionsStore$.subscribe({
      next: (value) => {
        if (value.type === compareValue) {
          const data = value.payload?.['data'];
          handleRefresh?.(data);
        }
      },
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, upDeps);
};
