import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { theme } from '@nask/theme';

type Props = {
  isActive: boolean;
  open: boolean;
  deep?: boolean;
};
export const useStyles = makeStyles<Theme, Props>(({ transitions, spacing }) =>
  createStyles({
    linkIcon: {
      // color: ({ isActive, open }) =>
      //   open
      //     ? theme.sidebar.bgColor
      //     : isActive
      //     ? theme.colors.focused
      //     : theme.sidebar.color,
      color: 'inherit',
      fontSize: '2rem',
    },
    linkIconSize: {
      minWidth: 24,
    },
    iconOpen: {
      transform: 'rotate(90deg)',
      transition: transitions.create('transform', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.enteringScreen,
      }),
    },
    iconClose: {
      transform: 'rotate(0deg)',
      transition: transitions.create('transform', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.leavingScreen,
      }),
    },
    collapseWrapper: {
      backgroundColor: theme.sidebar.backgroundSubMenu,
    },
    collapseEntered: {},
    routeText: {
      fontWeight: ({ isActive, deep }) => (deep && isActive ? 700 : 'inherit'),
      fontSize: theme.typography.fontSizeLarger,
      whiteSpace: 'pre-wrap',
    },
    open: {
      backgroundColor: theme.sidebar.selectMainRoot,
      '&:hover': {
        backgroundColor: theme.sidebar.selectMainRoot,
      },
    },
    padding: {
      paddingLeft: ({ deep }) => (deep ? 0 : spacing(1)),
      paddingRight: spacing(1),
      marginTop: '0',
      marginBottom: '0',
    },
    listItem: {
      transition: 'all 0.2s ease-in-out 0s',
      backgroundColor: ({ deep, isActive }) =>
        deep && isActive && theme.sidebar.selectSubMenu,
      padding: ({ deep }) =>
        deep ? '0.7rem 1.5rem 0.8rem 2.5rem' : '0.8rem 1.5rem 0.9rem',
      borderBottom: ({ deep }) =>
        deep
          ? `1px solid ${theme.sidebar.backgroundSubMenu}`
          : `1px solid ${theme.sidebar.border}`,
      color: ({ isActive, open }) =>
        open
          ? theme.sidebar.bgColor
          : isActive
          ? theme.colors.focused
          : theme.sidebar.color,
      '&:hover': {
        color: ({ isActive, open, deep }) =>
          deep ? theme.colors.focused : theme.sidebar.bgColor,
        backgroundColor: ({ deep, isActive }) =>
          deep ? theme.sidebar.selectSubMenu : theme.sidebar.selectMainRoot,
      },
      '&:focus': {
        color: ({ isActive, open, deep }) =>
          deep ? theme.colors.focused : theme.sidebar.bgColor,
        backgroundColor: ({ deep, isActive }) =>
          deep ? theme.sidebar.selectSubMenu : theme.sidebar.selectMainRoot,
      },
      '&:focus-within': {
        color: ({ isActive, open, deep }) =>
          deep ? theme.colors.focused : theme.sidebar.bgColor,
        backgroundColor: ({ deep, isActive }) =>
          deep ? theme.sidebar.selectSubMenu : theme.sidebar.selectMainRoot,
      },
    },
    list: {
      padding: '0',
    },
  })
);
