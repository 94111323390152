import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { useStyles } from './drawer-right.styles';
import { BehaviorSubject } from 'rxjs';
import {
  useBehaviorSubjectDispatch,
  useBehaviorSubjectSelector,
} from '@czaplej/use-behavior-subject';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

type DrawerSelectModel = {
  data: unknown[];
  command: string;
  label: string;
  onClick: () => void;
};
type DrawerRightMenuState = {
  data: unknown[];
  selected: DrawerSelectModel[];
};
const drawerRightStore$ = new BehaviorSubject<DrawerRightMenuState>({
  data: [],
  selected: [],
});
export const useDrawerRightSelector = <
  TState extends DrawerRightMenuState,
  TSelected
>(
  selector: (state: TState) => TSelected
) => useBehaviorSubjectSelector(selector, drawerRightStore$);
export const useDrawerRightDispatch = () =>
  useBehaviorSubjectDispatch(drawerRightStore$);

export const DrawerRight = () => {
  const location = useLocation();
  const drawerState = useDrawerRightSelector((state) => state);
  const { selected, data } = drawerState;

  const dispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const drawerOpen = data.length > 0;
  useEffect(() => {
    dispatch({ selected: [], data: [] });
  }, [location.pathname, location.search]);
  return (
    <Drawer
      classes={{
        root: classes.sticky,
        paper: clsx(classes.root, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      open={drawerOpen}
      variant="permanent"
      anchor="right"
    >
      <List>
        {selected.map((item) => (
          <ListItem key={item.command} button onClick={item.onClick}>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={item.label}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
