import { getRouteById, Routes } from '@archiwum/routes';
import { MenuModel } from '@archiwum/sidebar';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';

export type PathModel = {
  path: string;
  url: string;
  name: string;
  exclude?: boolean;
  params?: { [k in keyof {}]?: string };
};

export function recursiveFindDeep(menu: MenuModel[], id: string) {
  const find = menu?.find((x) => x.id === id);
  if (find) {
    return find;
  } else {
    const findChild = menu?.find((x) => recursiveFindDeep(x.lista, id));
    if (findChild) return findChild;
  }
}

export function getRecursiveStringName(
  findElement: MenuModel,
  id: string,
  result?: string
) {
  const find = findElement?.id === id;
  if (find) {
    return result
      ? `${result} ${findElement?.nazwa.toLowerCase()}`
      : findElement?.nazwa;
  } else {
    if (findElement?.lista) {
      // result = result ? `${result} ${findElement?.nazwa.toLowerCase()}` : findElement?.nazwa;
      for (const findChild of findElement.lista) {
        result = getRecursiveStringName(findChild, id, result);
      }
    }
  }
  return result;
}

export const findNameFromMenu = (
  menu: MenuModel[],
  id: string,
  defaultValue?: string
): string => {
  const model = recursiveFindDeep(menu, id);
  const result = getRecursiveStringName(model, id);
  return result ?? defaultValue;
};

export const getMenuIdFromPath = (path: string, menu: MenuModel[]) => {
  if (menu) {
    for (const m of menu) {
      const link = getRouteById(m.id, Routes.Home.path);
      if (link === path) {
        return m.id;
      }
      if (m.lista) {
        const url = getMenuIdFromPath(path, m.lista);
        if (url) {
          return url;
        }
      }
    }
  }
};

const getParams = <Params extends { [K in keyof Params]?: string }>(
  pathname,
  paths,
  exact?: boolean
): match<Params> | null => {
  const matchProfile = matchPath<Params>(pathname, {
    path: paths.map((x) => x.path),
    strict: true,
    exact: exact ?? false,
  });
  return matchProfile;
};

const checkParamsInsidePathname = (
  pathname: string,
  editPaths: PathModel[],
  findPath: match
): boolean => {
  if (findPath == null) {
    return true;
  }
  let paramsList = [];
  const currentPath = getParams(pathname, editPaths, true);
  if (currentPath) {
    paramsList = Object.keys(currentPath.params);
  }
  const findPathParams = findPath.params ? Object.keys(findPath.params) : [];
  return (
    intersection(paramsList, findPathParams).length === findPathParams.length
  );
};

function createBreadcrumbsArray(
  pathname: string,
  editPaths: PathModel[],
  result: PathModel[]
) {
  const findPath = getParams(pathname, editPaths);
  if (
    findPath !== null &&
    checkParamsInsidePathname(pathname, editPaths, findPath)
  ) {
    let find = editPaths.find((x) => x.path === findPath.path);
    if (find) {
      if (result.find((x) => x.path === findPath.url) == null)
        result.push({
          name: find.name,
          path: findPath.path,
          url: findPath.url,
          params: isEmpty(findPath.params) ? undefined : findPath.params,
        });
    }
    const deletePushedObject = editPaths.filter(
      (x) => x.path !== findPath.path
    );
    createBreadcrumbsArray(pathname, deletePushedObject, result);
  }
}

export const getBreadcrumbsArray = (
  pathname: string,
  paths: PathModel[]
): PathModel[] => {
  let result: PathModel[] = [];
  const editPaths = [...paths.filter((x) => !x.exclude)];
  createBreadcrumbsArray(pathname, editPaths, result);
  return result;
};

export const updateBreadcrumbsWithMenu = (
  paths: PathModel[],
  menu: MenuModel[]
) => {
  return paths.map((x, index) => {
    if (x.params) {
      const values = Object.values(x.params);
      const updatedName = findNameFromMenu(
        menu,
        values[values.length - 1] + ''
      );
      if (updatedName) {
        return { ...x, name: updatedName };
      } else {
        return x;
      }
    } else {
      const id = getMenuIdFromPath(x.path, menu);
      const updatedName = findNameFromMenu(menu, id);
      if (updatedName) {
        return { ...x, name: updatedName };
      } else {
        return x;
      }
    }
  });
};
