import { InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EventIcon from '@material-ui/icons/Event';
import { useDeepMemo, useFormDatepicker } from '@nask/hooks';
import { IconButton } from '@nask/ui';
import { DatePickerProps } from '@material-ui/pickers';
import { DatePicker } from '@archiwum/shared/date-pickers';

import { InputContainer } from '../styles';
import moment from 'moment';

export type FormDatePickerProps<Values> = Omit<
  DatePickerProps,
  'value' | 'onChange'
> & {
  notCallBlurOnClose?: boolean;
};

const FormDatePicker = <T extends unknown>({
  name,
  notCallBlurOnClose,
  ...props
}: FormDatePickerProps<T>) => {
  const {
    field,
    state,
    handleBlur,
    handleChange,
    context,
  } = useFormDatepicker<string>({
    name,
  });
  const { onChange: fieldOnChange, onBlur: fieldOnBlur, ...restField } = field;

  return useDeepMemo(
    () => (
      <>
        <InputContainer>
          <DatePicker
            {...restField}
            onChange={(date) => {
              handleChange(date);
            }}
            onClose={() => {
              if (!notCallBlurOnClose) {
                handleBlur();
              }
            }}
            onBlur={(event) => {
              handleBlur();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {field.value && (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            todayLabel={'Dzisiaj'}
            clearLabel={'Usuń'}
            showTodayButton
            {...state}
            {...props}
          />
        </InputContainer>
      </>
    ),
    [props, restField, state, context.validateOnBlur]
  );
};

export default FormDatePicker;
