import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

interface ReadOnlyFormInputProps {
  label: string;
  value: string | React.ReactNode;
}
const ReadOnlyFormInput = memo(({ label, value }: ReadOnlyFormInputProps) => {
  if (!value) {
    return null;
  }
  return (
    <FormLabel>
      <FormLabelText>{label}</FormLabelText>
      <FormLabelValue>{value}</FormLabelValue>
    </FormLabel>
  );
});
export default ReadOnlyFormInput;

const FormLabel = styled.div`
  box-sizing: border-box;
  display: grid;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
`;
const FormLabelText = styled.div`
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;
const FormLabelValue = styled.div`
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-size: 1.4rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875em;
`;
