import { useSnackbar } from '@archiwum/shared/toast-notification';
import { useDrawerRightSelector } from '@archiwum/drawer-right';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { actionsService$ } from '@archiwum/actions-handler';
import { getErrorResponse } from '@archiwum/utility/helper';
import * as yup from 'yup';
import { useMutationStream } from '@archiwum/hooks';

import { BrakowanieServiceCommand } from '../../../../services/brakowanie-service-command';
import {
  BrakowanieModel,
  GenerujSpisyBrakowaniaRequestModel,
  TypObiekt,
} from '../../../../models/models';
import { useCreateSpisDoBrakowaniaSelector } from '../create-spis-do-brakowania-store';

export type FormStepCasesModel = {
  dolaczanieDoSprawy: string;
  nazwaSprawy: string;
  idWykaz: string;
  kategoriaArchiwalna: string;
  znakSprawy: string;
};

type UseStepCasesFacadeProps = {
  handleClose: () => void;
  typObiekt: TypObiekt;
};

export const useStepCasesFacade = ({
  handleClose,
  typObiekt,
}: UseStepCasesFacadeProps) => {
  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as BrakowanieModel[];

  const { spisyDoWygenerowania } = useCreateSpisDoBrakowaniaSelector(
    (state) => state
  );
  const [showSnackbar] = useSnackbar();
  const { mutateAsync, isLoading } = useMutationStream<
    unknown,
    unknown,
    GenerujSpisyBrakowaniaRequestModel
  >('generuj-spisy-brakowania', (variables) =>
    BrakowanieServiceCommand.GenerujSpisyBrakowania(variables)
  );

  const initialValues: FormStepCasesModel = {
    dolaczanieDoSprawy: '',
    nazwaSprawy: '',
    idWykaz: '',
    kategoriaArchiwalna: '',
    znakSprawy: '',
  };

  const validationSchema: yup.SchemaOf<FormStepCasesModel> = yup
    .object()
    .shape({
      dolaczanieDoSprawy: yup.string().required('Pole wymagane'),
      nazwaSprawy: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'nowa',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
      idWykaz: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'nowa',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
      kategoriaArchiwalna: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'nowa',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
      znakSprawy: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'istniejaca',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
    });

  const onSubmit = async (values: FormStepCasesModel) => {
    try {
      await mutateAsync({
        typObiektu: typObiekt,
        listaIdObiektDoBrakowania: selected.map((x) => x.id),
        tytul: values.nazwaSprawy,
        znakSprawy: values.znakSprawy,
        idWykaz: values.idWykaz,
        kategoriaArchiwalna: values.kategoriaArchiwalna,
        spisyDoWygenerowania: spisyDoWygenerowania,
      });
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      handleClose();
      actionsService$.refresh();
    } catch (ex) {
      showSnackbar(getErrorResponse(ex), { variant: 'error' });
    }
  };

  return {
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};
