import { useEffect } from 'react';
import { SettingServiceQuery } from '../services/settings-service-query';
import {
  EZDRP_System_PotwierdzeniePrawidlowejOperacji,
  EZDRP_System_RozmiarStrony,
} from '../constants/settings-constants';

export const useGetSettings = () => {
  const lsRozmiarStrony = localStorage.getItem(EZDRP_System_RozmiarStrony);
  const lsPotwierdzeniePrawidlowejOperacji = localStorage.getItem(
    EZDRP_System_PotwierdzeniePrawidlowejOperacji
  );

  useEffect(() => {
    if (
      lsRozmiarStrony === null ||
      lsPotwierdzeniePrawidlowejOperacji === null
    ) {
      const subscription = SettingServiceQuery.GetSetting({
        klucze: [
          EZDRP_System_RozmiarStrony,
          EZDRP_System_PotwierdzeniePrawidlowejOperacji,
        ],
      }).subscribe({
        next: (value) => {
          const rozmiarStrony = value.find(
            (item) => item.klucz === EZDRP_System_RozmiarStrony
          );
          const potwierdzeniePrawidlowejOperacji = value.find(
            (item) =>
              item.klucz === EZDRP_System_PotwierdzeniePrawidlowejOperacji
          );
          rozmiarStrony &&
            localStorage.setItem(
              EZDRP_System_RozmiarStrony,
              rozmiarStrony.wartosc?.toString() ?? ''
            );
          potwierdzeniePrawidlowejOperacji &&
            localStorage.setItem(
              EZDRP_System_PotwierdzeniePrawidlowejOperacji,
              potwierdzeniePrawidlowejOperacji.wartosc?.toString() ?? ''
            );
        },
      });
      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {};
  }, []);

  const clear = () => {
    localStorage.removeItem(EZDRP_System_RozmiarStrony);
    localStorage.removeItem(EZDRP_System_PotwierdzeniePrawidlowejOperacji);
  };

  return {
    clear,
    settings: {
      rozmiarStrony: lsRozmiarStrony != null ? parseInt(lsRozmiarStrony) : 10,
      potwierdzeniePrawidlowejOperacji:
        lsPotwierdzeniePrawidlowejOperacji != null
          ? lsPotwierdzeniePrawidlowejOperacji.toUpperCase() === 'TRUE'
          : true,
    },
  };
};
