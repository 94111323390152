import { getShortDate } from '@archiwum/shared/table';
import { useApi } from '@nask/hooks';
import * as yup from 'yup';
import { PochodzenieRaportu } from '../models/models';
import { getErrorResponse } from '@archiwum/utility/helper';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { ReportsServiceCommand } from '../services/reports-service-command';
import { useHistory } from 'react-router';
import { Routes } from '@archiwum/routes';
import { useSnackbar } from '@archiwum/shared/toast-notification';

type LabelValue<T extends unknown> = {
  label: string;
  value: T;
};

export type FormValues = {
  reportType: number;
  dateFrom: string;
  dateTo: string;
  reportFormat: string;
};

export const useZamawianieRaportowFacade = () => {
  const history = useHistory();
  const [showSnackbar] = useSnackbar();

  const { setUrl } = useApi({
    onSuccess: (response) => {
      history.push(Routes.RaportyListaZamowionychRaportow.path);
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const reportTypeOptions: LabelValue<number>[] = [
    { label: 'Raport spraw na stanie archiwum', value: 1 },
    { label: 'Raport pism na stanie archiwum', value: 2 },
  ];

  const reportFormatOptions = ['csv', 'xlsx', 'xls'];

  const initialValues: FormValues = {
    reportType: 0,
    dateFrom: '',
    dateTo: '',
    reportFormat: '',
  };

  const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
    reportType: yup
      .number()
      .required('Wybierz rodzaj raportu')
      .oneOf(
        reportTypeOptions.map((i) => i.value),
        'Wybierz rodzaj raportu'
      ),
    dateFrom: yup.string().required('Data wymagana'),
    dateTo: yup.string().required('Data wymagana'),
    reportFormat: yup.string().required('Wybierz format'),
  });

  const onSubmit = (values: FormValues) => {
    const request = {
      dataPoczatkowa: getShortDate(new Date(values.dateFrom!).toISOString()),
      dataKoncowa: getShortDate(new Date(values.dateTo!).toISOString()),
      typ: values.reportType,
      format: values.reportFormat,
      pochodzenieRaportu: PochodzenieRaportu.Archiwum,
    };
    setUrl(ReportsServiceCommand.GenerateReport(request));
  };

  return {
    reportTypeOptions,
    reportFormatOptions,
    formikProps: { initialValues, validationSchema, onSubmit },
  };
};
