import React, { useMemo } from 'react';

import { DataList, DataListProps } from './data-list';
import { DataListStoreProvider } from './data-list-store-provider';

export type DataListWrapperProps<T> = DataListProps<T> & {};

export const DataListWrapper = <T extends unknown>(
  props: DataListWrapperProps<T>
) => {
  return (
    <DataListStoreProvider {...props}>
      {useMemo(
        () => (
          <DataList<T> {...props} />
        ),
        [props]
      )}
    </DataListStoreProvider>
  );
};
