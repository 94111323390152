import { Axios } from '@nask/axios-observable';
import { map, take } from 'rxjs/operators';
import { getUrl } from '@archiwum/utility/service';
import {
  GenerujPaczkeArchiwalnaRequestModel,
  GenerujSpisyBrakowaniaRequestModel,
  PrzekazDoPoprawyRequestModel,
  PrzeniesRequestModel,
  UtwórzZadanieWeryfikacjiSpisuRequest,
  UsunZeSpisuBrakowaniaRequest,
  SpisyBrakowaniaZmienTytulRequest,
  SpisyBrakowaniaBasicRequest,
  OznaczDoBrakowaniaRequest,
  OznaczDoEkspertyzyApRequest,
  ZmienKategorieOrazOznaczSpraweRequest,
  RozdzielSpisyBrakowaniaRequest,
  UtworzProcesBrakowaniaRequest,
  WstrzymajProcesBrakowaniaRequest,
  DodajDoSpisuBrakowaniaRequest,
  SpisBrakowaniaBasicRequest,
} from '../models/models';

export const BrakowanieServiceCommand = {
  Archiving: (request: PrzeniesRequestModel) =>
    Axios.post(getUrl('obiekt/lista/przenies'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  PrzekazDoPoprawySprawa: (request: PrzekazDoPoprawyRequestModel) =>
    Axios.post(getUrl('zadania/do-poprawy/utworz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  PrzekazDoPoprawyPismaNAS: (request: PrzekazDoPoprawyRequestModel) =>
    Axios.post(getUrl('pisma-nas/zadania/do-poprawy/utworz'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GenerujPaczkeArchivalna: (request: GenerujPaczkeArchiwalnaRequestModel) =>
    Axios.post(getUrl('paczka-archiwalna/generuj'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GenerujSpisyBrakowania: (request: GenerujSpisyBrakowaniaRequestModel) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/generuj', '/ezdrp'),
      request
    ).pipe(take(1)),
  UtworzProcesBrakowania: (request: UtworzProcesBrakowaniaRequest) =>
    Axios.post(
      getUrl('archiwum/proces-brakowania/rozpocznij', '/ezdrp'),
      request
    ).pipe(take(1)),
  UtwórzZadanieWeryfikacjiSpisu: (
    request: UtwórzZadanieWeryfikacjiSpisuRequest
  ) =>
    Axios.post(
      getUrl('archiwum/zadanie-weryfikacji-spisu-brakowania/dodaj', '/ezdrp'),
      request
    ).pipe(take(1)),
  UzunZeSpisuBrakowania: (request: UsunZeSpisuBrakowaniaRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/obiekty-archiwum/usun', '/ezdrp'),
      request
    ).pipe(take(1)),
  ScalSpisyBrakowania: (request: SpisyBrakowaniaBasicRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/scal', '/ezdrp'),
      request
    ).pipe(take(1)),

  SpisyBrakowaniaZmienTytul: (request: SpisyBrakowaniaZmienTytulRequest) =>
    Axios.post(getUrl('spisy-brakowania/zmien-tytul'), request).pipe(take(1)),

  UsunSpisyBrakowania: (request: SpisyBrakowaniaBasicRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/usun', '/ezdrp'),
      request
    ).pipe(take(1)),

  OznaczDoBrakowania: (request: OznaczDoBrakowaniaRequest) =>
    Axios.post(getUrl('brakowanie/oznacz-do-brakowania'), request).pipe(
      take(1)
    ),
  OznaczDoEkspertyzyAp: (request: OznaczDoEkspertyzyApRequest) =>
    Axios.post(getUrl('brakowanie/oznacz-do-oceny-ap'), request).pipe(take(1)),
  ZmienKategorieOrazOznaczSprawe: (
    request: ZmienKategorieOrazOznaczSpraweRequest
  ) =>
    Axios.post(
      getUrl('sprawy/zmien-kategorie-oraz-oznacz-sprawe'),
      request
    ).pipe(take(1)),

  RozdzielSpisyBrakowania: (request: RozdzielSpisyBrakowaniaRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/rozdziel', '/ezdrp'),
      request
    ).pipe(take(1)),

  WstrzymajProcesBrakowania: (request: WstrzymajProcesBrakowaniaRequest) =>
    Axios.post(
      getUrl('archiwum/brakowanie/proces-brakowania/wstrzymaj', '/ezdrp'),
      request
    ).pipe(take(1)),
  DodajDoSpisuBrakowania: (request: DodajDoSpisuBrakowaniaRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/obiekty-archiwum/dodaj', '/ezdrp'),
      request
    ).pipe(take(1)),
  PrzygotujDoEkspertyzy: (request: SpisyBrakowaniaBasicRequest) =>
    Axios.post(
      getUrl('archiwum/spisy-brakowania/przygotuj-do-oceny-ap', '/ezdrp'),
      request
    ).pipe(take(1)),
  RozpocznijZmianeKategorii: (request: SpisBrakowaniaBasicRequest) =>
    Axios.post(
      getUrl('archiwum/kategorie-archiwalne/aktualizuj-wedlug-spisu', '/ezdrp'),
      request
    ).pipe(take(1)),
  ZweryfikujSpisyBrakowania: (request: SpisyBrakowaniaBasicRequest) =>
    Axios.post(getUrl('spisy-brakowania/zweryfikuj'), request).pipe(take(1)),
};
