import { useEffect, useRef } from 'react';
import { TableRef } from '@archiwum/shared/table';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { useRefresh } from '@archiwum/actions-handler';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  TypProwadzenia,
  TypProwadzeniaLabelValue,
} from '@archiwum/features/archiving-documents';
import { TasksServiceQuery } from '../services/tasks-service-query';
import { useFiltering } from '../hooks/use-filtering';
import {
  getCeilingDate,
  getFloorDate,
} from 'libs/shared/table/src/lib/hooks/methods';

export type ZadaniaFilters = {
  komorkaOrganizacyjna: string | null;
  prowadzacy: string | null;
  symbolJRWA: string | null;
  kategoriaArchiwalna: string | null;
  typProwadzenia: TypProwadzenia | null;
  dataZakonczeniaOd: string | null;
  dataZakonczeniaDo: string | null;
  dataPrzyjeciaOd: string | null;
  dataPrzyjeciaDo: string | null;
};

const initialState: ZadaniaFilters = {
  dataPrzyjeciaOd: null,
  dataPrzyjeciaDo: null,
  dataZakonczeniaOd: null,
  dataZakonczeniaDo: null,
  kategoriaArchiwalna: null,
  komorkaOrganizacyjna: null,
  prowadzacy: null,
  symbolJRWA: null,
  typProwadzenia: null,
};

export const useTaskListFacade = () => {
  const tableRef = useRef<TableRef>();
  const inputRef = useRef();
  const dispatch = useDrawerRightDispatch();
  const { data: zadaniaFiltry } = useApi({
    service: TasksServiceQuery.GetZadaniaFiltry(),
  });

  const { state, setState } = useFiltering<ZadaniaFilters>({
    initialState: initialState,
  });
  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(undefined);
  }, [searchValue, state]);

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?: SelectModel | TypProwadzeniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const data = zadaniaFiltry;

  return {
    data,
    state,
    inputRef,
    tableRef,
    searchValue,
    handleUpdateDate,
    handleUpdateSelect,
    onChange,
  };
};
