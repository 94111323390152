import React, { useEffect, useRef, useState } from 'react';

type UseDraggableSnackbarProps = {
  ref: React.MutableRefObject<HTMLDivElement>;
};
export const useDraggableSnackbar = ({ ref }: UseDraggableSnackbarProps) => {
  const documentWidth = document.body.clientWidth;
  const documentHeight = document.body.clientHeight;
  // value in percent
  const pos = JSON.parse(
    localStorage.getItem('snackBarPosition') ??
      JSON.stringify({ top: 99, left: 1 })
  );
  const getPositionInPx = () => {
    let top = (documentHeight * pos.top) / 100;
    let left = (documentWidth * pos.left) / 100;
    if (left + ref.current?.offsetWidth > documentWidth) {
      left = documentWidth - ref.current?.offsetWidth - 5;
    }
    if (left + ref.current?.offsetWidth < 0) {
      left = 0;
    }
    if (top + ref.current?.offsetHeight > documentHeight) {
      //20 -> padding bottom
      top = documentHeight - 20;
    } else {
      // dziwnie dziala ustalanie pozycji top gdy jest w dolnej polowie
      if (pos.top > 50) {
        top += 50;
      }
    }
    if (top + ref.current?.offsetHeight < 0) {
      top = 0;
    }
    return { top, left };
  };
  const [currentPos, setCurrentPos] = useState<{ top: number; left: number }>(
    getPositionInPx()
  );
  const clientPosY = useRef<number>(0);
  const clientPosX = useRef<number>(0);
  const dragging = useRef<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.onmousedown = dragMouseDown;
      setCurrentPos(getPositionInPx());
    }
  }, [ref]);

  const dragMouseDown = (e) => {
    if (e.which === 1) {
      e = e || window.event;
      e.preventDefault();

      clientPosX.current = e.clientX;
      clientPosY.current = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
      document.body.style.cursor = 'grab';
      ref.current.style.cursor = 'grab';
    }
  };

  const elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    dragging.current = true;

    const pos1 = clientPosX.current - e.clientX;
    const pos2 = clientPosY.current - e.clientY;
    clientPosX.current = e.clientX;
    clientPosY.current = e.clientY;
    if (
      ref.current?.offsetTop - pos2 > 0 &&
      ref.current?.offsetTop - pos2 < documentHeight - 50
    ) {
      ref.current.style.top = ref.current?.offsetTop - pos2 + 'px';
    }
    if (
      ref.current?.offsetLeft - pos1 > 0 &&
      ref.current?.offsetLeft - pos1 + ref.current?.offsetWidth < documentWidth
    ) {
      ref.current.style.left = ref.current?.offsetLeft - pos1 + 'px';
    }
    ref.current.style.cursor = 'grabbing';
    document.body.style.cursor = 'grabbing';
  };

  const closeDragElement = () => {
    if (dragging.current) {
      localStorage.setItem(
        'snackBarPosition',
        JSON.stringify({
          top: (ref.current?.offsetTop / documentHeight) * 100,
          left: (ref.current?.offsetLeft / documentWidth) * 100,
        })
      );
      dragging.current = false;
    }
    document.onmouseup = null;
    document.onmousemove = null;
    document.body.style.cursor = 'default';
    ref.current.style.cursor = 'grab';
  };

  const isTopPosition = (): number => {
    //to correct calculation getTransitionStyles 50% document height
    return pos.top > 50 ? -1 : 1;
  };

  return { pos: currentPos, isTopPosition };
};
