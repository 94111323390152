import React from 'react';

import styled from 'styled-components';
import { spacing, theme } from '@nask/theme';
import { ThemeToggle } from './theme-toggle';
import { User } from './user';
import { Subject } from './subject';
import { Box, Divider } from '@material-ui/core';

const StyledTopBar = styled.div`
  color: ${theme.topbar.color};
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-content: center;
  padding-right: ${spacing(3)};
`;

const StyledTopBarContainer = styled.div`
  grid-area: topbar;
  display: grid;
  height: ${theme.topbar.height};
  background-color: ${theme.topbar.bgColor};
  grid-template-rows: 1fr;
`;

const RightContainer = styled.div<{ hidden?: boolean }>`
  display: grid;
  grid-gap: ${spacing(1)};
  grid-auto-flow: column;
  justify-content: center;
  align-content: center;
  visibility: ${(props) => props.hidden && 'hidden'};
`;

export const TopBar = () => {
  return (
    <StyledTopBarContainer>
      <StyledTopBar>
        <RightContainer hidden>
          <User />
          <ThemeToggle />
        </RightContainer>
        <Subject />
        <RightContainer>
          <User />
          <ThemeToggle />
        </RightContainer>
      </StyledTopBar>
      <Divider />
    </StyledTopBarContainer>
  );
};

export default TopBar;
