import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useStyles } from './input.styles';
import clsx from 'clsx';

export type InputProps = TextFieldProps & {};

export const Input = ({
  InputLabelProps = {},
  InputProps = {},
  FormHelperTextProps = {},
  ...props
}: InputProps) => {
  const classes = useStyles();
  const {
    classes: { root, focused, ...restClasses } = {},
    ...restInputProps
  } = InputProps;
  const {
    classes: { root: formHelperRootClasses, ...restFormHelperTextClasses } = {},
    ...restFormHelperTextProps
  } = FormHelperTextProps;
  const {
    classes: {
      root: labelRootClasses,
      focused: labelFocusedClasses,
      ...restLabelClasses
    } = {},
    ...restLabelProps
  } = InputLabelProps;
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        classes: {
          input: classes.input,
          root: clsx(classes.root, root),
          focused: classes.cssFocused,
          ...{ notchedOutline: classes.notchedOutline },
          ...restClasses,
        },
        ...restInputProps,
      }}
      InputLabelProps={{
        classes: {
          root: clsx(classes.labelRoot, labelRootClasses),
          focused: clsx(classes.cssFocused, labelFocusedClasses),
          ...restLabelClasses,
        },
        shrink: true,
        ...restLabelProps,
      }}
      FormHelperTextProps={{
        classes: {
          root: clsx(classes.formHelperRoot, formHelperRootClasses),
          ...restFormHelperTextClasses,
        },
        ...restFormHelperTextProps,
      }}
      {...props}
    />
  );
};
