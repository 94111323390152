import { Box, Container, Paper } from '@material-ui/core';
import { useSelectedDispatch } from '@archiwum/hooks';
import { SearchInput } from '@archiwum/shared/search-input';
import { DrawerRight } from '@archiwum/drawer-right';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';
import { useParams } from 'react-router';
import { RouterParams } from '@archiwum/routes';

import { getListaKartonow } from '../helper';
import { useCartonsFacade } from './use-cartons-facade';
import { useStyles } from './cartons.styles';
import { CartonsTable } from './cartons-table';
import { ChronologicalCompositionsLayout } from '../components/chronological-compositions-layout';

export function Cartons() {
  const { tableRef, onChange, searchValue } = useCartonsFacade();
  const classes = useStyles();
  const dispatch = useSelectedDispatch();
  const { idSpis } = useParams<RouterParams>();

  return (
    <ChronologicalCompositionsLayout>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          {/* <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4}>
                  <SearchInput
                    searchMethod={(phrase: string) => {
                      onChange(phrase);
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box> */}

          <Paper classes={{ root: classes.paper }}>
            <CartonsTable
              tableRef={tableRef}
              data={(query, result) => {
                getListaKartonow(query, result, searchValue, dispatch, idSpis);
              }}
              idSpis={idSpis}
            />
          </Paper>
        </Container>
      </Box>

      <DrawerRight />
    </ChronologicalCompositionsLayout>
  );
}

export default Cartons;
