import { useDeepMemo } from '@nask/hooks';
import { useField } from 'formik';
import React, { memo } from 'react';

import { ErrorContainer, InputContainer } from '../styles';
import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type Props = Omit<SwitchProps, 'form' | 'id' | 'checked' | 'onChange'>;
interface FormSwitchProps extends Props {
  noErrorText?: boolean;
  onChange?: (value) => void;
  label?: string;
}

const FormSwitch = memo(({ noErrorText, label, ...props }: FormSwitchProps) => {
  const [field, meta] = useField<boolean>(props.name);
  const { onBlur, onChange, ...rest } = field;
  return useDeepMemo(
    () => (
      <InputContainer style={{ justifyContent: 'flex-start' }}>
        <FormControlLabel
          style={{ marginLeft: '1px' }}
          control={
            <Switch
              checked={field.value}
              {...rest}
              {...props}
              id={props.name}
              color="primary"
              onChange={(e) => {
                if (props.onChange) props.onChange(e);
                field.onChange(e);
              }}
            />
          }
          label={label ?? ''}
        />
        {meta.error && meta.touched && (
          <ErrorContainer>
            {meta.error && meta.touched && meta.error}
          </ErrorContainer>
        )}
      </InputContainer>
    ),
    [rest, meta, props]
  );
});

export default FormSwitch;
