import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import {
  ColumnDto,
  OwnQuery,
  ResultOwnQuery,
  SubjectServiceModel,
} from '../table-model';
import { TableProps } from '../wtable';
import { updateDataProp, updateOptions } from './methods';
import { useTableColumns } from './use-table-columns';
import { useGetSettings } from '@archiwum/shared/settings';

export type UseTableFacadeProps<T extends object> = Omit<
  TableProps<T>,
  'data' | 'allColumns' | 'showedColumns'
> & {
  data:
    | T[]
    | ((query: OwnQuery, subscriptionService: SubjectServiceModel) => void);
  viewTableId?: string;
  columnsInfo?: ColumnDto[];
};

export const useTableFacade = <T extends object>(
  props: UseTableFacadeProps<T>
) => {
  const [error, setError] = useState(false);
  const [columnsInfo, setColumnsInfo] = useState<ColumnDto[]>([]);
  const subject = new Subject<ResultOwnQuery>();
  const [isLoading, setIsLoading] = useState(true);
  const { settings } = useGetSettings();

  const handleError = () => {
    subject.next({ totalCount: 0, page: 0, data: [] } as ResultOwnQuery);
    setError(true);
  };

  const subjectService: SubjectServiceModel = {
    onSuccess: (query: ResultOwnQuery) => {
      if (!isEqual(columnsInfo, query.columnsInfo)) {
        setColumnsInfo(query.columnsInfo);
      }
      return subject?.next(query);
    },
    onError: () => handleError(),
  };

  useEffect(() => {
    setIsLoading(false);
    return () => {
      subject?.unsubscribe();
    };
  }, []);

  const { columns, showedColumns } = useTableColumns<T>({
    ...props,
    columnsInfo,
  });
  const overrideProps: TableProps<T> = {
    ...props,
    showedColumns,
    allColumns: props.columns,
    columns: columns,
    data: updateDataProp(
      props.data,
      subject,
      subjectService,
      setError,
      props.selectedRows
    ),
    options: updateOptions(
      props.options,
      undefined,
      settings.rozmiarStrony.toString(),
      ['5', '10', '20', '50', '100'].map((x) => ({ label: x, value: x }))
    ),
  };
  return { isLoading, overrideProps, error, columnsInfo };
};
