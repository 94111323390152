import React from 'react';
import {
  Tabs as MaterialTabs,
  TabsProps as MaterialTabsProps,
} from '@material-ui/core';
import { useStyles } from './tabs.styles';
import clsx from 'clsx';

type TabsProps = MaterialTabsProps<any> & {
  separator?: boolean;
};

export const Tabs = (props: TabsProps) => {
  const classes = useStyles();
  return (
    <MaterialTabs classes={{ root: clsx(classes.separator) }} {...props} />
  );
};
