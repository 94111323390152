import React, { ReactNode } from 'react';
import { TypProwadzenia } from '@archiwum/features/archiving-documents';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  useTheme,
} from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import clsx from 'clsx';
import { PracownikModel } from '../../models/models';
import { useStyles } from '../../case.styles';

type MainInfoProps = {
  typProwadzenia?: TypProwadzenia;
  znak?: string;
  pracownikProwadzacy?: PracownikModel;
  kategoriaArchiwalna?: string;
};

export const MainInfo: React.FunctionComponent<MainInfoProps> = ({
  typProwadzenia,
  znak,
  pracownikProwadzacy,
  kategoriaArchiwalna,
}: MainInfoProps) => {
  const classes = useStyles();
  const typProwadzeniaClasses = {
    root: clsx(classes.typProwadzeniaBox, {
      [classes.elektroniczne]: typProwadzenia === TypProwadzenia.ELEKTRONICZNE,
      [classes.papierowe]: typProwadzenia === TypProwadzenia.PAPIEROWE,
    }),
  };

  const typProwadzeniaLabel = `Sprawa ${
    typProwadzenia === TypProwadzenia.ELEKTRONICZNE
      ? 'elektroniczna'
      : 'tradycyjna'
  }`;
  const theme = useTheme();
  return (
    <Grid item>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <Paper id="main-info" elevation={23} className={classes.elevation23}>
            {/* typ prowadzenia */}
            <Typography
              variantType={TypographyVariants.Header5}
              classes={typProwadzeniaClasses}
            >
              {typProwadzeniaLabel}
            </Typography>
            <Box px={3} pt={0} pb={1} minWidth="32rem">
              <Typography
                variantType={TypographyVariants.Header4}
                variant="h4"
                className={classes.textCentered}
              >
                <strong>{znak}</strong>
              </Typography>
              <Box mx={0} my={1} borderTop={6} color={'rgba(0,0,0,0.1)'}></Box>
              <Box my={2}>
                <Grid
                  container
                  direction="row"
                  align-items="flex-start"
                  justify="flex-start"
                  wrap="nowrap"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variantType={TypographyVariants.Paragraph}>
                      Prowadzący:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variantType={TypographyVariants.Paragraph}>
                      <strong>
                        {pracownikProwadzacy?.imie?.value}{' '}
                        {pracownikProwadzacy?.nazwisko?.value}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid id="kar-arch" item>
          <Card className={classes.katArchiwalna} variant="outlined">
            <CardContent>
              <Typography
                className={classes.textCentered}
                variantType={TypographyVariants.Header4}
              >
                <strong>{kategoriaArchiwalna}</strong>
              </Typography>
              <Typography>Kat. archiwalna</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
