import { Omit, Radio } from '@material-ui/core';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import React, { memo, useMemo } from 'react';

import { InputContainer } from '../styles';
import { useStyles } from './form-radio.styles';

interface FormRadioProps extends Omit<FormControlLabelProps, 'control'> {
  noErrorText?: boolean;
  spacing?: boolean;
}

const FormRadio = memo(({ spacing, noErrorText, ...props }: FormRadioProps) => {
  const classes = useStyles();
  const styles = useMemo(() => {
    if (spacing) {
      return { marginLeft: 0, marginRight: 0 };
    } else {
      return {};
    }
  }, [spacing]);
  return (
    <InputContainer style={{ justifyContent: 'flex-start' }}>
      <FormControlLabel
        style={styles}
        value={props.value}
        control={<Radio id={props.id} color="primary" />}
        label={props.label}
        labelPlacement="end"
        classes={{ label: classes.label }}
      />
    </InputContainer>
  );
});

export default FormRadio;
