import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import format from 'date-fns/format';
import { TableRef } from '@archiwum/shared/table';
import { useRefresh } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { countersSubject$, LicznikModel } from '@archiwum/counters';

import { ArchivingDocumentsServiceQuery } from '../services/archiving-documents-service-query';
import {
  FolderArchiwum,
  TypProwadzenia,
  TypProwadzeniaLabelValue,
} from '../models/models';
import { useFiltering } from './use-filtering';
import { getParams } from '../helper';
import {
  getCeilingDate,
  getFloorDate,
  getMaxDateOfTheYear,
  getMinDateOfTheYear,
} from 'libs/shared/table/src/lib/hooks/methods';

const tabsDefault = [
  {
    label: 'Sprawy',
    value: 'sprawy',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
  },
];
const tabsNowe = [
  {
    label: 'Sprawy',
    value: 'sprawy',
    idCounter: 'POCZEKALNIA-SPRAWY-NOWE',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
    idCounter: 'POCZEKALNIA-PISMANAS-NOWE',
  },
];
const tabsDoPoprawy = [
  {
    label: 'Sprawy',
    value: 'sprawy',
    idCounter: 'POCZEKALNIA-SPRAWY-DOPOPRAWY',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
    idCounter: 'POCZEKALNIA-PISMANAS-DOPOPRAWY',
  },
];
const tabsPoprawione = [
  {
    label: 'Sprawy',
    value: 'sprawy',
    idCounter: 'POCZEKALNIA-SPRAWY-POPRAWIONE',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
    idCounter: 'POCZEKALNIA-PISMANAS-POPRAWIONE',
  },
];
const tabsOdlozone = [
  {
    label: 'Sprawy',
    value: 'sprawy',
    idCounter: 'POCZEKALNIA-SPRAWY-ODLOZONE',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
    idCounter: 'POCZEKALNIA-PISMANAS-ODLOZONE',
  },
];

type TabsModel = {
  label: string;
  value: string;
  idCounter?: string;
};

export type SprawyPoczekalniaFilters = {
  komorkaOrganizacyjna: string | null;
  prowadzacy: string | null;
  symbolJRWA: string | null;
  kategoriaArchiwalna: string | null;
  typProwadzenia: TypProwadzenia | null;
  dataZakonczeniaOd: string | null;
  dataZakonczeniaDo: string | null;
  dataPrzyjeciaOd: string | null;
  dataPrzyjeciaDo: string | null;
};

const initialState: SprawyPoczekalniaFilters = {
  dataPrzyjeciaOd: null,
  dataPrzyjeciaDo: null,
  dataZakonczeniaOd: null,
  dataZakonczeniaDo: null,
  kategoriaArchiwalna: null,
  komorkaOrganizacyjna: null,
  prowadzacy: null,
  symbolJRWA: null,
  typProwadzenia: null,
};

type UsePoczekalniaProps = {
  foldery: FolderArchiwum[];
};

export const usePoczekalniaFacade = ({ foldery }: UsePoczekalniaProps) => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef<TableRef>();
  const inputRef = useRef();
  const dispatch = useDrawerRightDispatch();
  const search = location.search;
  const rodzaj = new URLSearchParams(search).get('rodzaj');
  const isSprawy = !rodzaj ? true : rodzaj?.toLowerCase() === 'sprawy';

  const getTabs = (folder: FolderArchiwum): TabsModel[] => {
    switch (folder) {
      case FolderArchiwum.Poczekalnia:
        return tabsNowe;
      case FolderArchiwum.DoPoprawy:
        return tabsDoPoprawy;
      case FolderArchiwum.Poprawione:
        return tabsPoprawione;
      case FolderArchiwum.Odlozone:
        return tabsOdlozone;
      default:
        return tabsDefault;
    }
  };

  const tabs = getTabs(foldery[0]);

  const { data: sprawaFiltry } = useApi({
    service: ArchivingDocumentsServiceQuery.GetSprawyFiltry({ foldery }),
  });

  const { data: pismaNASFiltry } = useApi({
    service: ArchivingDocumentsServiceQuery.GetPismaNASFiltry({ foldery }),
  });

  const { state, setState } = useFiltering<SprawyPoczekalniaFilters>({
    initialState: initialState,
  });
  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  const [activeTab, setActiveTab] = useState(() => {
    const rodzaj = getParams(location.search);
    return (rodzaj as string) ?? tabs[0].value;
  });

  const [counters, setCounters] = useState<LicznikModel[]>([]);

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    const rodzaj = getParams(location.search);
    if (rodzaj) {
      setActiveTab(rodzaj as string);
    }
  }, [location.search]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    setState(initialState);
  }, [activeTab]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue, state]);

  useEffect(() => {
    const subscription = countersSubject$.subscribe((counters) =>
      setCounters(counters)
    );
    return () => subscription.unsubscribe();
  }, []);

  const handleChangeTab = (event: ChangeEvent<unknown>, value: string) => {
    history.push({ search: `?rodzaj=${value}` });
    setActiveTab(value);
  };

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?: SelectModel | TypProwadzeniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const handleUpdateYear = (year: MaterialUiPickersDate, name: string) => {
    const newState: SprawyPoczekalniaFilters = { ...state };
    if (year && !isNaN(year as any)) {
      const minDate = year.startOf('year').format('yyyy-MM-DD HH:mm:ss');
      newState[`${name}Od`] = minDate;
      newState[`${name}Do`] = year.endOf('year').format('yyyy-MM-DD HH:mm:ss');
    } else {
      newState[`${name}Od`] = null;
      newState[`${name}Do`] = null;
    }

    setState(newState);
  };

  const data = isSprawy ? sprawaFiltry : pismaNASFiltry;

  const komorkaOrganizacyjnaOptions =
    data?.komorkiOrganizacyjne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const prowadzacyOptions =
    data?.prowadzacy?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const symboleJRWAOptions =
    data?.symboleJRWA?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const katArchiwalnaOptions =
    data?.kategorieArchiwalne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const typProwadzeniaOptions = data?.typProwadzenia?.dostepneWartosci ?? [];
  const minRokZakonczenia = Math.min(
    ...(data?.rokZakonczenia?.dostepneWartosci ?? [])
  );
  const maxRokZakonczenia = Math.max(
    ...(data?.rokZakonczenia?.dostepneWartosci ?? [])
  );

  return {
    data,
    state,
    inputRef,
    tableRef,
    komorkaOrganizacyjnaOptions,
    searchValue,
    prowadzacyOptions,
    symboleJRWAOptions,
    katArchiwalnaOptions,
    typProwadzeniaOptions,
    minRokZakonczenia,
    maxRokZakonczenia,
    handleUpdateDate,
    handleUpdateSelect,
    handleUpdateYear,
    handleChangeTab,
    tabs,
    activeTab,
    onChange,
    counters,
  };
};
