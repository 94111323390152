import { Axios } from '@nask/axios-observable';
import { map, take } from 'rxjs/operators';
import { getUrl } from '@archiwum/utility/service';
import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import {
  GetPoczekalniaRequestModel,
  GetRequestFiltry,
  GetRpwDetailsRequestModel,
  GetRpwDetailsResponse,
  GetStanowiskaResponseModel,
  GetUzytkownicyResponseModel,
  HistoriaRequestModel,
  HistoriaResponseModel,
  PoczekalniaModel,
  SprawyFiltry,
} from '../models/models';

export const ArchivingDocumentsServiceQuery = {
  GetSprawyPoczekalnia: (
    request: QueryViewRequestBase<GetPoczekalniaRequestModel>
  ) =>
    Axios.post<PagedResultDto<PoczekalniaModel>>(
      getUrl('folder/sprawy/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),

  GetPismaNASPoczekalnia: (
    request: QueryViewRequestBase<GetPoczekalniaRequestModel>
  ) =>
    Axios.post<PagedResultDto<PoczekalniaModel>>(
      getUrl('folder/pisma-nas/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),

  GetPismaNASFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(getUrl('folder/pisma-nas/filtry'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyFiltry: (request: GetRequestFiltry) =>
    Axios.post<SprawyFiltry>(getUrl('folder/sprawy/filtry'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetRpwDetails: (requestData: GetRpwDetailsRequestModel) =>
    Axios.post<GetRpwDetailsResponse>(
      getUrl('pisma/szczegoly-rpw'),
      requestData
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetSprawyDoPoprawy: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<PoczekalniaModel>>(
      getUrl('zadania/do-poprawy/utowrzone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetPismaNASDoPoprawy: (request: QueryViewRequestBase) =>
    Axios.post<PagedResultDto<PoczekalniaModel>>(
      getUrl('zadania/do-poprawy/utowrzone/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetTypyElementowDoPoprawy: () =>
    Axios.post<string[]>(
      getUrl('zadania/do-poprawy/typy-elementow-do-poprawy/lista'),
      {}
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetUzytkownicy: () =>
    Axios.post<GetUzytkownicyResponseModel>(
      getUrl('uzytkownicy/lista'),
      {}
    ).pipe(
      take(1),
      map((data) => data.data.items)
    ),
  GetHistoria: (request: HistoriaRequestModel) =>
    Axios.post<HistoriaResponseModel>(
      getUrl('historia-obiektu-archiwum/pobierz'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetStanowiska: () =>
    Axios.post<GetStanowiskaResponseModel>(getUrl('stanowiska/lista'), {}).pipe(
      take(1),
      map((data) => data.data)
    ),
};
