import { useApi } from '@nask/hooks';

import { ArchivingPackageServiceQuery } from '../services/archiving-package-service-query';

export const useFormCreateArchivingFacade = () => {
  const { data: listaKlasJRWADoAP } = useApi({
    service: ArchivingPackageServiceQuery.GetListaKlasJRWADoAP({
      searchValue: '',
    }),
  });

  const { data: znakiSpraw } = useApi({
    service: ArchivingPackageServiceQuery.GetZnakiSpraw(),
  });

  return {
    listaKlasJRWADoAP,
    znakiSpraw,
  };
};
