import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from '@material-ui/icons';
import React, { Dispatch, createContext, forwardRef } from 'react';

import { DataListActions, DataListState } from './list-state';

export const defaultOptions: DataListState<unknown> = {
  rows: [],
  data: [],
  selected: [],
  options: {
    rowsPerPageOptions: [
      { value: 5, label: '5 wierszy' },
      { value: 10, label: '10 wierszy' },
      { value: 20, label: '20 wierszy' },
      { value: 50, label: '50 wierszy' },
      { value: 100, label: '100 wierszy' },
    ],
    selectionType: 'checkbox',
  },
  pagination: { pageNumber: 0, pageSize: 10, totalItems: 10 },
  sorting: [],
  searchQuery: '',
  title: '',
  localization: {
    labelDisplayedRows: 'od {from} do {to} z {count} ogółem',
    labelRowsPerPage: 'Wierszy na stronie:',
    firstTooltip: 'Pierwsza strona',
    previousTooltip: 'Poprzednia strona',
    nextTooltip: 'Następna strona',
    lastTooltip: 'Ostatnia strona',
  },
  refresh: false,
  setRefresh: () => {},
  direction: 'ltl',
  icons: {
    FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
      <FirstPage {...props} ref={ref} />
    )),
    LastPage: forwardRef<SVGSVGElement>((props, ref) => (
      <LastPage {...props} ref={ref} />
    )),
    NextPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
  },
};

export const listStoreContext = createContext<{
  store: DataListState<unknown>;
  dispatch?: Dispatch<DataListActions<unknown>>;
}>({
  store: defaultOptions,
});
