import isEqual from 'lodash/isEqual';
import React, { useEffect, useReducer } from 'react';

import { DataListProps } from './data-list';
import { DataListState, dataFetchReducer } from './list-state';
import { defaultOptions, listStoreContext } from './list-store';
import { useApi } from '@nask/hooks';
import { PagedResultDto } from '@archiwum/shared/table';

type DataListStoreProviderProps = DataListProps<unknown> & {
  children?: React.ReactNode;
};

export const DataListStoreProvider = ({
  additionalHeaderIcon,
  children,
  options,
  ...rest
}: DataListStoreProviderProps) => {
  const { Provider } = listStoreContext;
  // const [apiState, setService] = useApiOLD<PagedResultDto<any>>();
  const { setUrl: setService, ...apiState } = useApi<PagedResultDto<any>>();
  const [store, dispatch] = useReducer(dataFetchReducer, {
    ...defaultOptions,
    onQueryChange: setService,
    selectionFilter: rest.selectionFilter || fakeSelection,
    renderBody: rest.renderBody,
    isActionsButtonVisible: rest.isActionsButtonVisible,

    setRefresh: () => {
      dispatch({ type: 'SET_REFRESH' });
    },
    pagination: {
      ...defaultOptions.pagination,
      pageSize:
        options.pagination?.pageSize ?? defaultOptions.pagination.pageSize,
    },
    options: { ...defaultOptions.options, ...options },
    additionalHeaderIcon: additionalHeaderIcon,
  } as DataListState<unknown>);
  useEffect(() => {
    if (!apiState.isError && !apiState.isLoading && apiState.data) {
      dispatch({
        type: 'UPDATE_API_DATA',
        payload: {
          data: apiState.data.items,
          pagination: apiState.data.pageInfo,
          loading: apiState.isLoading,
          error: apiState.isError,
        },
      });
    } else {
      dispatch({
        type: 'CHANGE_STATE',
        payload: { loading: apiState.isLoading, error: apiState.isError },
      });
    }
  }, [apiState.isLoading]);

  useEffect(() => {
    if (!isEqual({ ...store.options, ...options }, store.options)) {
      dispatch({ type: 'UPDATE_OPTIONS', payload: { ...options } });
    }
  }, [options]);

  return <Provider value={{ store, dispatch }}>{children}</Provider>;
};

const fakeSelection = () => {
  return false;
};
