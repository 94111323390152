import { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { Prawo117Icon } from '@nask/ezdrp-icons';

import { SpisBrakowania } from '../../models/models';
import { RenderDetailsPanel } from './details-panel';
import { useStyles } from '../../brakowanie.styles';

type SpisyBrakowaniaTableProps = Pick<
  UseTableFacadeProps<SpisBrakowania>,
  'tableRef' | 'data'
>;

export const SpisyBrakowaniaTable = (props: SpisyBrakowaniaTableProps) => {
  const classes = useStyles();
  const dispatch = useDrawerRightDispatch();
  return (
    <Table<SpisBrakowania>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'znakSprawy', title: 'Znak sprawy' },
        {
          field: 'tytul',
          title: 'Tytuł spisu',
        },
        {
          field: 'liczbaElementow',
          title: 'Liczba elementów',
        },
        { field: 'dataUtworzenia', title: 'Data utworzenia', type: 'date' },
        { field: 'statusOpis', title: 'Status' },
      ]}
      options={{
        filtering: false,
        selection: true,
        detailPanelColumnAlignment: 'right',
        detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
      detailPanel={[
        (rowData) => ({
          visible: true,

          tooltip: 'Lista obiektów w spisie',
          icon: () => <Prawo117Icon className={classes.rightArrowIcon} />,
          render: (rowData) => <RenderDetailsPanel rowData={rowData} />,
        }),
      ]}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case '*archiwum.spis-brakowania.usun':
      return 'Usuń';
    case '#archiwum.spis-brakowania.scal':
      return 'Scal';
    case 'archiwum.spis-brakowania.zmien-nazwe':
      return 'Zmień nazwę';
    case 'archiwum.spis-brakowania.utworz-zadanie-weryfikacji':
      return 'Utwórz zadanie weryfikacji';
    case 'archiwum.spis-brakowania.rozdziel-po-statusach':
      return 'Rozdziel spis';
    case 'archiwum.spis-brakowania.utworz-proces-brakowania':
      return 'Rozpocznij brakowanie';
    case '*archiwum.spis-brakowania.przygotuj-do-oceny':
      return 'Przygotuj do oceny';
    case 'archiwum.spis-brakowania.rozpocznij-zmiane-kategorii-archiwalnych':
      return 'Rozpocznij zmianę kategorii archiwalnej';
    case '*archiwum.spis-brakowania.zweryfikuj':
      return 'Zweryfikuj';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const ScalSpisyBrakowania = lazy(() =>
    import(
      '../../context-actions/scal-spisy-brakowania/scal-spisy-brakowania'
    ).then((module) => ({ default: module.ScalSpisyBrakowania }))
  );

  const UsunSpisBrakowania = lazy(() =>
    import(
      '../../context-actions/usun-spis-brakowania/usun-spis-brakowania'
    ).then((module) => ({ default: module.UsunSpisBrakowania }))
  );

  const UtworzZadanieWeryfikacji = lazy(() =>
    import(
      '../../context-actions/utworz-zadanie-weryfikacji/utworz-zadanie-weryfikacji'
    ).then((module) => ({ default: module.UtworzZadanieWeryfikacji }))
  );

  const ZmienNazweSpisu = lazy(() =>
    import(
      '../../context-actions/zmien-nazwe-spisu/zmien-nazwe-spisu'
    ).then((module) => ({ default: module.ZmienNazweSpisu }))
  );

  const RozdzielSpisBrakowania = lazy(() =>
    import(
      '../../context-actions/rozdziel-spis-brakowania/rozdziel-spis-brakowania'
    ).then((module) => ({ default: module.RozdzielSpisBrakowania }))
  );

  const UtworzProcesBrakowania = lazy(() =>
    import(
      '../../context-actions/utworz-proces-brakowania/utworz-proces-brakowania'
    ).then((module) => ({ default: module.UtworzProcesBrakowania }))
  );

  const PrzygotujDoEkspertyzy = lazy(() =>
    import(
      '../../context-actions/przygotuj-do-ekspertyzy/przygotuj-do-ekspertyzy'
    ).then((module) => ({ default: module.PrzygotujDoEkspertyzy }))
  );

  const RozpocznijZmianeKategorii = lazy(() =>
    import(
      '../../context-actions/rozpocznij-zmiane-kategorii/rozpocznij-zmiane-kategorii'
    ).then((module) => ({ default: module.RozpocznijZmianeKategorii }))
  );

  const ZweryfikujSpisyBrakowania = lazy(() =>
    import(
      '../../context-actions/zweryfikuj-spisy-brakowania/zweryfikuj-spisy-brakowania'
    ).then((module) => ({ default: module.ZweryfikujSpisyBrakowania }))
  );

  switch (command) {
    case 'archiwum.spis-brakowania.utworz-zadanie-weryfikacji':
      return <UtworzZadanieWeryfikacji />;
    case '#archiwum.spis-brakowania.scal':
      return <ScalSpisyBrakowania />;
    case 'archiwum.spis-brakowania.zmien-nazwe':
      return <ZmienNazweSpisu />;
    case '*archiwum.spis-brakowania.usun':
      return <UsunSpisBrakowania />;
    case 'archiwum.spis-brakowania.rozdziel-po-statusach':
      return <RozdzielSpisBrakowania />;
    case 'archiwum.spis-brakowania.utworz-proces-brakowania':
      return <UtworzProcesBrakowania />;
    case '*archiwum.spis-brakowania.przygotuj-do-oceny':
      return <PrzygotujDoEkspertyzy />;
    case 'archiwum.spis-brakowania.rozpocznij-zmiane-kategorii-archiwalnych':
      return <RozpocznijZmianeKategorii />;
    case '*archiwum.spis-brakowania.zweryfikuj':
      return <ZweryfikujSpisyBrakowania />;

    default:
      return <div>{`Nie znaleziono akcji: ${command}`}</div>;
  }
};
