import { createGlobalStyle } from 'styled-components';
import { theme } from '@nask/theme';

export const GlobalStyle = createGlobalStyle<{ whiteColor?: boolean }>`
  *{
   font-family: ${theme.typography.fontFamilyBase};
   margin: 0 ;
  }
  html, body, #app {
   height: 100%;
  }
  html{
   font-family: ${theme.typography.fontFamilyBase};
   font-size: 62.5%;
   font-weight: ${theme.typography.fontWeightBase};
  body {
    color: ${theme.typography.textColor};
    overflow: hidden;
    font-size: inherit;
  }
  }
  strong {
    font-weight: ${theme.typography.fontWeightBold};
  }
  label {
    font-weight: ${theme.typography.fontWeightNormal};
  }
  th {
    font-weight: ${theme.typography.fontWeightSemiBold};
    }
  ul ul,
  ol ul {
    list-style: none;
  }
  ul, ol {
    list-style: none;
    padding-left: 0;
  }
  a{
    text-decoration: none;
  }
`;
