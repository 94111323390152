import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Box } from '@material-ui/core';
import { actionsService$ } from '@archiwum/actions-handler';
import { useOznaczDoEkspertyzyFacade } from './use-oznacz-do-ekspertyzy-facade';
import { Formik } from 'formik';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Button } from '@archiwum/ui/button';
import { Form, FormInput } from '@archiwum/forms';

export const OznaczDoEkspertyzy = () => {
  const { formikProps, selected } = useOznaczDoEkspertyzyFacade();
  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>Oznacz do ekspertyzy AP</ActionHeader>
        <Formik {...formikProps}>
          <Form id={'mark-to-expertise'}>
            <Box my={2}>
              <Typography variantType={TypographyVariants.Paragraph}>
                Zaznaczono następujące spisy brakowania:
              </Typography>
            </Box>
            <Box mb={3}>
              {selected?.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    variantType={TypographyVariants.Paragraph}
                  >
                    <strong>{item.sygnatura}</strong>
                  </Typography>
                );
              })}
            </Box>

            <FormInput name="uwagi" label="Uwagi" />
            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                ANULUJ
              </Button>
              <Button type={'submit'} variant="contained" color="primary">
                OZNACZ
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};
