import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useStyles } from '../../case.styles';

type LabelValueRowProps = {
  label?: string;
  value?: string;
};

export const LabelValueRow: FunctionComponent<LabelValueRowProps> = ({
  label,
  value,
}: LabelValueRowProps) => {
  const classes = useStyles();
  return (
    <Grid container item spacing={1} direction={'row'}>
      <Grid item>
        <Typography variantType={TypographyVariants.Paragraph}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variantType={TypographyVariants.Paragraph}>
          <strong>{value}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};
