import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    endAdornment: {
      top: 'auto',
    },
    inputRoot: {
      '&&&[class*="MuiOutlinedInput-root"]': {
        paddingRight: 48,
        paddingTop: 4,
        paddingBottom: 5,
      },
    },
    option: {
      fontSize: theme.typography.paragraphFontSize,
    },
  })
);
