import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, shape }) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridGap: spacing(1),
    },
    container: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gridTemplateColumns: '1fr max-content',
      backgroundColor: '#E1DEE6',
      borderRadius: shape.borderRadius,
      padding: spacing(1, 2),
    },
  })
);
