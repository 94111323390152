import { useRef } from 'react';
import { TableRef } from '@archiwum/shared/table';
import { useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';

import { useRefresh } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';

export const useProcesyBrakowaniaFacade = () => {
  const tableRef = useRef<TableRef>();
  const dispatch = useDrawerRightDispatch();

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  const handleRefresh = () => {
    tableRef.current?.onQueryChange?.();
    dispatch({ selected: [], data: [] });
  };

  useRefresh({
    handleRefresh: () => {
      handleRefresh();
    },
  });

  return {
    tableRef,
    searchValue,
    onChange,
  };
};
