import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette, shape, spacing }) =>
  createStyles({
    root: {},
    header: {
      padding: spacing(1, 0, 1, 5),
    },
    tab: {
      fontSize: theme.typography.paragraphFontSize,
      textTransform: 'none',
    },
    tabSelected: {
      color: theme.colors.focused,
      fontWeight: 'bold',
    },
    indicator: {
      height: 6,
      borderRadius: shape.borderRadius,
      backgroundColor: theme.colors.focused,
    },
    tabs: {
      paddingLeft: '4rem',
      transform: 'translateY(3px)',
    },
    tabContent: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: spacing(1),
    },
  })
);
