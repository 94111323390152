import {
  createStyles,
  makeStyles,
  TableCell,
  ThemeProvider,
} from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing, shape }) =>
  createStyles({
    container: {
      margin: 0,
    },
    caseSection: {
      margin: spacing(3),
      padding: spacing(2.5),
    },
    menu: {
      position: 'relative',
      transform: 'none',
    },
    listItemText: {
      fontSize: 14,
    },
    elevation23: {
      boxShadow: '-1px 3px 25px 3px rgba(172,167,184,0.46)',
      borderRadius: '6px',
    },
    typProwadzeniaBox: {
      width: 'max-content',
      color: '#ffffff',
      borderRadius: '0.6rem',
      top: 0,
      transform: 'translate(3rem, -50%)',
      padding: spacing(1.5, 2.75),
      lineHeight: '1.5',
    },
    elektroniczne: {
      background: theme.colors.sprawaElektroniczna,
    },
    papierowe: {
      background: theme.colors.sprawaTradycyjna,
    },
    textCentered: {
      width: 'auto',
      textAlign: 'center',
    },
    katArchiwalna: {
      width: 'max-content',
      borderRadius: theme.general.borderRadius,
      backgroundColor: theme.sidebar.hover,
      border: 'none',
    },
    actsTableHead: {
      '& th': {
        backgroundColor: theme.colors.background,
      },
    },
    tablePagination: {
      fontSize: '1.4rem',
      '& p': { fontSize: '1.4rem' },
    },
    noYPadding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    statusBox: {
      width: 'max-content',
      margin: spacing(0, 0, 0, 'auto'),
      border: `${theme.sidebar.hover} 2px solid`,
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      top: 0,
      transform: 'translate(-3rem, -50%)',
      padding: spacing(1.5, 2.75),
      lineHeight: '1.5',
    },
    statusAvatar: {
      margin: spacing(0.5),
      color: theme.colors.statusColor,
      textTransform: 'capitalize',
    },
    statusInactive: {
      backgroundColor: theme.colors.statusBackgroundColorInactive,
    },
    statusActive: {
      backgroundColor: theme.colors.statusBackgroundColorActive,
    },
  })
);
