import { useDrawerRightSelector } from '@archiwum/drawer-right';
import * as yup from 'yup';

import {
  BrakowanieModel,
  Spis,
  TypSpisuBrakowania,
} from '../../../../models/models';
import { useCreateSpisDoBrakowaniaDispatch } from '../create-spis-do-brakowania-store';

export type FormStepSpisModel = {
  isMultiKomorki: boolean;
  spisyDoWygenerowania: Spis[];
  singleOrMultiSpisy: string;
};

export const useStepSpisTitleFacade = () => {
  const dispatch = useCreateSpisDoBrakowaniaDispatch();

  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as BrakowanieModel[];

  const tmpKomorkiOrganizacyjne = selected.map((x) => x.komorkaOrganizacyjna);
  const komorkiOrganizacyjne = [...new Set(tmpKomorkiOrganizacyjne)];

  const initialValues: FormStepSpisModel = {
    isMultiKomorki: komorkiOrganizacyjne.length > 1,
    spisyDoWygenerowania: [
      {
        komorkaOrganizacyjna: komorkiOrganizacyjne[0],
        typSpisu: TypSpisuBrakowania.Zwykly,
        tytul: '',
      },
    ],
    singleOrMultiSpisy: '',
  };
  const validationSchema: yup.SchemaOf<FormStepSpisModel> = yup.object().shape({
    isMultiKomorki: yup.boolean(),
    spisyDoWygenerowania: yup
      .array()
      .compact()
      .of(
        yup.object().shape({
          komorkaOrganizacyjna: yup.string(),
          typSpisu: yup.number(),
          tytul: yup.string().required('Pole wymagane'),
        })
      ),
    singleOrMultiSpisy: yup.string().when('isMultiKomorki', {
      is: true,
      then: yup.string().required('Pole wymagane'),
      otherwise: yup.string(),
    }),
  });

  const onSubmit = (values: FormStepSpisModel) => {
    dispatch({
      activeStep: 1,
      spisyDoWygenerowania: values.spisyDoWygenerowania,
    });
  };

  return {
    komorkiOrganizacyjne,
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};
