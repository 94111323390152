export enum TypProwadzenia {
  PAPIEROWE = 1,
  ELEKTRONICZNE = 2,
}

export enum TypObiekt {
  PISMA_NAS = 2,
  SPRAWY = 1,
}
export enum FolderDocelowy {
  POCZEKALNIA = 1,
  DO_POPRAWY = 2,
  POPRAWIONE,
  ODLOZONE,
  ZARCHIWIZOWANE,
}

export enum TypSpisuBrakowania {
  Zwykly = 1,
  CalyUrzad = 2,
  KomorkaOrganizacyjna = 3,
  Scalony = 4,
  EkspertyzaAP = 5,
  Brakowanie = 6,
  ZmianaKategorii = 7,
}

export enum StatusSpisuBrakowania {
  Nowy = 1,
  DoWeryfikacji = 2,
  Zweryfikowany = 3,
}

export type BrakowanieModel = {
  id: string;
  index: string;
  liczbaPorzadkowa: string;
  znak: string;
  numer: string;
  symbolJRWA: string;
  tytul: string;
  folder: FolderArchiwum;
  typObiekt: TypObiekt;
  folderOpis: string;
  kategoriaArchiwalna: string;
  prowadzacy: string;
  pracownikProwadzacy: UzytkownikModel;
  komorkaOrganizacyjna: string;
  typProwadzenia: TypProwadzenia;
  dataUtworzenia: string;
  dataPrzyjecia: string;
  dataZakonczenia: string;
  listaDostepnychOperacji: string[];
  czyWSpisieBrakowania: boolean;
  pracownicyWypozyczajacy: UzytkownikModel[];
};

export type LabelValue = {
  label: string;
  value: string;
};

export type TypProwadzeniaLabelValue = {
  label: string;
  value: TypProwadzenia;
};

export type StatusSpisuBrakowaniaLabelValue = {
  label: string;
  value: StatusSpisuBrakowania;
};

export type TypSpisuBrakowaniaLabelValue = {
  label: string;
  value: TypSpisuBrakowania;
};

export type TypObiektowWSpisieLabelValue = {
  label: string;
  value: TypObiekt;
};

export type GetRequestFiltry = {
  foldery: FolderArchiwum[];
  czyWszystkie?: boolean;
  pominBedaceWSpisach?: boolean;
};

export type SprawyFiltry = {
  komorkiOrganizacyjne: Filters;
  prowadzacy: Filters;
  symboleJRWA: Filters;
  kategorieArchiwalne: Filters;
  typProwadzenia: Filters<TypProwadzeniaLabelValue>;
  dataZakonczeniaOd: Filters<LabelValue>;
  dataZakonczeniaDo: Filters<LabelValue>;
  dataPrzyjeciaOd: Filters<LabelValue>;
  dataPrzyjeciaDo: Filters<LabelValue>;
};

export type SpisyBrakowaniaFiltry = {
  status: Filters<StatusSpisuBrakowaniaLabelValue>;
  typObiektowWSpisie: Filters<TypObiektowWSpisieLabelValue>;
  typProwadzenia: Filters<TypProwadzeniaLabelValue>;
  typSpisuBrakowania: Filters<TypSpisuBrakowaniaLabelValue>;
  dataUtworzeniaOd: Filters<LabelValue>;
  dataUtworzeniaDo: Filters<LabelValue>;
};

export enum PropertyValueType {
  STRING = 'System.String',
  INT = 'System.Int32',
  DATE = 'System.DateTime',
}

export enum PropertyFilterType {
  GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
  LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
  EQUALS = 'Equals',
}

export type Filters<T = string> = {
  dostepneWartosci?: T[];
  propertyValueType: PropertyValueType;
  propertyColumn: string;
  propertyFilterType: PropertyFilterType;
};

export type PrzeniesRequestModel = {
  listaIdObiekt: string[];
  typObiekt: TypObiekt;
  folderDocelowy: FolderArchiwum;
  komentarz: string;
};

export type ListaDokumentowPismaWithCommandsModel = {
  dokumentNazwa?: string;
  idDokument?: string;
  idKodKreskowy?: string;
  idPlikStorage?: string;
  idPismo?: string;
  listaDostepnychOperacji: string[];
};
export type GetRpwDetailsRequestModel = { idPismo: string };
export type GetRpwDetailsResponse = {
  idPismo: string;
  listaDokumentowPisma: ListaDokumentowPismaWithCommandsModel[];
};

export enum FolderArchiwum {
  Poczekalnia = 1,
  DoPoprawy = 2,
  Poprawione = 3,
  Odlozone = 4,
  Zarchiwizowane = 5,
  Wycofane = 6,
  WTrakcieBrakowania = 7,
  Wybrakowane = 8,
  WPaczceArchiwalnej = 9,
  PrzekazaneDoAP = 10,
  GotoweDoZarchiwizowania = 11,
}

export type GetBrakowanieRequestModel = {
  pominBedaceWSpisach: boolean;
  czyWszystkie: boolean;
};

export type ElementModel = {
  idElementDoPoprawy: string;
  wartoscElementuDoPoprawy: string;
};
export type OdbiorcaZadania = {
  idOdbiorcy: string;
  idObiektu: string;
};

export type PrzekazDoPoprawyRequestModel = {
  listaOdbiorcaZadania: OdbiorcaZadania[];
  tytul?: string;
  komentarz?: string;
  listaElementow?: ElementModel[];
  typObiektu?: TypObiekt;
};

export type UzytkownikModel = {
  id: string;
  email: string;
  imie: string;
  nazwisko: string;
  stanowisko: string;
  idStanowiska: string;
  pelnaNazwa: string;
  imieNazwisko: string;
  czyUsuniety: boolean;
  czyAdministratorPodmiotu: boolean;
  czyAdministratorSystemu: boolean;
};
export type GetUzytkownicyResponseModel = {
  items: UzytkownikModel[];
};

export type GenerujPaczkeArchiwalnaRequestModel = {
  typObiektu: TypObiekt;
  listaIdObiekt: string[];
};

export type SprawaTechniczna = {
  dataUtworzenia: string;
  idSprawa: string;
  znakSprawy: string;
  tytul: string;
  idPrzestrzenRobocza: string;
  status: number;
  typProwadzenia: TypProwadzenia;
};

export type Spis = {
  komorkaOrganizacyjna: string;
  typSpisu: TypSpisuBrakowania;
  tytul: string;
};

export type GenerujSpisyBrakowaniaModel = {
  lossIndex: 'single' | 'multiple';
  indexTitles: { [key: string]: string };
  newOrExistingCase: 'new' | 'existing';
  tytul: string;
  symbolJrwa: string;
  znakSprawy: string;
};

export type GenerujSpisyBrakowaniaRequestModel = {
  typObiektu: TypObiekt;
  listaIdObiektDoBrakowania: string[];
  tytul: string;
  znakSprawy: string;
  idWykaz: string;
  kategoriaArchiwalna: string;
  spisyDoWygenerowania: Spis[];
};

export type KategoriaArchiwalna = {
  id: string;
  katArch: string;
  idWykaz: string;
  czyGlowna: boolean;
};

export type KlasaJRWADoAP = {
  id: string;
  symbol: string;
  nazwa: string;
  czyNieStanowiAktSprawy: boolean;
  opis: string;
  typProwadzenia: number;
  liczbaDni: number;
  wykazLiczba: number;
  katArchLiczba: number;
  czyMoznaTworzycSprawyZUdostepnionegoPisma: boolean;
  czyMoznaTworzycSprawyDoBrakowania: boolean;
  czyMoznaTworzycSprawyDoPrzekazywaniaDokumentacjiDoAP: boolean;
  kategorieArchiwalne: KategoriaArchiwalna[];
};

export type GetListaWykazowJRWADoBrakowaniaResponseModel = {
  lista: KlasaJRWADoAP[];
};

export type KlasaJRWADoAPLista = {
  id: string;
  kategorieArchiwalne: KategoriaArchiwalna[];
  nazwa: string;
  rok: string;
  symbol: string;
  typ: string;
  typProwadzenia: number;
};

export type SpisBrakowania = {
  id: string;
  tytul: string;
  dataUtworzenia: string;
  listaDostepnychOperacji: string[];
  statusOpis: string;
  liczbaElementow: number;
  znakSprawy: string;
  idSprawa: string;
  idDokument: string;
};

export type GetObiektySpisuBrakowaniaRequestModel = {
  idSpisBrakowania: string;
  czyZadanie: boolean;
};

export type ObiektSpisuBrakowania = {
  id: string;
  idObiekt: string;
  idSpisBrakowania: string;
  sygnatura: string;
  tytul: string;
  prowadzacy: string;
  kategoriaArchiwalna: string;
  dataZakonczenia: string;
  status: string;
  typObiektuArchiwum: number;
  uwagi: string;
  symbolJRWA: string;
  listaDostepnychOperacji: string[];
};

export type ObiektProcesuBrakowania = {
  id: string;
  idObiekt: string;
  sygnatura: string;
  tytul: string;
  kategoriaArchiwalna: string;
  komorkaOrganizacyjna: string;
  symbolJRWA: string;
  prowadzacy: string;
  status: string;
  statusOpis: string;
  typObiektuArchiwum: number;
  typObiektuArchiwumOpis: number;
  idSpisBrakowania: string;
  dataUtworzenia: string;
  dataZakonczenia: string;
  listaDostepnychOperacji: string[];
};

export type DokumentModel = {
  idDokument: string;
};

export type ZadanieWeryfikacjiSpisuModel = {
  idStanowiskoOdbiorca: string;
  dokumenty: DokumentModel[];
};
export type UtwórzZadanieWeryfikacjiSpisuRequest = {
  opis: string | null;
  terminRealizacji: string | null;
  zadanie: ZadanieWeryfikacjiSpisuModel;
};

export type UsunZeSpisuBrakowaniaRequest = {
  idSpisBrakowania: string;
  listaIdObiektowBrakowania: string[];
  typObiektu: TypObiekt;
};

export type SpisyBrakowaniaBasicRequest = {
  listaIdSpisBrakowania: string[];
};

export type ScalSpisyBrakowaniaRequest = SpisyBrakowaniaBasicRequest & {
  tytul: string;
};

export type SpisyBrakowaniaZmienTytulRequest = {
  idSpisBrakowania: string;
  nowyTytul: string;
};

export type OznaczDoBrakowaniaRequest = {
  listaIdObiekt: string[];
  uwagi?: string;
};

export type OznaczDoEkspertyzyApRequest = OznaczDoBrakowaniaRequest;

export type ZmienKategorieOrazOznaczSpraweRequest = {
  listaIdObiektDoBrakowania: string[];
  kategoriaArchiwalna: string;
};

export type SpisBrakowaniaBasicRequest = { idSpisBrakowania: string };

export type RozdzielSpisyBrakowaniaRequest = SpisBrakowaniaBasicRequest;

export type GetJRWAWyszukajWykazRequest = { symbol?: string };

export type GetKategorieArchiwalneListaModel = { lista: string[] };

export type ProcesBrakowania = {
  id: string;
  tytul: string;
  komentarz: string;
  liczbaElementow: number;
  status: number;
  statusOpis: string;
  dataBrakowania: string;
  dataUtworzenia: string;
  listaDostepnychOperacji: string[];
};

export type UtworzProcesBrakowaniaRequest = {
  idSpisBrakowania: string;
  listaIdObiektDoBrakowania: string[];
  tytul?: string;
  komentarz?: string;
};

export type WstrzymajProcesBrakowaniaRequest = {
  idProcesBrakowania: string;
};

export type DodajDoSpisuBrakowaniaRequest = {
  idSpisBrakowania: string;
  listaIdObiektDoBrakowania: string[];
};

export type SpisBrakowaniaShort = {
  id: string;
  tytul: string;
  znakSprawy: string;
};
