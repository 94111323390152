import { useApi } from '@nask/hooks';
import {
  ObiektSpisuBrakowania,
  OznaczDoEkspertyzyApRequest,
} from '../../models/models';
import { useDrawerRightSelector } from '@archiwum/drawer-right';
import { BrakowanieServiceCommand } from '../../services/brakowanie-service-command';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { actionsService$ } from '@archiwum/actions-handler';
import { getErrorResponse } from '@archiwum/utility/helper';
import * as yup from 'yup';

export type FormModel = { uwagi?: string };

export const useOznaczDoEkspertyzyFacade = () => {
  const [showSnackbar] = useSnackbar();

  const initialValues: FormModel = { uwagi: '' };

  const validationSchema: yup.SchemaOf<FormModel> = yup
    .object()
    .shape({ uwagi: yup.string() });

  const selected: ObiektSpisuBrakowania[] = useDrawerRightSelector(
    (state) => state.data
  ) as any;

  const { setUrl } = useApi({
    onSuccess: () => {
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const handleSubmit = (values: FormModel) => {
    const request: OznaczDoEkspertyzyApRequest = {
      listaIdObiekt: selected?.map((i) => i.idObiekt),
      uwagi: values.uwagi,
    };

    setUrl(BrakowanieServiceCommand.OznaczDoEkspertyzyAp(request));
  };

  return {
    formikProps: { initialValues, validationSchema, onSubmit: handleSubmit },
    selected,
  };
};
