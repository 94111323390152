import { useEffect } from 'react';

import {
  useCreateSpisDoBrakowaniaDispatch,
  useCreateSpisDoBrakowaniaSelector,
  setInitValueCreateSpisDoBrakowania,
} from './create-spis-do-brakowania-store';
import { StepSpisTitle } from './step-spis-title/step-spis-title';
import { StepCases } from './step-cases/step-cases';
import { TypObiekt } from '../../../models/models';

export interface StepModel {
  name: string;
  stepNumber: number;
  completed?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  handleNext?: () => void;
  handlePrevious?: () => void;
  formId?: string;
  tooltipText?: string;
  nextButtonLabel?: string;
  hidePreviousBtn?: boolean;
}

type UseCreateSpisDoBrakowaniaFacadeProps = {
  handleClose: () => void;
  typObiekt: TypObiekt;
};

export const useCreateSpisDoBrakowaniaFacade = ({
  handleClose,
  typObiekt,
}: UseCreateSpisDoBrakowaniaFacadeProps) => {
  const dispatch = useCreateSpisDoBrakowaniaDispatch();
  const { activeStep, czyBrakujeKlasyJRWA } = useCreateSpisDoBrakowaniaSelector(
    (state) => state
  );

  useEffect(() => {
    return () => {
      setInitValueCreateSpisDoBrakowania();
    };
  }, []);

  const changeActiveStep = (stepNumber: number) => {
    dispatch({ activeStep: stepNumber });
  };

  const steps: StepModel[] = [
    {
      name: 'Wybór spraw do spisu',
      stepNumber: 0,
      formId: 'brakowanie-step-title',
    },
    {
      name: 'Tworzenie sprawy',
      stepNumber: 1,
      formId: 'brakowanie-step-cases',
      handlePrevious: () => {
        changeActiveStep(0);
      },
      disabled: activeStep === 0 || czyBrakujeKlasyJRWA,
    },
  ];

  const getActiveStep = (activeStepNumber: number) => {
    switch (activeStepNumber) {
      case 0:
        return <StepSpisTitle />;
      case 1:
        return <StepCases handleClose={handleClose} typObiekt={typObiekt} />;
      default:
        return null;
    }
  };

  return {
    activeStep,
    steps,
    changeActiveStep,
    getActiveStep,
  };
};
