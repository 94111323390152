import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ProcesBrakowania } from '../../models/models';
import { useStyles } from '../views/procesy-brakowania.styles';
import { RowCounter } from './row-counter';
import { brakowanieCountDown$, getRemainingTime } from './utils';

export type ProcesBrakowaniaOperacjeProps = {
  data: ProcesBrakowania;
};

export const ProcesBrakowaniaOperacje = ({
  data,
}: ProcesBrakowaniaOperacjeProps) => {
  const countTo = data.dataBrakowania;
  const [remainingTime, setRemainingTime] = useState(
    getRemainingTime(countTo)[0]
  );

  const classes = useStyles();

  useEffect(() => {
    const sub = brakowanieCountDown$.subscribe({
      next: () => {
        setRemainingTime(getRemainingTime(countTo)[0]);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Box display={'flex'} alignItems={'center'}>
      <RowCounter {...{ remainingTime }} />
    </Box>
  );
};
