import { useEffect, useRef } from 'react';
import { TableRef } from '@archiwum/shared/table';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TypProwadzeniaLabelValue } from '@archiwum/features/archiving-documents';
import { useRefresh } from '@archiwum/actions-handler';
import { CaseServiceQuery } from '../../services/case-service-query';
import { SprawyPoczekalniaFilters } from '../../models/models';
import { useFiltering } from '@archiwum/hooks';
import {
  getCeilingDate,
  getFloorDate,
} from 'libs/shared/table/src/lib/hooks/methods';

const initialState: SprawyPoczekalniaFilters = {
  dataPrzyjeciaOd: null,
  dataPrzyjeciaDo: null,
  dataZakonczeniaOd: null,
  dataZakonczeniaDo: null,
  kategoriaArchiwalna: null,
  komorkaOrganizacyjna: null,
  prowadzacy: null,
  symbolJRWA: null,
  typProwadzenia: null,
  folder: null,
};

export const useSpisNASFacade = () => {
  const tableRef = useRef<TableRef>();
  const dispatch = useDrawerRightDispatch();
  const { state, setState } = useFiltering<SprawyPoczekalniaFilters>({
    initialState: initialState,
  });
  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );
  const { data } = useApi({
    service: CaseServiceQuery.GetNASFiltry({ foldery: [] }),
  });

  useRefresh({
    handleRefresh: () => {
      tableRef.current.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue, state]);

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?: SelectModel | TypProwadzeniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const komorkaOrganizacyjnaOptions =
    data?.komorkiOrganizacyjne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const prowadzacyOptions =
    data?.prowadzacy?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const symboleJRWAOptions =
    data?.symboleJRWA?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const katArchiwalnaOptions =
    data?.kategorieArchiwalne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const typProwadzeniaOptions = data?.typProwadzenia?.dostepneWartosci ?? [];
  const statusOptions = data?.folder?.dostepneWartosci ?? [];
  return {
    data,
    state,
    tableRef,
    searchValue,
    handleUpdateDate,
    handleUpdateSelect,
    onChange,
    typProwadzeniaOptions,
    prowadzacyOptions,
    komorkaOrganizacyjnaOptions,
    katArchiwalnaOptions,
    symboleJRWAOptions,
    statusOptions,
  };
};
