import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { theme as themes } from '@nask/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarContainer: {
      gridArea: 'sidebar',
      position: 'relative',
    },

    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: 1,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: themes.sidebar.width,
      width: `calc(100% - ${themes.sidebar.width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: themes.sidebar.width,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: themes.sidebar.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: `${themes.sidebar.bgColor} 4px solid`,
        // borderColor: `${theme.menuStyle.backgroundColor}`,
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: themes.sidebar.widthClosed,
      [theme.breakpoints.up('sm')]: {
        width: themes.sidebar.widthClosed,
      },
    },
    drawerPaper: {
      backgroundColor: themes.sidebar.bgColor,
      color: themes.sidebar.color,
      position: 'absolute',
      border: 'none',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);
