import {
  FormDatePicker,
  FormRadio,
  FormRadioGroup,
  FormSelect,
} from '@archiwum/forms';
import { Button } from '@archiwum/ui/button';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Box, Grid } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { LabelValue } from '../../models/models';
import { FormValues } from '../use-zamawianie-raportow-facade';

type ZamawianieReportowFormProps = {
  reportTypeOptions: LabelValue<number>[];
  reportFormatOptions: string[];
};

export const ZamawianieReportowForm = ({
  reportTypeOptions,
  reportFormatOptions,
}: ZamawianieReportowFormProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  useEffect(() => {
    if (values.dateFrom && values.dateTo && values.dateFrom > values.dateTo)
      setFieldValue('dateTo', values.dateFrom);
  }, [values.dateFrom]);

  useEffect(() => {
    if (values.dateFrom && values.dateTo && values.dateFrom > values.dateTo)
      setFieldValue('dateFrom', values.dateTo);
  }, [values.dateTo]);

  return (
    <Form>
      <Grid container direction={'column'}>
        <Box mb={1}>
          <Typography variantType={TypographyVariants.Paragraph}>
            Wybierz typ raportu oraz wskaż zakres czasowy z jakiego wygenerować
            raport:
          </Typography>
          <Box
            py={2}
            display={'flex'}
            gridGap={12}
            justifyContent={'flex-start'}
            alignItems={'top'}
          >
            <FormSelect<number>
              containerStyles={{
                width: '100%',
              }}
              name={'reportType'}
              label="Typ raportu"
              options={reportTypeOptions.map((i) => i.value)}
              getOptionLabel={(option) =>
                reportTypeOptions.find((i) => i.value === option)?.label ?? ''
              }
              required
            />
            <Box minWidth="26rem">
              <FormDatePicker
                name={'dateFrom'}
                label="Data od"
                clearable
                disableFuture
                required
                showTodayButton
                notCallBlurOnClose
              />
            </Box>
            <Box minWidth="26rem">
              <FormDatePicker
                name={'dateTo'}
                label="Data do"
                clearable
                disableFuture
                required
                showTodayButton
                notCallBlurOnClose
              />
            </Box>
          </Box>
        </Box>
        <Box my={1}>
          <Typography variantType={TypographyVariants.Paragraph}>
            Wybierz format pliku do utworzenia:
          </Typography>
          <FormRadioGroup name="reportFormat">
            {reportFormatOptions.map((option) => (
              <FormRadio
                key={`reportFormat-${option}`}
                value={option}
                label={option}
              />
            ))}
          </FormRadioGroup>
        </Box>
        <Grid container justifyContent={'flex-end'}>
          <Button type="submit">Zamów raport</Button>
        </Grid>
      </Grid>
    </Form>
  );
};
