import { ArchivingDocumentsServiceCommand } from '../../../services/archiving-documents-service-command';
import { parseJSON, stringifyJSON, useApi } from '@nask/hooks';
import * as yup from 'yup';
import {
  FolderArchiwum,
  PoczekalniaModel,
  TypObiekt,
} from '../../../models/models';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { getErrorResponse } from '@archiwum/utility/helper';
import { actionsService$ } from '@archiwum/actions-handler';
import { useDrawerRightSelector } from '@archiwum/drawer-right';

export type OdlozFormModel = {
  od: string;
  do: string;
  numery: string;
  selected: string;
  komentarz: string;
};

export type UseOdlozFacade = {
  typObiekt: TypObiekt;
};

export const useOdlozFacade = ({ typObiekt }: UseOdlozFacade) => {
  const [showSnackbar] = useSnackbar();
  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as PoczekalniaModel[];
  const { isLoading, setUrl } = useApi({
    onSuccess: () => {
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });
  const initialValues: OdlozFormModel = {
    od: '',
    do: '',
    selected: stringifyJSON(selected),
    numery: '',
    komentarz: '',
  };
  const validationSchema: yup.SchemaOf<OdlozFormModel> = yup.object().shape({
    do: yup.string(),
    od: yup.string(),
    numery: yup.string(),
    selected: yup.string().required('Wybierz dokument'),
    komentarz: yup.string().required('Komentarz wymagany'),
  });

  const onSubmit = (values: OdlozFormModel) => {
    setUrl(
      ArchivingDocumentsServiceCommand.Archiving({
        listaIdObiekt: parseJSON<PoczekalniaModel[]>(values.selected).map(
          (x) => x.id
        ),
        typObiekt,
        folderDocelowy: FolderArchiwum.Odlozone,
        komentarz: values.komentarz,
      })
    );
  };
  return {
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
    isLoading,
  };
};
