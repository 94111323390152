import { useDeepMemo } from '@nask/hooks';
import { useField } from 'formik';
import React from 'react';

import { ErrorContainer, InputContainer } from '../styles';
import { Checkbox, CheckboxProps } from '@material-ui/core';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type Props = Omit<CheckboxProps, 'form' | 'id' | 'checked' | 'onChange'>;
interface FormCheckboxProps extends Props {
  noErrorText?: boolean;
  onChange?: (value) => void;
}

const FormCheckbox = ({ noErrorText, ...props }: FormCheckboxProps) => {
  const [field, meta] = useField<boolean>(props.name);
  const { onBlur, onChange, ...rest } = field;
  return useDeepMemo(
    () => (
      <InputContainer style={{ justifyContent: 'flex-start' }}>
        <Checkbox
          checked={field.value}
          {...rest}
          {...props}
          id={props.name}
          color="primary"
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
            field.onChange(e);
          }}
        />
        {meta.error && meta.touched && (
          <ErrorContainer>
            {meta.error && meta.touched && meta.error}
          </ErrorContainer>
        )}
      </InputContainer>
    ),
    [rest, meta, props]
  );
};

export default FormCheckbox;
