import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: theme.typography.paragraphFontSize,
      color: theme.typography.paragraphColor,
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.colors.focused} !important`,
      },
    },
    labelRoot: {
      fontSize: 16,
      '&$cssFocused': {
        color: `${theme.colors.focused} !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      //outlined line
      fontSize: 16,
    },
    input: {
      padding: '14px 14px',
    },
    formHelperRoot: {
      fontSize: 16,
    },
  })
);
