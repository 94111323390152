import React from 'react';
import ReactDOM from 'react-dom';
import './styles/_variables.scss';

import App from './app/app';
import { BrowserRouter } from 'react-router-dom';
import { GlobLoader } from './app/glob-loader';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <>
        <GlobLoader />
        <App />
      </>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
