import { Box, Container, Divider, Paper } from '@material-ui/core';
import { Tab, Tabs } from '@archiwum/ui/tabs';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { Typography } from '@archiwum/ui/typography';
import { SearchInput } from '@archiwum/shared/search-input';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { useSelectedDispatch } from '@archiwum/hooks';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';

import {
  TypProwadzeniaLabelValue,
  StatusSpisuBrakowaniaLabelValue,
  TypSpisuBrakowaniaLabelValue,
} from '../../models/models';
import { useSpisyBrakowaniaFacade } from './use-spisy-brakowania-facade';
import { getFilters, getSpisyBrakowaniaQuery } from '../../helper';
import { useStyles } from './spisy-brakowania.styles';
import { SpisyBrakowaniaTable } from '../components/spisy-brakowania-table';

export const SpisyBrakowania = () => {
  const {
    data,
    state,
    tableRef,
    searchValue,
    statusOptions,
    typObiektowWSpisieOptions,
    typProwadzeniaOptions,
    typSpisuBrakowaniaOptions,
    handleUpdateDate,
    handleUpdateSelect,
    handleChangeTab,
    tabs,
    activeTab,
    onChange,
    isSprawy,
    handleRefresh,
  } = useSpisyBrakowaniaFacade();
  const dispatch = useSelectedDispatch();
  const classes = useStyles();

  return (
    <>
      <Box width="100%">
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                  <Paper elevation={0}>
                    <Box display="grid" gridAutoFlow="row">
                      <Box display="flex" justifySelf="flex-end">
                        <Box m={0.5} p={0.5}>
                          <SearchInput
                            searchMethod={(phrase: string) => {
                              onChange(phrase);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box className={classes.filtersWrapper}>
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Status"
                            placeholder="Wszystkie"
                            name="status"
                            options={statusOptions}
                            value={
                              statusOptions.find(
                                (option) => option.value === state.status
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'status',
                                v as StatusSpisuBrakowaniaLabelValue
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Typ spisu brakowania"
                            placeholder="Wszystkie"
                            name={'typSpisuBrakowania'}
                            options={typSpisuBrakowaniaOptions}
                            value={
                              typSpisuBrakowaniaOptions.find(
                                (option) =>
                                  option.value === state.typSpisuBrakowania
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'typSpisuBrakowania',
                                v as TypSpisuBrakowaniaLabelValue
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Typ prowadzenia"
                            placeholder="Wszystkie"
                            name="typProwadzenia"
                            options={typProwadzeniaOptions}
                            value={
                              typProwadzeniaOptions.find(
                                (option) =>
                                  option.value === state.typProwadzenia
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            renderOption={(option) => (
                              <Box
                                display="grid"
                                gridGap={8}
                                gridAutoFlow="column"
                                alignItems="center"
                              >
                                <TypProwadzeniaAvatar
                                  typProwadzenia={option.value}
                                />
                                <Typography>{option.label}</Typography>
                              </Box>
                            )}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'typProwadzenia',
                                v as TypProwadzeniaLabelValue
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <DatePicker
                            label="Data utworzenia od"
                            name="dataUtworzeniaOd"
                            value={state.dataUtworzeniaOd}
                            onChange={(date) =>
                              handleUpdateDate(date, 'dataUtworzeniaOd')
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <DatePicker
                            label="Data utworzenia do"
                            name="dataUtworzeniaDo"
                            value={state.dataUtworzeniaDo}
                            onChange={(date) =>
                              handleUpdateDate(date, 'dataUtworzeniaDo')
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Tabs
              classes={{ indicator: classes.indicator, root: classes.root }}
              value={activeTab}
              onChange={handleChangeTab}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  classes={{ root: classes.tab, selected: classes.activeTab }}
                />
              ))}
            </Tabs>
            <Divider classes={{ root: classes.borderBottomTab }} />
          </Box>
          <Paper classes={{ root: classes.paperShadowBottom }}>
            <SpisyBrakowaniaTable
              tableRef={tableRef}
              data={(query, result) => {
                getSpisyBrakowaniaQuery(
                  query,
                  result,
                  searchValue,
                  getFilters(state, data),
                  dispatch
                );
              }}
            />
          </Paper>
        </Container>
      </Box>
    </>
  );
};
