import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useApi } from '@nask/hooks';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { MenuServiceQuery, MenuModel } from '@archiwum/sidebar';
import { getRouteById } from '@archiwum/routes';

import { useStyles } from './home.styles';

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useApi({ service: MenuServiceQuery.GetMenu() });
  const archiwumMenu = data?.filter((item) => item.id === 'ARCHIWUM')?.[0];

  const handleRouteClick = (route: MenuModel) => {
    if (route.url) {
      window.location.href = route.url;
    } else {
      const path = getRouteById(route.id, route.url);
      history.push(path);
    }
  };

  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            Archiwum
          </Typography>

          <Divider />
        </Paper>
      </Box>
      <Container
        maxWidth={'xl'}
        classes={{
          root: classes.container,
          maxWidthXl: classes.containerMaxWidth,
        }}
      >
        {archiwumMenu?.lista?.map((item) => (
          <Card key={item.id} classes={{ root: classes.card }}>
            <CardActionArea
              classes={{ root: classes.cardActionArea }}
              onClick={(e) => {
                handleRouteClick(item);
              }}
            >
              <CardContent>
                <Typography
                  align={'center'}
                  variantType={TypographyVariants.Paragraph}
                >
                  {item.nazwa}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Container>
    </div>
  );
};
