import { Box } from '@material-ui/core';
import { useRef } from 'react';
import { TableRef } from '@archiwum/shared/table';

import { ObiektyProcesuBrakowaniaTable } from './obiekty-procesu-brakowania-table';
import { getObiektyProcesuBrakowaniaQuery } from '../../helper';
import { ProcesBrakowania } from '../../models/models';
import { useStyles } from '../../brakowanie.styles';

type RenderDetailsPanelProps = {
  rowData: ProcesBrakowania;
};

export const RenderDetailsPanel = ({ rowData }: RenderDetailsPanelProps) => {
  const tableRef = useRef<TableRef>();
  const classes = useStyles();
  return (
    <Box pl={9} pt={6} pb={6} className={classes.detailPanel}>
      <ObiektyProcesuBrakowaniaTable
        tableRef={tableRef}
        data={(query, result) => {
          getObiektyProcesuBrakowaniaQuery(query, result, rowData.id);
        }}
      />
    </Box>
  );
};
