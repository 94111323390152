import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
  createStyles({
    indicator: {
      display: 'none',
    },
    root: {},
    tab: {
      backgroundColor: 'rgb(239, 240, 242)',
      color: palette.primary.main,
      fontSize: theme.typography.paragraphFontSize,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      maxWidth: 'inherit',
    },
    activeTab: {
      backgroundColor: palette.primary.main,
      color: palette.getContrastText(palette.primary.main),
      fontWeight: 'bold',
    },
    textField: {
      fontSize: theme.typography.descriptionFontSize,
    },
    iconButton: {
      padding: spacing(1),
    },
    datePicker: {
      paddingLeft: spacing(1.65),
    },
    modal: {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
      boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.5)',
      backgroundColor: theme.colors.white,
      overflowX: 'hidden',
      width: '600px',
      display: 'grid',
      rowGap: '2rem',
      borderRadius: '4px',
    },
    modalFooter: {
      minHeight: '40px',
      display: 'grid',
      justifyContent: 'flex-end',
    },
    modalButtonsContainer: {
      display: 'grid',
      gridAutoFlow: 'column',
      minWidth: '100px',
      maxWidth: '500px',
      gridGap: '1rem',
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, auto))',
    },
    modalHeader: {
      fontWeight: 700,
      fontSize: theme.typography.fontSizeHeaderPopup,
    },
    modalHeaderContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.6rem',
    },
    tableHeader: {
      color: theme.colors.primary,
      fontWeight: 700,
    },
    formControlLabel: {},
    formLabel: {
      fontSize: theme.typography.descriptionFontSize,
    },
    row: {
      padding: '1.5rem 0',
      display: 'grid',
      gridTemplateColumns: '20px 1fr',
    },
    paper: {
      boxShadow: '-1px 3px 25px 3px rgb(172 167 184 / 46%)',
      overflow: 'hidden',
    },
    wrapper: {
      overflowY: 'auto',
      maxHeight: '20vh',
    },
  })
);
