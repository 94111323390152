import { useDeepMemo, useFormSelect, UseFromSelectProps } from '@nask/hooks';
import React, { CSSProperties } from 'react';

import { InputContainer } from '../styles';
import { Autocomplete, AutocompleteProps } from '@archiwum/shared/autocomplete';

type FormSelectProps<T> = Omit<
  AutocompleteProps<T, any, any, any>,
  'onChange'
> &
  Pick<UseFromSelectProps, 'onChange'> & {
    containerStyles?: CSSProperties;
    dependName?: string | string[];
    dependNameUpdateOptions?: string | string[];
  };

export const FormSelect = <T extends unknown>({
  name,
  dependName,
  dependNameUpdateOptions,
  containerStyles,
  ...props
}: FormSelectProps<T>) => {
  const {
    field,
    state,
    handleChange,
    handleBlur,
    value,
    context,
    helper,
  } = useFormSelect({
    name,
    isMulti: props.multiple,
    options: props.options,
    onChange: props.onChange,
    // @ts-ignore
    onBlur: props.onBlur,
  });

  const isDepnedValueUpdate = dependName
    ? Array.isArray(dependName)
      ? dependName.map((x) => context.values[x])
      : context.values[dependName]
    : '';
  const isDependValueUpdateOptions = dependNameUpdateOptions
    ? Array.isArray(dependNameUpdateOptions)
      ? dependNameUpdateOptions.map((x) => context.values[x])
      : context.values[dependNameUpdateOptions]
    : '';
  const { onChange, onBlur, onFocus, ...rest } = props;
  const restProps = rest;
  return useDeepMemo(() => {
    console.log(
      `%cUPDATE ${name} `,
      'background-color: yellow; color: #000000; padding: 2px 4px'
    );
    return (
      <InputContainer style={containerStyles}>
        <Autocomplete
          disabled={state.disabled}
          id={name}
          helperText={state.helperText}
          error={state.error}
          required={state.required}
          value={value}
          options={props.options}
          {...restProps}
          onBlur={handleBlur}
          onChange={(event, value1) => handleChange(value1)}
        />
      </InputContainer>
    );
  }, [name, restProps, state, isDepnedValueUpdate, isDependValueUpdateOptions]);
};

/*TODO--INFO*/
//JESLI W FORMULARZU  KOMPONENT POJAWIA SIE W MOMENCIE WYBRANIA JAKIEJS WARTOSCI NALEZY DODAC WARUNEK DISPLAY: "NONE" ZAMIAST ROBIC MOUNT I UNMOUNT ZE WZGLEDU NA WARUNKI W FORMSELECTNASK
