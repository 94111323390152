import React, { forwardRef } from 'react';
import {
  Tab as MaterialTab,
  TabProps as MaterialTabProps,
} from '@material-ui/core';

type TabProps = MaterialTabProps & {};

export const Tab = forwardRef<HTMLDivElement, TabProps>((props, ref) => {
  return <MaterialTab ref={ref} {...props} />;
});
