import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { getTransitionStyles, styles } from '../snackbar-item-styles';
import {
  capitalise,
  defaultAnchorOrigin,
  getMuiClasses,
} from '../snackbar-item-util';
import { useDraggableSnackbar } from './use-draggable-snackbar';

export interface SnackbarItemProps extends SnackbarProps {
  classes: any;
  level: number;
  onExited: (key) => void;
  onClickAction?: () => void;
  onClose: (key) => void;
  hideIconVariant?: boolean;
  iconVariant?: {
    success: React.ReactNode;
    error: React.ReactNode;
    warning: React.ReactNode;
    info: React.ReactNode;
  };
  open?: boolean;
  key: number;
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info';
  message?: string;
  snack: any;
}

const SnackbarItem = (props: SnackbarItemProps) => {
  const handleClose = (key) => (event, reason) => {
    const { onClose } = props;
    if (reason === 'clickaway') return;
    onClose(key);
  };

  const {
    classes,
    action,
    anchorOrigin = defaultAnchorOrigin,
    ContentProps = {},
    hideIconVariant,
    iconVariant,
    level,
    snack,
    style,
    onExited,
    onClickAction,
    ...other
  } = props;

  const {
    action: contentAction,
    className,
    ...otherContentProps
  } = ContentProps;
  const { key, variant = 'default', ...singleSnackProps } = snack;
  const ref = useRef<HTMLDivElement>(null);
  const contentProps = {
    ...otherContentProps,
    ...singleSnackProps.ContentProps,
    action: snack.actions || contentAction || action,
  };

  let onClickHandler = snack.actions ? snack.onClickAction : onClickAction;
  onClickHandler = onClickHandler || handleClose(key);

  // const anchOrigin = singleSnackProps.anchorOrigin || anchorOrigin;
  const { pos, isTopPosition } = useDraggableSnackbar({
    ref,
  });
  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      style={{
        zIndex: 2001,
        ...style,
        left: pos.left,
        ...getTransitionStyles(
          level,
          { vertical: 'top', horizontal: 'left' },
          pos.top,
          isTopPosition()
        ),
      }}
      {...other}
      {...singleSnackProps}
      open={snack.open}
      classes={getMuiClasses(classes)}
      onClose={handleClose(key)}
      onExited={() => {
        onExited(key);
      }}
    >
      <DraggingDiv
        ref={ref}
        style={{
          position: 'fixed',
        }}
      >
        <StyledSnackbarContent
          type={variant}
          className={clsx(
            classes.base,
            classes[`variant${capitalise(variant)}`],
            className
          )}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              {!hideIconVariant && (
                <span className={classes.iconVariant}>
                  {iconVariant[variant]}
                </span>
              )}
              <span className={classes.messageContent}>
                {Array.isArray(snack.message)
                  ? snack.message.length > 1
                    ? snack.message.map((x) => (
                        <span className={classes.messageElement}>{x}</span>
                      ))
                    : snack.message.map((x) => x)
                  : snack.message}
              </span>
            </span>
          }
          action={
            contentProps.action && (
              <span onClick={onClickHandler}>{contentProps.action}</span>
            )
          }
          {...contentProps}
        />
      </DraggingDiv>
    </Snackbar>
  );
};

export default withStyles(styles)(SnackbarItem);

const StyledSnackbarContent = styled((props) => (
  <SnackbarContent {...props} />
))<{ type?: string }>`
  && {
    background-color: ${(props) => {
      if (props.type === 'success') {
        return props.theme.colors.success;
      }
      if (props.type === 'danger') {
        return props.theme.colors.danger;
      }
      if (props.type === 'warning') {
        return props.theme.colors.warning;
      }
      if (props.type === 'info') {
        return props.theme.colors.info;
      }
    }};
  }
`;
const DraggingDiv = styled.div`
  position: fixed;
`;
