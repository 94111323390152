import { Box, Container } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Breadcrumbs, Link } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useNavigationBreadcrumbsFacade } from './hooks/use-navigation-breadcrumbs';
import { useStyles } from './navigation-breadcrumbs.styles';
import { DomekWsciezce058Icon } from '@nask/ezdrp-icons';

type NavigationBreadcrumbsProps = {
  // menu?: MenuModel[];
};

export const NavigationBreadcrumbs = (props: NavigationBreadcrumbsProps) => {
  const classes = useStyles();
  const { state, handleClick } = useNavigationBreadcrumbsFacade({});
  /*global EZDRP_APP_URL*/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ezdrpURL = EZDRP_APP_URL;

  document.title = state
    .map((x) => x.name)
    .reverse()
    .join(' - ');

  return (
    <Box
      pt={3}
      pb={3}
      style={{
        display: state.length < 2 ? 'none' : 'inherit',
      }}
    >
      <Container maxWidth={'xl'}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            key={'strona-startowa'}
            href={ezdrpURL}
            color={'inherit'}
            aria-label="Strona główna"
          >
            <Typography className={classes.link}>
              <DomekWsciezce058Icon className={classes.icon} />
            </Typography>
          </Link>
          {state.map((item, index) => {
            if (state.length - 1 === index) {
              return (
                <Typography key={item.name} className={classes.activeLink}>
                  {item.name}
                </Typography>
              );
            } else {
              return (
                <Link
                  key={item.name}
                  href={item.url}
                  color={'inherit'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(item.url);
                  }}
                >
                  <Typography className={classes.link}>{item.name}</Typography>
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      </Container>
    </Box>
  );
};
