import React from 'react';
import styled from 'styled-components';
import {
  Administracja015Icon,
  Archiwum016Icon,
  BazaKontaktow011Icon,
  Biurko002Icon,
  Kancelaria013Icon,
  Pisma005Icon,
  Procesy007Icon,
  Raporty010Icon,
  Rejestry009Icon,
  Reorganizacja017Icon,
  Rodo008Icon,
  SpisNas006Icon,
  SpisSpraw004Icon,
  Wydruki014Icon,
  Wyszukiwanie012Icon,
  Zadania001Icon,
  Zadania089Icon,
} from '@nask/ezdrp-icons';

export type RouterParams = {
  caseId: string;
  idSpis: string;
  idKarton: string;
};

// TODO należy pamietać o kolejności obiektów do breadcumba (od najprostrzego do najbardziej zlożonego) //
export const Routes = {
  All: {
    path: '*',
    name: '.',
    viewId: '..',
  },
  Home: {
    path: '/',
    name: 'Archiwum',
    viewId: 'DASHBOARD',
  },
  NotFound: {
    path: '/not-found',
    name: 'Błędna strona',
    viewId: 'NOT_FOUND',
  },
  ErrorPage: {
    path: '/error',
    name: 'Błędna strona',
    viewId: 'ERROR',
  },
  Poczekalnia: {
    path: '/poczekalnia',
    name: 'Poczekalnia',
    viewId: 'POCZEKALNIA',
  },
  Nowe: {
    path: '/poczekalnia/nowe',
    name: 'Poczekalnia',
    viewId: 'POCZEKALNIA',
    exclude: true,
  },
  DoPoprawy: {
    path: '/poczekalnia/do-poprawy',
    name: 'Poczekalnia',
    viewId: 'POCZEKALNIA',
    exclude: true,
  },
  Poprawione: {
    path: '/poczekalnia/poprawione',
    name: 'Poczekalnia',
    viewId: 'POCZEKALNIA',
    exclude: true,
  },
  Odlozone: {
    path: '/poczekalnia/odlozone',
    name: 'Poczekalnia',
    viewId: 'POCZEKALNIA',
    exclude: true,
  },
  Zarchiwizowane: {
    path: '/zarchiwizowane',
    name: 'Zarchwizowane',
    viewId: 'Zarchwizowane',
  },
  ZarchiwizowaneSprawy: {
    path: '/zarchiwizowane/sprawy',
    name: 'Zarchwizowane sprawy',
    viewId: 'Zarchwizowane sprawy',
    exclude: true,
  },
  ZarchiwizowaneNAS: {
    path: '/zarchiwizowane/nas',
    name: 'Zarchwizowane NAS',
    viewId: 'Zarchwizowane NAS',
    exclude: true,
  },
  //ZADANIA
  ZadaniaOtrzymane: {
    path: '/zadania/otrzymane',
    name: 'Zadania',
    viewId: 'ZADANIA',
  },
  ZadaniaZlecone: {
    path: '/zadania/zlecone',
    name: 'Zadania',
    viewId: 'ZADANIA',
  },
  ZadaniaZrealizowane: {
    path: '/zadania/zrealizowane',
    name: 'Zadania',
    viewId: 'ZADANIA',
  },
  // SPIS SPRAW
  SpisSpraw: {
    path: '/spis-spraw',
    name: 'Spis spraw',
    viewId: 'SPIS_SPRAW',
  },
  // SPIS PISM NAS
  SpisNAS: {
    path: '/spis-nas',
    name: 'Spis pism niestanowiących akt spraw',
    viewId: 'SPIS_NAS',
  },
  // ARCHIWUM
  // Archives: {
  //   path: `/archiwum`,
  //   name: 'Archiwum',
  //   viewId: 'Archiwum',
  // },
  CaseDetails: {
    path: `/archiwum/szczegoly-sprawy/:caseId`,
    name: 'Inicjowanie Archiwum',
    viewId: 'INIT_Archiwum',
  },
  NasDetails: {
    path: `/archiwum/szczegoly-pisma-nas/:caseId`,
    name: 'Szczegóły pisma',
    viewId: 'NAS_DETAILS',
  },
  //Paczka archiwalna
  PaczkaArchiwalna: {
    path: '/paczka-achiwalna',
    name: 'Paczka archiwalna',
    viewId: 'PACZKA_ARCHIWALNA',
  },
  PaczkaArchiwalnaGenerowanie: {
    path: '/paczka-achiwalna/generowanie',
    name: 'Paczka archiwalna',
    viewId: 'PACZKA_ARCHIWALNA',
    exclude: true,
  },
  PaczkaArchiwalnaLista: {
    path: '/paczka-achiwalna/lista',
    name: 'Paczka archiwalna',
    viewId: 'PACZKA_ARCHIWALNA',
    exclude: true,
  },
  //Brakowanie
  Brakowanie: {
    path: '/brakowanie',
    name: 'Brakowanie',
    viewId: 'BRAKOWANIE',
  },
  BrakowanieWytypowaneDoBrakowania: {
    path: '/brakowanie/wytypowane-do-brakowania',
    name: 'Wytypowne do brakowania',
    viewId: 'WYTYPOWANE_DO_BRAKOWANIE',
    exclude: true,
  },
  BrakowanieSpisy: {
    path: '/brakowanie/spisy',
    name: 'Spisy',
    viewId: 'SPISY',
    exclude: true,
  },
  BrakowanieProcesy: {
    path: '/brakowanie/procesy',
    name: 'Procesy',
    viewId: 'PROCESY',
    exclude: true,
  },
  //Gotowe do zarchiwizowania
  GotoweDoArchiwizacji: {
    path: '/gotowe-do-archiwizacji',
    name: 'Gotowe do zarchiwizowania',
    viewId: 'GOTOWE_DO_ARCHIWIZACJI',
  },
  Raporty: {
    path: '/raporty',
    name: 'Raporty',
    viewId: 'RAPORTY',
  },
  RaportyZamawianieRaportow: {
    path: '/raporty/zamow',
    name: 'Zamów raport',
    viewId: 'RAPORTY_ZAMAWIANIE',
    exclude: true,
  },
  RaportyListaZamowionychRaportow: {
    path: '/raporty/lista',
    name: 'Lista zamówionych raportów',
    viewId: 'RAPORTY_LISTA_ZAMOWIONYCH',
    exclude: true,
  },
  // Skład chronologiczny
  SkladChronologicznyArchiwalnySpisy: {
    path: '/sklad-chronologiczny-archiwalny',
    name: 'Spisy',
    viewId: 'ARCHIWUM_SKLAD_CHRONOLOGICZNY',
  },
  SkladChronologicznyKartony: {
    path: '/sklad-chronologiczny-archiwalny/:idSpis',
    name: 'Kartony',
    viewId: 'ARCHIWUM_SKLAD_CHRONOLOGICZNY_SPIS',
  },
  SkladChronologicznyDokumenty: {
    path: '/sklad-chronologiczny-archiwalny/:idSpis/:idKarton',
    name: 'Dokumenty',
    viewId: 'ARCHIWUM_SKLAD_CHRONOLOGICZNY_KARTON',
  },
};

export const getRouteById = (menuId: string, url: string): string => {
  switch (menuId) {
    case 'ARCHIWUM':
      return Routes.Home.path;
    case 'ARCHIWUM_POCZEKALNIA':
      return Routes.Poczekalnia.path;
    case 'POCZEKALNIA-NOWE':
      return Routes.Nowe.path;
    case 'POCZEKALNIA-DOPOPRAWY':
      return Routes.DoPoprawy.path;
    case 'POCZEKALNIA-ODLOZONE':
      return Routes.Odlozone.path;
    case 'POCZEKALNIA-POPRAWIONE':
      return Routes.Poprawione.path;
    case 'ZADANIA':
    case 'ZADANIA-OTRZYMANE':
      return Routes.ZadaniaOtrzymane.path;
    case 'ZADANIA-ZLECONE':
      return Routes.ZadaniaZlecone.path;
    case 'POCZEKALNIA-ZREALIZOWANE':
      return Routes.ZadaniaZrealizowane.path;
    case 'ARCHIWUM_ZARCHIWIZOWANE':
      return Routes.Zarchiwizowane.path;
    case 'ARCHIWUM_NAS_SPIS':
      return Routes.SpisNAS.path;
    case 'ARCHIWUM_SPRAWY_SPIS':
      return Routes.SpisSpraw.path;
    case 'ARCHIWUM_PACZKA_ARCHIWALNA':
      return Routes.PaczkaArchiwalna.path;
    case 'BRAKOWANIE-WYTYPOWANE-DO-BRAKOWANIA':
    case 'ARCHIWUM_BRAKOWANIE': {
      return Routes.Brakowanie.path;
    }
    case 'ARCHIWUM_GOTOWE_DO_ARCHIWIZACJI': {
      return Routes.GotoweDoArchiwizacji.path;
    }
    case 'ARCHIWUM_SPRAWOZDAWCZOSC': {
      return Routes.Raporty.path;
    }
    case 'ARCHIWUM_SKLAD_CHRONOLOGICZNY': {
      return Routes.SkladChronologicznyArchiwalnySpisy.path;
    }
    default:
      return url;
  }
};

export const getRouteIcon = (menuId: string): React.ReactNode => {
  switch (menuId) {
    case 'ZADANIA_MOJE':
      return <SEzdrpIcon as={Zadania001Icon} />;
    case 'BIURKO':
      return <SEzdrpIcon as={Biurko002Icon} />;
    case 'PISMA':
      return <SEzdrpIcon as={Pisma005Icon} />;
    case 'SPIS_SPRAW':
      return <SEzdrpIcon as={SpisSpraw004Icon} />;
    case 'SPIS_NAS':
      return <SEzdrpIcon as={SpisNas006Icon} />;
    case 'ZADANIA_ZLECONE':
      return <SEzdrpIcon as={Zadania089Icon} />;
    case 'PROCESY':
      return <SEzdrpIcon as={Procesy007Icon} />;
    case 'RODO':
      return <SEzdrpIcon as={Rodo008Icon} />;
    case 'REJESTRY':
      return <SEzdrpIcon as={Rejestry009Icon} />;
    case 'RAPORTY':
      return <SEzdrpIcon as={Raporty010Icon} />;
    case 'BAZA_ADRESATOW':
      return <SEzdrpIcon as={BazaKontaktow011Icon} />;
    case 'WYSZUKIWANIE':
      return <SEzdrpIcon as={Wyszukiwanie012Icon} />;
    case 'KANCELARIA':
      return <SEzdrpIcon as={Kancelaria013Icon} />;
    case 'WYDRUKI':
      return <SEzdrpIcon as={Wydruki014Icon} />;
    case 'ADMINISTRACJA':
      return <SEzdrpIcon as={Administracja015Icon} />;
    case 'ARCHIWUM':
      return <SEzdrpIcon as={Archiwum016Icon} />;
    case 'REORGANIZACJA':
      return <SEzdrpIcon as={Reorganizacja017Icon} />;

    // case 'ARCHIWUM_INICJOWANIE':
    //    return <SIcon className={'fas fa-inbox'} />;
    // case 'POCZEKALNIA':
    //   return <SIcon className={'far fa-clock'} />;
    // case 'GOTOWE_DO_ARCHIWIZACJI':
    //   return <SIcon className={'far fa-flag'} />;
    // case 'ZARCHIWIZOWANE':
    //   return <SIcon className={'fas fa-archive'} />;
    // case 'SPRAWY_SPIS':
    //   return <SIcon className={'fas fa-bars'} />;
    // case 'PACZKA_ARCHIWALNA':
    //   return <SIcon className={'fas fa-boxes'} />;
    // case 'BRAKOWANIE':
    //   return <SIcon className={'fas fa-dumpster'} />;
    // case 'SPRAWOZDAWCZOSC':
    //   return <SIcon className={'fas fa-chart-line'} />;
    default:
      return undefined;
  }
};

const SEzdrpIcon = styled.svg`
  grid-area: icon;
  width: 2.2rem;
  height: 2.2rem;
  justify-self: center;
`;

// const IconHeader = styled(Book)`
//   grid-area: icon;
//   font-size: 2.6rem;
//   justify-self: center;
// `;
