import React from 'react';
import { TasksLayout } from '../components/tasks-layout';
import { Box, Container, IconButton, Paper } from '@material-ui/core';
import { Input } from '@archiwum/shared/input';
import { Clear, Search } from '@material-ui/icons';
import { DrawerRight } from '@archiwum/drawer-right';
import { useStyles } from './task-list.styles';
import { useTaskListFacade } from '../components/use-task-list-facade';
import { ZadaniaOtrzymaneTable } from './zadania-otrzymane-table';
import { getZadaniaOtrzymaneQuery } from '../helper';
import { useSelectedDispatch } from '@archiwum/hooks';
import { getFilters } from '@archiwum/utility/helper';

export const ZadaniaOtrzymane = () => {
  const {
    onChange,
    tableRef,
    inputRef,
    state,
    data,
    searchValue,
  } = useTaskListFacade();
  const dispatch = useSelectedDispatch();
  const classes = useStyles();
  return (
    <TasksLayout>
      <Box pt={3}>
        <Container maxWidth={'xl'}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Box pb={3}>
              <Paper elevation={0}>
                <Input
                  inputRef={inputRef}
                  variant={'outlined'}
                  placeholder={'Wyszukaj'}
                  InputProps={{
                    classes: { root: classes.textField },
                    endAdornment: (
                      <IconButton
                        classes={{ root: classes.iconButton }}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          inputRef.current.value = '';

                          onChange('');
                        }}
                      >
                        <Clear fontSize="large" />
                      </IconButton>
                    ),
                    startAdornment: <Search fontSize="large" />,
                  }}
                  onChange={(event) => onChange(event.target.value)}
                />
              </Paper>
            </Box>
          </Box>
          <Paper elevation={1}>
            <ZadaniaOtrzymaneTable
              tableRef={tableRef}
              data={(query, result) => {
                getZadaniaOtrzymaneQuery(
                  query,
                  result,
                  searchValue,
                  getFilters(state, data),
                  dispatch
                );
              }}
            />
          </Paper>
        </Container>
      </Box>

      <DrawerRight />
    </TasksLayout>
  );
};
