import { FolderArchiwum } from './models/models';
import {
  FilteringModel,
  OwnQuery,
  SubjectServiceModel,
} from '@archiwum/shared/table';
import queryString from 'querystring';
import { ArchivingDocumentsServiceQuery } from './services/archiving-documents-service-query';

export function getParams(search: string) {
  const { rodzaj } = queryString.parse(search.slice(1, search.length));
  return rodzaj;
}

export function camelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function getFilters<T>(state, data: T): FilteringModel[] {
  const result = [] as FilteringModel[];
  const filterArray = data ? Object.entries(data) : [];

  if (state) {
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        const checkIfExistInArray = filterArray.find(
          ([fKey, fValue]) => key === camelCase(fValue.propertyColumn)
        );
        const checkIfExistInArrayData = filterArray.find(
          ([fKey, fValue]) => key === fKey
        );
        if (checkIfExistInArray || checkIfExistInArrayData) {
          const copy = checkIfExistInArray
            ? { ...checkIfExistInArray[1] }
            : { ...checkIfExistInArrayData[1] };
          delete copy.dostepneWartosci;
          // (KCZ) => I'm not sure what filtersConjunction model is
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          result.push({ ...copy, propertyValue: value as string });
        }
      }
    }
  }

  return result;
}

export function getPoczekalniaNASQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters,
  dispatch,
  foldery: FolderArchiwum[]
) {
  ArchivingDocumentsServiceQuery.GetPismaNASPoczekalnia({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    foldery,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}

export function getPoczekalniaSprawyQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters,
  dispatch,
  foldery: FolderArchiwum[]
) {
  ArchivingDocumentsServiceQuery.GetSprawyPoczekalnia({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    foldery,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}
