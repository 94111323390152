import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button } from '@archiwum/ui/button';
import { Formik } from 'formik';
import { SelectModel } from '@nask/hooks';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { SearchInput } from '@archiwum/shared/search-input';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';

import { useCreateArchivingFacade } from './use-create-archiving-package-facade';
import { useStyles } from './create-archiving-package.styles';
import { CreateArchivingPackageTable } from './create-archiving-package-table';
import { getListaSprawArchiwalnych, getFilters } from '../helper';
import { FormCreateArchivingPackage } from './form-create-archiving-package';

export function CreateArchivingPackage() {
  const {
    tableRef,
    searchValue,
    onChange,
    selectedSprawy,
    setSelectedSprawy,
    handleUpdateDate,
    handleUpdateSelect,
    komorkaOrganizacyjnaOptions,
    prowadzacyOptions,
    symboleJRWAOptions,
    state,
    data,
    isShowAll,
    handleChangeShowAll,
    open,
    handleOpen,
    handleClose,
    formikProps,
    isLoading,
  } = useCreateArchivingFacade();
  const classes = useStyles();

  const modalBody = (
    <div className={classes.modalPaper}>
      <div>
        <div className={classes.modalHeaderContent}>
          <Typography
            id="simple-modal-title"
            className={classes.modalHeader}
            variantType={TypographyVariants.Header3}
            variant={'h3'}
          >
            Generowanie paczki archiwalnej
          </Typography>
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={() => {
              handleClose();
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
        <Divider />
      </div>

      <Box p={2}>
        <Formik {...formikProps}>
          <FormCreateArchivingPackage
            isLoading={isLoading}
            handleClose={handleClose}
          />
        </Formik>
      </Box>
    </div>
  );

  return (
    <>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                  <Paper elevation={0}>
                    <Box display="grid" gridAutoFlow="row">
                      <Box display="flex" justifySelf="flex-end">
                        <Box m={0.5} p={0.5}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            style={{ flexShrink: 0 }}
                            onClick={() => {
                              handleOpen();
                            }}
                            disabled={selectedSprawy.length < 1}
                          >
                            Generuj paczkę
                          </Button>
                        </Box>

                        <Box m={0.5} p={0.5}>
                          <SearchInput
                            searchMethod={(phrase: string) => {
                              onChange(phrase);
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="flex-start"
                      >
                        <Box m={0.5} p={0.5} width={200}>
                          <DatePicker
                            label="Data zakończenia od"
                            name="dataZakonczeniaOd"
                            value={state.dataZakonczeniaOd}
                            onChange={(date) =>
                              handleUpdateDate(date, 'dataZakonczeniaOd')
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5} width={200}>
                          <DatePicker
                            label="Data zakończenia do"
                            name="dataZakonczeniaDo"
                            value={state.dataZakonczeniaDo}
                            onChange={(date) =>
                              handleUpdateDate(date, 'dataZakonczeniaDo')
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5} width={400}>
                          <Autocomplete
                            label="Symbol JRWA"
                            name="symbolJRWA"
                            options={symboleJRWAOptions}
                            value={
                              symboleJRWAOptions.find(
                                (option) => option.value === state.symbolJRWA
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect('symbolJRWA', v as SelectModel)
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5} width={300}>
                          <Autocomplete
                            label="Prowadzący sprawę"
                            name="prowadzacy"
                            options={prowadzacyOptions}
                            value={
                              prowadzacyOptions.find(
                                (option) => option.value === state.prowadzacy
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect('prowadzacy', v as SelectModel)
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5} width={400}>
                          <Autocomplete
                            label="Komórka organizacyjna"
                            placeholder="Wszystkie"
                            name="komorkaOrganizacyjna"
                            options={komorkaOrganizacyjnaOptions}
                            value={
                              komorkaOrganizacyjnaOptions.find(
                                (option) =>
                                  option.value === state.komorkaOrganizacyjna
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'komorkaOrganizacyjna',
                                v as SelectModel
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5} display="flex" alignItems="center">
                          <FormControlLabel
                            classes={{
                              root: classes.formControlLabel,
                              label: classes.formLabel,
                            }}
                            control={
                              <Checkbox
                                checked={isShowAll}
                                onChange={handleChangeShowAll}
                                name="showAll"
                                color="primary"
                              />
                            }
                            label="pokaż wszystkie zakończone sprawy"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Paper classes={{ root: classes.paper }}>
            <CreateArchivingPackageTable
              tableRef={tableRef}
              data={(query, result) => {
                getListaSprawArchiwalnych(
                  query,
                  result,
                  searchValue,
                  getFilters(state, data),
                  isShowAll
                );
              }}
              onSelectionChange={(data) => {
                setSelectedSprawy(data);
              }}
            />
          </Paper>
        </Container>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  );
}
