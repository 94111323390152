import React from 'react';

import { UseTableFacadeProps, useTableFacade } from './hooks/use-table-facade';
import WTable from './wtable';

export const Table = <T extends object>({
  filterColumn,
  ...props
}: UseTableFacadeProps<T>) => {
  const { isLoading, overrideProps, error, columnsInfo } = useTableFacade(
    props
  );
  return !isLoading ? (
    <WTable
      hideSearchInput
      disableToolbar
      error={error}
      columnsInfo={columnsInfo}
      filterColumn={filterColumn}
      {...overrideProps}
    />
  ) : null;
};
