import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      fontWeight: 700,
      color: palette.primary.main,
      borderBottom: `dotted 2px ${palette.primary.main}`,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
    iconButton: {
      padding: spacing(1),
      backgroundColor: '#e1dee6',
      color: '#4f4f4f',
      // backgroundColor: theme.actionButton.backgroundColor,
      // color: theme.actionButton.color,
      width: '3.2rem',
      height: '3.2rem',
    },
    tooltip: {
      fontSize: theme.typography.descriptionFontSize,
    },
    detailPanel: {
      backgroundColor: theme.colors.background,
    },
    downloadIcon: {
      color: theme.colors.primary,
      marginRight: '1.6rem',
    },
    rightArrowIcon: {
      width: '1.6rem',
      height: '1.6rem',
    },
  })
);
