import { Formik } from 'formik';

import { FormStepCases } from './form-step-cases';
import { useStepCasesFacade } from './use-step-cases-facade';
import { TypObiekt } from '../../../../models/models';

type StepCasesProps = {
  handleClose: () => void;
  typObiekt: TypObiekt;
};

export const StepCases = (props: StepCasesProps) => {
  const { formikProps } = useStepCasesFacade({ ...props });

  return (
    <Formik {...formikProps}>
      <FormStepCases />
    </Formik>
  );
};
