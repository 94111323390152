import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import { LicznikiResponseModel } from '../models/models';

export const CountersServiceQuery = {
  GetCounters: () =>
    Axios.get<LicznikiResponseModel>(getUrl('pobierz-licznik-menu', '')).pipe(
      take(1),
      map((data) => data?.data?.lista)
    ),
};
