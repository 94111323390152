import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) => {
  return createStyles({
    bodyRow: {
      display: 'grid',
      alignItems: 'center',
      '&:hover': { backgroundColor: theme.colors.grayLight },
    },

    gridHeader: {
      display: 'grid',
      gridArea: 'header',
      alignItems: 'center',
      gridTemplateColumns: '40px 2fr max-content 1fr',
      paddingBottom: spacing(1.5),
      gridGap: spacing(1.5),
      maxWidth: 'none',
    },

    // gridColumns3: {
    //   gridTemplateColumns: '8rem auto 10rem',
    // },
    // gridColumns4: {
    //   gridTemplateColumns: '8rem auto 20rem 10rem',
    // },
    // gridColumns5: {
    //   gridTemplateColumns: '8rem auto auto 20rem 10rem',
    // },

    labelsRow: {
      display: 'grid',
      paddingTop: spacing(2),
      paddingBottom: spacing(1),
      gridGap: spacing(1.5),
      '& p': {
        marginLeft: spacing(1),
        marginRight: spacing(1),
      },
    },
  });
});
