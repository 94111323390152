import React from 'react';
import { Typography } from '@archiwum/ui/typography';
import { PoczekalniaModel } from '../../../models/models';
import { useField } from 'formik';
import { Box, IconButton } from '@material-ui/core';
import { parseJSON, stringifyJSON } from '@nask/hooks';
import { useStyles } from './selected.styles';
import { CloseIcon } from '@archiwum/icons';

export const Selected = () => {
  const [field, meta, helper] = useField<string>('selected');
  const selected = parseJSON<PoczekalniaModel[]>(field.value);
  const classes = useStyles();
  const handleRemoveItem = (item: PoczekalniaModel) => {
    const newSelectedItems = selected.filter((select) => select.id !== item.id);
    helper.setValue(
      stringifyJSON(newSelectedItems.length > 0 ? newSelectedItems : '')
    );
  };
  return (
    <div className={classes.wrapper}>
      {selected?.map((item) => {
        const numerOrZnak = item.hasOwnProperty('znak')
          ? item.znak
          : item.numer;
        return (
          <div className={classes.container} key={item.id}>
            <Typography>{numerOrZnak}</Typography>
            {selected.length > 1 && (
              <IconButton onClick={() => handleRemoveItem(item)}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        );
      })}
      <Box>{meta.touched && meta.error && meta.error}</Box>
    </div>
  );
};
