import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    clearIcon: {
      order: 1,
      padding: spacing(1),
    },
    inputAdornedEnd: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  })
);
