import { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Button } from '@archiwum/ui/button';
import { Form, FormInput, FormRadio, FormRadioGroup } from '@archiwum/forms';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

import { GenerujPaczkeFormModel } from './use-create-archiving-package-facade';
import { useStyles } from './create-archiving-package.styles';
import { useFormCreateArchivingFacade } from './use-form-create-archiving-package-facade';

export type FormCreateArchivingPackageProps = {
  isLoading: boolean;
  handleClose: () => void;
};

export const FormCreateArchivingPackage = ({
  isLoading,
  handleClose,
}: FormCreateArchivingPackageProps) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<GenerujPaczkeFormModel>();
  const classes = useStyles();

  const { listaKlasJRWADoAP, znakiSpraw } = useFormCreateArchivingFacade();

  useEffect(() => {
    if (values.dolaczanieDoSprawy === 'nowa') {
      setFieldValue('znakSprawy', '');
      setFieldTouched('idWykaz', false);
    }
    if (values.dolaczanieDoSprawy === 'istniejaca') {
      setFieldValue('idWykaz', '');
      setFieldTouched('znakSprawy', false);
    }
  }, [values.dolaczanieDoSprawy]);

  useEffect(() => {
    setFieldValue(
      'kategoriaArchiwalna',
      listaKlasJRWADoAP
        ?.filter((x) => x.id === values.idWykaz)?.[0]
        ?.kategorieArchiwalne?.filter((y) => y.czyGlowna === true)?.[0]?.katArch
    );
  }, [values.idWykaz]);

  return (
    <Form>
      <FormInput name={'nazwa'} label={'Nazwa paczki archiwalnej'} />
      <FormInput name={'opis'} label={'Opis'} />
      <FormRadioGroup name="dolaczanieDoSprawy">
        <FormRadio
          key={'nowa-sprawa'}
          value={'nowa'}
          label={'Załóż nową sprawę'}
        />
        {values.dolaczanieDoSprawy === 'nowa' && (
          <>
            <Box pb={3} pl={3} className={classes.wrapper}>
              <FormRadioGroup name="idWykaz">
                {listaKlasJRWADoAP?.map((item) => (
                  <>
                    <FormRadio
                      key={`item-${item.id}`}
                      value={item.id}
                      label={`[${item.symbol}] ${item.nazwa}`}
                    />
                    <Divider />
                  </>
                ))}
              </FormRadioGroup>
            </Box>
          </>
        )}

        <FormRadio
          key={'istniejaca-sprawa'}
          value={'istniejaca'}
          label={'Dołączenie paczki archiwalnej do istniejącej sprawy'}
        />
        {values.dolaczanieDoSprawy === 'istniejaca' && (
          <Box pb={3} pl={3} className={classes.wrapper}>
            <Box className={classes.row} fontWeight={700}>
              <span></span>
              <Typography
                className={classes.tableHeader}
                variantType={TypographyVariants.Paragraph}
              >
                Znak sprawy
              </Typography>
            </Box>
            <Divider />
            <FormRadioGroup name="znakSprawy">
              {znakiSpraw?.map((item) => (
                <>
                  <FormRadio key={`item-${item}`} value={item} label={item} />
                  <Divider />
                </>
              ))}
            </FormRadioGroup>
          </Box>
        )}
      </FormRadioGroup>

      <div className={classes.modalFooter}>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={() => handleClose()}
            variant="outlined"
            color="default"
          >
            Anuluj
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="primary"
          >
            Utwórz
          </Button>
        </div>
      </div>
    </Form>
  );
};
