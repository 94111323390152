import { PartialCounter } from './components/partial-counter';
import { useStyles } from './counters.styles';
import { LicznikModel } from './models/models';

export type CounterProps = { data?: LicznikModel };

export const Counter = (props: CounterProps) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <>
      {data && (
        <div className={classes.countsContainer}>
          {data.values.map((item, index) => (
            <PartialCounter key={index} {...item} />
          ))}
        </div>
      )}
    </>
  );
};
