import { CircularProgress } from '@material-ui/core';
import React, { memo } from 'react';
import styled from 'styled-components';

import { useDataListContext } from '../../util/context';

type DataListLoaderProps = {
  children?: React.ReactNode;
};

export const DataListLoader = memo(({ children }: DataListLoaderProps) => {
  const { store } = useDataListContext();
  return (
    <Loader.Container loading={store.loading}>
      {store.loading && <Loader.LoaderCmp color="secondary" />}
      {children}
    </Loader.Container>
  );
});

const Container = styled(({ loading, ...props }) => <div {...props} />)<{
  loading?: boolean;
}>`
  grid-area: loader;
  z-index: 100;
  position: relative;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  transition: opacity 0.3s ease-in-out;
`;

const LoaderCmp = styled((props) => <CircularProgress {...props} />)`
  &&& {
    position: absolute;
    top: 50%;
    left: 50%;
  }
` as typeof CircularProgress;

const Loader = { Container, LoaderCmp };
