import { OwnQuery, SubjectServiceModel } from '@archiwum/shared/table';
import { ArchivingDocumentsServiceQuery } from '../services/archiving-documents-service-query';
import { FolderArchiwum } from '../models/models';

export function getSprawyQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters,
  dispatch
) {
  ArchivingDocumentsServiceQuery.GetSprawyPoczekalnia({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    foldery: [FolderArchiwum.Poprawione],
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}
export function getPismaNASQuery(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  filters,
  dispatch
) {
  ArchivingDocumentsServiceQuery.GetPismaNASPoczekalnia({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    filtersConjunction: filters,
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    foldery: [FolderArchiwum.Poprawione],
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}
