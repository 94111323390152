import {
  Box,
  Container,
  Divider,
  IconButton,
  Paper,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button } from '@archiwum/ui/button';
import { Tab, Tabs } from '@archiwum/ui/tabs';
import { DrawerRight } from '@archiwum/drawer-right';
import { Input } from '@archiwum/shared/input';
import { Typography } from '@archiwum/ui/typography';
import { SearchInput } from '@archiwum/shared/search-input';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';
import { Counter } from '@archiwum/counters';

import { FolderArchiwum } from '../../models/models';
import { useSelectedDispatch } from '../../hooks/use-selected';
import { ArchivingLayout } from '../../components/archiving-layout';
import { SprawyTable } from '../../poczekalnia/components/sprawy-table';
import { PismaNasTable } from '../../poczekalnia/components/pisma-nas-table';
import {
  getPoczekalniaNASQuery,
  getPoczekalniaSprawyQuery,
} from '../../helper';
import { useStyles } from './gotowe-do-archiwizacji.styles';
import { useGotoweDoArchiwizacjiFacade } from '../hooks/use-gotowe-do-archiwizacji-facade';

export const GotoweDoArchiwizacji = () => {
  const {
    open,
    handleOpen,
    handleClose,
    handleSubmitPisma,
    handleSubmitSprawy,
    tableRef,
    searchValue,
    onChange,
    handleChangeTab,
    activeTab,
    tabs,
    handleChangeComment,
    inputCommentRef,
    counters,
  } = useGotoweDoArchiwizacjiFacade();
  const dispatch = useSelectedDispatch();
  const classes = useStyles();

  const modalBody = (
    <div className={classes.modalPaper}>
      <div>
        <div className={classes.modalHeaderContent}>
          <Typography id="simple-modal-title" className={classes.modalHeader}>
            Potwierdzenie
          </Typography>
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={() => {
              handleClose();
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
        <Divider />
      </div>
      <Input
        inputRef={inputCommentRef}
        variant={'outlined'}
        label={'Komentarz'}
        InputProps={{
          classes: { root: classes.textField },
        }}
        onChange={(event) => handleChangeComment(event.target.value)}
      />
      <Typography id="simple-modal-description">
        {activeTab === tabs[0].value
          ? 'Czy zarchiwizować wszystkie sprawy gotowe do zarchiwizowania?'
          : 'Czy zarchiwizować wszystkie pisma gotowe do zarchiwizowania?'}
      </Typography>
      <div className={classes.modalFooter}>
        <div className={classes.modalButtonsContainer}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={() => {
              handleClose();
            }}
          >
            Anuluj
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              activeTab === tabs[0].value
                ? handleSubmitSprawy()
                : handleSubmitPisma();
            }}
          >
            Tak
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <ArchivingLayout title={'Gotowe do archiwizacji'}>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Box width="100%">
          <Container maxWidth={'xl'}>
            <Box>
              <Paper classes={{ root: classes.paper }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box pb={3} pt={3} pr={4}>
                    <Paper elevation={0}>
                      <Box display={'flex'}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: '1.2rem', flexShrink: 0 }}
                          onClick={() => {
                            handleOpen();
                          }}
                        >
                          Archiwizuj wszystko
                        </Button>
                        <SearchInput
                          searchMethod={(phrase: string) => {
                            onChange(phrase);
                          }}
                        />
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box pt={3}>
              <Tabs
                indicatorColor="primary"
                classes={{ indicator: classes.indicator, root: classes.root }}
                value={activeTab}
                onChange={handleChangeTab}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={
                      <div className={classes.tabContent}>
                        {tab.label}
                        <Counter
                          data={counters?.find(
                            (item) => item.id === tab.idCounter
                          )}
                        />
                      </div>
                    }
                    value={tab.value}
                    classes={{ root: classes.tab, selected: classes.activeTab }}
                  />
                ))}
              </Tabs>
            </Box>
            <Divider classes={{ root: classes.borderBottomTab }} />
            <Paper classes={{ root: classes.paperShadowBottom }}>
              {activeTab === tabs[0].value ? (
                <SprawyTable
                  tableRef={tableRef}
                  data={(query, result) => {
                    getPoczekalniaSprawyQuery(
                      query,
                      result,
                      searchValue,
                      [],
                      dispatch,
                      [FolderArchiwum.GotoweDoZarchiwizowania]
                    );
                  }}
                  hiddenFields={['dataZarchiwizowania', 'historia']}
                  nameColumnIncomingDate={
                    'Data przyjęcia na stan gotowych do archiwizacji'
                  }
                />
              ) : (
                <PismaNasTable
                  tableRef={tableRef}
                  data={(query, result) => {
                    getPoczekalniaNASQuery(
                      query,
                      result,
                      searchValue,
                      [],
                      dispatch,
                      [FolderArchiwum.GotoweDoZarchiwizowania]
                    );
                  }}
                  hiddenFields={['dataZarchiwizowania', 'historia']}
                  nameColumnIncomingDate={
                    'Data przyjęcia na stan gotowych do archiwizacji'
                  }
                />
              )}
            </Paper>
          </Container>
        </Box>
      </Box>
      <DrawerRight />
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </ArchivingLayout>
  );
};
