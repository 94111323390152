import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
  createStyles({
    indicator: {
      display: 'none',
    },
    root: {},
    borderBottomTab: {
      backgroundColor: palette.primary.main,
      height: 4,
    },
    tab: {
      backgroundColor: 'rgb(239, 240, 242)',
      opacity: 1,
      color: palette.primary.main,
      fontSize: theme.typography.fontSizeBase,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      maxWidth: 'inherit',
      marginRight: '3px',
      minWidth: '128px',
      fontWeight: 600,
    },
    activeTab: {
      backgroundColor: palette.primary.main,
      color: palette.getContrastText(palette.primary.main),
      fontWeight: 600,
    },
    tabContent: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: spacing(1),
      alignItems: 'center',
    },
    textField: {
      fontSize: theme.typography.descriptionFontSize,
    },
    iconButton: {
      padding: spacing(1),
    },
    datePicker: {
      paddingLeft: spacing(1.65),
    },
    caseLink: {
      color: theme.typography.linkColor,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    userInactive: {
      color: theme.colors.danger,
    },
    paper: {
      boxShadow: '-1px 3px 25px 3px rgb(172 167 184 / 46%)',
    },
    paperShadowBottom: {
      boxShadow: '0px 14px 20px 0px rgb(0 0 0 / 15%)',
    },
    icon: {
      color: theme.colors.primary,
      width: '2rem',
      height: '2rem',
    },
    tooltip: {
      fontSize: theme.typography.descriptionFontSize,
    },
  })
);
