import { useApi } from '@nask/hooks';

import { BrakowanieServiceQuery } from '../../../../services/brakowanie-service-query';

export const useFormStepCasesFacade = () => {
  const { data: listaKlasJRWADoAP, isLoading: jRWAIsLoading } = useApi({
    service: BrakowanieServiceQuery.GetListaWykazowJRWADoBrakowania({
      searchValue: '',
    }),
  });

  const { data: listaSprawTechnicznych } = useApi({
    service: BrakowanieServiceQuery.GetListaSprawTechnicznych({
      czySprawyBrakowania: true,
      czySprawyPaczkiArchiwalnej: false,
    }),
  });

  return {
    listaKlasJRWADoAP,
    jRWAIsLoading,
    listaKlasJRWADoAPMissing: !jRWAIsLoading && listaKlasJRWADoAP?.length === 0,
    listaSprawTechnicznych,
  };
};
