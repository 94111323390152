import { actionsService$ } from '@archiwum/actions-handler';
import { ButtonsContainer, Form } from '@archiwum/forms';
import {
  ActionContainer,
  ActionHeader,
} from '@archiwum/shared/context-actions';
import { Button } from '@archiwum/ui/button';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Box, List, ListItem } from '@material-ui/core';
import { Formik } from 'formik';
import { ObiektSpisuBrakowania } from '../../models/models';
import { useUsunObiektZeSpisuBrakowaniaFacade } from './use-usun-obiekt-ze-spisu-brakowania-facade';

type SelectedProps = { selected: ObiektSpisuBrakowania[] };

const Selected = ({ selected }: SelectedProps) => {
  return (
    <>
      <Typography variantType={TypographyVariants.Paragraph}>
        Wybrano:
      </Typography>
      <List>
        {selected?.map((item) => {
          return (
            <ListItem key={item.id}>
              <Typography variantType={TypographyVariants.Paragraph}>
                {item.sygnatura}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export const UsunObiektZeSpisuBrakowania = () => {
  const { selected, formikProps } = useUsunObiektZeSpisuBrakowaniaFacade();

  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>Usuń ze spisu brakowania</ActionHeader>
        <Formik {...formikProps}>
          <Form>
            <Box pt={3} pb={2}>
              <Selected selected={selected} />
              <Typography variantType={TypographyVariants.Paragraph}>
                Czy usunąć?
              </Typography>
            </Box>
            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                NIE
              </Button>
              <Button type="submit" variant="contained" color="primary">
                TAK
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};
