import { Box } from '@material-ui/core';
import { Button } from '@archiwum/ui/button';
import { useListaZamowionychRaportowFacade } from '../use-lista-zamowionych-raportow-facade';
import { getShortDate, Table } from '@archiwum/shared/table';
import { LabelValue, ReportModel } from '../../models/models';
import { useStyles } from '../../reports.styles';

enum ReportType {
  CasesReport = 1,
  NASReport,
  OutboxReport,
  EventLogReport,
}

enum ReportStatus {
  Waiting = 1,
  Preparing,
  Complete,
  Error,
  Deleted,
}

const reportTypes: LabelValue<ReportType>[] = [
  { value: ReportType.CasesReport, label: 'Raport spraw' },
  { value: ReportType.NASReport, label: 'Raport pism' },
  {
    value: ReportType.OutboxReport,
    label: 'Raport korespondencji wychodzącej',
  },
  { value: ReportType.EventLogReport, label: 'Raport dziennika zdarzeń' },
];

const reportStatuses: LabelValue<ReportStatus>[] = [
  { value: ReportStatus.Waiting, label: 'Oczekiwanie' },
  { value: ReportStatus.Preparing, label: 'Generowanie' },
  { value: ReportStatus.Complete, label: 'Wygenerowany' },
  { value: ReportStatus.Error, label: 'Błąd' },
  { value: ReportStatus.Deleted, label: 'Skasowany' },
];

export const ListaZamowionychRaportow = () => {
  const classes = useStyles();

  const {
    onSearchChange,
    getUserReportsQuery,
    handleDownloadReport,
  } = useListaZamowionychRaportowFacade();

  return (
    <>
      {/* <Box p={3} display="flex" justifyContent="flex-end">
        <Box maxWidth={'32rem'}>
          <SearchInput
            searchMethod={(phrase: string) => {
              onSearchChange(phrase);
            }}
          />
        </Box>
      </Box> */}
      <Box p={3}>
        <Table<ReportModel>
          columns={[
            {
              field: 'typ',
              title: 'Typ',
              render: (data) =>
                reportTypes.find(
                  (x) => x.value === ((data.typ as unknown) as ReportType)
                )?.label,
            },
            {
              field: 'status',
              title: 'Status',
              render: (data) =>
                reportStatuses.find(
                  (x) => x.value === ((data.status as unknown) as ReportStatus)
                )?.label,
            },
            {
              field: 'dataWygenerowania',
              title: 'Data wygenerowania',
              render: (data) => getShortDate(data.dataWygenerowania!),
            },
            {
              sorting: false,
              field: 'idReport',
              render: (data) => {
                return (
                  ((data?.status as unknown) as ReportStatus) ===
                    ReportStatus.Complete && (
                    <Button onClick={() => handleDownloadReport(data.idRaport)}>
                      Pobierz raport
                    </Button>
                  )
                );
              },
            },
          ]}
          data={(query, result) => {
            getUserReportsQuery(query, result);
          }}
        />
      </Box>
    </>
  );
};
