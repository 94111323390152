import { LicznikModel } from '@archiwum/counters';
import { useEffect } from 'react';
import { BehaviorSubject, timer } from 'rxjs';
import { CountersServiceQuery } from '../services/counters-service-query';
import isEqual from 'react-fast-compare';

export const countersSubject$ = new BehaviorSubject<LicznikModel[]>([]);
const ticker = timer(2000, 30000);

export const getCounter = (counterId: string) => {
  return countersSubject$?.value?.find((item) => item.id === counterId);
};

export const useCounters = () => {
  useEffect(() => {
    const subscription = ticker.subscribe(() => {
      CountersServiceQuery.GetCounters().subscribe((data) => {
        if (!isEqual(data, countersSubject$.value)) countersSubject$.next(data);
      });
    });
    return () => subscription.unsubscribe();
  }, []);

  return {};
};
