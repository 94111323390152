import { lazy } from 'react';
import { Box, Link, Tooltip, IconButton } from '@material-ui/core';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';

import { useStyles } from '../poczekalnia.styles';
import { Prowadzacy } from './prowadzacy';
import { PrzeniesDoFolderu } from '../context-actions/przenies-do-folderu/przenies-do-folderu';
import { Komentarz } from './komentarz';
import {
  FolderArchiwum,
  PoczekalniaModel,
  TypObiekt,
} from '../../models/models';
import { Historia074Icon } from '@nask/ezdrp-icons';

type PismaNasTableProps = Pick<
  UseTableFacadeProps<PoczekalniaModel>,
  'tableRef' | 'data'
> & {
  hiddenFields?: string[];
  disableSelection?: boolean;
  nameColumnIncomingDate?: string;
};

export const PismaNasTable = (props: PismaNasTableProps) => {
  const classes = useStyles();
  const dispatch = useDrawerRightDispatch();
  const { hiddenFields, disableSelection } = props;
  const handleRedirect = (item: PoczekalniaModel) => {
    /*global EZDRP_APP_URL*/
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ezdrpURL = EZDRP_APP_URL;
    const suffix = `(a${
      [2, 6, 7, 8, 9, 10].includes(+item.folder) ? 'r' : ''
    })`;
    const href = `${ezdrpURL}?viewId=PISMO_NAS&caseId=${btoa(
      `${item.id}${suffix}`
    )}`;
    window.location.href = href;
  };

  const isHidden = (fieldName: string) =>
    hiddenFields && hiddenFields.indexOf(fieldName) >= 0;

  return (
    <Table<PoczekalniaModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'numer',
          title: 'Numer NAS',
          render: (data) => (
            <Box
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
            >
              <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
              <Link
                classes={{ root: classes.caseLink }}
                onClick={() => handleRedirect(data)}
              >
                {data.numer}
              </Link>
            </Box>
          ),
        },
        {
          field: 'tytul',
          title: 'Tytuł pisma',
          hidden: isHidden('tytul'),
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kat. arch.',
          hidden: isHidden('kategoriaArchiwalna'),
        },
        {
          field: 'prowadzacy',
          title: 'Prowadzący sprawę',
          render: (data) => <Prowadzacy {...data} />,
          hidden: isHidden('prowadzacy'),
        },
        {
          field: 'komorkaOrganizacyjna',
          title: 'Komórka organizacyjna',
          hidden: isHidden('komorkaOrganizacyjna'),
        },
        {
          field: 'dataZakonczenia',
          title: 'Data zakończenia',
          type: 'date',
          hidden: isHidden('dataZakonczenia'),
        },
        {
          field: 'dataPrzeniesieniaDoFolderu',
          title: props.nameColumnIncomingDate ?? 'Data przyjęcia',
          type: 'date',
          hidden: isHidden('dataPrzyjecia'),
        },
        {
          field: 'dataZarchiwizowania',
          title: 'Data zarchiwizowania',
          type: 'date',
          hidden: isHidden('dataZarchiwizowania'),
        },
        {
          field: 'komentarz',
          title: 'Komentarz',
          render: (data) => <Komentarz data={data} />,
          hidden: isHidden('komentarz'),
        },
        {
          field: 'id',
          title: '',
          render: (data) => (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={'Historia'}>
              <IconButton
                aria-label={'Historia'}
                classes={{ root: classes.iconButton }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({ data: [data] });
                  actionsService$.openCommand({
                    command: 'archiwum.obiekt.historia',
                    data: [data],
                    component: getComponent('archiwum.obiekt.historia', [data]),
                  });
                }}
              >
                <Historia074Icon className={classes.icon} />
              </IconButton>
            </Tooltip>
          ),
          hidden: isHidden('historia'),
        },
      ]}
      options={{
        filtering: false,
        selection: disableSelection ? false : true,
        detailPanelColumnAlignment: 'right',
        detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
    />
  );
};
const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, data),
  });
};
const getLabel = (command: string): string => {
  switch (command) {
    case '*archiwum.przenies-do.poczekalnia':
      return 'Przenieś do poczekalni';
    case '*archiwum.przenies-do.gotowe-do-archiwizacji':
      return 'Przenieś do gotowych do zarchiwizowania';
    case '*przenies-do-folderu.zarchiwizowane':
      return 'Archiwizuj';
    case '*archiwum.przekaz.do-poprawy':
    case '*archiwum.przekaz.nieaktywny.do-poprawy':
      return 'Przekaż do poprawy';
    case '*archiwum.przenies-do.odlozone':
      return 'Odłóż';
    case 'archiwum.obiekt.historia':
      return 'Historia';
    default:
      return command;
  }
};

const getComponent = (command: string, data) => {
  const Archiwizuj = lazy(() =>
    import('../context-actions/archiwizuj/archiwizuj').then((module) => ({
      default: module.Archiwizuj,
    }))
  );
  const PrzekazDoPoprawy = lazy(() =>
    import('../context-actions/przekaz-do-poprawy/przekaz-do-poprawy').then(
      (module) => ({
        default: module.PrzekazDoPoprawy,
      })
    )
  );
  const PrzeniesDoFolderuOdlozone = lazy(() =>
    import(
      '../context-actions/przenies-do-folderu-odlozone/przenies-do-folderu-odlozone'
    ).then((module) => ({
      default: module.PrzeniesDoFolderuOdlozone,
    }))
  );
  const Historia = lazy(() =>
    import('../context-actions/historia/historia').then((module) => ({
      default: module.Historia,
    }))
  );
  switch (command) {
    case '*archiwum.przenies-do.gotowe-do-archiwizacji':
      return (
        <PrzeniesDoFolderu
          title="Przenieś do gotowych do zarchiwizowania"
          typObiekt={TypObiekt.PISMA_NAS}
          folderDocelowy={FolderArchiwum.GotoweDoZarchiwizowania}
          isCommentRequired={false}
        />
      );
    case '*archiwum.przenies-do.poczekalnia':
      return (
        <PrzeniesDoFolderu
          title="Przenieś do poczekalni"
          typObiekt={TypObiekt.PISMA_NAS}
          folderDocelowy={FolderArchiwum.Poczekalnia}
          isCommentRequired={false}
        />
      );
    case '*przenies-do-folderu.zarchiwizowane':
      return <Archiwizuj typObiekt={TypObiekt.PISMA_NAS} />;
    case '*archiwum.przekaz.do-poprawy':
      return (
        <PrzekazDoPoprawy doProwadzacego typObiekt={TypObiekt.PISMA_NAS} />
      );
    case '*archiwum.przekaz.nieaktywny.do-poprawy':
      return <PrzekazDoPoprawy typObiekt={TypObiekt.PISMA_NAS} />;
    case '*archiwum.przenies-do.odlozone':
      return <PrzeniesDoFolderuOdlozone typObiekt={TypObiekt.PISMA_NAS} />;
    case 'archiwum.obiekt.historia':
      return <Historia typObiekt={TypObiekt.PISMA_NAS} />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
