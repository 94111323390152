import { QueryFunction, useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { QueryKey } from 'react-query/types/core/types';
import { isObservable } from 'rxjs';
export const useQueryStream = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  fetcher: QueryFunction<TQueryFnData, TQueryKey>,
  config?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> => {
  const queryClient = useQueryClient();
  const queryResult = useQuery(queryKey, fetcher, config);

  useEffect(() => {
    if (isObservable(queryResult.data)) {
      queryResult.data.subscribe?.({
        next: (data) => {
          console.log('data', data);
          queryClient.setQueryData(queryKey, (currentQueryData: any) => {
            return currentQueryData.length
              ? currentQueryData.concat(data)
              : data;
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }, [queryKey, queryResult.data]);

  return queryResult;
};
