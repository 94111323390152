import React from 'react';
import { useFormikContext } from 'formik';
import { useSelectedSelector } from '../../../hooks/use-selected';
import { PoczekalniaModel } from '../../../models/models';
import { ArchiwizujFormModel } from './use-archiwizuj-facade';
import { stringifyJSON } from '@nask/hooks';
import { Button } from '@archiwum/ui/button';

type WczytajButtonProps = {};

export const WczytajButton = (props: WczytajButtonProps) => {
  const {
    values,
    setValues,
    setFieldValue,
  } = useFormikContext<ArchiwizujFormModel>();
  const allData = useSelectedSelector(
    (state) => state.data
  ) as PoczekalniaModel[];
  const handleWczytaj = () => {
    if (values.numery) {
      const numery = values.numery.trim().split(',');
      const newData = allData.filter(
        (item) => numery.find((num) => +num === +item.index) !== undefined
      );
      setFieldValue('selected', stringifyJSON(newData));
    } else if (values.od && values.od) {
      const newData = allData.slice(+values.od - 1, +values.do);
      setFieldValue('selected', stringifyJSON(newData));
    }
  };
  return (
    <Button variant="contained" color="primary" onClick={handleWczytaj}>
      Wczytaj
    </Button>
  );
};
