import { PoczekalniaModel } from '@archiwum/features/archiving-documents';
import { useStyles } from '../poczekalnia.styles';

export const Prowadzacy = (data: PoczekalniaModel) => {
  const classes = useStyles();

  return (
    <span
      className={
        !(data.pracownikProwadzacy?.czyAktywny ?? true)
          ? classes.userInactive
          : ''
      }
    >
      {data?.prowadzacy}
    </span>
  );
};
