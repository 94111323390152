import { DokumentModel, PismoModel, AktaModel } from '../models/models';

const getDate = (a: DokumentModel | PismoModel) => {
  const strDate = a?.hasOwnProperty('idDokument')
    ? (a as DokumentModel).aktualnaWersja.dataUtworzenia.value
    : a?.hasOwnProperty('idPismo')
    ? (a as PismoModel).daty.dataUtworzenia.value
    : '';
  return new Date(strDate);
};

export const prepareActs = (
  acts: AktaModel
): (DokumentModel | PismoModel)[] | any => {
  const result: (DokumentModel | PismoModel)[] = [
    ...acts?.pisma,
    ...acts?.dokumenty,
  ];

  return result.sort((a, b) => getDate(a).getTime() - getDate(b).getTime());
};
