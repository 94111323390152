import { Box, Link, Tooltip } from '@material-ui/core';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';

import { SprawaArchiwalna } from '../models/models';
import { useStyles } from './create-archiving-package-table.styles';
import { DataUtworzenia064Icon } from '@nask/ezdrp-icons';

type CreateArchivingPackageTableProps = Pick<
  UseTableFacadeProps<SprawaArchiwalna>,
  'tableRef' | 'data' | 'onSelectionChange'
>;

export const CreateArchivingPackageTable = (
  props: CreateArchivingPackageTableProps
) => {
  const dispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const handleRedirect = (id: string) => {
    /*global EZDRP_APP_URL*/
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ezdrpURL = EZDRP_APP_URL;
    const href = `${ezdrpURL}?viewId=SPRAWA&caseId=${id}`;
    window.location.href = href;
  };
  return (
    <Table<SprawaArchiwalna>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'znak',
          title: 'Znak sprawy',
          render: (data) => (
            <Box
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
            >
              <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
              <Link
                classes={{ root: classes.caseLink }}
                onClick={() => handleRedirect(data.id)}
              >
                {data.znak}
              </Link>
              {data.czyWPaczceArchiwalnej && (
                <Tooltip title={'Sprawa już znajduje się w paczce'}>
                  <span>
                    <DataUtworzenia064Icon
                      className={classes.exclamationIcon}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          ),
        },
        { field: 'tytul', title: 'Tytuł sprawy' },
        { field: 'symbolJRWA', title: 'Symbol JRWA' },
        { field: 'kategoriaArchiwalna', title: 'Kat. arch.' },
        { field: 'komorkaOrganizacyjna', title: 'Komórka organizacyjna' },
        { field: 'dataZakonczenia', title: 'Data zakończenia', type: 'date' },
        {
          field: 'pracownikProwadzacy',
          title: 'Prowadzący sprawę',
          render: (data) => (
            <span
              className={
                !(data.pracownikProwadzacy?.czyAktywny ?? true)
                  ? classes.userInactive
                  : ''
              }
            >{`${data.pracownikProwadzacy.imie} ${data.pracownikProwadzacy.nazwisko}`}</span>
          ),
        },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={props.onSelectionChange}
    />
  );
};
