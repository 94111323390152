import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@material-ui/core';

type ButtonsContainerProps = BoxProps & {
  children?: ReactNode;
};

export const ButtonsContainer = (props: ButtonsContainerProps) => {
  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridGap={16}
      justifyContent="space-between"
      {...props}
    />
  );
};
