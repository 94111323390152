import React from 'react';
import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Box } from '@material-ui/core';
import { Formik, useField } from 'formik';
import { Form, FormInput } from '@archiwum/forms';
import { Button } from '@archiwum/ui/button';
import { actionsService$ } from '@archiwum/actions-handler';
import {
  usePrzeniesDoFolderu,
  UsePrzeniesDoFolderuProps,
} from './use-przenies-do-folderu';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { parseJSON } from '@nask/hooks';
import { PoczekalniaModel } from '@archiwum/features/archiving-documents';
import { Selected } from '../archiwizuj/selected';
import { TypObiekt } from '../../../models/models';

export type PrzeniesDoFolderuProps = UsePrzeniesDoFolderuProps & {
  title: string;
  question?: string;
};

export const PrzeniesDoFolderu = (props: PrzeniesDoFolderuProps) => {
  const { question, title = 'PrzeniesDoFolderu', ...rest } = props;
  const { isLoading, formikProps } = usePrzeniesDoFolderu(rest);

  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>{title}</ActionHeader>
        <Formik {...formikProps}>
          <Form>
            {rest.typObiekt === TypObiekt.SPRAWY ? (
              <SelectedCasesInfo />
            ) : (
              <SelectedPismaInfo />
            )}
            <Selected />
            <Box pt={3} pb={2}>
              {question?.length > 0 && (
                <Typography variantType={TypographyVariants.Paragraph}>
                  {question}
                </Typography>
              )}
            </Box>
            <FormInput label="Komentarz" name="komentarz" multiline />

            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                NIE
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                TAK
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};

const SelectedCasesInfo = () => {
  const [field] = useField<string>('selected');
  const selected = parseJSON<PoczekalniaModel[]>(field.value);
  const count = selected.length;
  const lastDigitOfCount = count % 10;
  const title =
    (count > 4 && count < 22) || // np. 11, 12, 13
    lastDigitOfCount > 4 || // np. 5, 7, 45, 99,
    lastDigitOfCount === 0 || // np. 10, 20, 50
    (lastDigitOfCount === 1 && count > 24) // np 25, 51, 71
      ? `Wybrałeś ${count} spraw`
      : lastDigitOfCount > 1
      ? `Wybrałeś ${count} sprawy` // to, co kończy się na 2, 3, 4 i jest większe równe 22
      : 'Wybrałeś 1 sprawę'; //reszta, czyli 1

  return <Typography>{title}</Typography>;
};

const SelectedPismaInfo = () => {
  const [field] = useField<string>('selected');
  const selected = parseJSON<PoczekalniaModel[]>(field.value);
  const count = selected.length;
  const lastDigitOfCount = count % 10;
  const title =
    (count > 4 && count < 22) || // np. 11, 12, 13
    lastDigitOfCount > 4 || // np. 5, 7, 45, 99,
    lastDigitOfCount === 0 || // np. 10, 20, 50
    (lastDigitOfCount === 1 && count > 24) // np 25, 51, 71
      ? `Wybrałeś ${count} pism`
      : lastDigitOfCount > 1
      ? `Wybrałeś ${count} pisma` // to, co kończy się na 2, 3, 4 i jest większe równe 22
      : 'Wybrałeś 1 pismo'; //reszta, czyli 1

  return <Typography>{title}</Typography>;
};
