import { useState } from 'react';
import { Box } from '@material-ui/core';
import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Formik, useField } from 'formik';
import { Form, FormInput, FormSelect } from '@archiwum/forms';
import { Selected } from '../archiwizuj/selected';
import { actionsService$ } from '@archiwum/actions-handler';
import {
  usePrzekazDoPoprawyFacade,
  UsePrzekazDoPoprawyProps,
} from './use-przekaz-do-poprawy-facade';
import { Typography } from '@archiwum/ui/typography';
import { parseJSON } from '@nask/hooks';
import { PoczekalniaModel, TypObiekt } from '../../../models/models';
import { Button } from '@archiwum/ui/button';

type PrzekazDoPoprawyProps = UsePrzekazDoPoprawyProps & {};

export const PrzekazDoPoprawy = (props: PrzekazDoPoprawyProps) => {
  const {
    formikProps,
    isLoading,
    stanowiskaOptions,
    bledy,
  } = usePrzekazDoPoprawyFacade(props);

  const [isInneSelected, setIsInneSelected] = useState(false);

  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>Przekaż do poprawy</ActionHeader>
        <Formik {...formikProps}>
          <Form>
            <Title typObiekt={props.typObiekt} />
            <Selected />
            {!props.doProwadzacego && (
              <FormSelect
                name={'odbiorca'}
                label={'Odbiorca'}
                options={stanowiskaOptions}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
              />
            )}
            <FormSelect
              multiple
              name={'komentarz'}
              label={'Elementy do poprawy'}
              options={bledy}
              onChange={(e) => {
                setIsInneSelected(
                  ((e as unknown) as string[])?.indexOf('inne') >= 0
                );
              }}
            />
            <FormInput
              style={!isInneSelected ? { display: 'none' } : undefined}
              label="Inne"
              name="inne"
            />

            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                Przekaż
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};

type TitleProps = { typObiekt: TypObiekt };

const Title = ({ typObiekt }: TitleProps) => {
  const [field] = useField<string>('selected');
  const selected = parseJSON<PoczekalniaModel[]>(field.value);
  const count = selected.length;

  const title = {
    sprawa:
      count > 4
        ? `Wybrałeś ${count} spraw do poprawy`
        : count > 1
        ? `Wybrałeś ${count} sprawy do poprawy`
        : 'Wybrałeś 1 sprawę do poprawy',
    pismoNAS:
      count > 4
        ? `Wybrałeś ${count} dokumentów do poprawy`
        : count > 1
        ? `Wybrałeś ${count} dokumenty do poprawy`
        : 'Wybrałeś 1 dokument do poprawy',
  };
  return (
    <Typography>
      {typObiekt === TypObiekt.SPRAWY ? title.sprawa : title.pismoNAS}
    </Typography>
  );
};
