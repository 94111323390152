import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';

import { ProcesBrakowania } from '../../models/models';
import { RenderDetailsPanel } from './details-panel';
import { ProcesBrakowaniaOperacje } from './proces-brakowania-operacje';
import { lazy } from 'react';
import { useStyles } from '../../brakowanie.styles';
import { Prawo117Icon } from '@nask/ezdrp-icons';

type ProcesyBrakowaniaTableProps = Pick<
  UseTableFacadeProps<ProcesBrakowania>,
  'tableRef' | 'data'
>;

export const ProcesyBrakowaniaTable = (props: ProcesyBrakowaniaTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const classes = useStyles();
  return (
    <Table<ProcesBrakowania>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'tytul',
          title: 'Tytuł spisu',
        },
        {
          field: 'komentarz',
          title: 'Komentarz',
        },
        {
          field: 'liczbaElementow',
          title: 'Liczba elementów',
        },
        { field: 'dataUtworzenia', title: 'Data utworzenia', type: 'date' },
        { field: 'dataBrakowania', title: 'Data brakowania', type: 'datetime' },
        { field: 'statusOpis', title: 'Status' },
        {
          field: 'operacje',
          render: (data) => <ProcesBrakowaniaOperacje {...{ data }} />,
        },
      ]}
      options={{
        filtering: false,
        selection: true,
        detailPanelColumnAlignment: 'right',
        detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
      detailPanel={[
        () => ({
          visible: true,
          tooltip: 'Lista obiektów w procesie',
          icon: () => <Prawo117Icon className={classes.rightArrowIcon} />,
          render: (rowData) => <RenderDetailsPanel rowData={rowData} />,
        }),
      ]}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'archiwum.proces-brakowania.wstrzymaj':
      return 'Zatrzymaj proces brakowania';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const WstrzymajProcesBrakowania = lazy(() =>
    import(
      '../../context-actions/wstrzymaj-proces-brakowania/wstrzymaj-proces-brakowania'
    ).then((module) => ({ default: module.WstrzymajProcesBrakowania }))
  );

  switch (command) {
    case 'archiwum.proces-brakowania.wstrzymaj':
      return <WstrzymajProcesBrakowania />;
    default:
      return <div>{`Nie znaleziono akcji: ${command}`}</div>;
  }
};
