import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { debounceTime } from 'rxjs/operators';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as yup from 'yup';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { TableRef, getCeilingDate, getFloorDate } from '@archiwum/shared/table';
import { useFiltering } from '@archiwum/hooks';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { getErrorResponse } from '@archiwum/utility/helper';
import moment from 'moment';

import {
  SprawaArchiwalna,
  TypProwadzenia,
  TypProwadzeniaLabelValue,
  TypObiekt,
} from '../models/models';
import { ArchivingPackageServiceQuery } from '../services/archiving-package-service-query';
import { ArchivingPackageServiceCommand } from '../services/archiving-package-service-command';
import { SprawyFiltry } from '@archiwum/features/archiving-documents';

export type GenerujPaczkeFormModel = {
  nazwa: string;
  opis: string;
  znakSprawy: string;
  idWykaz: string;
  kategoriaArchiwalna: string;
  dolaczanieDoSprawy: string;
};

export type SprawyFilters = {
  komorkaOrganizacyjna: string | null;
  prowadzacy: string | null;
  symbolJRWA: string | null;
  kategoriaArchiwalna: string | null;
  typProwadzenia: TypProwadzenia | null;
  dataZakonczeniaOd: string | null;
  dataZakonczeniaDo: string | null;
  dataPrzyjeciaOd: string | null;
  dataPrzyjeciaDo: string | null;
};

const initialState: SprawyFilters = {
  dataPrzyjeciaOd: null,
  dataPrzyjeciaDo: null,
  dataZakonczeniaOd: null,
  dataZakonczeniaDo: null,
  kategoriaArchiwalna: null,
  komorkaOrganizacyjna: null,
  prowadzacy: null,
  symbolJRWA: null,
  typProwadzenia: null,
};

export const useCreateArchivingFacade = () => {
  const [showSnackbar] = useSnackbar();
  const tableRef = useRef<TableRef>();
  const [selectedSprawy, setSelectedSprawy] = useState<SprawaArchiwalna[]>([]);
  const [filtersData, setFiltersData] = useState<SprawyFiltry>();
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const { setUrl: getFilters } = useApi({
    onSuccess: (data) => {
      if (data) setFiltersData((data as unknown) as SprawyFiltry);
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateFilters = () => {
    getFilters(
      ArchivingPackageServiceQuery.GetSprawyFiltry({ czyWszystkie: isShowAll })
    );
  };

  const { state, setState } = useFiltering<SprawyFilters>({
    initialState: initialState,
  });

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
    setSelectedSprawy([]);
  }, [searchValue, state, isShowAll]);

  useEffect(() => {
    handleUpdateFilters();
  }, [isShowAll]);

  useEffect(() => {
    setIsShowAll(false);
  }, []);

  const handleChangeShowAll = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowAll(event.target.checked);
  };

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?: SelectModel | TypProwadzeniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const komorkaOrganizacyjnaOptions =
    filtersData?.komorkiOrganizacyjne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const prowadzacyOptions =
    filtersData?.prowadzacy?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const symboleJRWAOptions =
    filtersData?.symboleJRWA?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];

  // FORM
  const { isLoading, setUrl } = useApi({
    onSuccess: () => {
      handleClose();
      setSelectedSprawy([]);
      tableRef.current?.onQueryChange?.();
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });
  const initialValues: GenerujPaczkeFormModel = {
    nazwa: `Paczka archiwalna ${moment(new Date()).format('DD/MM/YYYY')}`,
    opis: '',
    znakSprawy: '',
    idWykaz: '',
    kategoriaArchiwalna: '',
    dolaczanieDoSprawy: '',
  };

  const validationSchema: yup.SchemaOf<GenerujPaczkeFormModel> = yup
    .object()
    .shape({
      dolaczanieDoSprawy: yup.string().required('Pole wymagane'),
      nazwa: yup.string().required('Pole wymagane'),
      opis: yup.string(),
      idWykaz: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'nowa',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
      kategoriaArchiwalna: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'nowa',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
      znakSprawy: yup.string().when('dolaczanieDoSprawy', {
        is: (dolacznieDoSprawy: string) => dolacznieDoSprawy === 'istniejaca',
        then: yup.string().required('Pole wymagane'),
        otherwise: yup.string(),
      }),
    });

  const onSubmit = (values: GenerujPaczkeFormModel) => {
    setUrl(
      ArchivingPackageServiceCommand.GenerujPaczkeArchiwalna({
        nazwa: values.nazwa,
        opis: values.opis,
        typObiekt: TypObiekt.SPRAWY,
        listaIdObiekt: selectedSprawy.map((x) => x.id),
        znakSprawy: values.znakSprawy,
        idWykaz: values.idWykaz,
        kategoriaArchiwalna: values.kategoriaArchiwalna,
      })
    );
  };

  return {
    tableRef,
    searchValue,
    onChange,
    selectedSprawy,
    setSelectedSprawy,
    handleUpdateDate,
    handleUpdateSelect,
    komorkaOrganizacyjnaOptions,
    prowadzacyOptions,
    symboleJRWAOptions,
    state,
    data: filtersData,
    isShowAll,
    handleChangeShowAll,
    open,
    handleOpen,
    handleClose,
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
    isLoading,
  };
};
