import { Box, Container, Paper } from '@material-ui/core';
import { SearchInput } from '@archiwum/shared/search-input';
import { useSelectedDispatch } from '@archiwum/hooks';

import { useProcesyBrakowaniaFacade } from './use-procesy-brakowania-facade';
import { useStyles } from './procesy-brakowania.styles';
import { ProcesyBrakowaniaTable } from '../components/procesy-brakowania-table';
import { getProcesyBrakowaniaQuery } from '../../helper';

export const ProcesyBrakowania = () => {
  const { tableRef, searchValue, onChange } = useProcesyBrakowaniaFacade();
  const dispatch = useSelectedDispatch();
  const classes = useStyles();

  return (
    <>
      <Box width="100%">
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                  <Paper elevation={0}>
                    <Box display="grid" gridAutoFlow="row">
                      <Box display="flex" justifySelf="flex-end">
                        <Box m={0.5} p={0.5}>
                          <SearchInput
                            searchMethod={(phrase: string) => {
                              onChange(phrase);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Paper classes={{ root: classes.paper }}>
            <ProcesyBrakowaniaTable
              tableRef={tableRef}
              data={(query, result) => {
                getProcesyBrakowaniaQuery(query, result, searchValue, dispatch);
              }}
            />
          </Paper>
        </Container>
      </Box>
    </>
  );
};
