import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { Routes } from '@archiwum/routes';

type TabModel = {
  label: string;
  path: string;
};

const tabs = [
  {
    label: 'Wytypowane do brakowania',
    path: Routes.BrakowanieWytypowaneDoBrakowania.path,
  },
  { label: 'Spisy brakowania', path: Routes.BrakowanieSpisy.path },
  { label: 'Brakowanie', path: Routes.BrakowanieProcesy.path },
];

export const useBrakowanieFacade = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    tabs.map((tab) => {
      if (matchPath(location.pathname, { path: tab.path, exact: true })) {
        setActiveTab(tab);
      }
    });
  }, [location.pathname]);

  const handleChangeTab = (event: ChangeEvent<unknown>, value: TabModel) => {
    history.push(value.path);
    setActiveTab(value);
  };

  return {
    tabs,
    activeTab,
    handleChangeTab,
  };
};
