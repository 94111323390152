import { lazy } from 'react';
import { Box, Link, Tooltip } from '@material-ui/core';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { useStyles } from './spis-spraw-table.styles';
import {
  FolderArchiwum,
  PoczekalniaModel,
  TypObiekt,
} from '@archiwum/features/archiving-documents';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';
import { Prowadzacy } from 'libs/features/archiving-documents/src/lib/poczekalnia/components/prowadzacy';
import { DataUtworzenia064Icon } from '@nask/ezdrp-icons';

type SpisSprawTableProps = Pick<
  UseTableFacadeProps<PoczekalniaModel>,
  'tableRef' | 'data'
>;

export const SpisSprawTable = (props: SpisSprawTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const handleRedirect = (item: PoczekalniaModel) => {
    /*global EZDRP_APP_URL*/
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ezdrpURL = EZDRP_APP_URL;
    const href = `${ezdrpURL}?viewId=SPRAWA&caseId=${item.id}`;
    window.location.href = href;
  };

  return (
    <Table<PoczekalniaModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'znak',
          title: 'Znak',
          render: (data) => (
            <Box
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
            >
              <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
              <Link
                classes={{ root: classes.caseLink }}
                onClick={() => handleRedirect(data)}
              >
                {data.znak}
              </Link>
              {data.czyWSpisieBrakowania && (
                <Tooltip title={'Sprawa do brakowania'}>
                  <span>
                    <DataUtworzenia064Icon
                      className={classes.exclamationIcon}
                    />
                  </span>
                </Tooltip>
              )}
              {data.czyWPaczceArchiwalnej && (
                <Tooltip title={'Sprawa już znajduje się w paczce archiwalnej'}>
                  <span>
                    <DataUtworzenia064Icon
                      className={classes.exclamationIconPink}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          ),
        },
        { field: 'kategoriaArchiwalna', title: 'Kat. arch.' },
        {
          field: 'tytul',
          title: 'Tytuł',
        },
        {
          field: 'folderOpis',
          title: 'Status',
        },
        { field: 'dataUtworzenia', title: 'Data wszczęcia', type: 'date' },
        { field: 'dataZakonczenia', title: 'Data zakończenia', type: 'date' },
        {
          field: 'prowadzacy',
          title: 'Prowadzący sprawę',
          render: (data) => <Prowadzacy {...data} />,
        },
        {
          field: 'uwagi',
          title: 'Uwagi',
        },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, data),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'archiwum.obiekt.historia':
      return 'Historia';
    case '*archiwum.przekaz.do-poprawy':
    case '*archiwum.przekaz.nieaktywny.do-poprawy':
      return 'Przekaż do poprawy';
    case '*archiwum.przenies-do.gotowe-do-archiwizacji':
      return 'Przenieś do gotowych do zarchiwizowania';
    case '*archiwum.przenies-do.odlozone':
      return 'Odłóż';
    case '*archiwum.przenies-do.poczekalnia':
      return 'Przenieś do poczekalni';
    case '*przenies-do-folderu.zarchiwizowane':
      return 'Archiwizuj';
    case '*zadanie-do-poprawy.utworz':
      return 'Przekaż do poprawy';
    case '*przenies-do-folderu.odlozone':
      return 'Odłóż';
    case '*paczka-archiwalna.generuj':
      return 'Generuj paczkę archiwalną';
    default:
      return command;
  }
};

const getComponent = (command: string, data) => {
  const Archiwizuj = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Archiwizuj,
    }))
  );

  const Historia = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Historia,
    }))
  );
  const PrzekazDoPoprawy = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.PrzekazDoPoprawy,
    }))
  );
  const Odloz = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Odloz,
    }))
  );
  const GenerujPaczkeArchiwalna = lazy(() =>
    import('../../context-actions/generuj-paczke-archiwalna').then(
      (module) => ({
        default: module.GenerujPaczkeArchiwalna,
      })
    )
  );
  const PrzeniesDoFolderu = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.PrzeniesDoFolderu,
    }))
  );

  switch (command) {
    case 'archiwum.obiekt.historia':
      return <Historia typObiekt={TypObiekt.SPRAWY} />;
    case '*archiwum.przekaz.do-poprawy':
      return <PrzekazDoPoprawy doProwadzacego typObiekt={TypObiekt.SPRAWY} />;
    case '*archiwum.przekaz.nieaktywny.do-poprawy':
      return <PrzekazDoPoprawy typObiekt={TypObiekt.SPRAWY} />;

    case '*archiwum.przenies-do.gotowe-do-archiwizacji':
      return (
        <PrzeniesDoFolderu
          title="Przenieś do Gotowe do zarchiwizowania"
          question="Czy chcesz przenieść wybrane sprawy do gotowych do zarchiwizowania?"
          folderDocelowy={FolderArchiwum.GotoweDoZarchiwizowania}
          typObiekt={TypObiekt.SPRAWY}
          isCommentRequired={false}
        />
      );
    case '*archiwum.przenies-do.odlozone':
      return (
        <PrzeniesDoFolderu
          title="Odłóż"
          question="Czy chcesz odłóżyć wybrane sprawy?"
          folderDocelowy={FolderArchiwum.Odlozone}
          typObiekt={TypObiekt.SPRAWY}
          isCommentRequired={false}
        />
      );
    case '*archiwum.przenies-do.poczekalnia':
      return (
        <PrzeniesDoFolderu
          title="Przenieś do poczekalni"
          question="Czy chcesz przenieść wybrane sprawy do poczekalni?"
          folderDocelowy={FolderArchiwum.Poczekalnia}
          typObiekt={TypObiekt.SPRAWY}
          isCommentRequired={false}
        />
      );
    case '*przenies-do-folderu.zarchiwizowane':
      return <Archiwizuj showSearch={false} typObiekt={TypObiekt.SPRAWY} />;
    case '*przenies-do-folderu.odlozone':
      return <Odloz showSearch={false} typObiekt={TypObiekt.SPRAWY} />;
    case '*zadanie-do-poprawy.utworz':
      return <PrzekazDoPoprawy typObiekt={TypObiekt.SPRAWY} />;
    case '*paczka-archiwalna.generuj':
      return <GenerujPaczkeArchiwalna />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
