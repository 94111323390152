import { Input, InputProps } from '@archiwum/shared/input';
import {
  Autocomplete as AutocompleteMat,
  AutocompleteProps as AutocompleteMatProps,
} from '@material-ui/lab';
import { useStyles } from './autocomplete.styles';
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = PartialBy<
  AutocompleteMatProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> &
  Pick<InputProps, 'label' | 'name' | 'helperText' | 'error' | 'required'> & {};

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  label,
  name,
  helperText,
  error,
  required,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();
  return (
    <AutocompleteMat
      classes={{
        endAdornment: classes.endAdornment,
        inputRoot: classes.inputRoot,
        option: classes.option,
      }}
      noOptionsText={'Brak opcji'}
      filterSelectedOptions
      renderInput={(params) => (
        <Input
          placeholder={props.placeholder}
          name={name}
          label={label}
          {...params}
          helperText={helperText}
          error={error}
          required={required}
        />
      )}
      {...props}
    />
  );
};
