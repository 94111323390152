import { getUrl } from '@archiwum/utility/service';
import { Axios } from '@nask/axios-observable';
import { GenerateReportRequestModel } from '../models/models';
import { map, take } from 'rxjs/operators';

export const ReportsServiceCommand = {
  GenerateReport: (request: GenerateReportRequestModel) =>
    Axios.post(getUrl('zamow-raport', ''), request).pipe(
      take(1),
      map((data) => data.data)
    ),
};
