import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import {
  GetSubjectResponseModel,
  GetUserResponse,
  GetUserInfoRequestModel,
} from '../models/models';

export const UserServiceQuery = {
  GetUser: (request: GetUserInfoRequestModel) =>
    Axios.post<GetUserResponse>(
      getUrl('pobierz-uzytkownika', ''),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),

  GetSubject: () =>
    Axios.get<GetSubjectResponseModel>(
      getUrl('pobierz-jednostke-nadrzedna', '')
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
};
