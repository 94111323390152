import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      fontSize: 16,
      fontWeight: 600,
      minWidth: 128,
      padding: '0.8rem 1.6rem',
      '&$disabled': {
        backgroundColor: '#C0BCC9',
        color: theme.colors.white,
      },
    },
    outlined: {
      fontWeight: 700,
      border: `2px solid ${palette.primary.main}`,
      padding: '0.5rem 1.5rem',
    },
    disabled: {},
  })
);
