import { Formik } from 'formik';
import { Form, FormInput } from '@archiwum/forms';

import { useStepSpisTitleFacade } from './use-step-spis-title-facade';

import { FormMulti } from './form-multi';

export const StepSpisTitle = () => {
  const { komorkiOrganizacyjne, formikProps } = useStepSpisTitleFacade();

  return (
    <Formik {...formikProps}>
      <Form id={'brakowanie-step-title'}>
        {komorkiOrganizacyjne.length === 1 && (
          <FormInput
            name={`spisyDoWygenerowania[0].tytul`}
            label={'Nazwa spisu brakowania'}
          />
        )}
        {komorkiOrganizacyjne.length > 1 && (
          <FormMulti komorkiOrganizacyjne={komorkiOrganizacyjne} />
        )}
      </Form>
    </Formik>
  );
};
