import { useSnackbar } from '@archiwum/shared/toast-notification';
import { useDrawerRightSelector } from '@archiwum/drawer-right';
import {
  FolderArchiwum,
  PoczekalniaModel,
  PrzeniesRequestModel,
  TypObiekt,
} from '@archiwum/features/archiving-documents';
import { stringifyJSON } from '@nask/hooks';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { actionsService$ } from '@archiwum/actions-handler';
import { getErrorResponse } from '@archiwum/utility/helper';
import * as yup from 'yup';
import { useMutationStream } from '@archiwum/hooks';
import { ArchivingDocumentsServiceCommand } from '../../../services/archiving-documents-service-command';

type PrzeniesDoFolderuFormModel = {
  selected: string;
  komentarz: string;
};

export type UsePrzeniesDoFolderuProps = {
  folderDocelowy: FolderArchiwum;
  typObiekt: TypObiekt;
  isCommentRequired: Boolean;
};

export const usePrzeniesDoFolderu = ({
  folderDocelowy,
  typObiekt,
  isCommentRequired,
}: UsePrzeniesDoFolderuProps) => {
  const { mutateAsync, isLoading } = useMutationStream<
    unknown,
    unknown,
    PrzeniesRequestModel
  >(
    `przenies-${TypObiekt[typObiekt]}-do-folderu-${FolderArchiwum[folderDocelowy]}`,
    (variables) => ArchivingDocumentsServiceCommand.Archiving(variables)
  );
  const [showSnackbar] = useSnackbar();

  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as PoczekalniaModel[];

  const initialValues: PrzeniesDoFolderuFormModel = {
    komentarz: '',
    selected: stringifyJSON(selected),
  };

  const validationSchema: yup.SchemaOf<PrzeniesDoFolderuFormModel> = yup
    .object()
    .shape({
      selected: yup.string().required('Wybierz dokument'),
      komentarz: isCommentRequired
        ? yup.string().required('komentarz obowiązkowy')
        : yup.string(),
    });

  const onSubmit = async (values: PrzeniesDoFolderuFormModel) => {
    try {
      await mutateAsync({
        folderDocelowy,
        listaIdObiekt: selected.map((x) => x.id),
        komentarz: values.komentarz,
        typObiekt,
      });
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    } catch (ex) {
      showSnackbar(getErrorResponse(ex), { variant: 'error' });
    }
  };
  return {
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
    isLoading,
  };
};
