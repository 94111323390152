import { CaseStatus, DatyModel } from '../models/models';

export const getProperDate = (status?: CaseStatus, dateSet?: DatyModel) => {
  switch (status) {
    case 'W_Toku':
      return {
        label: 'Termin realizacji do:',
        value: dateSet.terminZalatwienia.value,
      };
    case 'Odlozona':
      return { label: 'Data odłożenia:', value: dateSet.dataOdlozenia.value };
    case 'Zawieszona':
      return {
        label: 'Data zawieszenia:',
        value: dateSet.dataZawieszenia.value,
      };
    case 'Zakonczona':
      return {
        label: 'Data zakończenia:',
        value: dateSet.dataZakonczenia.value,
      };
    default:
      return { label: '', value: '' };
  }
};
