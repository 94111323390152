import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Tab, Tabs } from '@archiwum/ui/tabs';
import { Button } from '@archiwum/ui/button';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { SelectModel } from '@nask/hooks';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { SearchInput } from '@archiwum/shared/search-input';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { useSelectedDispatch } from '@archiwum/hooks';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';
import { DrawerRight } from '@archiwum/drawer-right';
import { useDrawerRightSelector } from '@archiwum/drawer-right';

import {
  BrakowanieModel,
  FolderArchiwum,
  TypProwadzeniaLabelValue,
  TypObiekt,
} from '../../models/models';
import { SprawyTable } from '../components/sprawy-table';
import { PismaNasTable } from '../components/pisma-nas-table';
import { useWytypowaneDoBrakowaniaFacade } from '../../hooks/use-wytypowane-do-brakowania-facade';
import {
  getFilters,
  getPismaNASBrakowanieQuery,
  getSprawyBrakowaniaQuery,
} from '../../helper';
import { useStyles } from './wytypowane-do-brakowania.styles';
import { CreateSpisDoBrakowania } from '../components/create-spis-do-brakowania/create-spis-do-brakowania';

export const WytypowaneDoBrakowania = () => {
  const {
    onChange,
    tableRef,
    state,
    data,
    handleChangeTab,
    handleUpdateSelect,
    handleUpdateDate,
    typProwadzeniaOptions,
    katArchiwalnaOptions,
    symboleJRWAOptions,
    prowadzacyOptions,
    komorkaOrganizacyjnaOptions,
    searchValue,
    activeTab,
    tabs,
    isShowAll,
    handleChangeShowAll,
    isSkipContainedInIndices,
    handleChangeSkipContainedInIndices,
    isSprawy,
    open,
    handleOpen,
    handleClose,
    handleRefresh,
  } = useWytypowaneDoBrakowaniaFacade({
    foldery: [FolderArchiwum.Poczekalnia],
  });
  const dispatch = useSelectedDispatch();
  const classes = useStyles();

  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as BrakowanieModel[];

  const modalBody = (
    <Box className={classes.modalPaper}>
      <Box>
        <Box className={classes.modalHeaderContent}>
          <Typography
            id="simple-modal-title"
            className={classes.modalHeader}
            variantType={TypographyVariants.Header3}
            variant={'h3'}
          >
            Generowanie spisu do brakowania
          </Typography>
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={() => {
              handleClose();
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </Box>
        <Divider />
      </Box>

      <Box pb={2}>
        <CreateSpisDoBrakowania
          handleClose={handleClose}
          handleRefresh={handleRefresh}
          typObiekt={isSprawy ? TypObiekt.SPRAWY : TypObiekt.PISMA_NAS}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Box width="100%">
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                  <Paper elevation={0}>
                    <Box display="grid" gridAutoFlow="row">
                      <Box display="flex" justifySelf="flex-end">
                        <Box m={0.5} p={0.5}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            style={{ flexShrink: 0 }}
                            onClick={() => {
                              handleOpen();
                            }}
                            disabled={selected.length < 1}
                          >
                            Generuj spis
                          </Button>
                        </Box>

                        <Box m={0.5} p={0.5}>
                          <SearchInput
                            searchMethod={(phrase: string) => {
                              onChange(phrase);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box display="grid" flexWrap="wrap" gridAutoFlow="column">
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Komórka organizacyjna"
                            placeholder="Wszystkie"
                            name="komorkaOrganizacyjna"
                            options={komorkaOrganizacyjnaOptions}
                            value={
                              komorkaOrganizacyjnaOptions.find(
                                (option) =>
                                  option.value === state.komorkaOrganizacyjna
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'komorkaOrganizacyjna',
                                v as SelectModel
                              )
                            }
                          />
                        </Box>
                        {/*<Box m={0.5} p={0.5} width={220}>*/}
                        {/*  <Autocomplete*/}
                        {/*    label="Prowadzący sprawę"*/}
                        {/*    name="prowadzacy"*/}
                        {/*    options={prowadzacyOptions}*/}
                        {/*    value={*/}
                        {/*      prowadzacyOptions.find(*/}
                        {/*        (option) => option.value === state.prowadzacy*/}
                        {/*      ) ?? null*/}
                        {/*    }*/}
                        {/*    getOptionLabel={(option) => option.label}*/}
                        {/*    onChange={(e, v) =>*/}
                        {/*      handleUpdateSelect('prowadzacy', v as SelectModel)*/}
                        {/*    }*/}
                        {/*  />*/}
                        {/*</Box>*/}
                        {/*<Box m={0.5} p={0.5} width={120}>*/}
                        {/*  <Autocomplete*/}
                        {/*    label="Symbol JRWA"*/}
                        {/*    name="symbolJRWA"*/}
                        {/*    options={symboleJRWAOptions}*/}
                        {/*    value={*/}
                        {/*      symboleJRWAOptions.find(*/}
                        {/*        (option) => option.value === state.symbolJRWA*/}
                        {/*      ) ?? null*/}
                        {/*    }*/}
                        {/*    getOptionLabel={(option) => option.label}*/}
                        {/*    onChange={(e, v) =>*/}
                        {/*      handleUpdateSelect('symbolJRWA', v as SelectModel)*/}
                        {/*    }*/}
                        {/*  />*/}
                        {/*</Box>*/}
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Kat. archiwalna"
                            placeholder="Wszystkie"
                            name={'kategoriaArchiwalna'}
                            options={katArchiwalnaOptions}
                            value={
                              katArchiwalnaOptions.find(
                                (option) =>
                                  option.value === state.kategoriaArchiwalna
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'kategoriaArchiwalna',
                                v as SelectModel
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <Autocomplete
                            label="Typ prowadzenia"
                            placeholder="Wszystkie"
                            name="typProwadzenia"
                            options={typProwadzeniaOptions}
                            value={
                              typProwadzeniaOptions.find(
                                (option) =>
                                  option.value === state.typProwadzenia
                              ) ?? null
                            }
                            getOptionLabel={(option) => option.label}
                            renderOption={(option) => (
                              <Box
                                display="grid"
                                gridGap={8}
                                gridAutoFlow="column"
                                alignItems="center"
                              >
                                <TypProwadzeniaAvatar
                                  typProwadzenia={option.value}
                                />
                                <Typography>{option.label}</Typography>
                              </Box>
                            )}
                            onChange={(e, v) =>
                              handleUpdateSelect(
                                'typProwadzenia',
                                v as TypProwadzeniaLabelValue
                              )
                            }
                          />
                        </Box>
                        <Box m={0.5} p={0.5}>
                          <DatePicker
                            InputProps={{
                              classes: { adornedEnd: classes.datePicker },
                            }}
                            format={'yyyy'}
                            placeholder="Wszystkie"
                            label="Rok zakończenia"
                            name="dataZakonczeniaOd"
                            value={state.dataZakonczeniaOd}
                            views={['year']}
                            onChange={(date) =>
                              handleUpdateDate(date, 'dataZakonczeniaOd')
                            }
                          />
                        </Box>
                      </Box>
                      <Box
                        m={0.5}
                        p={0.5}
                        display="flex"
                        alignItems="center"
                        gridGap="2rem"
                      >
                        <FormControlLabel
                          classes={{
                            root: classes.formControlLabel,
                            label: classes.formLabel,
                          }}
                          control={
                            <Checkbox
                              checked={isShowAll}
                              onChange={handleChangeShowAll}
                              name="showAll"
                              color="primary"
                            />
                          }
                          label={
                            isSprawy
                              ? 'pokaż wszystkie zakończone sprawy'
                              : 'pokaż wszystkie zakończone pisma'
                          }
                        />
                        <FormControlLabel
                          classes={{
                            root: classes.formControlLabel,
                            label: classes.formLabel,
                          }}
                          control={
                            <Checkbox
                              checked={isSkipContainedInIndices}
                              onChange={handleChangeSkipContainedInIndices}
                              name="skipContainedInIndices"
                              color="primary"
                            />
                          }
                          label="Pomiń zawarte w spisach"
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Tabs
              classes={{ indicator: classes.indicator, root: classes.root }}
              value={activeTab}
              onChange={handleChangeTab}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  classes={{ root: classes.tab, selected: classes.activeTab }}
                />
              ))}
            </Tabs>
            <Divider classes={{ root: classes.borderBottomTab }} />
          </Box>
          <Paper classes={{ root: classes.paperShadowBottom }}>
            {activeTab === tabs[0].value ? (
              <SprawyTable
                tableRef={tableRef}
                data={(query, result) => {
                  getSprawyBrakowaniaQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch,
                    isShowAll,
                    isSkipContainedInIndices
                  );
                }}
              />
            ) : (
              <PismaNasTable
                tableRef={tableRef}
                data={(query, result) => {
                  getPismaNASBrakowanieQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch,
                    isShowAll,
                    isSkipContainedInIndices
                  );
                }}
              />
            )}
          </Paper>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </Box>
      {/* <DrawerRight /> */}
    </>
  );
};
