import { OwnQuery, SubjectServiceModel } from '@archiwum/shared/table';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { getErrorResponse } from '@archiwum/utility/helper';
import { useApi } from '@nask/hooks';
import { useEffect, useState } from 'react';
import { PochodzenieRaportu } from '../models/models';
import { ReportsServiceQuery } from '../services/reports-service-query';

export const useListaZamowionychRaportowFacade = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const onSearchChange = (phrase: string) => {
    setSearchPhrase(phrase);
  };
  const [showSnackbar] = useSnackbar();
  const { setUrl, data, isLoading } = useApi<string>({
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      window.open(data, '_blank');
    }
  }, [isLoading]);

  const handleDownloadReport = (idReport: string) => {
    setUrl(
      ReportsServiceQuery.DownloadReport({
        idRaport: idReport,
      })
    );
  };

  const getUserReportsQuery = (
    query: OwnQuery,
    result: SubjectServiceModel
  ) => {
    ReportsServiceQuery.GetUserReports({
      pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
      sort: query.sort,
      searchValue: searchPhrase,
      searchBy: 'typ',
      pochodzenieRaportu: PochodzenieRaportu.Archiwum,
    }).subscribe({
      next: (value) => {
        const data = value.items.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        return result.onSuccess({
          data,
          page: value.pageInfo.pageNumber,
          totalCount: value.pageInfo.totalItems,
        });
      },
      error: (err) => {
        return result.onError();
      },
    });
  };

  return { onSearchChange, getUserReportsQuery, handleDownloadReport };
};
