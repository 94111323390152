import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ transitions }) =>
  createStyles({
    root: {
      position: 'relative',
      top: 1,
    },
    sticky: {
      position: 'sticky',
      top: 0,
      height: `calc(100vh - ${theme.topbar.height})`,
    },

    drawerOpen: {
      width: 200,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    listItemText: {
      fontSize: 16,
    },
  })
);
