import { Box, Stepper, Step, StepButton, StepLabel } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '@archiwum/ui/button';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

import { useCreateSpisDoBrakowaniaFacade } from './use-create-spis-do-brakowania-facade';
import { useStyles } from './create-spis-do-brakowania.styles';
import { TypObiekt } from '../../../models/models';

type CreateSpisDoBrakowaniaProps = {
  handleClose: () => void;
  handleRefresh: () => void;
  typObiekt: TypObiekt;
};

export const CreateSpisDoBrakowania = ({
  handleClose,
  typObiekt,
}: CreateSpisDoBrakowaniaProps) => {
  const classes = useStyles();
  const {
    activeStep,
    steps,
    changeActiveStep,
    getActiveStep,
  } = useCreateSpisDoBrakowaniaFacade({
    typObiekt,
    handleClose,
  });

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps
          .sort((a, b) => a.stepNumber - b.stepNumber)
          .map((value, index) => {
            const active = value.stepNumber === activeStep;
            const touched = value.stepNumber <= activeStep;
            return (
              <Step key={value.name}>
                <StepButton
                  id={`stepButton${index}`}
                  icon={
                    <div className={classes.stepNumberContainer}>
                      <div
                        className={clsx(classes.stepNumber, {
                          [classes.stepNumberTouched]: touched,
                          [classes.stepNumberError]: value.error,
                        })}
                      >
                        {index + 1}
                      </div>
                    </div>
                  }
                  onClick={() => {
                    changeActiveStep(value.stepNumber);
                  }}
                  disabled={value.disabled}
                >
                  <Typography variantType={TypographyVariants.Paragraph}>
                    {value.name}
                  </Typography>
                </StepButton>
              </Step>
            );
          })}
      </Stepper>
      <Box className={classes.modalContent}>{getActiveStep(activeStep)}</Box>

      <div className={classes.modalFooter}>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={() => handleClose()}
            variant="outlined"
            color="default"
          >
            Zamknij
          </Button>
          {activeStep > 0 && (
            <Button
              onClick={() => {
                steps[activeStep].handlePrevious?.();
              }}
              variant="outlined"
              color="default"
            >
              Wstecz
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button
              type="submit"
              disabled={steps[activeStep].disabled}
              variant="contained"
              color="primary"
              form={steps[activeStep].formId}
            >
              Utwórz
            </Button>
          ) : (
            <Button
              disabled={steps[activeStep].disabled}
              variant="contained"
              color="primary"
              type={steps[activeStep].formId ? 'submit' : 'button'}
              form={steps[activeStep].formId}
              onClick={(e) => {
                e.stopPropagation();
                steps[activeStep].handleNext?.();
              }}
            >
              Dalej
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
