import { Box, Container, IconButton, Paper } from '@material-ui/core';
import { useSelectedDispatch } from '@archiwum/hooks';
import { SearchInput } from '@archiwum/shared/search-input';
import { DrawerRight } from '@archiwum/drawer-right';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';

import { getListaPaczekArchiwalnych } from '../helper';
import { useArchivingPackageFacade } from './use-archiving-package-facade';
import { useStyles } from './archiving-packages-list.styles';
import { ArchivingPackageTable } from './archiving-package-table';

export function ArchivingPackagesList() {
  const { tableRef, onChange, searchValue } = useArchivingPackageFacade();
  const classes = useStyles();
  const dispatch = useSelectedDispatch();
  return (
    <>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4}>
                  <SearchInput
                    searchMethod={(phrase: string) => {
                      onChange(phrase);
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>

          <Paper classes={{ root: classes.paper }}>
            <ArchivingPackageTable
              tableRef={tableRef}
              data={(query, result) => {
                getListaPaczekArchiwalnych(
                  query,
                  result,
                  searchValue,
                  dispatch
                );
              }}
            />
          </Paper>
        </Container>
      </Box>

      <DrawerRight />
    </>
  );
}

export default ArchivingPackagesList;
