import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    avatar: {
      display: 'flex',
      width: 26,
      height: 26,
    },
    elektroniczne: {
      color: '#40852B',
    },
    papierowe: {
      color: '#D4213D',
    },
  })
);
