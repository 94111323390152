import { ObiektSpisuBrakowania, TypObiekt } from '../../models/models';
import * as yup from 'yup';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { useApi } from '@nask/hooks';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { actionsService$ } from '@archiwum/actions-handler';
import { getErrorResponse } from '@archiwum/utility/helper';
import { useDrawerRightSelector } from '@archiwum/drawer-right';
import { BrakowanieServiceCommand } from '../../services/brakowanie-service-command';

type FormValues = {
  uwagi: string;
};

export const useUsunObiektZeSpisuBrakowaniaFacade = () => {
  const [showSnackbar] = useSnackbar();
  const selected: ObiektSpisuBrakowania[] = useDrawerRightSelector(
    (state) => state.data
  ) as any;

  const { setUrl } = useApi({
    onSuccess: () => {
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const initialValues: FormValues = { uwagi: '' };
  const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
    uwagi: yup.string().nullable(),
  });

  const onSubmit = () => {
    selected?.length > 0 &&
      setUrl(
        BrakowanieServiceCommand.UzunZeSpisuBrakowania({
          idSpisBrakowania: selected[0].idSpisBrakowania,
          listaIdObiektowBrakowania: selected.map((item) => item.idObiekt),
          typObiektu: selected[0].typObiektuArchiwum as TypObiekt,
        })
      );
  };

  return {
    selected,
    formikProps: { initialValues, validationSchema, onSubmit },
  };
};
