import { Axios } from '@nask/axios-observable';
import { map, take } from 'rxjs/operators';
import { GetMenuResponse } from '../models/models';
import { getUrl } from '@archiwum/utility/service';

export const MenuServiceQuery = {
  GetMenu: () =>
    Axios.post<GetMenuResponse>(getUrl('pobierz-menu', ''), {
      modul: 'archiwum',
    }).pipe(
      take(1),
      map((data) => data.data.lista)
    ),
};
