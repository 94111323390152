import React from 'react';
import { Button as MatButton, ButtonProps } from '@material-ui/core';
import { useStyles } from './button.styles';
import clsx from 'clsx';

export const Button = ({
  classes: classesFromProps = {},
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <MatButton
      variant="contained"
      color="primary"
      classes={{
        root: clsx(classes.root, classesFromProps.root),
        outlined: clsx(classes.outlined, classesFromProps.outlined),
        disabled: clsx(classes.disabled, classesFromProps.disabled),
        ...classesFromProps,
      }}
      {...props}
    />
  );
};
