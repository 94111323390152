import React, { memo } from 'react';
import styled from 'styled-components';

import { useDataListContext } from '../../util/context';
import { Typography } from '@archiwum/ui/typography';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { LabelsHeader } from '../components/labels-header';
import { DataListBodyRow } from '../components/data-list-body-row';

type BodyProps<T extends unknown> = {};

export const DataListBody = memo(<T extends object>(props: BodyProps<T>) => {
  const { dispatch, store } = useDataListContext();
  const renderInfoRow = (content: string) => {
    return (
      <Row last={false} style={{ gridTemplateColumns: '1fr' }}>
        <Typography style={{ justifySelf: 'center' }}>{content}</Typography>
      </Row>
    );
  };

  console.log(store);

  return (
    <BodyContainer>
      {store?.options?.showColumnLabels && (
        <LabelsHeader labels={store?.options?.labels} />
      )}
      {store.error
        ? renderInfoRow('Error')
        : store.rows.length === 0 && !store.loading
        ? renderInfoRow('Brak danych do wyświetlenia')
        : store.renderBody
        ? store.rows.map((row, index) => {
            return <DataListBodyRow key={index} {...row} />;
          })
        : JSON.stringify(store.data)}
    </BodyContainer>
  );
});

const DataCheckbox = styled((props: CheckboxProps) => <Checkbox {...props} />)<{
  hidden?: boolean;
}>`
  .MuiFormControlLabel-root {
    margin: 0;
  }
  &&& {
    opacity: ${(props) => (props.hidden ? 0 : 1)};
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }
`;

const BodyContainer = styled.div`
  grid-area: body;
`;

const Row = styled.div<{ last: boolean }>`
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding: 5px;
  display: grid;
  align-items: center;
  &:last-child {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;
