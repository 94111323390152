import React from 'react';
import { actionsService$ } from '@archiwum/actions-handler';
import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Button } from '@archiwum/ui/button';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Avatar, Box, List, ListItem } from '@material-ui/core';
import { Loader } from '@nask/ui';
import clsx from 'clsx';
import { getFullDate } from 'libs/shared/table/src/lib/hooks/methods';
import { FolderArchiwum, TypObiekt } from '../../../models/models';
import { useStyles } from './historia.styles';
import { useHistoriaFacade, UseHistoriaFacade } from './use-historia-facade';

type HistoriaProps = UseHistoriaFacade & {};

export const Historia = (props: HistoriaProps) => {
  const { isLoading, znak, numer, data } = useHistoriaFacade(props);

  const classes = useStyles();
  const title =
    props.typObiekt === TypObiekt.SPRAWY ? `Sprawa ${znak}` : `Pismo ${numer}`;

  if (isLoading) return <Loader show />;
  return (
    <ActionContainer>
      <Box width={350} m={1}>
        <ActionHeader>Historia archiwizacji</ActionHeader>
        <div className={classes.topInfoContainer}>
          <Typography variantType={TypographyVariants.Description}>
            {title}
          </Typography>
        </div>
        <div className={classes.historyContainer}>
          {data?.items?.length > 0 ? (
            <List>
              {data.items.map((item, index) => {
                return (
                  <ListItem key={index} className={classes.historyRow}>
                    <Typography
                      variantType={TypographyVariants.Description}
                      className={classes.theGreenColor}
                    >
                      <strong>{getFullDate(item.dataUtworzenia)}</strong>
                    </Typography>
                    <Avatar
                      className={clsx(
                        classes.indexAvatar,
                        classes.theGreenColor,
                        classes.theGreenBorderColor
                      )}
                    >
                      <Typography
                        variantType={TypographyVariants.Description}
                        className={classes.theGreenColor}
                      >
                        {index + 1}
                      </Typography>
                    </Avatar>
                    <Typography
                      variantType={TypographyVariants.Description}
                      className={classes.theGreenColor}
                    >
                      <strong>{item.typOperacji}</strong>
                      {/* {item.typOperacji} */}
                    </Typography>
                    <div className={classes.historyRowIndent} />
                    <div className={classes.historyRowInfo}>
                      <Typography className={classes.infoLabel}>
                        Użytkownik:
                      </Typography>
                      <Typography className={classes.infoField}>
                        {`${item.uzytkownik.imie} ${item.uzytkownik.nazwisko} - ${item.uzytkownik.nazwa}`}
                      </Typography>
                      <Typography className={classes.infoLabel}>
                        Komentarz:
                      </Typography>
                      <Typography className={classes.infoField}>
                        {item.opis}
                      </Typography>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            'brak danych do wyświetlenia'
          )}
        </div>
        <ButtonsContainer>
          <Button
            onClick={() => actionsService$.close()}
            variant="outlined"
            color="default"
          >
            Zamknij
          </Button>
        </ButtonsContainer>
      </Box>
    </ActionContainer>
  );
};
