import { useMutation } from 'react-query';
import {
  UseMutationOptions,
  UseMutationResult,
} from 'react-query/types/react/types';
import { MutationKey } from 'react-query/types/core/types';
import { Observable } from 'rxjs';

export const useMutationStream = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  queryKey: MutationKey,
  mutationFn: (variables: TVariables) => Observable<TData>,
  config?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> => {
  return useMutation(
    queryKey,
    (variables) => mutationFn(variables)?.toPromise?.(),
    config
  );
};
