import { BrakowanieModel, TypObiekt } from '../../models/models';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { Box, Tooltip } from '@material-ui/core';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { generatePath, useHistory } from 'react-router';
import { Routes } from '@archiwum/routes';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';
import { useStyles } from './sprawy-table.styles';
import { lazy } from 'react';
import { DataUtworzenia064Icon } from '@nask/ezdrp-icons';

type PismaNasTableProps = Pick<
  UseTableFacadeProps<BrakowanieModel>,
  'tableRef' | 'data'
>;

export const PismaNasTable = (props: PismaNasTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const history = useHistory();
  const classes = useStyles();
  const handleRedirect = (item: BrakowanieModel) => {
    history.push(generatePath(Routes.NasDetails.path, { caseId: item.id }));
  };

  return (
    <Table<BrakowanieModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        {
          field: 'numer',
          title: 'Numer NAS',
          render: (data) => (
            <Box
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
            >
              <TypProwadzeniaAvatar typProwadzenia={data.typProwadzenia} />
              {/* <Link onClick={() => handleRedirect(data)}> */}
              {data.numer}
              {/* </Link> */}
              {data.czyWSpisieBrakowania && (
                <Tooltip title={'Pismo do brakowania'}>
                  <span>
                    <DataUtworzenia064Icon
                      className={classes.exclamationIcon}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          ),
        },
        {
          field: 'tytul',
          title: 'Tytuł pisma',
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kat. arch.',
        },
        {
          field: 'prowadzacy',
          title: 'Prowadzący sprawę',
        },

        {
          field: 'komorkaOrganizacyjna',
          title: 'Komórka organizacyjna',
        },
        {
          field: 'dataZakonczenia',
          title: 'Data zakończenia',
          type: 'date',
        },
        { field: 'dataPrzyjecia', title: 'Data przyjęcia', type: 'date' },
      ]}
      options={{
        filtering: false,
        selection: true,
        // detailPanelColumnAlignment: 'right',
        // detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations
              .filter(
                (f) =>
                  ![
                    '*archiwum.obiekt.dodaj-do-spisu',
                    '*archiwum.obiekt.spis-brakowania.utworz',
                  ].includes(f)
              )
              .map((x) => ({
                data,
                command: x,
                label: getLabel(x),
                onClick: () => handleActionClick(x, data),
              })),
          });
        }
      }}
      // detailPanel={[
      //   (rowData) => ({
      //     visible: true,

      //     tooltip: 'Lista dokumentów',
      //     icon: () => (
      //       <FontAwesomeIcon
      //         style={{ width: '1.6rem', height: '1.6rem' }}
      //         icon={faAngleRight}
      //       />
      //     ),
      //     render: (rowData) => (
      //       <RenderDetailPanelForDocuments
      //         parentTableRef={props.tableRef}
      //         idPismo={rowData.id}
      //       />
      //     ),
      //   }),
      // ]}
      // onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
};
const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, data),
  });
};
const getLabel = (command: string): string => {
  switch (command) {
    case '*przenies-do-folderu.zarchiwizowane':
      return 'Archiwizuj';
    case '*zadanie-do-poprawy.utworz':
      return 'Przekaż do poprawy';
    case 'archiwum.obiekt.historia':
      return 'Historia';
    case '*archiwum.obiekt.dodaj-do-spisu':
      return 'Dodaj do spisu brakowania';
    default:
      return command;
  }
};

const getComponent = (command: string, data) => {
  const DodajDoSpisuBrakowania = lazy(() =>
    import(
      '../../context-actions/dodaj-do-spisu-brakowania/dodaj-do-spisu-brakowania'
    ).then((module) => ({
      default: module.DodajDoSpisuBrakowania,
    }))
  );
  const Historia = lazy(() =>
    import('@archiwum/features/archiving-documents').then((module) => ({
      default: module.Historia,
    }))
  );
  switch (command) {
    case 'archiwum.obiekt.historia':
      return <Historia typObiekt={TypObiekt.PISMA_NAS} />;
    case '*archiwum.obiekt.dodaj-do-spisu':
      return <DodajDoSpisuBrakowania typObiekt={TypObiekt.PISMA_NAS} />;

    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
