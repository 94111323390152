import { ReactNode } from 'react';
import { Box, Divider, Paper } from '@material-ui/core';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { useStyles } from './chronological-compositions.styles';

type ChronologicalCompositionsLayoutProps = {
  children?: ReactNode;
};

export const ChronologicalCompositionsLayout = ({
  children,
}: ChronologicalCompositionsLayoutProps) => {
  const classes = useStyles();
  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            Skład chronologiczny archiwalny
          </Typography>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex" height="100%" justifyContent="space-between">
        {children}
      </Box>
    </div>
  );
};
