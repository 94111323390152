import { Paper } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';
import { useStyles } from '../case.styles';

interface CaseSectionProps {
  id?: string;
}

export const CaseSection: React.FunctionComponent<CaseSectionProps> = ({
  id,
  children,
}) => {
  const classes = useStyles();
  return (
    <Paper
      id={id}
      classes={{ root: classes.caseSection, elevation23: classes.elevation23 }}
      elevation={23}
    >
      {children}
    </Paper>
  );
};
