import { BehaviorSubject } from 'rxjs';
import {
  useBehaviorSubjectDispatch,
  useBehaviorSubjectSelector,
} from '@czaplej/use-behavior-subject';

type Selected<T> = {
  data: T[];
};
const selectedStore$ = new BehaviorSubject<Selected<unknown>>({
  data: [],
});
export const useSelectedSelector = <TState extends Selected<TState>, TSelected>(
  selector: (state: TState) => TSelected
) => useBehaviorSubjectSelector(selector, selectedStore$);
export const useSelectedDispatch = () =>
  useBehaviorSubjectDispatch(selectedStore$);
