import { useSubject } from '@czaplej/use-subject';
import { debounceTime } from 'rxjs/operators';

export type UseFilteringProps<T> = {
  initialState: T;
};
export const useFiltering = <T>(props: UseFilteringProps<T>) => {
  const { state, setState } = useSubject<T>({
    initialState: props.initialState,
    pipe: (subject) => subject.pipe(debounceTime(100)),
  });
  return {
    state,
    setState,
  };
};
