import styled from 'styled-components';
import {
  Checkbox,
  CheckboxProps,
  Radio as MaterialRadio,
  RadioProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';
import { useStyles } from '../../data-list.style';
import { RowModel } from '../../list-state';
import { useDataListContext } from '../../util/context';
import { RowActionsButton } from './row-actions-button';

const Radio: FunctionComponent<RadioProps> = (props) => {
  return <MaterialRadio {...props} />;
};

export const DataListBodyRow: <T>(props: RowModel<T>) => JSX.Element = (
  props
) => {
  const classes = useStyles();
  const { dispatch, store } = useDataListContext();
  const checkVisible = store.options?.selection && !props.disableCheckbox;
  const actionsVisible =
    store.options?.showActionsButton && !props.disableActionsButton;
  const gridTemplateColumns = `${
    store.options.preserveUnusedSpace || checkVisible ? '6rem ' : ' '
  }${store.options.preserveUnusedSpace || actionsVisible ? '6rem ' : ' '}${
    store.options.templateColumns ?? 'auto'
  }`;

  return (
    <div className={classes.bodyRow} style={{ gridTemplateColumns }}>
      {store.options.selection &&
        (store.options.selectionType === 'radio' ? (
          <Radio
            checked={props.checked}
            children={store.renderBody(props.rowData)}
          />
        ) : (
          <DataCheckbox
            hidden={props.disableCheckbox}
            checked={props.checked}
            disabled={props.disableCheckbox}
          />
        ))}

      {store.options.showActionsButton && !props.disableActionsButton && (
        <RowActionsButton<typeof props.rowData>
          rowData={props.rowData}
          //getMenuItems={null}
        />
      )}

      {store.renderBody(props.rowData)}
    </div>
  );
};

// const row: DataListBodyRow<Model> =
const DataCheckbox = styled((props: CheckboxProps) => <Checkbox {...props} />)<{
  hidden?: boolean;
}>`
  .MuiFormControlLabel-root {
    margin: 0;
  }
  &&& {
    opacity: ${(props) => (props.hidden ? 0 : 1)};
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }
`;
