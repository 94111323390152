import { ArchivingDocumentsServiceCommand } from '../../../services/archiving-documents-service-command';
import { parseJSON, stringifyJSON, useApi } from '@nask/hooks';
import * as yup from 'yup';
import {
  FolderArchiwum,
  PoczekalniaModel,
  TypObiekt,
} from '../../../models/models';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { getErrorResponse } from '@archiwum/utility/helper';
import { actionsService$ } from '@archiwum/actions-handler';
import { useDrawerRightSelector } from '@archiwum/drawer-right';

export type ArchiwizujFormModel = {
  od: string;
  do: string;
  numery: string;
  selected: string;
  komentarz: string;
};

export type UseArchiwizujFacade = {
  typObiekt: TypObiekt;
};

export const useArchiwizujFacade = ({ typObiekt }: UseArchiwizujFacade) => {
  const [showSnackbar] = useSnackbar();
  const selected = useDrawerRightSelector(
    (state) => state.data
  ) as PoczekalniaModel[];
  const { isLoading, setUrl } = useApi({
    onSuccess: () => {
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
      actionsService$.refresh();
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });
  const initialValues: ArchiwizujFormModel = {
    od: '',
    do: '',
    selected: stringifyJSON(selected),
    numery: '',
    komentarz: '',
  };
  const validationSchema: yup.SchemaOf<ArchiwizujFormModel> = yup
    .object()
    .shape({
      do: yup.string(),
      od: yup.string(),
      numery: yup.string(),
      selected: yup.string().required('Wybierz dokument'),
      komentarz: yup.string(),
    });

  const onSubmit = (values: ArchiwizujFormModel) => {
    setUrl(
      ArchivingDocumentsServiceCommand.Archiving({
        listaIdObiekt: parseJSON<PoczekalniaModel[]>(values.selected).map(
          (x) => x.id
        ),
        typObiekt,
        folderDocelowy: FolderArchiwum.Zarchiwizowane,
        komentarz: values.komentarz,
      })
    );
  };
  return {
    formikProps: {
      initialValues,
      validationSchema,
      onSubmit,
    },
    isLoading,
  };
};
