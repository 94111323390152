import React, { FC, useEffect } from 'react';
import { Box, Divider, Paper } from '@material-ui/core';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { useStyles } from './spis-layout.styles';

export const SpisNASLayout: FC = (props) => {
  const { children } = props;
  const drawerDispatch = useDrawerRightDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      drawerDispatch({ selected: [], data: [] });
    };
  }, []);

  return (
    <div>
      <Box>
        <Paper square elevation={0}>
          <Typography
            classes={{ root: classes.header }}
            variantType={TypographyVariants.Header2}
          >
            Spis pism niestanowiących akt spraw
          </Typography>
          <Divider />
        </Paper>
      </Box>
      <Box display="flex" height="100%" justifyContent="space-between">
        {children}
      </Box>
    </div>
  );
};
