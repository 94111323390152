export type GetListaPaczekArchiwalnychRequestModel = {};

export type GetListaSprawArchiwalnychRequestModel = {
  czyWszystkie: boolean;
};

export type GetObiektyPaczkiArchiwalnejRequestModel = {
  typObiektu: TypObiekt;
  idZestawPaczkiArchiwalnej: string;
};

export enum TypObiekt {
  SPRAWY = 1,
  PISMA_NAS,
}

export type UzytkownikModel = {
  id: string;
  email: string;
  imie: string;
  nazwisko: string;
  stanowisko: string;
  idStanowiska: string;
  pelnaNazwa: string;
  imieNazwisko: string;
};

export type PracownikProwadzacy = {
  imie: string;
  nazwisko: string;
  nazwa: string;
  idStanowisko: string;
  czyUsuniety: boolean;
  czyAktywny: boolean;
};

export type PaczkaArchiwalna = {
  id: string;
  typObiektu: TypObiekt;
  znakSprawy: string;
  nazwa: string;
  opis: string;
  idUzytkownikTworca: string;
  dataUtworzenia: string;
  uzytkownikTworca: UzytkownikModel;
  listaDostepnychOperacji: string[];
  status: string;
  wielkoscPaczki: string;
  liczbaPorzadkowa: string;
};

export type ObiektPaczkiArchiwalnej = {
  id: string;
  idObiekt: string;
  typObiektu: TypObiekt;
  typProwadzenia: TypProwadzenia;
  sygnatura: string;
  nazwa: string;
  jrwa: string;
  dataZakonczenia: string;
};

export type SprawaArchiwalna = {
  id: string;
  nazwa: string;
  liczbaPorzadkowa: number;
  znak: string;
  dataZakonczenia: string;
  pracownikProwadzacy: PracownikProwadzacy;
  symbolJRWA: string;
  tytul: string;
  kategoriaArchiwalna: string;
  komorkaOrganizacyjna: string;
  listaDostepnychOperacji: string[];
  typProwadzenia: TypProwadzenia;
  czyWPaczceArchiwalnej: boolean;
};

export enum ElementPaczki {
  WNIOSEK = 1,
  SPIS_ZDAWCZO_ZBIORCZY,
  PACZKA_TAR,
  PLIK_SHA256,
  PLIK_JRWA,
}
type Model = {
  elementPaczki: ElementPaczki;
  idZestawPaczkiArchiwalnej: string | string[];
};
export type GenerujSpisZdawczoZbiorczyRequestModel = Model;

export type PobierzPaczkeRequestModel = Model;

export type GenerujWniosekDoApRequestModel = Model;

export enum PropertyValueType {
  STRING = 'System.String',
  INT = 'System.Int32',
  DATE = 'System.DateTime',
}

export enum PropertyFilterType {
  GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
  LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
  EQUALS = 'Equals',
}

export enum TypProwadzenia {
  PAPIEROWE = 1,
  ELEKTRONICZNE = 2,
}

export type LabelValue = {
  label: string;
  value: string;
};

export type TypProwadzeniaLabelValue = {
  label: string;
  value: TypProwadzenia;
};

export type GetRequestFiltry = {};

export type SprawyFiltry = {
  komorkiOrganizacyjne: Filters;
  prowadzacy: Filters;
  symboleJRWA: Filters;
  kategorieArchiwalne: Filters;
  typProwadzenia: Filters<TypProwadzeniaLabelValue>;
  dataZakonczeniaOd: Filters<LabelValue>;
  dataZakonczeniaDo: Filters<LabelValue>;
  dataPrzyjeciaOd: Filters<LabelValue>;
  dataPrzyjeciaDo: Filters<LabelValue>;
};

export type Filters<T = string> = {
  dostepneWartosci?: T[];
  propertyValueType: PropertyValueType;
  propertyColumn: string;
  propertyFilterType: PropertyFilterType;
};

export type GenerujPaczkeArchiwalnaRequestModel = {
  typObiekt: TypObiekt;
  nazwa: string;
  opis: string;
  listaIdObiekt: string[];
  znakSprawy?: string;
  idWykaz?: string;
  kategoriaArchiwalna?: string;
};

export type UsunPaczkeArchiwalnaRequestModel = {
  idZestawPaczkiArchiwalnej: string;
};

export type EdytujPaczkeArchiwalnaRequestModel = {
  idZestawPaczkiArchiwalnej: string;
  nowaNazwa: string;
  nowyOpis: string;
};

export type KategoriaArchiwalna = {
  id: string;
  katArch: string;
  idWykaz?: string;
  czyGlowna: boolean;
};

export type KlasaJRWADoAP = {
  id: string;
  symbol: string;
  nazwa: string;
  czyNieStanowiAktSprawy: boolean;
  opis: string;
  typProwadzenia: number;
  liczbaDni: number;
  wykazLiczba: number;
  katArchLiczba: number;
  czyMoznaTworzycSprawyZUdostepnionegoPisma: boolean;
  czyMoznaTworzycSprawyDoBrakowania: boolean;
  czyMoznaTworzycSprawyDoPrzekazywaniaDokumentacjiDoAP: boolean;
  kategorieArchiwalne: KategoriaArchiwalna[];
};

export type GetListaKlasJRWADoAPRequestModel = {
  searchValue: string;
};

export type GetListaKlasJRWADoAPResponseModel = {
  lista: KlasaJRWADoAP[];
};
