import { InputAdornment, TextField } from '@material-ui/core';
import { memo, useState } from 'react';
import { Wyszukiwanie012Icon } from '@nask/ezdrp-icons';
import { theme } from '@nask/theme';

interface SearchInputProps {
  searchText: string;
  searchInputId?: string;
  searchMethod?: (value: string) => void;
  minLengthToSearch?: number;
}
const SearchToolbarInput = ({
  searchMethod,
  searchText,
  minLengthToSearch,
  searchInputId,
  ...props
}: SearchInputProps) => {
  let typingTimer;
  const searchTimeout = 400;
  const [searchValue, setSearchValue] = useState<string>(null);
  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    typingTimer = setTimeout(() => {
      if (value != null) {
        if (
          minLengthToSearch == null ||
          value === '' ||
          value.length >= minLengthToSearch
        ) {
          searchMethod(value);
        }
      }
    }, searchTimeout);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (searchValue != null) {
        if (
          minLengthToSearch == null ||
          searchValue === '' ||
          searchValue.length >= minLengthToSearch
        ) {
          searchMethod(searchValue);
        }
      }
    }
    clearTimeout(typingTimer);
  };

  return (
    <TextField
      defaultValue={searchText}
      // placeholder='Wyszukaj'
      label="Wyszukaj"
      onChange={(e) => onChange(e)}
      onKeyPress={(e) => onKeyPress(e)}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      id={`${searchInputId ?? 'wyszukaj'}`}
      {...props}
      InputProps={{
        id: searchInputId ?? 'wyszukaj',
        endAdornment: (
          <InputAdornment position="end">
            <Wyszukiwanie012Icon
              fontSize={'1.8px'}
              color={theme.typography.descriptionColor}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default memo(SearchToolbarInput);
