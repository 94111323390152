import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FormInput, FormRadio, FormRadioGroup } from '@archiwum/forms';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

import { useStyles } from '../create-spis-do-brakowania.styles';
import { FormStepSpisModel } from './use-step-spis-title-facade';
import { TypSpisuBrakowania } from '../../../../models/models';

type FormMultiProps = {
  komorkiOrganizacyjne: string[];
};

export const FormMulti = ({ komorkiOrganizacyjne }: FormMultiProps) => {
  const classes = useStyles();
  const {
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormStepSpisModel>();

  useEffect(() => {
    if (values.singleOrMultiSpisy === 'single') {
      setFieldValue('spisyDoWygenerowania', [
        {
          komorkaOrganizacyjna: '',
          typSpisu: TypSpisuBrakowania.Zwykly,
          tytul: '',
        },
      ]);
    }
    if (values.singleOrMultiSpisy === 'multi') {
      setFieldValue(
        'spisyDoWygenerowania',
        komorkiOrganizacyjne.map((item) => ({
          komorkaOrganizacyjna: item,
          typSpisu: TypSpisuBrakowania.KomorkaOrganizacyjna,
          tytul: '',
        }))
      );
    }
    setFieldTouched('spisyDoWygenerowania[0].tytul', false);
  }, [values.singleOrMultiSpisy]);

  return (
    <>
      <Paper elevation={0} className={classes.infoCard}>
        <InfoIcon className={classes.infoIcon} />
        <Typography
          className={classes.infoDescription}
          variantType={TypographyVariants.Paragraph}
        >
          Na liście wskazanych spraw znajdują się sprawy z różnych komórek
          organizacyjnych!
        </Typography>
      </Paper>
      <Typography variantType={TypographyVariants.Paragraph}>
        Czy chcesz utworzyć:
      </Typography>
      <FormRadioGroup name="singleOrMultiSpisy">
        <FormRadio
          key={'single'}
          value={'single'}
          label={'Wspólny spis dla wybranych spraw i komórek organizacyjnych'}
        />
        {values.singleOrMultiSpisy === 'single' && (
          <FormInput
            name={`spisyDoWygenerowania[0].tytul`}
            label={'Nazwa spisu brakowania'}
          />
        )}
        <FormRadio
          key={'multi'}
          value={'multi'}
          label={'Oddzielne spisy dla różnych komórek organizacyjnych'}
        />
        {values.singleOrMultiSpisy === 'multi' &&
          komorkiOrganizacyjne.map((item, index) => (
            <Box pb={3}>
              <Box pb={2}>
                <Typography variantType={TypographyVariants.Paragraph}>
                  Komórka organizacyjna {item}
                </Typography>
              </Box>
              <FormInput
                name={`spisyDoWygenerowania[${index}].tytul`}
                label={'Nazwa spisu brakowania'}
              />
            </Box>
          ))}
      </FormRadioGroup>
    </>
  );
};
