import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    header1: {
      fontSize: theme.typography.header1FontSize,
      color: theme.typography.headerColor,
    },
    header2: {
      fontSize: theme.typography.header2FontSize,
      color: theme.typography.headerColor,
    },
    header3: {
      fontSize: theme.typography.header3FontSize,
      color: theme.typography.headerColor,
    },
    header4: {
      fontSize: theme.typography.header4FontSize,
      color: theme.typography.headerColor,
    },
    header5: {
      fontSize: theme.typography.header5FontSize,
      fontWeight: 'bold',
      color: theme.typography.headerColor,
    },
    paragraph: {
      fontSize: theme.typography.paragraphFontSize,
      color: theme.typography.paragraphColor,
    },
    description: {
      fontSize: theme.typography.descriptionFontSize,
      color: theme.typography.descriptionColor,
    },
    default: {
      fontSize: theme.typography.descriptionFontSize,
      lineHeight: 1,
    },
  })
);
