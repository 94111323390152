import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ transitions }) =>
  createStyles({
    root: {},
    drawerOpen: {
      transition: transitions.create('width', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.complex,
      }),
    },
    drawerClose: {
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    listItemText: {},
  })
);
