import React, { ReactNode } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { SelectModel } from '@nask/hooks';
import { useStyles } from '../../case.styles';
import { LabelValueRow } from './label-value-row';
import { getShortDate } from 'libs/shared/table/src/lib/hooks/methods';

type AdditionalInfoProps = {
  status;
  nadawca?: string;
  pozostaliNadawcy?: string[] | (() => string[]);
  dataWszczecia?: string;
  proper2ndDate?: SelectModel;
};

export const AdditionalInfo: React.FunctionComponent<AdditionalInfoProps> = ({
  status,
  nadawca,
  dataWszczecia,
  proper2ndDate,
  children,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs zeroMinWidth>
      <Paper elevation={23} className={classes.elevation23}>
        <Box px={3} p={0}>
          {children}
        </Box>
        <Box px={3} py={3}>
          <Box pb={2}>
            <LabelValueRow label="Nadawca:" value={nadawca} />
          </Box>
          <LabelValueRow
            label="Data wszczęcia:"
            value={dataWszczecia ? getShortDate(dataWszczecia) : ''}
          />
          <LabelValueRow
            label={proper2ndDate.label}
            value={proper2ndDate.value ? getShortDate(proper2ndDate.value) : ''}
          />
        </Box>
      </Paper>
    </Grid>
  );
};
