import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Box } from '@material-ui/core';
import { Timer } from '@material-ui/icons';
import { useStyles } from '../views/procesy-brakowania.styles';
import clsx from 'clsx';

type RowCounterProps = { remainingTime: string };
export const RowCounter = ({ remainingTime }: RowCounterProps) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} gridColumnGap={'1rem'}>
      <Timer
        className={clsx(classes.counterValue, classes.smallerCounterIcon)}
      />
      <Typography
        className={classes.counterValue}
        variantType={TypographyVariants.Paragraph}
      >
        {remainingTime}
      </Typography>
    </Box>
  );
};
