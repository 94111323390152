import { FormLabel, RadioGroup, RadioGroupProps } from '@material-ui/core';
import { useDeepMemo } from '@nask/hooks';
import { useField } from 'formik';
import React, { memo } from 'react';

import { ErrorContainer } from '../styles';

type FormRadioProps = RadioGroupProps & {
  name: string;
  label?: string;
  onChange?: (value) => void;
};

const FormRadioGroup = memo((props: FormRadioProps) => {
  const { name, label, onChange, ...rest } = props;
  const [field, meta, helper] = useField(name);

  return useDeepMemo(
    () => (
      <>
        <RadioGroup
          name={name}
          value={field.value}
          onChange={(event1, val) => {
            helper.setValue(val);
            if (onChange) onChange(event1);
          }}
          {...rest}
        >
          {label && <FormLabel component="legend">{label}</FormLabel>}
          {props.children}
        </RadioGroup>
        {meta.error && meta.touched && (
          <ErrorContainer>{meta.error}</ErrorContainer>
        )}
      </>
    ),
    [rest, meta, field.value, name, label, props.children]
  );
});

export default FormRadioGroup;
