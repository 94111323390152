import React from 'react';

import { OptionsObject } from '../interface';
import { SnackbarContext } from './snackbar-context';
import { useGetSettings } from '@archiwum/shared/settings';

/**
 * Callback types provided for descriptive type-hints
 */
type ShowSnackbar = (
  message: string | string[],
  options: OptionsObject
) => void;

/**
 * React hook for showing snackbar windows
 */
const useSnackbar = (): [ShowSnackbar] => {
  const { settings } = useGetSettings();
  const context = React.useContext(SnackbarContext);

  const showSnackbar = React.useCallback(
    (message: string | string[], options: OptionsObject) => {
      if (options.variant === 'success') {
        if (settings.potwierdzeniePrawidlowejOperacji) {
          context.showSnackbar(message, options);
        }
      } else {
        context.showSnackbar(message, options);
      }
    },
    []
  );

  return [showSnackbar];
};
export default useSnackbar;
