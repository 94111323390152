import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TableRef } from '@archiwum/shared/table';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  StatusSpisuBrakowania,
  StatusSpisuBrakowaniaLabelValue,
  TypProwadzenia,
  TypProwadzeniaLabelValue,
  TypObiekt,
  TypObiektowWSpisieLabelValue,
  TypSpisuBrakowania,
  TypSpisuBrakowaniaLabelValue,
} from '../../models/models';
import { useRefresh } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { getParams } from '../../helper';
import { BrakowanieServiceQuery } from '../../services/brakowanie-service-query';
import { useFiltering } from '@archiwum/hooks';
import {
  getCeilingDate,
  getFloorDate,
} from 'libs/shared/table/src/lib/hooks/methods';

const tabs = [
  {
    label: 'Sprawy',
    value: 'sprawy',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
  },
];

export type SpisyFilters = {
  status: StatusSpisuBrakowania | null;
  typObiektowWSpisie: TypObiekt | null;
  typProwadzenia: TypProwadzenia | null;
  typSpisuBrakowania: TypSpisuBrakowania | null;
  dataUtworzeniaOd: string | null;
  dataUtworzeniaDo: string | null;
};

const initialState: SpisyFilters = {
  status: null,
  typObiektowWSpisie: null,
  typProwadzenia: null,
  typSpisuBrakowania: null,
  dataUtworzeniaOd: null,
  dataUtworzeniaDo: null,
};

export const useSpisyBrakowaniaFacade = () => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef<TableRef>();
  const dispatch = useDrawerRightDispatch();
  const search = location.search;
  const rodzaj = new URLSearchParams(search).get('rodzaj');
  const isSprawy = !rodzaj ? true : rodzaj?.toLowerCase() === 'sprawy';

  const { data } = useApi({
    service: BrakowanieServiceQuery.GetSpisyBrakowaniaFiltry(),
  });

  const { state, setState } = useFiltering<SpisyFilters>({
    initialState: initialState,
  });

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  const [activeTab, setActiveTab] = useState(() => {
    const rodzaj = getParams(location.search);
    return (rodzaj as string) ?? tabs[0].value;
  });

  const handleRefresh = () => {
    tableRef.current?.onQueryChange?.();
    dispatch({ selected: [], data: [] });
  };

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    const rodzaj = getParams(location.search);
    if (rodzaj) {
      setActiveTab(rodzaj as string);
    }
  }, [location.search]);

  useEffect(() => {
    setState({
      ...initialState,
      typObiektowWSpisie: isSprawy ? TypObiekt.SPRAWY : TypObiekt.PISMA_NAS,
    });
  }, [activeTab]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue, state]);

  const handleChangeTab = (event: ChangeEvent<unknown>, value: string) => {
    history.push({ search: `?rodzaj=${value}` });
    setActiveTab(value);
  };

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?:
      | SelectModel
      | TypProwadzeniaLabelValue
      | StatusSpisuBrakowaniaLabelValue
      | TypObiektowWSpisieLabelValue
      | TypSpisuBrakowaniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const statusOptions = data?.status?.dostepneWartosci ?? [];
  const typObiektowWSpisieOptions =
    data?.typObiektowWSpisie?.dostepneWartosci ?? [];
  const typProwadzeniaOptions = data?.typProwadzenia?.dostepneWartosci ?? [];
  const typSpisuBrakowaniaOptions =
    data?.typSpisuBrakowania?.dostepneWartosci ?? [];

  return {
    data,
    state,
    tableRef,
    searchValue,
    statusOptions,
    typObiektowWSpisieOptions,
    typProwadzeniaOptions,
    typSpisuBrakowaniaOptions,
    handleUpdateDate,
    handleUpdateSelect,
    handleChangeTab,
    tabs,
    activeTab,
    onChange,
    isSprawy,
    handleRefresh,
  };
};
