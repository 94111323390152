import React, { forwardRef } from 'react';
import {
  Typography,
  TypographyProps,
  TypographyVariants,
} from '@archiwum/ui/typography';

type HeaderProps = TypographyProps;

export const ActionHeader = forwardRef<HTMLDivElement, HeaderProps>(
  (props, ref) => {
    return (
      <Typography
        variant={'h4'}
        variantType={TypographyVariants.Header4}
        ref={ref}
        {...props}
      />
    );
  }
);
