import React, { MutableRefObject, useEffect } from 'react';
import { Observable } from 'rxjs';
import styled from 'styled-components';

import { DataListBody } from './body/view/data-list-body';
import { Header } from './header/view/header';
import {
  DataListState,
  OptionsModel,
  PaginationModel,
  RowModel,
} from './list-state';
import { DataListLoader } from './loader/view/data-list-loader';
import { Pagination } from './pagination/view/pagination';
import { useDataListContext } from './util/context';
import { QueryViewRequestBase } from '@archiwum/shared/table';

export type DataListProps<T extends unknown> = Pick<
  DataListState<T>,
  'additionalHeaderIcon' | 'selectionFilter' | 'renderBody'
> & {
  title?: string;
  data: ((query: QueryViewRequestBase<unknown>) => Observable<any> | T[]) | T[];
  onSelect?: (selectedData: RowModel<T | unknown>[]) => void;
  listContext?: MutableRefObject<DataListState<unknown>>;
  isActionsButtonVisible?: (data: T) => boolean;
  options?: Partial<OptionsModel & { pagination: Partial<PaginationModel> }>;
};

export const DataList = <T extends unknown>({
  title,
  data,
  onSelect,
  listContext,
}: //columnsInfo,
DataListProps<T>) => {
  const { dispatch, store } = useDataListContext();

  useEffect(() => {
    dispatch({
      type: 'SET_INITIAL_STORE',
      payload: { title: title /*, columnsInfo */ },
    });
  }, [title]);

  useEffect(() => {
    if (typeof data === 'function') {
      store.onQueryChange(
        data({
          pageInfo: store.pagination,
          searchValue: store.searchQuery,
          sort: store.sorting,
          filtersConjunction: [],
        }) as Observable<any>
      );
    } else if (typeof data === 'object') {
      dispatch({ type: 'UPDATE_DATA', payload: data });
    }
  }, [
    store.searchQuery,
    store.pagination.pageSize,
    store.pagination.pageNumber,
    store.sorting,
    store.refresh,
  ]);

  useEffect(() => {
    if (onSelect) {
      onSelect(store.selected);
    }
  }, [store.selected]);

  useEffect(() => {
    if (listContext) {
      listContext.current = store;
    }
  }, [store]);

  return (
    <LoaderWrapper>
      <DataListLoader>
        <Container>
          <Header />
          <DataListBody />
          <Pagination />
        </Container>
      </DataListLoader>
    </LoaderWrapper>
  );
};

const Container = styled.div`
  display: grid;
  z-index: 99;
  grid-template-areas: 'header' 'body' 'pagination';
`;
const LoaderWrapper = styled.div`
  display: grid;
  grid-template-areas: 'loader';
`;
