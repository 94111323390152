import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { Routes } from '@archiwum/routes';

type TabModel = {
  label: string;
  path: string;
};

const tabs = [
  {
    label: 'Zamów raport',
    path: Routes.RaportyZamawianieRaportow.path,
  },
  {
    label: 'Lista zamówionych raportów',
    path: Routes.RaportyListaZamowionychRaportow.path,
  },
];

export const useReportsFacade = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (
      matchPath(history.location.pathname, {
        path: Routes.Raporty.path,
        exact: true,
      }) !== null
    ) {
      history.replace(Routes.RaportyZamawianieRaportow.path);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    tabs.map((tab) => {
      if (matchPath(location.pathname, { path: tab.path, exact: true })) {
        setActiveTab(tab);
      }
    });
  }, [location.pathname]);

  const handleChangeTab = (_: ChangeEvent<unknown>, value: TabModel) => {
    history.push(value.path);
    setActiveTab(value);
  };

  return {
    tabs,
    activeTab,
    handleChangeTab,
  };
};
