import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) => {
  const additionalColor = '#2a9d8f';
  return createStyles({
    historyContainer: {
      display: 'grid',
      marginTop: spacing(1),
    },
    indexAvatar: {
      width: spacing(4),
      height: spacing(4),
      borderWidth: '2px',
      borderStyle: 'solid',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      '& > p': { color: additionalColor, fontSize: spacing(2) },
    },
    historyRow: {
      display: 'grid',
      gridTemplateColumns: '8rem 6rem auto',
      marginTop: spacing(1),
    },
    historyRowIndent: {
      gridColumnStart: 1,
      height: '100%',
      marginTop: spacing(0.5),
      marginRight: spacing(-2),
      marginBottom: spacing(-2),
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: additionalColor,
      gridColumnEnd: 2,
    },
    historyRowInfo: {
      marginTop: spacing(1),
      marginLeft: spacing(3),
      padding: spacing(1),
      gridColumnStart: 2,
      gridColumnEnd: -1,
      display: 'grid',
      gridTemplateColumns: '7rem auto',
      gridGap: spacing(1),
      borderRadius: theme.general.borderRadius,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme.colors.borderColor,
    },
    theGreenBorderColor: {
      borderColor: additionalColor,
    },
    theGreenColor: {
      color: additionalColor,
    },
    infoLabel: { justifySelf: 'right' },
    infoField: { fontWeight: 'bold' },
    topInfoContainer: {
      display: 'flex',
      backgroundColor: theme.sidebar.hover,
      borderRadius: theme.general.borderRadius,
      padding: spacing(1, 2),
    },
  });
});
