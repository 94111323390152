import React, { FC } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { useStyles } from '../../case.styles';
import { CaseSection } from '../case-section';

export const CaseInfoSection: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <CaseSection id="case-info-section">
      <Box px={2} pt={2} mt={2}>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {children}
        </Grid>
        <Divider />
      </Box>
    </CaseSection>
  );
};
