import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    separator: {
      '& button:nth-of-type(n)': {
        // borderRight: '1px solid',
      },

      '& button:last-of-type': {
        borderRight: 'none',
      },
    },
  })
);
