import { IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useDeepMemo, useFormInput } from '@nask/hooks';
import React, { useRef } from 'react';

import { InputContainer } from '../styles';
import { Input, InputProps } from '@archiwum/shared/input';

type FormInputProps<V> = Omit<InputProps, 'onBlur'> & {
  depend?: string;
  isClearable?: boolean;
  multiline?: boolean;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: (value: string | number) => void
  ) => void;
};
export const FormInput = <V extends unknown>({
  ...props
}: FormInputProps<V>) => {
  const { onBlur, variant, multiline, ...rest } = props;
  const inputRef = useRef(null);
  const {
    field,
    meta,
    state,
    context,
    helper: { setValue, setTouched },
  } = useFormInput<string>({ name: props.name });

  const { onChange: fieldOnChange, onBlur: fieldOnBlur, ...restField } = field;
  return useDeepMemo(
    () => (
      <InputContainer>
        <Input
          id={props.name}
          inputRef={inputRef}
          InputProps={{
            endAdornment: field.value !== '' && props.isClearable && (
              <InputAdornment position="end">
                <>
                  {field.value !== '' && props.isClearable && (
                    <IconButton
                      size={'small'}
                      onClick={(e) => {
                        setValue('');
                        setTouched(true);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...restField}
          multiline={multiline}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            // setTouched(true);
          }}
          {...state}
          value={field.value}
          {...rest}
          variant={variant ?? 'outlined'}
          onBlur={(event) => {
            if (context.validateOnBlur) setTouched(true);
            if (onBlur) {
              onBlur(event, setValue);
            }
          }}
        />
      </InputContainer>
    ),
    [rest, restField, state, context.validateOnBlur]
  );
};

export default FormInput;
