import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) => {
  return createStyles({
    infoContainer: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto',
      gridGap: spacing(2.5),
      alignItems: 'center',
      padding: spacing(2.5),
      boxSizing: 'border-box',
      borderRadius: spacing(0.5),
      border: `1px solid ${theme.colors.gray}`,
    },
    icon: { fontSize: spacing(3.6), color: theme.colors.danger },
    infoRegular: { color: theme.typography.paragraphColor },
    infoDanger: { color: theme.colors.danger },
  });
});
