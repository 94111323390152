import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g id="Group_609" data-name="Group 609" transform="translate(-675 -2951)">
        <g id="Group_568" data-name="Group 568" transform="translate(675 2951)">
          <g
            id="Ellipse_139"
            data-name="Ellipse 139"
            fill="#f9f9f9"
            stroke="#f0f0f0"
            strokeWidth="2"
          >
            <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
            <circle cx="14.5" cy="14.5" r="13.5" fill="none" />
          </g>
          <path
            id="Icon_awesome-check"
            data-name="Icon awesome-check"
            d="M4.782,14.871.206,10.295a.7.7,0,0,1,0-1l1-1a.7.7,0,0,1,1,0L5.28,11.386l6.6-6.6a.7.7,0,0,1,1,0l1,1a.7.7,0,0,1,0,1l-8.1,8.1A.7.7,0,0,1,4.782,14.871Z"
            transform="translate(8 4.673)"
            fill="#5dd47f"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export const UnCheckedIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g id="Group_651" data-name="Group 651" transform="translate(-675 -2951)">
        <g id="Group_568" data-name="Group 568" transform="translate(675 2951)">
          <g
            id="Ellipse_139"
            data-name="Ellipse 139"
            fill="#f9f9f9"
            stroke="#aca7b8"
            strokeWidth="1"
            strokeDasharray="3"
          >
            <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
            <circle cx="14.5" cy="14.5" r="14" fill="none" />
          </g>
          <path
            id="Icon_ionic-md-add"
            data-name="Icon ionic-md-add"
            d="M17.945,13.094H13.094v4.851H11.6V13.094H6.75V11.6H11.6V6.75h1.493V11.6h4.851Z"
            transform="translate(1.792 1.792)"
            fill="#aca7b8"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export const CloseIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25.721"
      height="25.721"
      viewBox="0 0 25.721 25.721"
    >
      <path
        id="Icon_awesome-times-circle"
        data-name="Icon awesome-times-circle"
        d="M13.423.563a12.861,12.861,0,0,0,0,25.721,13,13,0,0,0,9.094-3.766,12.644,12.644,0,0,0,3.766-9.094A12.858,12.858,0,0,0,13.423.563ZM19.729,16.8a.623.623,0,0,1,0,.882l-2.054,2.048a.623.623,0,0,1-.882,0l-3.371-3.4-3.376,3.4a.623.623,0,0,1-.882,0L7.117,17.675a.623.623,0,0,1,0-.882l3.4-3.371-3.4-3.376a.623.623,0,0,1,0-.882L9.171,7.112a.623.623,0,0,1,.882,0l3.371,3.407,3.376-3.4a.623.623,0,0,1,.882,0l2.054,2.054a.623.623,0,0,1,0,.882l-3.407,3.371Z"
        transform="translate(-0.563 -0.563)"
        fill="#f9f9f9"
      />
    </SvgIcon>
  );
};
