import { useRouteMatch } from 'react-router';
import { Box, Container, Divider, IconButton, Paper } from '@material-ui/core';
import {
  ArchivingLayout,
  FolderArchiwum,
  TypProwadzeniaLabelValue,
} from '@archiwum/features/archiving-documents';
import { SelectModel } from '@nask/hooks';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';
import { Tab, Tabs } from '@archiwum/ui/tabs';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { Typography } from '@archiwum/ui/typography';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { SearchInput } from '@archiwum/shared/search-input';
import {
  getMaxDateOfTheYear,
  getMinDateOfTheYear,
} from '@archiwum/shared/table';

import { PismaNasTable } from '../../poczekalnia/components/pisma-nas-table';
import { useStyles } from './zarchiwizowane.styles';
import { usePoczekalniaFacade } from '../../hooks/use-poczekalnia-facade';
import { useSelectedDispatch } from '../../hooks/use-selected';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';
import { SprawyTable } from '../../poczekalnia/components/sprawy-table';
import {
  getFilters,
  getPoczekalniaNASQuery,
  getPoczekalniaSprawyQuery,
} from '../../helper';

export const Zarchiwizowane = () => {
  const {
    onChange,
    tableRef,
    state,
    data,
    handleUpdateSelect,
    handleUpdateDate,
    handleUpdateYear,
    typProwadzeniaOptions,
    katArchiwalnaOptions,
    symboleJRWAOptions,
    prowadzacyOptions,
    komorkaOrganizacyjnaOptions,
    minRokZakonczenia,
    maxRokZakonczenia,
    searchValue,
    handleChangeTab,
    activeTab,
    tabs,
  } = usePoczekalniaFacade({ foldery: [FolderArchiwum.Zarchiwizowane] });
  const dispatch = useSelectedDispatch();
  const classes = useStyles();

  const isSprawa = activeTab === tabs[0].value;

  return (
    <ArchivingLayout title="Dokumentacja zarchiwizowana">
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                <Box display="grid" gridAutoFlow="row">
                  <Box p={0.5} m={0.5} justifySelf="flex-end">
                    <SearchInput
                      searchMethod={(phrase: string) => {
                        onChange(phrase);
                      }}
                    />
                  </Box>
                  <Box display="grid" flexWrap="wrap" gridAutoFlow="column">
                    <Box m={0.5} p={0.5}>
                      <Autocomplete
                        label="Komórka organizacyjna"
                        placeholder="Wszystkie"
                        name="komorkaOrganizacyjna"
                        options={komorkaOrganizacyjnaOptions}
                        value={
                          komorkaOrganizacyjnaOptions.find(
                            (option) =>
                              option.value === state.komorkaOrganizacyjna
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'komorkaOrganizacyjna',
                            v as SelectModel
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5}>
                      <Autocomplete
                        label="Kat. archiwalna"
                        placeholder="Wszystkie"
                        name={'kategoriaArchiwalna'}
                        options={katArchiwalnaOptions}
                        value={
                          katArchiwalnaOptions.find(
                            (option) =>
                              option.value === state.kategoriaArchiwalna
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'kategoriaArchiwalna',
                            v as SelectModel
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5}>
                      <Autocomplete
                        label="Typ prowadzenia"
                        placeholder="Wszystkie"
                        name="typProwadzenia"
                        options={typProwadzeniaOptions}
                        value={
                          typProwadzeniaOptions.find(
                            (option) => option.value === state.typProwadzenia
                          ) ?? null
                        }
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <Box
                            display="grid"
                            gridGap={8}
                            gridAutoFlow="column"
                            alignItems="center"
                          >
                            <TypProwadzeniaAvatar
                              typProwadzenia={option.value}
                            />
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        onChange={(e, v) =>
                          handleUpdateSelect(
                            'typProwadzenia',
                            v as TypProwadzeniaLabelValue
                          )
                        }
                      />
                    </Box>
                    <Box m={0.5} p={0.5}>
                      <DatePicker
                        InputProps={{
                          classes: { adornedEnd: classes.datePicker },
                        }}
                        minDate={getMinDateOfTheYear(minRokZakonczenia)}
                        maxDate={getMaxDateOfTheYear(maxRokZakonczenia)}
                        format={'yyyy'}
                        placeholder="Wszystkie"
                        label="Rok zakończenia"
                        name="dataZakonczeniaOd"
                        value={state.dataZakonczeniaOd}
                        views={['year']}
                        onChange={(year) =>
                          handleUpdateYear(year, 'dataZakonczenia')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Tabs
              classes={{ indicator: classes.indicator, root: classes.root }}
              value={activeTab}
              onChange={handleChangeTab}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  classes={{ root: classes.tab, selected: classes.activeTab }}
                />
              ))}
            </Tabs>
            <Divider classes={{ root: classes.borderBottomTab }} />
          </Box>
          <Paper classes={{ root: classes.paperShadowBottom }}>
            {isSprawa ? (
              <SprawyTable
                tableRef={tableRef}
                data={(query, result) => {
                  getPoczekalniaSprawyQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch,
                    [FolderArchiwum.Zarchiwizowane]
                  );
                }}
                hiddenFields={['dataPrzyjecia']}
                disableSelection
              />
            ) : (
              <PismaNasTable
                tableRef={tableRef}
                data={(query, result) => {
                  getPoczekalniaNASQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch,
                    [FolderArchiwum.Zarchiwizowane]
                  );
                }}
                hiddenFields={['dataPrzyjecia']}
                disableSelection
              />
            )}
          </Paper>
        </Container>
      </Box>
    </ArchivingLayout>
  );
};
