import React, { FunctionComponent, ReactNode, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

type MenuAction = {
  caption: string;
};

const ITEM_HEIGHT = 48;

const options = ['option1', 'option2', 'option3'];

type RowActionsButtonProps<T extends unknown> = {
  rowData?: T;
  getMenuItems?: (rowData: T) => MenuAction[];
};

const useLoadMenuItems = () => {};

export const RowActionsButton: <T>(
  data: RowActionsButtonProps<T>
) => JSX.Element = (data) => {
  const { ...rest } = data;

  return (
    <div {...rest}>
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};
