import { Paper } from '@material-ui/core';
import { useStyles } from './exclamation-info-container.style';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { Ostrzezenie084Icon } from '@nask/ezdrp-icons';

type InfoType = 'info' | 'danger';
type ExclamationInfoContainerProps = {
  contentInfo: string | React.ReactNode;
  infoType?: InfoType;
};

export const ExclamationInfoContainer = ({
  contentInfo,
  infoType,
}: ExclamationInfoContainerProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.infoContainer} elevation={0}>
      <Ostrzezenie084Icon className={classes.icon} />
      <Typography
        className={
          infoType === 'info' ? classes.infoRegular : classes.infoDanger
        }
        variantType={TypographyVariants.Description}
      >
        {contentInfo}
      </Typography>
    </Paper>
  );
};
