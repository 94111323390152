import { TablePagination } from '@material-ui/core';
import { MTableBody, MTableBodyRow, Query, QueryResult } from 'material-table';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';
import styled from 'styled-components';

import TableBase, { TableBaseProps } from './table-base';
import {
  ColumnDto,
  FilteringModel,
  PropType,
  SortingModel,
} from './table-model';
import Toolbar from './toolbar';

export interface OwnQuery extends Query<any> {
  sort: SortingModel[];
  filtry: FilteringModel[];
  searchBy: string;
}
export interface SubjectServiceModel {
  onSuccess: (query: ResultOwnQuery) => void;
  onError: () => void;
}
export type ResultOwnQuery = QueryResult<any> & {
  columnsInfo?: ColumnDto[];
};

export type SelectedRow<T extends {}> = T & {
  id: string;
};

export interface TableProps<T extends object> extends TableBaseProps<T> {
  refreshButton?: boolean;
  disableToolbar?: boolean;
  filterColumn?: boolean;
  toolbarComponent?: ReactNode;
  selectedRows?: Array<SelectedRow<T>>;
  showedColumns: Array<string | keyof T>;
  hideSearchInput?: boolean;
  disablePagination?: boolean;
  allColumns: PropType<TableBaseProps<T>, 'columns'>;
  error?: boolean;
  searchInputId?: string;
  minLengthToSearch?: number;
  infiniteScroll?: {
    minHeight: string;
  };
  columnsInfo?: ColumnDto[];
}

const WTable = <T extends object>({
  filterColumn,
  allColumns,
  showedColumns,
  error,
  minLengthToSearch,
  columnsInfo,
  ...props
}: TableProps<T>) => {
  const infiniteScrollTableBody = (propsParam) => {
    const rowRenderer = useCallback(
      (props): ListRowRenderer => ({ index, key, style }) => {
        return (
          <div key={key} style={style} className="table-row-wrapper">
            <MTableBodyRow
              key={key}
              index={index}
              data={props.renderData[index]}
              options={props.options}
              onToggleDetailPanel={props.onToggleDetailPanel}
              icons={props.icons}
              actions={props.actions}
              components={props.components}
              columns={props.columns}
              getFieldValue={props.getFieldValue}
              onRowClick={props.onRowClick}
            />
          </div>
        );
      },
      []
    );
    return (
      <AutoSizer>
        {() => (
          <List
            rowCount={propsParam.renderData.length}
            height={propsParam.tableHeight - propsParam.headerHeight}
            width={propsParam.tableWidth}
            rowHeight={propsParam.headerHeight}
            rowRenderer={rowRenderer(propsParam)}
            scrollToIndex={propsParam.scrollIndex}
          />
        )}
      </AutoSizer>
    );
  };

  return useMemo(() => {
    return (
      <TableBase<T>
        {...props}
        localization={{
          body: {
            emptyDataSourceMessage: 'Brak danych do wyświetlenia',
            addTooltip: 'Dodaj',
            deleteTooltip: 'Usuń',
            editTooltip: 'Edytuj',
            filterRow: {
              filterTooltip: 'Filtruj',
            },
            editRow: {
              deleteText: 'Czy na pewno chcesz usunąć ten rząd?',
              cancelTooltip: 'Anuluj',
              saveTooltip: 'Zatwierdź',
            },
          },
          grouping: {
            placeholder: 'Przeciągaj nagłówki..',
          },
          header: {
            actions: 'Akcje',
          },
          pagination: {
            labelDisplayedRows: 'od {from} do {to} z {count} ogółem',
            labelRowsSelect: 'wierszy',
            labelRowsPerPage: 'Wierszy na stronie:',
            firstTooltip: 'Pierwsza strona',
            previousTooltip: 'Poprzednia strona',
            nextTooltip: 'Następna strona',
            lastTooltip: 'Ostatnia strona',
          },
          toolbar: {
            addRemoveColumns: 'Dodaj lub usuń kolumny',
            nRowsSelected: '{0} rzędów zaznaczonych',
            showColumnsTitle: 'Pokaż tytuły kolumn',
            showColumnsAriaLabel: 'Pokaż tytuły kolumn',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',
            exportCSVName: 'Export do .CSV',
            searchTooltip: 'Szukaj',
            searchPlaceholder: 'Szukaj',
          },
        }}
        options={{
          emptyRowsWhenPaging: false,
          debounceInterval: 400,
          ...props.options,
        }}
        components={{
          ...props.components,
          Container: (props1) => {
            return (
              <div {...props1} style={{ ...props1.style, display: 'grid' }} />
            );
          },
          Toolbar: (props1) => {
            // console.log('TOOLBAR PROPS', props1);
            return (
              <>
                {!props.disableToolbar && (
                  <Toolbar<T>
                    columnsInfo={columnsInfo}
                    filterColumn={filterColumn}
                    {...props1}
                    toolbarComponent={props.toolbarComponent}
                    columns={allColumns}
                    showedColumns={showedColumns}
                    hideSearchInput={props.hideSearchInput}
                    searchInputId={props.searchInputId}
                    minLengthToSearch={minLengthToSearch}
                  />
                )}
              </>
            );
          },
          Pagination: (props1) => {
            // console.log(props1);
            // if (props1.count > initialPageSize) {
            return !props.disablePagination && <TablePagination {...props1} />;
            // } else {
            //   return null;
            // }
          },
          Body: (props1) => {
            // console.log('BODY ERRROR', error);
            if (error) {
              return (
                <tbody>
                  <tr>
                    <td colSpan={props1.columns.length}>
                      <StyledSpan>
                        Wystąpił błąd podczas pobierania danych
                        <i className={'fa fa-exclamation-circle'} />
                      </StyledSpan>
                    </td>
                  </tr>
                </tbody>
              );
            } else {
              return props.infiniteScroll ? (
                infiniteScrollTableBody(props1)
              ) : (
                <MTableBody {...props1} />
              );
            }
          },
          Row: (props1) => {
            return useMemo(() => {
              return <MTableBodyRow {...props1} />;
            }, [
              props1.data,
              props1.data.tableData?.checked,
              props1.data.tableData?.showDetailPanel,
              props1.columns,
            ]);
          },
        }}
      />
    );
  }, [props, allColumns, showedColumns, error]);
};

export default WTable;

const StyledSpan = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: inherit;
  padding: 10px 4px;
  color: ${(props) => props.theme.colors.danger};
`;
