import { Axios } from '@nask/axios-observable';
import { map, take } from 'rxjs/operators';
import { getUrl } from '@archiwum/utility/service';
import {
  ArchiwizujWszystkieSprawyRequestModel,
  ArchiwizujWszystkiePismaRequestModel,
  GenerujPaczkeArchiwalnaRequestModel,
  PrzeniesRequestModel,
  ZadaniaDoPoprawyRequestModel,
  ZadanieDoPoprawyPismoNASModel,
  ZadanieDoPoprawySprawaModel,
} from '../models/models';

export const ArchivingDocumentsServiceCommand = {
  Archiving: (request: PrzeniesRequestModel) =>
    Axios.post(getUrl('obiekt/lista/przenies'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  PrzekazDoPoprawySprawa: (
    request: ZadaniaDoPoprawyRequestModel<ZadanieDoPoprawySprawaModel>
  ) =>
    Axios.post(
      getUrl('sprawa/zadania-poprawy/dodaj', '/ezdrp/archiwum'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  PrzekazDoPoprawyPismaNAS: (
    request: ZadaniaDoPoprawyRequestModel<ZadanieDoPoprawyPismoNASModel>
  ) =>
    Axios.post(
      getUrl('pismo-nas/zadania-poprawy/dodaj', '/ezdrp/archiwum'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GenerujPaczkeArchivalna: (request: GenerujPaczkeArchiwalnaRequestModel) =>
    Axios.post(getUrl('paczka-archiwalna/generuj'), request).pipe(
      take(1),
      map((data) => data.data)
    ),
  ArchiwizujWszystkieSprawy: (request: ArchiwizujWszystkieSprawyRequestModel) =>
    Axios.post(
      getUrl('sprawy/gotowe-do-zarchiwizowania/archiwizuj-wszystko'),
      request
    ).pipe(take(1)),
  ArchiwizujWszystkiePisma: (request: ArchiwizujWszystkiePismaRequestModel) =>
    Axios.post(
      getUrl('pisma-nas/gotowe-do-zarchiwizowania/archiwizuj-wszystko'),
      request
    ).pipe(take(1)),
};
