import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { ObiektProcesuBrakowania } from '../../models/models';

type ObiektyProcesuBrakowaniaTableProps = Pick<
  UseTableFacadeProps<ObiektProcesuBrakowania>,
  'tableRef' | 'data'
>;

export const ObiektyProcesuBrakowaniaTable = (
  props: ObiektyProcesuBrakowaniaTableProps
) => {
  const dispatch = useDrawerRightDispatch();

  return (
    <Table<ObiektProcesuBrakowania>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'sygnatura', title: 'Znak sprawy' },
        {
          field: 'prowadzacy',
          title: 'Prowadzący',
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kategoria archiwalna',
        },
        { field: 'dataZakonczenia', title: 'Data zakończenia', type: 'date' },
        { field: 'statusOpis', title: 'Status' },
      ]}
      options={{
        filtering: false,
        selection: false,
      }}
    />
  );
};
