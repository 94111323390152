import { FilteringModel } from '@archiwum/shared/table';
import { MESSAGE_ERROR } from './constants';
import { ErrorModel } from './model';

export function camelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const getErrorResponse = (err) => {
  console.log(err?.response?.headers?.get?.('x-errorid'));
  if (err != null) {
    if (err.response != null) {
      if (err.response.data != null) {
        const data: ErrorModel = err.response.data;
        if (Array.isArray(data?.messages)) {
          const messages = data.messages.map(
            (x) => `${x.text} ${x.code ? `(${x.code})` : ''}`
          );
          if (data.errorId != null) {
            return [...messages, data.errorId];
          }
          return messages;
        } else {
          if (data != null && data !== '') {
            if (data.errorId != null) {
              return [
                'Pojawił się błąd w aplikacji, który został zarejestrowany pod numerem',
                data.errorId,
              ];
            }
          }
        }
      }
    }
  }
  return [MESSAGE_ERROR];
};

export function getFilters<T, S extends Record<string, unknown>>(
  state: S,
  data: T
): FilteringModel[] {
  const result = [] as FilteringModel[];
  const filterArray = data ? Object.entries(data) : [];

  if (state) {
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        const checkIfExistInArray = filterArray.find(
          ([fKey, fValue]) => key === camelCase(fValue.propertyColumn)
        );
        const checkIfExistInArrayData = filterArray.find(
          ([fKey, fValue]) => key === fKey
        );
        if (checkIfExistInArray || checkIfExistInArrayData) {
          const copy = checkIfExistInArray
            ? { ...checkIfExistInArray[1] }
            : checkIfExistInArrayData
            ? { ...checkIfExistInArrayData[1] }
            : null;
          delete copy.dostepneWartosci;
          // (KCZ) => I'm not sure what filtersConjunction model is
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          result.push({ ...copy, propertyValue: value as string });
        }
      }
    }
  }

  return result;
}
