import { Form as FormikForm, FormikFormProps } from 'formik';
import React, { memo } from 'react';
import styled from 'styled-components';

type FormProps = FormikFormProps & {
  spacing?: boolean;
};

export const Form = memo(({ spacing = true, ...props }: FormProps) => {
  return (
    <StyledForm spacing={spacing} noValidate {...props}>
      {props.children}
    </StyledForm>
  );
});

const StyledForm = styled(({ spacing, ...props }) => <FormikForm {...props} />)`
  display: grid;
  grid-gap: 16px;
`;
