export enum TypProwadzenia {
  PAPIEROWE = 1,
  ELEKTRONICZNE = 2,
}

export enum TypObiekt {
  PISMA_NAS = 2,
  SPRAWY = 1,
}

export type PoczekalniaModel = {
  id: string;
  index: string;
  liczbaPorzadkowa: string;
  znak: string;
  numer: string;
  symbolJRWA: string;
  tytul: string;
  folder: string;
  folderOpis: string;
  kategoriaArchiwalna: string;
  prowadzacy: string;
  pracownikProwadzacy: UzytkownikModel;
  komorkaOrganizacyjna: string;
  typProwadzenia: TypProwadzenia;
  dataPrzyjecia: string;
  dataUtworzenia: string;
  dataPrzeniesieniaDoFolderu: string;
  dataZakonczenia: string;
  listaDostepnychOperacji: string[];
  komentarz?: string;
  czyWSpisieBrakowania: boolean;
  czyWPaczceArchiwalnej: boolean;
};

export type LabelValue = {
  label: string;
  value: string;
};

export type TypProwadzeniaLabelValue = {
  label: string;
  value: TypProwadzenia;
};

export type GetRequestFiltry = {
  foldery: FolderArchiwum[];
};

export type SprawyFiltry = {
  komorkiOrganizacyjne: Filters;
  prowadzacy: Filters;
  symboleJRWA: Filters;
  kategorieArchiwalne: Filters;
  typProwadzenia: Filters<TypProwadzeniaLabelValue>;
  dataZakonczeniaOd: Filters<LabelValue>;
  dataZakonczeniaDo: Filters<LabelValue>;
  dataPrzyjeciaOd: Filters<LabelValue>;
  dataPrzyjeciaDo: Filters<LabelValue>;
  rokZakonczenia: Filters<number>;
};

export enum PropertyValueType {
  STRING = 'System.String',
  INT = 'System.Int32',
  DATE = 'System.DateTime',
}

export enum PropertyFilterType {
  GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
  LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
  EQUALS = 'Equals',
}

export type Filters<T = string> = {
  dostepneWartosci?: T[];
  propertyValueType: PropertyValueType;
  propertyColumn: string;
  propertyFilterType: PropertyFilterType;
};

export type PrzeniesRequestModel = {
  listaIdObiekt: string[];
  typObiekt: TypObiekt;
  folderDocelowy: FolderArchiwum;
  komentarz: string;
};

export type ListaDokumentowPismaWithCommandsModel = {
  dokumentNazwa?: string;
  idDokument?: string;
  idKodKreskowy?: string;
  idPlikStorage?: string;
  idPismo?: string;
  listaDostepnychOperacji: string[];
};
export type GetRpwDetailsRequestModel = { idPismo: string };
export type GetRpwDetailsResponse = {
  idPismo: string;
  listaDokumentowPisma: ListaDokumentowPismaWithCommandsModel[];
};

export enum FolderArchiwum {
  Poczekalnia = 1,
  DoPoprawy = 2,
  Poprawione = 3,
  Odlozone = 4,
  Zarchiwizowane = 5,
  Wycofane = 6,
  WTrakcieBrakowania = 7,
  Wybrakowane = 8,
  WPaczceArchiwalnej = 9,
  PrzekazaneDoAP = 10,
  GotoweDoZarchiwizowania = 11,
}

export type GetPoczekalniaRequestModel = {
  foldery: FolderArchiwum[];
};

export type ElementModel = {
  idElementDoPoprawy: string;
  wartoscElementuDoPoprawy: string;
};
export type OdbiorcaZadania = {
  idOdbiorcy: string;
  idObiektu: string;
};

export type PrzekazDoPoprawyRequestModel = {
  listaOdbiorcaZadania: OdbiorcaZadania[];
  tytul?: string;
  komentarz?: string;
  listaElementow?: ElementModel[];
  typObiektu?: TypObiekt;
};

export type UzytkownikModel = {
  id: string;
  email: string;
  imie: string;
  nazwisko: string;
  stanowisko: string;
  idStanowisko: string;
  pelnaNazwa: string;
  imieNazwisko: string;
  czyUsuniety?: boolean;
  czyAktywny?: boolean;
};

export type Uzytkownik2Model = {
  idStanowisko: string;
  nazwisko: string;
  imie: string;
  nazwa: string;
  czyUsuniety: boolean;
  czyAktywny: boolean;
  czyAdministratorPodmiotu: boolean;
  czyAdministratorSystemu: boolean;
  imieINazwisko: string;
  pelnaNazwa: string;
};

export type GetUzytkownicyResponseModel = {
  items: UzytkownikModel[];
};

export type HistoriaRequestModel = {
  idObiektu: string;
};

export type HistoriaResponseModel = {
  items: {
    id: string;
    typObiektuArchiwum: string;
    idUzytkownik: number;
    typOperacji: string;
    opis: string;
    dataUtworzenia: string;
    uzytkownik: Uzytkownik2Model;
    komentarz: string;
  }[];
};

export type StanowiskoModel = {
  idStanowisko: string;
  nazwisko: string;
  imie: string;
  nazwa: string;
  komorkaOrganizacyjna: string;
  czyUsuniety: boolean;
  czyAktywny: boolean;
};

export type GetStanowiskaResponseModel = {
  items: StanowiskoModel[];
};

export type GenerujPaczkeArchiwalnaRequestModel = {
  typObiektu: TypObiekt;
  listaIdObiekt: string[];
};

type ZadanieBaseModel = { idStanowiskoOdbiorca: string };

export type ZadanieDoPoprawySprawaModel = {
  idSprawa: string;
} & ZadanieBaseModel;

export type ZadanieDoPoprawyPismoNASModel = {
  idPismo: string;
} & ZadanieBaseModel;

export type ZadaniaDoPoprawyRequestModel<T extends ZadanieBaseModel> = {
  listaZadan: T[];
  opis?: string;
};

export type ArchiwizujWszystkieSprawyRequestModel = {
  komentarz: string;
};

export type ArchiwizujWszystkiePismaRequestModel = {
  komentarz: string;
};
