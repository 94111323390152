import { Box, Container, Paper } from '@material-ui/core';
import { SelectModel } from '@nask/hooks';
import { getFilters } from '@archiwum/utility/helper';
import { useSelectedDispatch } from '@archiwum/hooks';
import { Autocomplete } from '@archiwum/shared/autocomplete';
import { TypProwadzeniaAvatar } from '@archiwum/shared/typ-prowadzenia';
import { Typography } from '@archiwum/ui/typography';
import { TypProwadzeniaLabelValue } from '@archiwum/features/archiving-documents';
import { DrawerRight } from '@archiwum/drawer-right';
import { DatePicker } from '@archiwum/shared/date-pickers';
import { SearchInput } from '@archiwum/shared/search-input';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';

import { getSpisSprawQuery } from '../components/spis-spraw-pism/helper';
import { SpisSprawTable } from '../components/spis-spraw-pism/spis-spraw-table';
import { useStyles } from './spis.styles';
import { useSpisSprawFacade } from '../components/spis-spraw-pism/use-spis-spraw-facade';
import { SpisSprawLayout } from '../components/spis-spraw-pism/spis-spraw-layout';

export const SpisSpraw = () => {
  const {
    tableRef,
    onChange,
    searchValue,
    data,
    state,
    katArchiwalnaOptions,
    symboleJRWAOptions,
    komorkaOrganizacyjnaOptions,
    prowadzacyOptions,
    typProwadzeniaOptions,
    statusOptions,
    handleUpdateSelect,
    handleUpdateDate,
  } = useSpisSprawFacade();
  const classes = useStyles();
  const dispatch = useSelectedDispatch();
  return (
    <SpisSprawLayout>
      <Box>
        <NavigationBreadcrumbs />

        <Box width="100%">
          <Container maxWidth={'xl'}>
            <Box pb={3}>
              <Paper classes={{ root: classes.paper }}>
                <Box pb={3} pt={3} pr={4} pl={4} width="100%">
                  <Box display="grid" gridAutoFlow="row">
                    <Box p={0.5} m={0.5} justifySelf="flex-end">
                      <SearchInput
                        searchMethod={(phrase: string) => {
                          onChange(phrase);
                        }}
                      />
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                      <Box m={0.5} p={0.5} width={180}>
                        <Autocomplete
                          label="Komórka organizacyjna"
                          name="komorkaOrganizacyjna"
                          options={komorkaOrganizacyjnaOptions}
                          value={
                            komorkaOrganizacyjnaOptions.find(
                              (option) =>
                                option.value === state.komorkaOrganizacyjna
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(e, v) =>
                            handleUpdateSelect(
                              'komorkaOrganizacyjna',
                              v as SelectModel
                            )
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={220}>
                        <Autocomplete
                          label="Prowadzący sprawę"
                          name="prowadzacy"
                          options={prowadzacyOptions}
                          value={
                            prowadzacyOptions.find(
                              (option) => option.value === state.prowadzacy
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(e, v) =>
                            handleUpdateSelect('prowadzacy', v as SelectModel)
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={120}>
                        <Autocomplete
                          label="Symbol JRWA"
                          name="symbolJRWA"
                          options={symboleJRWAOptions}
                          value={
                            symboleJRWAOptions.find(
                              (option) => option.value === state.symbolJRWA
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(e, v) =>
                            handleUpdateSelect('symbolJRWA', v as SelectModel)
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={120}>
                        <Autocomplete
                          label="Kat. archiwalna"
                          name={'kategoriaArchiwalna'}
                          options={katArchiwalnaOptions}
                          value={
                            katArchiwalnaOptions.find(
                              (option) =>
                                option.value === state.kategoriaArchiwalna
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(e, v) =>
                            handleUpdateSelect(
                              'kategoriaArchiwalna',
                              v as SelectModel
                            )
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={200}>
                        <Autocomplete
                          label="Typ prowadzenia"
                          name="typProwadzenia"
                          options={typProwadzeniaOptions}
                          value={
                            typProwadzeniaOptions.find(
                              (option) => option.value === state.typProwadzenia
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          renderOption={(option) => (
                            <Box
                              display="grid"
                              gridGap={8}
                              gridAutoFlow="column"
                              alignItems="center"
                            >
                              <TypProwadzeniaAvatar
                                typProwadzenia={option.value}
                              />
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          onChange={(e, v) =>
                            handleUpdateSelect(
                              'typProwadzenia',
                              v as TypProwadzeniaLabelValue
                            )
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={220}>
                        <Autocomplete
                          label="Status"
                          name={'folder'}
                          options={statusOptions}
                          value={
                            statusOptions.find(
                              (option) => option.value === state.folder
                            ) ?? null
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(e, v) =>
                            handleUpdateSelect('folder', v as SelectModel)
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={220}>
                        <DatePicker
                          label="Data przyjęcia od"
                          name="dataPrzyjeciaOd"
                          maxDate={new Date()}
                          maxDateMessage={
                            'Data nie powinna być późniejsza niż dzień bieżący'
                          }
                          value={state.dataPrzyjeciaOd}
                          onChange={(date) =>
                            handleUpdateDate(date, 'dataPrzyjeciaOd')
                          }
                        />
                      </Box>
                      <Box m={0.5} p={0.5} width={220}>
                        <DatePicker
                          label="Data przyjęcia do"
                          name="dataPrzyjeciaDo"
                          maxDate={new Date()}
                          maxDateMessage={
                            'Data nie powinna być późniejsza niż dzień bieżący'
                          }
                          value={state.dataPrzyjeciaDo}
                          onChange={(date) =>
                            handleUpdateDate(date, 'dataPrzyjeciaDo')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Paper classes={{ root: classes.paper }}>
              <SpisSprawTable
                tableRef={tableRef}
                data={(query, result) => {
                  getSpisSprawQuery(
                    query,
                    result,
                    searchValue,
                    getFilters(state, data),
                    dispatch
                  );
                }}
              />
            </Paper>
          </Container>
        </Box>
      </Box>
      <DrawerRight />
    </SpisSprawLayout>
  );
};
