import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';

import { DocumentInCartonModel } from '../models/models';

type DocumentsTableProps = Pick<
  UseTableFacadeProps<DocumentInCartonModel>,
  'tableRef' | 'data'
>;

export const DocumentsTable = (props: DocumentsTableProps) => {
  const handleRefresh = () => {
    props.tableRef?.current?.onQueryChange?.();
  };

  return (
    <Table<DocumentInCartonModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'znak', title: 'Znak' },
        {
          field: 'nazwa',
          title: 'Nazwa dokumentu',
        },
        {
          field: 'numer',
          title: 'Numer w składzie',
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kat. archiwalna',
        },
        {
          field: 'uwagi',
          title: 'Uwagi',
        },
        { field: 'dataUtworzenia', title: 'Data utworzenia', type: 'date' },
      ]}
      options={{
        filtering: false,
        selection: false,
      }}
    />
  );
};
