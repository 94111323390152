import { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';

import { CartonModel } from '../models/models';

type CartonsTableProps = Pick<
  UseTableFacadeProps<CartonModel>,
  'tableRef' | 'data'
> & {
  idSpis: string;
};

export const CartonsTable = (props: CartonsTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const handleRefresh = () => {
    props.tableRef?.current?.onQueryChange?.();
  };

  return (
    <Table<CartonModel>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'znak', title: 'Znak kartonu' },
        {
          field: 'liczbaDokumentow',
          title: 'Liczba dokumentów',
        },
        {
          field: 'znakDokumentPoczatkowy',
          title: 'Znak początkowego dokumentu',
        },
        {
          field: 'znakDokumentKoncowy',
          title: 'Znak końcowego dokumentu',
        },
        { field: 'dataZamkniecia', title: 'Data zamknięcia', type: 'date' },
      ]}
      options={{
        filtering: false,
        selection: true,
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data, props.idSpis),
            })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (
  command: string,
  data: unknown[],
  idSpis: string
) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command, idSpis),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'archiwum.skladyArchiwalne.przegladajKarton':
      return 'Przeglądaj dokumenty w kartonie';
    default:
      return command;
  }
};

const getComponent = (command: string, idSpis: string) => {
  const PrzegladajKarton = lazy(() =>
    import('../context-actions/przegladaj-karton').then((module) => ({
      default: module.PrzegladajKarton,
    }))
  );

  switch (command) {
    case 'archiwum.skladyArchiwalne.przegladajKarton':
      return <PrzegladajKarton idSpis={idSpis} />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
