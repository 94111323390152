import React, { CSSProperties, useMemo } from 'react';

import { useDataListContext } from '../../util/context';
import { SearchInput } from '@archiwum/shared/search-input';
import {
  Checkbox,
  Container,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../../data-list.style';
import clsx from 'clsx';

export const Header = () => {
  const classes = useStyles();
  const { dispatch, store } = useDataListContext();
  const isASC =
    store.sorting.length > 0 &&
    store.sorting.find((x) => x.propertyColumn == 'dataUtworzenia')
      .propertySortType === 'asc';

  const handleSearch = (searchPhrase: string) => {
    dispatch({ type: 'CHANGE_SEARCH_QUERY', payload: searchPhrase });
  };

  const columnsCount = store.title ? (store.additionalHeaderIcon ? 5 : 4) : 3;

  return (
    <>
      <Container className={classes.gridHeader}>
        <div>
          {!store?.options?.selection &&
            (store.selected.length > 0 || store.options.showClearButton) && (
              <Checkbox
                id={'clear-icon'}
                checked
                indeterminate
                onClick={() => {
                  dispatch({ type: 'CLEAR_SELECTED' });
                  store.options.onClear && store.options.onClear();
                }}
              />
            )}
        </div>
        {store.title && <Typography variant={'h4'}>{store.title}</Typography>}
        {store.additionalHeaderIcon}
        {useMemo(
          () => (
            <Tooltip title={'Sortowanie'}>
              <IconButton
                onClick={() => {
                  dispatch({
                    type: 'CHANGE_SORT',
                    payload: {
                      propertyColumn: 'dataUtworzenia',
                      propertySortType:
                        store.sorting.length > 0
                          ? store.sorting.find(
                              (x) => x.propertyColumn == 'dataUtworzenia'
                            ).propertySortType === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc',
                    },
                  });
                }}
              >
                {isASC ? (
                  <Icon className={'fas fa-sort-amount-up'} />
                ) : (
                  <Icon className={'fas fa-sort-amount-down'} />
                )}
              </IconButton>
            </Tooltip>
          ),
          [isASC]
        )}
        {<SearchInput searchMethod={handleSearch} />}
      </Container>
    </>
  );
};
