import React from 'react';
import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Box } from '@material-ui/core';
import { Form, FormInput } from '@archiwum/forms';
import { Formik } from 'formik';
import {
  UseArchiwizujFacade,
  useArchiwizujFacade,
} from './use-archiwizuj-facade';
import { Selected } from './selected';
import { actionsService$ } from '@archiwum/actions-handler';
import { WczytajButton } from './wczytaj-button';
import { Button } from '@archiwum/ui/button';

type ArchiwizujProps = UseArchiwizujFacade & {
  showSearch?: boolean;
};

export const Archiwizuj = ({
  showSearch = true,
  ...props
}: ArchiwizujProps) => {
  const { formikProps, isLoading } = useArchiwizujFacade(props);
  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>Archiwizuj</ActionHeader>
        <Formik {...formikProps}>
          <Form>
            {showSearch && (
              <>
                <Box display="grid" gridAutoFlow="column" gridGap={16}>
                  <FormInput name={'od'} label={'Od'} />
                  <FormInput name={'do'} label={'Do'} />
                </Box>
                <FormInput name={'numery'} label={'Wprowadź numery'} />
                <WczytajButton />
              </>
            )}

            <Selected />
            <FormInput
              name={'komentarz'}
              label={'Komentarz'}
              multiline={true}
            />
            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                Archiwuzuj
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};
