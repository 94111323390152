import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, FC } from 'react';
import { getRouteById, Routes } from '@archiwum/routes';
import { parseJSON, stringifyJSON } from '@nask/hooks';
import { useCounters } from '@archiwum/counters';

/* eslint-disable-next-line */
export interface InitialConfProps {}

export const InitialConf: FC<InitialConfProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  useCounters();

  useEffect(() => {
    // const userSetting = localStorage.getItem('userSetting');
    // const sid =
    //   userSetting && parseJSON<{ uid: string; sid: string }>(userSetting)?.sid;
    // if (sid) {
    //   localStorage.setItem('sid', sid);
    // }
    if (window.location.href.includes('viewId')) {
      const viewId = new URLSearchParams(location.search).get('viewId');
      viewId && history.push(getRouteById(viewId, Routes.Home.path));
    }
    localStorage.removeItem('initPath');
  }, []);

  return <>{props.children}</>;
};

export default InitialConf;
