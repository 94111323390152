import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    indicator: {
      top: 0,
    },
    root: {
      marginBottom: -4,
    },
    tab: {
      fontSize: theme.typography.descriptionFontSize,
    },
    activeTab: {
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
      fontWeight: 'bold',
    },
    textField: {
      fontSize: theme.typography.descriptionFontSize,
    },
    iconButton: {
      padding: spacing(1),
    },
  })
);
