import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    link: {
      transition: 'color 0.2s',
      color: theme.typography.headerColor,
      '&:hover': {
        color: theme.colors.focused,
      },
    },
    activeLink: {
      color: theme.typography.headerColor,
      fontWeight: 600,
    },
    icon: {
      color: theme.colors.primary,
    },
  })
);
