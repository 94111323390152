export type LabelValue<T extends unknown> = {
  label: string;
  value: T;
};

export enum PochodzenieRaportu {
  Archiwum = 1,
  Ezdrp,
}

export type GetUserReportsRequest = {
  pochodzenieRaportu: PochodzenieRaportu;
};

export type GenerateReportRequestModel = {
  dataKoncowa: string;
  dataPoczatkowa: string;
  typ: number;
  format: string;
} & GetUserReportsRequest;

export type ReportModel = {
  idRaport: string;
  typ: string;
  status: string;
  dataUtworzenia: string;
  dataWygenerowania?: string;
};
