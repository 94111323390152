import {
  FilteringModel,
  OwnQuery,
  SubjectServiceModel,
} from '@archiwum/shared/table';
import { ChronologicalCompositionsServiceQuery } from './services/chronological-compositions-service-query';

export function camelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function getListaSpisow(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  dispatch
) {
  ChronologicalCompositionsServiceQuery.GetSpisy({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    sort: query.sort,
    searchValue: search,
    searchBy: search,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}

export function getListaKartonow(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  dispatch,
  idSpis: string
) {
  ChronologicalCompositionsServiceQuery.GetCartons({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    idSpis: idSpis,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}

export function getListaDokumentow(
  query: OwnQuery,
  result: SubjectServiceModel,
  search: string,
  dispatch,
  idKarton: string
) {
  ChronologicalCompositionsServiceQuery.GetDocumentsInCarton({
    pageInfo: { pageNumber: query.page, pageSize: query.pageSize },
    sort: query.sort,
    searchValue: search,
    searchBy: search,
    idKarton: idKarton,
  }).subscribe({
    next: (value) => {
      const data = value.items.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      dispatch?.({ data });
      return result.onSuccess({
        data,
        page: value.pageInfo.pageNumber,
        totalCount: value.pageInfo.totalItems,
      });
    },
    error: (err) => {
      return result.onError();
      // reject();
    },
  });
}
