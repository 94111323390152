import { Box } from '@material-ui/core';
import React from 'react';
import { DocumentStatus } from '../../types/acts-table-types';
import { StatusAvatar, StatusLabels } from './status-avatar';

type StatusAreaProps = {
  actId: string;
  status: DocumentStatus;
  onMetaClick?: (id: string) => unknown;
};

export const StatusArea = ({ actId, status, onMetaClick }: StatusAreaProps) => {
  const map = new Map(Object.entries(status));
  console.log(status);

  return (
    <Box display="flex">
      {Object.entries(status).map(([key, value]) => {
        return (
          <StatusAvatar
            key={`${actId}-${key}`}
            label={key as StatusLabels}
            isActive={value}
          />
        );
      })}
    </Box>
  );
};
