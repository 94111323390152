import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => {
  return createStyles({
    iconButtonRoot: {
      width: '30px',
      height: '30px',
      color: 'white',
    },
    iconButtonLabel: {
      color: 'white',
      width: 'auto',
    },
    icon: {
      width: '16px',
      height: '16px',
    },
  });
});
