import { Route, Switch } from 'react-router';
import { Box, Container, Divider, Paper, Tab } from '@material-ui/core';
import { Tabs } from '@archiwum/ui/tabs';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';
import { useStyles } from './reports.styles';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';
import { Routes } from '@archiwum/routes';
import { ZamawianieRaportow } from './zamawianie-raportow/views/zamawianie-raportow';
import { ListaZamowionychRaportow } from './lista-zamowionych-raportow/views/lista-zamowionych-raportow';
import { useReportsFacade } from './use-reports-facade';

export const Reports = () => {
  const classes = useStyles();
  const { tabs, activeTab, handleChangeTab } = useReportsFacade();

  return (
    <Box>
      <Paper square elevation={0}>
        <Typography
          classes={{ root: classes.header }}
          variantType={TypographyVariants.Header2}
        >
          Sprawozdawczość
        </Typography>
        <Divider />
      </Paper>
      <Container maxWidth="xl">
        <NavigationBreadcrumbs />
        <Tabs
          classes={{ indicator: classes.indicator, root: classes.tabs }}
          TabIndicatorProps={{ children: <span /> }}
          value={activeTab}
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.activeTab,
              }}
              key={tab.label}
              label={tab.label}
              value={tab}
            />
          ))}
        </Tabs>
        <Divider classes={{ root: classes.borderBottomTab }} />
        <Paper elevation={0} classes={{ root: classes.paperShadowBottom }}>
          <Switch>
            <Route
              exact
              path={Routes.RaportyZamawianieRaportow.path}
              component={ZamawianieRaportow}
            />
            <Route
              exact
              path={Routes.RaportyListaZamowionychRaportow.path}
              component={ListaZamowionychRaportow}
            />
          </Switch>
        </Paper>
      </Container>
    </Box>
  );
};
