import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { MESSAGE_ORDER_TO_EXECUTION } from '@archiwum/messages';
import { getErrorResponse } from '@archiwum/utility/helper';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { TableRef } from '@archiwum/shared/table';
import { useHistory } from 'react-router';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { useRefresh } from '@archiwum/actions-handler';
import { countersSubject$, LicznikModel } from '@archiwum/counters';

import { getParams } from '../../helper';
import { ArchivingDocumentsServiceCommand } from '../../services/archiving-documents-service-command';

const tabs = [
  {
    label: 'Sprawy',
    value: 'sprawy',
    idCounter: 'POCZEKALNIA-SPRAWY-GOTOWEDOARCHIWIZACJI',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
    idCounter: 'POCZEKALNIA-PISMANAS-GOTOWEDOARCHIWIZACJI',
  },
];

export const useGotoweDoArchiwizacjiFacade = () => {
  const history = useHistory();
  const tableRef = useRef<TableRef>();
  const inputCommentRef = useRef();
  const [showSnackbar] = useSnackbar();
  const dispatch = useDrawerRightDispatch();
  const [open, setOpen] = useState(false);

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  const {
    value: comment,
    onChange: handleChangeComment,
  } = useObservableCallback<string>((subject) =>
    subject.pipe(debounceTime(150))
  );

  const [activeTab, setActiveTab] = useState(() => {
    const rodzaj = getParams(location.search);
    return (rodzaj as string) ?? tabs[0].value;
  });

  const [counters, setCounters] = useState<LicznikModel[]>([]);

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    handleChangeComment('');
  }, []);

  useEffect(() => {
    const subscription = countersSubject$.subscribe((counters) =>
      setCounters(counters)
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const rodzaj = getParams(location.search);
    if (rodzaj) {
      setActiveTab(rodzaj as string);
    }
  }, [location.search]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
  }, [activeTab]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue]);

  const handleChangeTab = (event: ChangeEvent<unknown>, value: string) => {
    history.push({ search: `?rodzaj=${value}` });
    setActiveTab(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { setUrl } = useApi({
    onSuccess: (response) => {
      tableRef.current?.onQueryChange?.();
      handleClose();
      showSnackbar(MESSAGE_ORDER_TO_EXECUTION, { variant: 'success' });
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const handleSubmitPisma = () => {
    setUrl(
      ArchivingDocumentsServiceCommand.ArchiwizujWszystkiePisma({
        komentarz: comment,
      })
    );
  };

  const handleSubmitSprawy = () => {
    setUrl(
      ArchivingDocumentsServiceCommand.ArchiwizujWszystkieSprawy({
        komentarz: comment,
      })
    );
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleSubmitPisma,
    handleSubmitSprawy,
    tableRef,
    searchValue,
    onChange,
    tabs,
    handleChangeTab,
    activeTab,
    comment,
    handleChangeComment,
    inputCommentRef,
    counters,
  };
};
