import { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';

import { ObiektSpisuBrakowania } from '../../models/models';
import { UsunObiektZeSpisuBrakowania } from '../../context-actions/usun-obiekt-ze-spisu-brakowania/usun-obiekt-ze-spisu-brakowania';
import { OznaczDoEkspertyzy } from '../../context-actions/oznacz-do-ekspertyzy/oznacz-do-ekspertyzy';

type ObiektySpisuBrakowaniaTableProps = Pick<
  UseTableFacadeProps<ObiektSpisuBrakowania>,
  'tableRef' | 'data'
>;

export const ObiektySpisuBrakowaniaTable = (
  props: ObiektySpisuBrakowaniaTableProps
) => {
  const dispatch = useDrawerRightDispatch();

  return (
    <Table<ObiektSpisuBrakowania>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'sygnatura', title: 'Znak sprawy' },
        {
          field: 'prowadzacy',
          title: 'Prowadzący',
        },
        {
          field: 'kategoriaArchiwalna',
          title: 'Kategoria archiwalna',
        },
        { field: 'dataZakonczenia', title: 'Data zakończenia', type: 'date' },
        { field: 'statusOpis', title: 'Status' },
        { field: 'uwagi', title: 'Uwagi' },
      ]}
      options={{
        filtering: false,
        selection: true,
        detailPanelColumnAlignment: 'right',
        detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case '*archiwum.spis-brakowania.usun':
      return 'Usuń';
    case '*archiwum.spis-brakowania.utworz-zadanie-weryfikacji':
      return 'Utwórz zadanie weryfikacji';
    case '*archiwum.obiekt-do-brakowania.usun':
      return 'Usuń z brakowania';
    case '*archiwum.obiekt-do-brakowania.oznacz-do-brakowania':
      return 'Oznacz do brakowania';
    case '*archiwum.obiekt-do-brakowania.oznacz-do-oceny-ap':
      return 'Oznacz do ekspertyzy AP';
    case '*archiwum.obiekt-do-brakowania.oznacz-do-zmiany-kategorii':
      return 'Oznacz do zmiany kategorii';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const WylaczObiektZBrakowania = lazy(() =>
    import(
      '../../context-actions/usun-obiekt-ze-spisu-brakowania/usun-obiekt-ze-spisu-brakowania'
    ).then((module) => ({ default: module.UsunObiektZeSpisuBrakowania }))
  );
  const OznaczDoBrakowania = lazy(() =>
    import(
      '../../context-actions/oznacz-do-brakowania/oznacz-do-brakowania'
    ).then((module) => ({ default: module.OznaczDoBrakowania }))
  );
  const OznaczDoZmianyKategorii = lazy(() =>
    import(
      '../../context-actions/oznacz-do-zmiany-kategorii/oznacz-do-zmiany-kategorii'
    ).then((module) => ({ default: module.OznaczDoZmianyKategorii }))
  );

  switch (command) {
    case '*archiwum.obiekt-do-brakowania.usun':
      return <UsunObiektZeSpisuBrakowania />;
    case '*archiwum.obiekt-do-brakowania.oznacz-do-brakowania':
      return <OznaczDoBrakowania />;
    case '*archiwum.obiekt-do-brakowania.oznacz-do-oceny-ap':
      return <OznaczDoEkspertyzy />;
    case '*archiwum.obiekt-do-brakowania.oznacz-do-zmiany-kategorii':
      return <OznaczDoZmianyKategorii />;

    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
