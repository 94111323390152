import React, { useMemo } from 'react';

import { DataListWrapper, DataListWrapperProps } from './data-list-wrapper';

type WDataListProps<T> = DataListWrapperProps<T> & {};

export const DataList = <T extends unknown>(props: WDataListProps<T>) => {
  return useMemo(
    () => (
      <>
        <DataListWrapper<T>
          {...props}
          options={{
            ...props.options,
          }}
        />
      </>
    ),
    [props]
  );
};
