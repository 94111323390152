import { useFormButton, UseFormButtonProps } from '@nask/hooks';
import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

type FormButtonProps = ButtonProps & UseFormButtonProps & {};

const FormButton = (props: FormButtonProps) => {
  const { name, label, onClick, ...rest } = props;
  const {
    btnLabel,
    isSubmitting,
    handleSubmit,
    helper,
    field,
  } = useFormButton({ name, label });

  return (
    <Button
      disabled={isSubmitting}
      onClick={(e) => {
        e.preventDefault();
        if (rest.type !== 'button') {
          handleSubmit();
        } else {
          if (onClick) {
            onClick(e);
          } else {
            helper.setValue(!field.value);
          }
        }
      }}
      {...rest}
    >
      {btnLabel}
    </Button>
  );
};

export default FormButton;
