import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: theme.typography.paragraphFontSize,
      color: theme.typography.paragraphColor,
    },
  })
);
