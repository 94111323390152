import { IconButton, MenuItem, Select, Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { useDataListContext } from '../../util/context';
import { Typography } from '@archiwum/ui/typography';

export const Pagination = () => {
  const { dispatch, store } = useDataListContext();
  const {
    localization,
    pagination,
    icons: { FirstPage, LastPage, NextPage, PreviousPage },
    options: { rowsPerPageOptions },
  } = store;
  const handleFirstPageButtonClick = (event) => {
    dispatch({ type: 'CHANGE_PAGINATION', payload: { pageNumber: 0 } });
  };

  const handleBackButtonClick = (event) => {
    dispatch({
      type: 'CHANGE_PAGINATION',
      payload: { pageNumber: pagination.pageNumber - 1 },
    });
  };

  const handleNextButtonClick = (event) => {
    dispatch({
      type: 'CHANGE_PAGINATION',
      payload: { pageNumber: pagination.pageNumber + 1 },
    });
  };

  const handleLastPageButtonClick = (event) => {
    dispatch({
      type: 'CHANGE_PAGINATION',
      payload: {
        pageNumber: Math.max(
          0,
          Math.ceil(pagination.totalItems / pagination.pageSize) - 1
        ),
      },
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: number }>
  ) => {
    dispatch({
      type: 'CHANGE_PAGINATION',
      payload: { pageSize: event.target.value },
    });
  };
  return (
    <Container>
      <Select
        disabled={store.loading}
        disableUnderline
        value={pagination.pageSize}
        onChange={handleChangeRowsPerPage}
        displayEmpty
      >
        {rowsPerPageOptions.map((rowPerPage, index) => {
          return (
            <MenuItem
              key={`option-${index}-${rowPerPage.value}`}
              value={rowPerPage.value}
            >
              {rowPerPage.label}
            </MenuItem>
          );
        })}
      </Select>
      <PaginationButton
        title={localization.firstTooltip}
        onClick={handleFirstPageButtonClick}
        icon={store.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        disabled={pagination.pageNumber === 0 || store.loading}
      />
      <PaginationButton
        title={localization.previousTooltip}
        onClick={handleBackButtonClick}
        icon={store.direction === 'rtl' ? <NextPage /> : <PreviousPage />}
        disabled={pagination.pageNumber === 0 || store.loading}
      />
      <Typography
        variant="caption"
        style={{
          flex: 1,
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit',
        }}
      >
        {localization.labelDisplayedRows
          .replace(
            '{from}',
            pagination.pageNumber * pagination.pageSize + 1 + ''
          )
          .replace(
            '{to}',
            String(
              Math.min(
                (pagination.pageNumber + 1) * pagination.pageSize,
                pagination.totalItems
              )
            )
          )
          .replace('{count}', pagination.totalItems + '')}
      </Typography>
      <PaginationButton
        title={localization.nextTooltip}
        onClick={handleNextButtonClick}
        icon={store.direction === 'rtl' ? <PreviousPage /> : <NextPage />}
        disabled={
          pagination.pageNumber >=
            Math.ceil(pagination.totalItems / pagination.pageSize) - 1 ||
          store.loading
        }
      />
      <PaginationButton
        title={localization.lastTooltip}
        onClick={handleLastPageButtonClick}
        icon={store.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        disabled={
          pagination.pageNumber >=
            Math.ceil(pagination.totalItems / pagination.pageSize) - 1 ||
          store.loading
        }
      />
    </Container>
  );
};

type PaginationButtonProps = {
  title: string;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  icon: React.ReactNode;
};

const PaginationButton = ({
  disabled,
  onClick,
  title,
  icon,
}: PaginationButtonProps) => {
  return (
    <Tooltip title={title}>
      <span>
        <IconButton onClick={onClick} disabled={disabled} aria-label={title}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const Container = styled.div`
  display: grid;
  grid-area: pagination;
  grid-auto-flow: column;
  justify-content: flex-end;
`;
