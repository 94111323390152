import { NadawcaModel } from '../models/models';

export const renderNadawca = (
  nadawca: NadawcaModel,
  returnEmptyString?: boolean,
  defaultValue?: string
) => {
  if (!nadawca) return returnEmptyString ? '' : defaultValue ?? 'Anonimowy';

  const newNadawca = {
    imie: nadawca.imie?.value,
    drugieImie: nadawca.drugieImie?.value,
    nazwisko: nadawca.nazwisko?.value,
    nazwa: nadawca.nazwa?.value,
  };

  return newNadawca.nazwa
    ? `${newNadawca.nazwa}`
    : newNadawca.drugieImie
    ? `${newNadawca.imie} ${newNadawca.drugieImie} ${newNadawca.nazwisko}`
    : `${newNadawca.imie} ${newNadawca.nazwisko}`;
};
