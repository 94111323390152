import { useEffect, useRef } from 'react';
import { debounceTime } from 'rxjs/operators';
import { useObservableCallback } from '@nask/hooks';
import { TableRef } from '@archiwum/shared/table';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { useRefresh } from '@archiwum/actions-handler';

export const useDocumentsFacade = () => {
  const tableRef = useRef<TableRef>();
  const dispatch = useDrawerRightDispatch();

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue]);

  return {
    tableRef,
    searchValue,
    onChange,
  };
};
