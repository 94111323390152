import { lazy } from 'react';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { Table, UseTableFacadeProps } from '@archiwum/shared/table';
import { actionsService$, getOperations } from '@archiwum/actions-handler';
import { Prawo117Icon } from '@nask/ezdrp-icons';

import { useStyles } from './archiving-package-table.styles';
import { PaczkaArchiwalna } from '../models/models';
import { RenderDetailPanel } from './details-panel';

type ArchivingPackageTableProps = Pick<
  UseTableFacadeProps<PaczkaArchiwalna>,
  'tableRef' | 'data'
>;

export const ArchivingPackageTable = (props: ArchivingPackageTableProps) => {
  const dispatch = useDrawerRightDispatch();
  const classes = useStyles();
  const handleRefresh = () => {
    props.tableRef?.current?.onQueryChange?.();
  };

  return (
    <Table<PaczkaArchiwalna>
      tableRef={props.tableRef}
      disableToolbar
      data={props.data}
      columns={[
        {
          field: 'index',
          title: 'Lp.',
          width: '1% !important',
          headerStyle: {
            width: '1% !important',
          },
          sorting: false,
        },
        { field: 'znakSprawy', title: 'Znak sprawy' },
        {
          field: 'nazwa',
          title: 'Nazwa paczki archiwalnej',
        },
        {
          field: 'opis',
          title: 'Opis paczki archiwalnej',
        },
        {
          field: 'wielkoscPaczki',
          title: 'Wielkość paczki',
        },
        { field: 'dataUtworzenia', title: 'Data utworzenia', type: 'date' },
        { field: 'rozmiar', title: 'Status' },
      ]}
      options={{
        filtering: false,
        selection: true,
        detailPanelColumnAlignment: 'right',
        detailPanelType: 'single',
      }}
      onSelectionChange={(data, rowData) => {
        if (rowData === null) {
          dispatch({ data: [], selected: [] });
        } else {
          const operations = getOperations(data);
          dispatch({
            data,
            selected: operations.map((x) => ({
              data,
              command: x,
              label: getLabel(x),
              onClick: () => handleActionClick(x, data),
            })),
          });
        }
      }}
      detailPanel={[
        (rowData) => ({
          visible: true,

          tooltip: 'Lista dokumentów',
          icon: () => <Prawo117Icon className={classes.rightArrowIcon} />,
          render: (rowData) => <RenderDetailPanel rowData={rowData} />,
        }),
      ]}
      // onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
};

const handleActionClick = (command: string, data: unknown[]) => {
  actionsService$.openCommand({
    command,
    data,
    component: getComponent(command),
  });
};

const getLabel = (command: string): string => {
  switch (command) {
    case 'archiwum.paczka-archiwalna.pobierz':
      return 'Pobierz paczkę';
    case 'archiwum.paczka-archiwalna.zmien-nazwe':
      return 'Zmień nazwę';
    case 'archiwum.paczka-archiwalna.usun':
      return 'Usuń paczkę';
    default:
      return command;
  }
};

const getComponent = (command: string) => {
  const PobierzPaczke = lazy(() =>
    import('../context-actions/pobierz-paczke/pobierz-paczke').then(
      (module) => ({
        default: module.PobierzPaczke,
      })
    )
  );

  const UsunPaczke = lazy(() =>
    import('../context-actions/usun-paczke/usun-paczke').then((module) => ({
      default: module.UsunPaczke,
    }))
  );

  const EdytujPaczke = lazy(() =>
    import('../context-actions/edytuj-paczke/edytuj-paczke').then((module) => ({
      default: module.EdytujPaczke,
    }))
  );

  switch (command) {
    case 'archiwum.paczka-archiwalna.pobierz':
      return <PobierzPaczke />;
    case 'archiwum.paczka-archiwalna.usun':
      return <UsunPaczke />;
    case 'archiwum.paczka-archiwalna.zmien-nazwe':
      return <EdytujPaczke />;
    default:
      return <div>Nie znaleziono akcji</div>;
  }
};
