import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';
import { SettingResponse, SettingModelRequest } from '../models/models';

export const SettingServiceQuery = {
  GetSetting: (request: SettingModelRequest) => {
    return Axios.post<SettingResponse<string | number | boolean>[]>(
      getUrl('/pobierz-ustawienie', ''),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    );
  },
};
