import React from 'react';
import { useApi } from '@nask/hooks';
import { UserServiceQuery } from './services/user-service-query';
import { Loader } from '@nask/ui';
import styled from 'styled-components';
import { Typography, TypographyVariants } from '@archiwum/ui/typography';

type SubjectProps = {};

export const Subject = (props: SubjectProps) => {
  const { data: subject } = useApi({ service: UserServiceQuery.GetSubject() });

  if (!subject) {
    return (
      <LoaderContainer>
        <StyledLoader show />
      </LoaderContainer>
    );
  }
  return (
    <SubjectContainer>
      <Typography component="h1" variantType={TypographyVariants.Header5}>
        {subject.nazwa}
      </Typography>
    </SubjectContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100px;
  align-content: center;
  display: grid;
  margin-bottom: 15px;
`;
const SubjectContainer = styled.div`
  align-self: center;
`;

const StyledLoader = styled(Loader)`
  display: grid;
  grid-auto-flow: column;
  & > div {
    width: 15px;
    height: 15px;
  }
`;
