import { Axios } from '@nask/axios-observable';
import { getUrl } from '@archiwum/utility/service';
import { map, take } from 'rxjs/operators';

import { PagedResultDto, QueryViewRequestBase } from '@archiwum/shared/table';
import {
  GetCartonsRequestModel,
  GetDocumentsInCartonRequestModel,
  DocumentInCartonModel,
  CartonModel,
  SpisModel,
} from '../models/models';

export const ChronologicalCompositionsServiceQuery = {
  GetSpisy: (request: QueryViewRequestBase<{}>) =>
    Axios.post<PagedResultDto<SpisModel>>(
      getUrl('sklad-chronologiczny/spisy/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetCartons: (request: QueryViewRequestBase<GetCartonsRequestModel>) =>
    Axios.post<PagedResultDto<CartonModel>>(
      getUrl('sklad-chronologiczny/kartony/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
  GetDocumentsInCarton: (
    request: QueryViewRequestBase<GetDocumentsInCartonRequestModel>
  ) =>
    Axios.post<PagedResultDto<DocumentInCartonModel>>(
      getUrl('sklad-chronologiczny/dokumenty/lista-stronicowana'),
      request
    ).pipe(
      take(1),
      map((data) => data.data)
    ),
};
