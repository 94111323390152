import isEqual from 'lodash/isEqual';
import React, { Dispatch } from 'react';
import { Observable } from 'rxjs';
import { SortingModel } from '@archiwum/shared/table';

export type DataListState<RowData extends unknown> = {
  options: OptionsModel;
  error?: boolean;
  data?: RowData[];
  selected?: RowModel<RowData>[];
  rows?: RowModel<RowData>[];
  pagination: PaginationModel;
  sorting: SortingModel[];
  loading?: boolean;
  searchQuery: string;
  title: string;
  onQueryChange?: Dispatch<Observable<RowData>>;
  refresh: boolean;
  setRefresh: () => void;
  localization: LocalizationModel;
  direction: 'rtl' | 'ltl';
  icons: IconsModel;
  additionalHeaderIcon?: React.ReactNode;
  renderBody?: (data: RowData | unknown) => React.ReactNode;
  selectionFilter?: (selected: RowModel<RowData>[], val: RowData) => boolean;
  isActionsButtonVisible?: (data: RowData) => boolean;
};

type IconsModel = {
  FirstPage: React.ForwardRefExoticComponent<
    React.RefAttributes<SVGSVGElement>
  >;
  LastPage: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
  NextPage: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
  PreviousPage: React.ForwardRefExoticComponent<
    React.RefAttributes<SVGSVGElement>
  >;
};

type LocalizationModel = {
  firstTooltip: string;
  previousTooltip: string;
  nextTooltip: string;
  lastTooltip: string;
  labelDisplayedRows: string;
  labelRowsPerPage: string;
};

export type RowModel<RowData extends unknown> = {
  checked?: boolean;
  disableCheckbox?: boolean;
  disableActionsButton?: boolean;
  rowData: RowData;
};

export type OptionsModel = {
  selection?: boolean;
  rowsPerPageOptions?: RowsPerPageOptionsModel[];
  showClearButton?: boolean;
  onClear?: () => void;
  selectionType: 'radio' | 'checkbox';
  showColumnLabels?: boolean;
  labels?: LabelModel[];
  showActionsButton?: boolean;
  preserveUnusedSpace?: boolean;
  templateColumns?: string;
};

export type LabelModel = {
  labelText: string;
};

type RowsPerPageOptionsModel = {
  label: string;
  value: number;
};

export type PaginationModel = {
  pageNumber: number;
  pageSize: number;
  totalItems?: number;
};

export type ExternalActions = {
  caption: string;
};

export type ExternalActionModel = {
  caption: string;
};

export type DataListActions<T> =
  | { type: 'CHANGE_STATE'; payload: Partial<DataListState<T>> }
  | { type: 'UPDATE_DATA'; payload: T[] }
  | { type: 'CHANGE_SEARCH_QUERY'; payload: string }
  | { type: 'SET_INITIAL_STORE'; payload: Partial<DataListState<T>> }
  | { type: 'CHANGE_PAGINATION'; payload: Partial<PaginationModel> }
  | { type: 'SELECT_ROW'; payload: RowModel<T> }
  | { type: 'CHANGE_ROW'; payload: RowModel<T> }
  | { type: 'UNSELECT_ROW'; payload: RowModel<T> }
  | { type: 'CLEAR_SELECTED' }
  | { type: 'SET_REFRESH' }
  | { type: 'CHANGE_SORT'; payload: SortingModel }
  | { type: 'UPDATE_OPTIONS'; payload: Partial<OptionsModel> }
  | {
      type: 'UPDATE_API_DATA';
      payload: {
        data: T[];
        pagination: PaginationModel;
        loading: boolean;
        error: boolean;
        selectionFilter?: () => boolean;
      };
    };

export const dataFetchReducer = <T extends unknown>(
  state: DataListState<T>,
  action: DataListActions<T>
) => {
  switch (action.type) {
    case 'CHANGE_STATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'UPDATE_DATA':
      return {
        ...state,
        ...action.payload,
        rows: action.payload?.map((value, index) => {
          const disableCheckbox = state.selectionFilter(state.selected, value);
          const enableActionsButton =
            state?.isActionsButtonVisible?.(value) ?? true;
          return {
            rowData: value,
            checked:
              state.selected.find((x) => isEqual(value, x.rowData)) !==
              undefined,
            disableCheckbox: disableCheckbox,
            disableActionsButton: !enableActionsButton,
          };
        }),
      };
    case 'UPDATE_API_DATA':
      return {
        ...state,
        ...action.payload,
        rows: action.payload.data?.map((value, index) => {
          const disableCheckbox = state.selectionFilter(state.selected, value);
          const enableActionsButton =
            state.isActionsButtonVisible?.(value) ?? true;
          return {
            rowData: value,
            checked:
              state.selected.find((x) => isEqual(value, x.rowData)) !==
              undefined,
            disableCheckbox: disableCheckbox,
            disableActionsButton: !enableActionsButton,
          };
        }),
      };
    case 'SET_INITIAL_STORE':
      return {
        ...state,
        ...action.payload,
      };
    case 'CHANGE_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
      };
    case 'CHANGE_PAGINATION':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case 'SELECT_ROW': {
      const selected = [
        ...state.selected,
        { checked: !action.payload.checked, rowData: action.payload.rowData },
      ];
      return {
        ...state,
        rows: state.rows.map((x) => {
          const disableCheckbox = state.selectionFilter(selected, x.rowData);
          if (isEqual(x, action.payload)) {
            return {
              checked: !action.payload.checked,
              rowData: action.payload.rowData,
              disableCheckbox: disableCheckbox,
            };
          } else {
            return { ...x, disableCheckbox: disableCheckbox };
          }
        }),
        selected: selected,
      };
    }
    case 'CHANGE_ROW': {
      return {
        ...state,
        rows: state.rows.map((x) => {
          if (isEqual(x, action.payload)) {
            return {
              checked: !action.payload.checked,
              rowData: action.payload.rowData,
              disableCheckbox: false,
            };
          } else {
            return { ...x, disableCheckbox: false, checked: false };
          }
        }),
        selected: [action.payload],
      };
    }
    case 'UNSELECT_ROW': {
      const selected = state.selected.filter(
        (x) => !isEqual(x.rowData, action.payload.rowData)
      );
      return {
        ...state,
        rows: state.rows.map((x) => {
          const disableCheckbox = state.selectionFilter(selected, x.rowData);
          if (isEqual(x, action.payload)) {
            return {
              checked: !action.payload.checked,
              rowData: action.payload.rowData,
              disableCheckbox: disableCheckbox,
            };
          } else {
            return { ...x, disableCheckbox: disableCheckbox };
          }
        }),
        selected: selected,
      };
    }
    case 'CLEAR_SELECTED':
      return {
        ...state,
        rows: state.rows.map((x) => {
          return { checked: false, rowData: x.rowData };
        }),
        selected: [],
      };
    case 'CHANGE_SORT':
      return {
        ...state,
        sorting: [
          ...state.sorting.filter(
            (x) => x.propertyColumn !== action.payload.propertyColumn
          ),
          action.payload,
        ],
      };
    case 'SET_REFRESH':
      return { ...state, refresh: !state.refresh, selected: [] };
    case 'UPDATE_OPTIONS':
      return { ...state, options: { ...state.options, ...action.payload } };
    default:
      throw new Error();
  }
};

export type Query<RowData extends object> = {
  page: number;
  pageSize: number;
  search: string;
  orderBy: string;
  orderDirection: 'asc' | 'desc';
};
