import React from 'react';
import {
  ActionContainer,
  ActionHeader,
  ButtonsContainer,
} from '@archiwum/shared/context-actions';
import { Box } from '@material-ui/core';
import { Form, FormInput } from '@archiwum/forms';
import { Formik } from 'formik';
import { actionsService$ } from '@archiwum/actions-handler';
import { Button } from '@archiwum/ui/button';
import { Selected } from '../archiwizuj/selected';
import { WczytajButton } from '../archiwizuj/wczytaj-button';
import { UseOdlozFacade, useOdlozFacade } from './use-odloz-facade';

type ArchiwizujProps = UseOdlozFacade & {
  showSearch?: boolean;
};

export const Odloz = ({ showSearch = true, ...props }: ArchiwizujProps) => {
  const { formikProps, isLoading } = useOdlozFacade(props);
  return (
    <ActionContainer>
      <Box width={350}>
        <ActionHeader>Odłóż</ActionHeader>
        <Formik {...formikProps}>
          <Form>
            {showSearch && (
              <>
                <Box display="grid" gridAutoFlow="column" gridGap={16}>
                  <FormInput name={'od'} label={'Od'} />
                  <FormInput name={'do'} label={'Do'} />
                </Box>
                <FormInput name={'numery'} label={'Wprowadź numery'} />
                <WczytajButton />
              </>
            )}

            <Selected />
            <FormInput multiline name={'komentarz'} label={'Komentarz'} />
            <ButtonsContainer>
              <Button
                onClick={() => actionsService$.close()}
                variant="outlined"
                color="default"
              >
                Anuluj
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                Odłóż
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </ActionContainer>
  );
};
