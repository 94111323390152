import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    countsContainer: {
      display: 'flex',
      gridGap: spacing(0, 1),
    },
    tooltip: {
      fontSize: theme.typography.descriptionFontSize,
    },
    counter: {
      borderRadius: '0.6rem',
      color: 'white',
      padding: spacing(0.5, 1),
      minWidth: spacing(3),
      width: '-content',
      textAlign: 'center',
      fontSize: theme.typography.descriptionFontSize,
      fontWeight: 400,
    },
    normal: { backgroundColor: theme.colors.statusBackgroundColorActive },
    warning: { backgroundColor: theme.colors.notatkaBackgroundColorMedium },
    danger: { backgroundColor: theme.colors.danger },
  })
);
