import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@nask/theme';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    header: {
      padding: spacing(1, 0, 1, 5),
    },
    tab: {
      fontSize: theme.typography.paragraphFontSize,
      textTransform: 'none',
    },
    tabSelected: {
      color: theme.colors.focused,
      fontWeight: 'bold',
    },
    indicator: {
      height: 0,
      backgroundColor: 'transparent',
    },
    container: {
      display: 'grid',
      gridGap: '16px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto))',
      paddingTop: '2.4rem',
      paddingBottom: '2.4rem',
    },
    containerMaxWidth: {
      maxWidth: '100%',
    },
    card: {
      height: '150px',
    },
    cardActionArea: {
      height: '100%',
      width: '100%',
    },
  })
);
