import { IconButton } from '@material-ui/core';
import { useGlobalMouseClick } from '@nask/hooks';
import React from 'react';
import { Cofnij079Icon } from '@nask/ezdrp-icons';

import SnackbarItem, {
  SnackbarItemProps,
} from '../snackbar-item/hooks/snackbar-item';
import { TRANSITION_DELAY, TRANSITION_DOWN_DURATION } from '../utils/constants';
import { SnackbarContext } from './snackbar-context';
import { useStyles } from './snackbar-provider.styles';

type SnackbarProviderProps = Partial<SnackbarItemProps> & {
  maxSnacks: number;
  onClickAction?: () => void;
};
export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const [snacks, setSnacks] = React.useState([]);
  const [queue, setQueue] = React.useState([]);
  const enterDelay = TRANSITION_DELAY + TRANSITION_DOWN_DURATION + 40;

  const classes = useStyles();

  useGlobalMouseClick(
    (e) => {
      if (snacks.length > 0) handleCloseSnack(snacks[0].key);
    },
    [snacks]
  );
  React.useEffect(() => {
    handleDisplaySnack();
  }, [queue]);

  React.useEffect(() => {
    setTimeout(handleDisplaySnack, enterDelay);
  }, [snacks]);

  /**
   * Adds a new snackbar to the queue to be presented.
   * @param {string} message - text of the notification
   * @param {object} options - additional options for the snackbar we want to enqueue.
   * We can pass Material-ui Snackbar props for individual customisation.
   * @param {string} options.variant - type of the snackbar. default value is 'default'.
   * can be: (default, success, error, warning, info)
   * @returns generated or user defined key referencing the new snackbar
   */
  const handleEnqueueSnackbar = (message, options) => {
    const tmpQueue = [...queue];
    tmpQueue.push({
      message,
      ...options,
      open: true,
      key: new Date().getTime(),
    });
    setQueue(tmpQueue);

    handleDisplaySnack();
  };

  /**
   * Display snack if there's space for it. Otherwise, immediately begin dismissing the
   * oldest message to start showing the new one.
   */
  const handleDisplaySnack = () => {
    if (snacks.length >= props.maxSnacks) {
      return handleDismissOldest();
    }
    return processQueue();
  };

  /**
   * Display items (notifications) in the queue if there's space for them.
   */
  const processQueue = () => {
    if (queue.length > 0) {
      const newOne = queue.shift();
      setSnacks((prevState) => [...prevState, newOne]);
    }
  };

  /**
   * Hide oldest snackbar on the screen because there exists a new one which we have to display.
   */
  const handleDismissOldest = () => {
    setSnacks((prevState) => [
      ...prevState
        .filter((item) => item.open === true)
        .map((item, i) => (i === 0 ? { ...item, open: false } : { ...item })),
    ]);
  };

  /**
   * Hide a snackbar after its timeout.
   * @param {object} event - The event source of the callback
   * @param {string} reason - can be timeout or clickaway
   * @param {number} key - id of the snackbar we want to hide
   */
  const handleCloseSnack = (key) => {
    setSnacks((prevState) =>
      prevState.map((item) =>
        item.key === key ? { ...item, open: false } : { ...item }
      )
    );
  };

  /**
   * Close snackbar with the given key
   * @param {number} key - id of the snackbar we want to hide
   */
  const handleDismissSnack = (key) => {
    handleCloseSnack(key);
  };

  /**
   * When we set open attribute of a snackbar to false (i.e. after we hide a snackbar),
   * it leaves the screen and immediately after leaving animation is done, method
   * gets called. We remove the hidden snackbar from state and then display notifications
   * waiting in the queue (if any).
   * @param {number} key - id of the snackbar we want to remove
   */
  const handleExitedSnack = (key) => {
    setSnacks((prevState) => [...prevState.filter((item) => item.key !== key)]);
  };

  const contextValue = React.useMemo(
    () => ({
      showSnackbar: handleEnqueueSnackbar,
      // hideSnackbar: handleDismissSnack
    }),
    []
  );
  const { maxSnacks, children, onClickAction, ...rest } = props;
  return (
    <SnackbarContext.Provider value={contextValue}>
      <React.Fragment>
        {children}
        {snacks.map((snack, index) => (
          <SnackbarItem
            {...rest}
            key={snack.key}
            level={index}
            action={
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                  label: classes.iconButtonLabel,
                }}
                onClick={(e) => handleCloseSnack(snack.key)}
              >
                <Cofnij079Icon className={classes.icon} />
              </IconButton>
            }
            snack={snack}
            onClose={(e) => handleCloseSnack(snack.key)}
            onExited={handleExitedSnack}
          />
        ))}
      </React.Fragment>
    </SnackbarContext.Provider>
  );
};
