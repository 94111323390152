import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TableRef } from '@archiwum/shared/table';
import { SelectModel, useApi, useObservableCallback } from '@nask/hooks';
import { debounceTime } from 'rxjs/operators';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  FolderArchiwum,
  TypProwadzenia,
  TypProwadzeniaLabelValue,
} from '../models/models';
import { useRefresh } from '@archiwum/actions-handler';
import { useDrawerRightDispatch } from '@archiwum/drawer-right';
import { getParams } from '../helper';
import { BrakowanieServiceQuery } from '../services/brakowanie-service-query';
import { useFiltering } from '@archiwum/hooks';
import {
  getCeilingDate,
  getFloorDate,
} from 'libs/shared/table/src/lib/hooks/methods';
import { useSnackbar } from '@archiwum/shared/toast-notification';
import { getErrorResponse } from '@archiwum/utility/helper';
import { SprawyFiltry } from '@archiwum/features/archiving-documents';

const tabs = [
  {
    label: 'Sprawy',
    value: 'sprawy',
  },
  {
    label: 'Niestanowiace Akt Sprawy',
    value: 'niestanowiace-akt-sprawy',
  },
];

export type SprawyPoczekalniaFilters = {
  komorkaOrganizacyjna: string | null;
  prowadzacy: string | null;
  symbolJRWA: string | null;
  kategoriaArchiwalna: string | null;
  typProwadzenia: TypProwadzenia | null;
  dataZakonczeniaOd: string | null;
  dataZakonczeniaDo: string | null;
  dataPrzyjeciaOd: string | null;
  dataPrzyjeciaDo: string | null;
};

const initialState: SprawyPoczekalniaFilters = {
  dataPrzyjeciaOd: null,
  dataPrzyjeciaDo: null,
  dataZakonczeniaOd: null,
  dataZakonczeniaDo: null,
  kategoriaArchiwalna: null,
  komorkaOrganizacyjna: null,
  prowadzacy: null,
  symbolJRWA: null,
  typProwadzenia: null,
};

type UsePoczekalniaProps = {
  foldery: FolderArchiwum[];
};

export const useWytypowaneDoBrakowaniaFacade = ({
  foldery,
}: UsePoczekalniaProps) => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef<TableRef>();
  const [showSnackbar] = useSnackbar();
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [isSkipContainedInIndices, setIsSkipContainedInIndices] = useState(
    true
  );
  const [filtersData, setFiltersData] = useState<SprawyFiltry>();
  const dispatch = useDrawerRightDispatch();
  const search = location.search;
  const rodzaj = new URLSearchParams(search).get('rodzaj');
  const isSprawy = !rodzaj ? true : rodzaj?.toLowerCase() === 'sprawy';
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { setUrl } = useApi({
    onSuccess: (data) => {
      if (data) setFiltersData((data as unknown) as SprawyFiltry);
    },
    onError: (error) => {
      showSnackbar(getErrorResponse(error), { variant: 'error' });
    },
  });

  const { state, setState } = useFiltering<SprawyPoczekalniaFilters>({
    initialState: initialState,
  });

  const { value: searchValue, onChange } = useObservableCallback<string>(
    (subject) => subject.pipe(debounceTime(400))
  );

  const [activeTab, setActiveTab] = useState(() => {
    const rodzaj = getParams(location.search);
    return (rodzaj as string) ?? tabs[0].value;
  });

  const handleRefresh = () => {
    tableRef.current?.onQueryChange?.();
    dispatch({ selected: [], data: [] });
  };

  useRefresh({
    handleRefresh: () => {
      tableRef.current?.onQueryChange?.();
      dispatch({ selected: [], data: [] });
    },
  });

  useEffect(() => {
    const rodzaj = getParams(location.search);
    if (rodzaj) {
      setActiveTab(rodzaj as string);
    }
  }, [location.search]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    setState(initialState);
    setIsShowAll(false);
    setIsSkipContainedInIndices(true);
  }, [activeTab]);

  useEffect(() => {
    tableRef.current?.onQueryChange?.();
    tableRef.current?.onSelectionChange?.(null);
  }, [searchValue, state, isShowAll, isSkipContainedInIndices]);

  useEffect(() => {
    handleUpdateFilters();
  }, [isShowAll, isSkipContainedInIndices]);

  const handleChangeTab = (event: ChangeEvent<unknown>, value: string) => {
    history.push({ search: `?rodzaj=${value}` });
    setActiveTab(value);
  };

  const handleUpdateDate = (date: MaterialUiPickersDate, name: string) => {
    if (date instanceof Date && !isNaN(date as any)) {
      setState({
        [name]: name.endsWith('Do') ? getCeilingDate(date) : getFloorDate(date),
      });
    } else
      setState({
        [name]: date,
      });
  };

  const handleUpdateSelect = (
    name: string,
    value?: SelectModel | TypProwadzeniaLabelValue
  ) => {
    setState({
      [name]: value?.value,
    });
  };

  const handleChangeShowAll = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowAll(event.target.checked);
  };

  const handleChangeSkipContainedInIndices = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsSkipContainedInIndices(event.target.checked);
  };

  const handleUpdateFilters = () => {
    setUrl(
      isSprawy
        ? BrakowanieServiceQuery.GetSprawyFiltry({
            foldery,
            czyWszystkie: isShowAll,
            pominBedaceWSpisach: isSkipContainedInIndices,
          })
        : BrakowanieServiceQuery.GetPismaNASFiltry({
            foldery,
            czyWszystkie: isShowAll,
            pominBedaceWSpisach: isSkipContainedInIndices,
          })
    );
  };

  const komorkaOrganizacyjnaOptions =
    filtersData?.komorkiOrganizacyjne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const prowadzacyOptions =
    filtersData?.prowadzacy?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const symboleJRWAOptions =
    filtersData?.symboleJRWA?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const katArchiwalnaOptions =
    filtersData?.kategorieArchiwalne?.dostepneWartosci?.map((item) => ({
      value: item,
      label: item,
    })) ?? [];
  const typProwadzeniaOptions =
    filtersData?.typProwadzenia?.dostepneWartosci ?? [];

  return {
    data: filtersData,
    state,
    tableRef,
    komorkaOrganizacyjnaOptions,
    searchValue,
    prowadzacyOptions,
    symboleJRWAOptions,
    katArchiwalnaOptions,
    typProwadzeniaOptions,
    handleUpdateDate,
    handleUpdateSelect,
    handleChangeTab,
    tabs,
    activeTab,
    onChange,
    isShowAll,
    handleChangeShowAll,
    isSkipContainedInIndices,
    handleChangeSkipContainedInIndices,
    isSprawy,
    open,
    handleOpen,
    handleClose,
    handleRefresh,
  };
};
