import { Box, Container, Paper } from '@material-ui/core';
import { useSelectedDispatch } from '@archiwum/hooks';
import { SearchInput } from '@archiwum/shared/search-input';
import { DrawerRight } from '@archiwum/drawer-right';
import { NavigationBreadcrumbs } from '@archiwum/navigation-breadcrumbs';

import { getListaSpisow } from '../helper';
import { useSpisyFacade } from './use-spisy-facade';
import { useStyles } from './spisy.styles';
import { SpisyTable } from './spisy-table';
import { ChronologicalCompositionsLayout } from '../components/chronological-compositions-layout';

export function Spisy() {
  const { tableRef, onChange, searchValue } = useSpisyFacade();
  const classes = useStyles();
  const dispatch = useSelectedDispatch();
  return (
    <ChronologicalCompositionsLayout>
      <Box width="100%">
        <NavigationBreadcrumbs />
        <Container maxWidth={'xl'}>
          {/* <Box pb={3}>
            <Paper classes={{ root: classes.paper }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Box pb={3} pt={3} pr={4} pl={4}>
                  <SearchInput
                    searchMethod={(phrase: string) => {
                      onChange(phrase);
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box> */}

          <Paper classes={{ root: classes.paper }}>
            <SpisyTable
              tableRef={tableRef}
              data={(query, result) => {
                getListaSpisow(query, result, searchValue, dispatch);
              }}
            />
          </Paper>
        </Container>
      </Box>

      <DrawerRight />
    </ChronologicalCompositionsLayout>
  );
}

export default Spisy;
