import styled from 'styled-components';
import { theme } from '@nask/theme';

export const InputContainer = styled.div`
  padding-top: 5px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.gutterHorizontal};
  justify-content: flex-end;
  grid-auto-flow: column;
`;

export const InputLabel = styled.label`
  margin-bottom: 0;
`;

export const StyledInput = styled.input<{ error?: string }>`
  display: inline-flex;
  width: 100%;
  line-height: 40px;
  height: 40px;
  background-color: white;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.general.borderRadius};
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.borderColor};
  padding: 10px 12px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
`;

export const ErrorContainer = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: ${(props) => props.theme.typography.paragraphFontSize};
`;
