import { interval } from 'rxjs';

export const brakowanieCountDown$ = interval(1000);

import moment from 'moment';

export const getRemainingTime = (countTo: string[] | string) => {
  const now = moment(new Date());
  if (Array.isArray(countTo))
    return countTo.map((x) =>
      formatRemainingTime(moment(x).diff(now, 'seconds'))
    );
  return [formatRemainingTime(moment(countTo).diff(now, 'seconds'))];
};

const formatRemainingTime = (x: number) => {
  if (x > 3600 * 8) return `ponad ${Math.floor(x / 3600)} h`;
  if (x > 0) {
    const sTime = moment
      .utc(moment.duration(x, 'seconds').asMilliseconds())
      .format('HH:mm:ss');
    return `${sTime}`;
  }
  return '--:--:--';
};
